import React,{ Component } from "react";

export class RecommendedServiceList extends Component{
    render(){
        return(
            <div className="recommended-services-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <h3>Recommended for you</h3>  
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <a href="#" className="pull-right view-all">View all</a>  
                  </div>
                </div>
              </div>
              <div className="row">
              {this.props.recomendedServices && this.props.recomendedServices.map((item,index)=>{
                      return <ServiceItem 
                      name={item.name}
                      descriptionTitle={item.descriptionTitle}
                      description={item.description}
                      reviews={item.reviews}
                       />
                  }) } 
              </div>
            </div>
          </div>
        )
    }
}

function ServiceItem(props){
    return <div className="col-xs-12 col-sm-3">
    <div className="services-items">
      <div className="services-image">
        <img src={require('../../../../img/services-img-5.jpg')} alt="Service" />
        <div className="service-logo-sec">
          <div className="table-method">
            <a href="#">
              <img src={require('../../../../img/icon/company-logo-5.png')}  alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="services-content">
        <div className="time-sec">
          <a href="#"><small>{props.name}</small></a>  
        </div>
        <h4><a href="#">{props.descriptionTitle}</a></h4>
        <p>{props.description}</p>
        <ul className="feedback-star">
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><span className="total-feedback">{props.reviews} Reviews</span></a></li>
        </ul>
      </div>
    </div>
  </div>
}