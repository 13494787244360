export const GetCountriesCommon = 'GET_COUNTRIES_COMMON';
export const GetStateByCountryCommon = 'GET_STATE_BY_COUNTRY_COMMON';
export const GetCityByStateCommon = 'GET_CITY_BY_STATE_COMMON';
export const GetServiceTypeCommon = 'GET_SERVICE_TYPE_COMMON';
export const GetProfileDataCommon = 'GET_PROFILE_DATA_COMMON';
export const GetAddressDataCommon = 'GET_ADDRESS_DATA-_COMMON';
export const GetServiceTypes = 'GET_SERVICE_TYPES_LANDING_PAGE';
export const GetServiceDurations = 'GET_SERVICE_DURATION_LANDING_PAGE';

export function GetCountriesAction(countries) {
    return {
        type: GetCountriesCommon,
        payload: {
            countries
        }
    }
}

export function GetServiceDurationsAction(data) {
    return {
        type: GetServiceDurations,
        payload: {
            data
        }
    }
}

export function GetServiceTypesAction(data) {
    return {
        type: GetServiceTypes,
        payload: {
            data
        }
    }
}


export function GetStateByCountryAction(states) {
    return {
        type: GetStateByCountryCommon,
        payload: {
            states
        }
    }
}

export function GetCityByStateAction(cities) {
    return {
        type: GetCityByStateCommon,
        payload: {
            cities
        }
    }
}

export function GetAddressDataCommonAction(countries, states, cities) {
    return {
        type: GetAddressDataCommon,
        payload: {
            countries, states, cities
        }
    }
}

export function GetServiceTypeCommonAction(services) {
    return {
        type: GetServiceTypeCommon,
        payload: {
            services
        }
    }
}

export function GetProfileDataCommonAction(data) {
    return {
        type: GetProfileDataCommon,
        payload: {
            data
        }
    }
}