
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { resetPassword } from "./../ApiCall/apiCalls"
import { Link } from "react-router-dom";
import loader from './../../../img/Spinner-1s-200px.gif';
import externalFileLoad from  '../../externalfileLoad';
class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword:'',
            token :'',
            message:'',
            messageType:'',
            messageFor :'',
            serverValidation:{}
        };

    }
    componentDidMount() {
        externalFileLoad();
        var urlParams = new URLSearchParams(window.location.search);

        if(urlParams.has('auth_token') && urlParams.get('auth_token') !== ''){
            this.setState({
                token : urlParams.get('auth_token')
            })
        }else{
            // this.props.history.push('/login')
        }
    }
    
    componentWillReceiveProps(nextProps) {
       if (nextProps.apiCallStatus.apiCallFor === 'resetPassword' &&
        nextProps.apiCallStatus.isCompleted === true &&
         nextProps.apiCallStatus.isFailed === false) {
            this.setState({
                email: '',
                message:nextProps.apiCallStatus.message,
                messageType:'success'
            })
        }
        if (nextProps.apiCallStatus.apiCallFor === 'resetPassword' &&
        nextProps.apiCallStatus.isCompleted === true &&
        nextProps.apiCallStatus.isFailed === true) {
        this.setState({
          serverValidation: nextProps.apiCallStatus.message
        })
        // this.props.history.push("/healer-services-list");
      }
    }
    handleChange = (e) => {
        e.preventDefault();
        var id = e.target.id;
        var val = e.target.value;
        this.setState({
            [id]: val,
            message:'',
            messageType:'',
            messageFor :'',
            serverValidation:{}
        })
    }
    handleSubmit = (event) => {
        var isEmpty = this.isEmptyFields();
        if (isEmpty === true) {
            this.props.resetPassword(this.state.token,this.state.password,this.state.confirmPassword);
            this.setState({
                message:"",
                messageType:"",
                messageFor :''
            })
        } 

        event.preventDefault();
    }
    isEmptyFields = () => {
        if (this.state.password === '') {
            this.setState({ 
                message:'Password is empty',
                messageType:'danger',
                messageFor :'password'
             });
            return false;
        }else if (this.state.confirmPassword === '') {
            this.setState({ 
                message:'Confirm Password is empty',
                messageType:'danger',
                messageFor :'confirmPassword'
             });
            return false;
        }if (this.state.password.length < 5) {
            this.setState({ 
                message:'Password length must be greater than 6',
                messageType:'danger',
                messageFor :'password'
             });
            return false;
        }else if (this.state.confirmPassword.length < 5) {
            this.setState({ 
                message:'Confirm Password length must be greater than 6',
                messageType:'danger',
                messageFor :'confirmPassword'
             });
            return false;
        }else if (this.state.confirmPassword !== this.state.password) {
            this.setState({ 
                message:'Your password and confirmation password do not match',
                messageType:'danger',
                messageFor :'confirmPassword'
             });
            return false;
        }
        else {
            return true
        }
    }
    render() {
        return (
            <div className="login-sec">
            <div className="container">
              <div className="login2-panel">
                <div className="login-holder">
                  <img src={require("./../../../img/login-logo.jpg")} alt="logo" />
                </div>
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Password</label>
                    <input type="password" value={this.state.password} 
                     onChange={(e) => this.handleChange(e)} className="form-control" id="password"/>
                     {this.state.message !== "" && this.state.messageType === "danger" &&
                     this.state.messageFor === 'password' ?
                    <div className="alert alert-danger">
                    {this.state.message}
                    </div>
                    : ""}  
                     {this.state.serverValidation &&
                              this.state.serverValidation.password &&
                              this.state.serverValidation.password[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.password[0]}
                              </div>
                              : ""}
                  </div>
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Retype Password</label>
                    <input type="password" value={this.state.confirmPassword} 
                     onChange={(e) => this.handleChange(e)} className="form-control" id="confirmPassword"/>
                     {this.state.message !== "" && this.state.messageType === "danger" &&
                     this.state.messageFor === 'confirmPassword' ?
                    <div className="alert alert-danger">
                    {this.state.message}
                    </div>
                    : ""}
                     {this.state.serverValidation &&
                              this.state.serverValidation.password_confirmation &&
                              this.state.serverValidation.password_confirmation[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.password_confirmation[0]}
                              </div>
                              : ""}
                  </div>
                                                   
                  {this.state.message !== "" && this.state.messageType === "success" ?
                      <div className="alert alert-success">
                          {this.state.message}
                      </div>
                      : ""}
                  {this.props.apiCallStatus.apiCallFor === 'resetPassword' &&
                   this.props.apiCallStatus.isCompleted === false &&
                    this.props.apiCallStatus.isFailed === false ?
                      <div className="text-center">
                          <img className="logo"
                                  style={{ width: "83px", display: 'inline' }}
                                 src={loader} />
                            </div>
                              : ""}
                    {this.props.apiCallStatus.apiCallFor === 'resetPassword' 
                                   && this.props.apiCallStatus.isCompleted === true &&
                                   this.props.apiCallStatus.isFailed === true &&
                       ((typeof this.props.apiCallStatus.message) === 'string')?
                                      <div className = "alert alert-danger" > {
                                          this.props.apiCallStatus.message
                                      } </div> :
                                      ""
                              }
                  <div className="submit-btn text-center">
                    <button type="submit"  onClick={(e) => this.handleSubmit(e)} 
                    className="btn btn-primary btn-raised">Reset Now</button>
                  </div>
                </form>
              </div>
              <div className="login-now text-center">
                <p>Dont have any Account? <Link to="/sign-up">Sign Up</Link></p>
              </div>
            </div>
          </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    resetPassword: (token,password,confirmPassword) => dispatch(resetPassword(token,password,confirmPassword))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword)