import React, { Component } from "react";
import { connect } from 'react-redux';
import { uploadDocument, addService ,addHealerAvaliablity, getHealerAvaliablity } from "../ApiCalls/apicalls";
import { getCookie } from "../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import loader from './../../../../img/Spinner-1s-200px.gif';
import externalFileLoad from '../../../externalfileLoad';
import { Constant } from "../../../../Constants/constant";
const cloneDeep = require('clone-deep');

class AddAvaliability extends Component {
  constructor() {
    super();
    this.state = {
      rawAvaliablityData : "",
      weekDays : [{ "id": 1,"day_of_week": "1","name":"M","value":"1","active":0},{"id": 2,"day_of_week": "2","name":"T","value":"2","active":0},{"id": 3,"day_of_week": "3","name":"W","value":"3","active":0},{"id": 4,"day_of_week": "4","name":"Th","value":"4","active":0}, {"id": 5,"day_of_week": "5","name":"F","value":"5","active":0}, {"id": 6,"day_of_week": "6","name":"S","value":"6","active":0},{"id": 7,"day_of_week": "7","name":"Su","value":"7","active":0},{"id": "0","day_of_week": "","name":"All","value":"0","active":0}],
      selectedDays: [],
      blockDateFrom: "",
      blockDateTo: "",
      avaliablityTImeFrom: "1",
      avaliablityTImeTo: "1",
      avaliablityTImeFromAmPm: "AM",
      avaliablityTImeToAmPm: "AM",
      errorMsg: "",
      errorType: "",
      errorState: "",
      initAvaliablitySlot: {
        id: "",
        from: {
          "hr": "9",
          "minutes": "00",
          "dayNight": "AM"
        },
        to: {
          "hr": "9",
          "minutes": "30",
          "dayNight": "AM"
        }
      },
      avaliablitySlotsLength: [{
        id:"",
        from :{
          "hr" : "9",
          "minutes" : "00",
          "dayNight": "AM"
        },
        to: {
          "hr": "9",
          "minutes": "30",
          "dayNight": "AM"
        }
      }],
      blockTimeSlot : [{
        id: "",
        blockFrom : "",
        blockTo : ""
      }],
      appointmentTime: "30",
      reservationTime: "30",
      hours: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
      minutes: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60"],
      commuteTime: ["05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55", "60"]
    }
  }
  componentDidMount() {
    externalFileLoad();

    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_id');
    let userRole = getCookie('user_Role');
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (token === '') {
      this.props.history.push('/login')
    } else {
      if (hasUnacceptedAgreements === '1') {
        this.props.history.push('/verify-service-document')
      }
    }

    this.addBlockSlots();
    this.props.getHealerAvaliablity(getCookie("token"),this.props.match.params.id);
  }

  addBlockSlots = () => {
    var self = this;
    if (this.state.blockTimeSlot && this.state.blockTimeSlot.length > 0) {
      for (let i = 0; i < this.state.blockTimeSlot.length; i++) {
        window.jQuery("#blockDateFrom-" + i).datepicker({
          dateFormat: 'yy-mm-dd'
        });
        window.jQuery("#blockDateTo-" + i).datepicker({
          dateFormat: 'yy-mm-dd'
        });
        window.jQuery("#blockDateFrom-" + i).on('change', function (e) {

          if ((e.target.name == "blockDateFrom-" + i) && (e.target.name == "blockDateFrom-" + i)) {
            let blockTimeSlot = cloneDeep(self.state.blockTimeSlot);
            blockTimeSlot[i].blockFrom = e.target.value;
           self.setState({
              blockTimeSlot: blockTimeSlot
            })
          }
        })
        window.jQuery("#blockDateTo-" + i).on('change', function (e) {

          if ((e.target.name == "blockDateTo-" + i) && (e.target.name == "blockDateTo-" + i)) {
            let blockTimeSlot = cloneDeep(self.state.blockTimeSlot);

            blockTimeSlot[i].blockTo = e.target.value
            self.setState({
              blockTimeSlot: blockTimeSlot
            })
          }
        })
      }

    }
  }
  componentWillReceiveProps(nextProps) {
    console.log("nextProps",nextProps)
    if(nextProps.apiCallStatus.apiCallFor == "addHealerAvalaiablity" && nextProps.apiCallStatus.isCompleted == true  && nextProps.apiCallStatus.isFailed == false){
      this.props.history.push("/healer-services-list")
    }
    if(nextProps.apiCallStatus.apiCallFor == "geAvalaiablity" && nextProps.apiCallStatus.isCompleted == true  && nextProps.apiCallStatus.isFailed == false){
      
      if(nextProps.apiCallStatus.response){
        let res = nextProps.apiCallStatus.response;
      this.setState({
        rawAvaliablityData : res
      })
      let arr = [];
      if(res.service_days && res.service_days.length > 0){
        for(let i=0; i < res.service_days.length;i++){
          if(res.service_days[i].active == 1){
            let selectedDays = cloneDeep(this.state.selectedDays);
            selectedDays.push(res.service_days[i])
            this.setState({
              selectedDays: selectedDays
            })
          }
          arr.push({ "id": res.service_days[i].id,"day_of_week":  res.service_days[i].day_of_week,"name": res.service_days[i].short_name,"value": res.service_days[i].id,"active": res.service_days[i].active})
        }
      }
      let selectedSlots = [];
      if(res.availability_slots && res.availability_slots.length > 0){
        for(let i=0; i < res.availability_slots.length;i++){
          selectedSlots.push({
            id:res.availability_slots[i].id, 
            from :{
              "hr" : res.availability_slots[i].from.hr,
              "minutes" : res.availability_slots[i].from.minutes,
              "dayNight": res.availability_slots[i].from.dayNight
            },
            to : {
              "hr" : res.availability_slots[i].to.hr,
              "minutes" : res.availability_slots[i].to.minutes,
              "dayNight": res.availability_slots[i].to.dayNight
            }
          })
        }
      }
      let blockSlot = []
      if(res.blocked_slots && res.blocked_slots.length > 0){
        for(let i=0; i < res.blocked_slots.length;i++){
          blockSlot.push({
              "id" : res.blocked_slots[i].id,
              "blockFrom" : res.blocked_slots[i].blockFrom,
              "blockTo" : res.blocked_slots[i].blockTo
            
          })
        }
      }
      
      let reservation_time =   res.advance_prepration_time != null ? res.advance_prepration_time.toString() : "";
      let commute_time = res.commute_time != null ? res.commute_time.toString() : "";
      if(arr && arr.length > 0){
        arr.push({"id": "0","day_of_week": "","name":"All","value":"0","active":0})
      }
      this.setState({
        weekDays: arr && arr.length > 0 ? arr : this.state.weekDays,
        avaliablitySlotsLength:selectedSlots && selectedSlots.length > 0 ? selectedSlots : this.state.avaliablitySlotsLength,
        blockTimeSlot : blockSlot && blockSlot.length > 0 ? blockSlot : this.state.blockTimeSlot,
        reservationTime : reservation_time,
        appointmentTime: commute_time
      }, () => {
        console.log("this.state.avaliable",this.state.avaliablitySlotsLength)
      })

      }

    }
  }
  redirectToBack = () => {
    this.props.history.goBack();
  }
  validation = () => {
    if (this.state.selectedDays && this.state.selectedDays.length > 0) {
      return true;
    } else {
      this.setState({
        errorMsg: "Please check atleast one day",
        errorType: "danger",
        errorState: "selectedDays"
      })
    }
  }
  handleSubmit = () => {

    let isValid = this.validation();
    if (isValid) {
      let selectedDays = cloneDeep(this.state.selectedDays)
      let findIndex = selectedDays.findIndex(s => s.name == "All");
      if (findIndex > -1) {
        selectedDays.splice(findIndex, 1);
        this.setState({
          selectedDays: selectedDays
        }, () => {
          this.props.addHealerAvaliablity({
            "blocked_slots": this.state.blockTimeSlot,
            "commute_time": this.state.appointmentTime, // commute time
            "advance_prepration_time": this.state.reservationTime,
            "service_days": this.state.selectedDays,
            "availability_slots": this.state.avaliablitySlotsLength,
            'service_id': this.props.match.params.id,
            'auth_token': getCookie("token")
          })
        })
      } else {
        this.props.addHealerAvaliablity({
          "blocked_slots": this.state.blockTimeSlot,
          "commute_time": this.state.appointmentTime, // commute time
          "advance_prepration_time": this.state.reservationTime,
          "service_days": this.state.selectedDays,
          "availability_slots": this.state.avaliablitySlotsLength,
          'service_id': this.props.match.params.id,
          'auth_token': getCookie("token")
        })
      }

      // this.props.history.push('/healer-location')
    }

  }
  addAvaliableDays = (e) => {
    if (e && e.target && e.target.value) {
      let daysAvaliablity = [];
      let weeksDay = [...this.state.weekDays];
      let findDayIndex = weeksDay.findIndex((item) => {
        if (e.target.value == item.value) {
          return true;
        } else {
          return false;
        }

      });
      if (this.state.selectedDays && this.state.selectedDays.length > 0) {

        daysAvaliablity = [...this.state.selectedDays];

        let findIndex = this.state.selectedDays.findIndex((item) => {
          if (e.target.value == item.id) {
            return true;
          } else {
            return false;
          }

        });
        if (findIndex > -1) {
          if (e.target.value == "0") {
            for (let i = 0; i < weeksDay.length; i++) {
              weeksDay[i].active = 0;
            }
            this.setState({
              selectedDays: []
            }, () => {
            })
          } else {
            weeksDay[findDayIndex].active = 0;
            daysAvaliablity.splice(findIndex, 1);
            let allDayIndex = weeksDay.findIndex((item) => {
              if ("0" == item.value) {
                return true;
              } else {
                return false;
              }

            });
            let allDayIndex1 = daysAvaliablity.findIndex((item) => {
              if ("0" == item) {
                return true;
              }

            });
            weeksDay[allDayIndex].active = 0;
            if (allDayIndex1 > -1) {
              daysAvaliablity.splice(allDayIndex1, 1);
            }

            this.setState({
              selectedDays: daysAvaliablity
            }, () => {
            })
          }
        } else {
          if (e.target.value == "0") {
            let days = [];
            for (let i = 0; i < weeksDay.length; i++) {
              weeksDay[i].active = 1;
              days.push(weeksDay[i]);
            }
            daysAvaliablity = [];
            daysAvaliablity = days;
            this.setState({
              selectedDays: days
            }, () => {
            })
          } else {
            let days = [...this.state.weekDays];
            days.splice(days.length - 1, 1)


            weeksDay[findDayIndex].active = 1;
            daysAvaliablity.push(weeksDay[findDayIndex]);
            this.setState({
              selectedDays: daysAvaliablity
            }, () => {
              let checkDayStatus = days.find((day) => day.active == 0);

              if (checkDayStatus == undefined) {
                let dayAllIndex = this.state.weekDays.findIndex((day) => {
                  if (day.value == "0") {
                    return true;
                  }
                  else {
                    return false;
                  }
                });

                if (dayAllIndex > -1) {
                  weeksDay[dayAllIndex].active = 1;
                  daysAvaliablity.push(weeksDay[dayAllIndex]);
                  this.setState({
                    selectedDays: daysAvaliablity
                  }, () => {
                  })
                }
              }
            })


          }

        }
      } else {
        let index = weeksDay.findIndex(item => item.id == e.target.value)
        daysAvaliablity.push(weeksDay[index]);
        let days = [];
        if (e.target.value == "0") {
          for (let i = 0; i < weeksDay.length; i++) {
            weeksDay[i].active = 1;
            days.push(weeksDay[i]);
          }
          this.setState({
            selectedDays: days
          }, () => {
          })
        } else {
          weeksDay[findDayIndex].active = 1;
          this.setState({
            selectedDays: daysAvaliablity
          }, () => {
          })
        }

      }
    }

    let checkDayStatus = this.state.weekDays.find((day) => day.active == 1);
    if (checkDayStatus) {
      this.setState({
        errorMsg: "",
        errorType: "",
        errorState: ""
      })
    }
  }
  removeBlockDate = (slot,idx,id) => {
    // if(id != ""){
      
    // }
    // e.preventDefault()
    // this.setState({
    //   blockDateFrom: "",
    //   blockDateTo: ""
    // })
  }
  addTimeSlotSection = (e) => {
    e.preventDefault();
    let avaliablitySlots = cloneDeep(this.state.avaliablitySlotsLength);

    avaliablitySlots.push(this.state.initAvaliablitySlot);
    this.setState({
      avaliablitySlotsLength: avaliablitySlots
    }, () => {

    })
  }
  removeTimeSlotSection = (e,index,id) => {
    e.preventDefault();
    
    let self = this;
    if(this.state.rawAvaliablityData.availability_slots && this.state.rawAvaliablityData.availability_slots.length > 0){
     let elIndex = this.state.avaliablitySlotsLength.findIndex(item => item.id == id);
     if(elIndex > -1){
      fetch(Constant.apiURl + "remove-availablility-slot", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          auth_token: getCookie("token"),
          id: id
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(myJson) {
          
          if (myJson.success === "1") {
            let avaliableSlots = cloneDeep(self.state.avaliablitySlotsLength);
            avaliableSlots.splice(elIndex,1)
            self.setState({
              avaliablitySlotsLength: avaliableSlots
            })
          } else {
            console.log("error")
          }
        })
        .catch(error => {
          console.log("error",error)
        });
     }
     else{
      let avaliablitySlots = [...this.state.avaliablitySlotsLength];
      avaliablitySlots.splice(index,1);
      this.setState({
        avaliablitySlotsLength : avaliablitySlots
      })
     }
    }else{
      let avaliablitySlots = [...this.state.avaliablitySlotsLength];
      avaliablitySlots.splice(index,1);
      this.setState({
        avaliablitySlotsLength : avaliablitySlots
      })
    }

  }
  removeBlockSlot = (e,index,id) => {
    e.preventDefault();
    let self = this;
    let blockTimeSlot  = cloneDeep(this.state.blockTimeSlot)
    if(id !=""){
     let slotIndex = blockTimeSlot.findIndex(item => item.id == id);
     if(slotIndex > -1){

      fetch(Constant.apiURl + "remove-blocked-slot", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          auth_token : getCookie("token"),
          id:id
        })
      })
        .then(function(response) {
          return response.json();
        })
        .then(function(myJson) {
          if (myJson.success === '1') {
            blockTimeSlot.splice(slotIndex,1);
            self.setState({
              blockTimeSlot : blockTimeSlot
            })
        } else {

        }
        })
        .catch(error => {
        });
     }
    }else{
      if(blockTimeSlot && blockTimeSlot.length > 1){
        blockTimeSlot.splice(index,1);
        this.setState({
          blockTimeSlot: blockTimeSlot
        })
      }
    }


  }
  addBlockTime = (e) => {
    e.preventDefault();
    let blockTimeSlot = cloneDeep(this.state.blockTimeSlot)

    blockTimeSlot.push({
      blockFrom: "",
      blockTo: ""
    })
    this.setState({
      blockTimeSlot: blockTimeSlot
    }, () => {
      this.addBlockSlots();
    })
    // if((this.state.blockDateFrom  == "") || (this.state.blockDateTo == "") ) {

    //     this.setState({
    //       errorMsg : "Please add complete block time slot",
    //       errorType : "danger",
    //       errorState: "blockTime"
    //     }, () => {
    //     })
    // }else{
    //   var blockTimeSlot = this.state.blockTimeSlot;
    //   blockTimeSlot.blockFrom = this.state.blockDateFrom;
    //   blockTimeSlot.blockTo = this.state.blockDateTo;
    //   this.setState({
    //     blockTimeSlot : blockTimeSlot
    //   }, () => {
    //   })
    // }
  }
  addHoursFrom = (e,i) => {
    
    let avaliablitySlot = [...this.state.avaliablitySlotsLength];
    avaliablitySlot[i].from.hr = e.target.value;
    this.setState({
      avaliablitySlotsLength: avaliablitySlot
    }, () => {
    })
  }
  addMinuteFrom = (e, i) => {
    let avaliablitySlot = [...this.state.avaliablitySlotsLength];
    avaliablitySlot[i].from.minutes = e.target.value;
    this.setState({
      avaliablitySlotsLength: avaliablitySlot
    }, () => {
    })
  }
  addFromDayNight = (e, i) => {
    let avaliablitySlot = [...this.state.avaliablitySlotsLength];
    avaliablitySlot[i].from.dayNight = e.target.value;
    this.setState({
      avaliablitySlotsLength: avaliablitySlot
    }, () => {
    })
  }
  addHoursTo = (e, i) => {
    let avaliablitySlot = [...this.state.avaliablitySlotsLength];
    avaliablitySlot[i].to.hr = e.target.value;
    this.setState({
      avaliablitySlotsLength: avaliablitySlot
    }, () => {
    })
  }
  addMinuteTo = (e, i) => {
    let avaliablitySlot = [...this.state.avaliablitySlotsLength];
    avaliablitySlot[i].to.minutes = e.target.value;
    this.setState({
      avaliablitySlotsLength: avaliablitySlot
    }, () => {
    })
  }
  addToDayNight = (e, i) => {
    let avaliablitySlot = [...this.state.avaliablitySlotsLength];
    avaliablitySlot[i].to.dayNight = e.target.value;
    this.setState({
      avaliablitySlotsLength: avaliablitySlot
    }, () => {
    })
  }
  render() {
    return (
      <main>
        <div className="card-sec services-pages">
          <div className="container">
            <strong>Tell us your availibility for “Body Massage” at Nicole’s Yoga World </strong>
            <div className="Card-panel">
              <form>
                <div className="booking-date-form">
                  <div className="row mb-30">
                    <div className="col-xs-12 col-sm-1">
                      <label className="font-pink"> Days</label>
                    </div>
                    <div className="col-xs-12 col-sm-11">
                      {this.state.weekDays && this.state.weekDays.length > 0 ? this.state.weekDays.map((day, index) => {
                        return <div key={index} className="checkbox form-check-inline">
                          <label>
                            <input type="checkbox" value={day.value} onChange={(e) => this.addAvaliableDays(e)} checked={day.active} id={index} name={day.value} /> {day.name}
                          </label>
                        </div>
                      }) : ""}

                    </div>
                    {this.state.errorMsg != "" && this.state.errorState == "selectedDays" ? <div className="col-xs-12 col-sm-12">
                      <div className={"alert alert-" + this.state.errorType}>
                        {this.state.errorMsg}
                      </div>
                    </div> : ""}
                  </div>

                 
                    {this.state.avaliablitySlotsLength && this.state.avaliablitySlotsLength.length > 0 ? this.state.avaliablitySlotsLength.map( (slot,i) => {
                      let from = {};
                      from.hr = slot.from.hr;
                      from.minutes = slot.from.minutes;
                      from.dayNight = slot.from.dayNight;
                      let to = {};
                      to.hr = slot.to.hr;
                      to.minutes = slot.to.minutes;
                      to.dayNight = slot.to.dayNight;
                      return  <div key={i} className="row mb-19 d-flex align-items-center" id={i}> 
  
                    <div className="col-xs-12 col-sm-1">
                      <label className="mt-15">From:</label>
                    </div>
                    <div className="col-xs-12 col-sm-2">
                     
                      <select className="custom-select" onChange={(e) => this.addHoursFrom(e,i)}>
                        {this.state.hours.map( (hr,index) => {
                          return  <option value={hr} key={index} selected={hr == from.hr}>{hr}</option>
                        })}
                       
                      </select>
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className="form-inline">
                        <span class="bmd-form-group is-filled">
                          <select className="custom-select BR-0" onChange={(e) => this.addMinuteFrom(e,i)}>
                          {this.state.minutes.map( (min,index) => {
                            return  <option value={min} key={index} selected={min == from.minutes}>{min}</option>
                          })}
                          </select>
                        </span>
                        <span class="bmd-form-group is-filled">
                          <select className="custom-select" onChange={(e) => this.addFromDayNight(e,i)}>
                            <option value="AM" selected={"AM" == from.dayNight}>AM</option>
                            <option value="PM" selected={"PM" == from.dayNight}>PM</option>
                          </select>
                        </span>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-1">
                      <label className="mt-15">To:</label>
                    </div>
                    <div className="col-xs-12 col-sm-2">
                      <select className="custom-select" onChange={(e) => this.addHoursTo(e,i)}>
                      {this.state.hours.map( (hr,index) => {
                          return  <option value={hr} key={index} selected={hr == to.hr}>{hr}</option>
                        })}
                      </select>
                    </div>
                      <div className="col-xs-12 col-sm-4">
                        <div className="form-inline">
                          <span class="bmd-form-group is-filled">
                            <select className="custom-select BR-0" onChange={(e) => this.addMinuteTo(e, i)}>
                              {this.state.minutes.map((min, index) => {
                                return <option value={min} key={index} selected={min == to.minutes}>{min}</option>
                              })}
                            </select>
                          </span>
                          <span class="bmd-form-group is-filled">
                            <select className="custom-select" onChange={(e) => this.addToDayNight(e, i)}>
                              <option value="AM" selected={"AM" == to.dayNight}>AM</option>
                              <option value="PM" selected={"PM" == to.dayNight}>PM</option>
                            </select>
                          </span>
                        </div>
                      </div>
                      {/* {i == (this.state.avaliablitySlotsLength.length - 1) ? <div className="col-xs-12 col-sm-1">
                        <a href="" onClick={(e) => this.addTimeSlotSection(e)}><i className="fa fa-plus-circle"></i></a>
                      </div> : <div className="col-xs-12 col-sm-1">
                          <a href="" onClick={(e) => this.removeTimeSlotSection(e, i)}><i className="fa fa-minus-circle"></i></a>
                        </div>} */}


                   
                    {i == (this.state.avaliablitySlotsLength.length -1) ?  <div className="col-xs-12 col-sm-1">
                      <a href="" onClick={(e) => this.addTimeSlotSection(e)}><i className="fa fa-plus-circle"></i></a>
                    </div> :  <div className="col-xs-12 col-sm-1">
                      <a href="" onClick={(e) => this.removeTimeSlotSection(e,i,slot.id)}><i className="fa fa-minus-circle"></i></a>
                    </div>}
                   
                   
                    </div>
                    }) : ""}
   
                  <label className="font-pink font-18">Not available, or vacation time ? Block specific date rages on your calendar here:</label>
                  <div className="row mb-19 width-inherit d-flex align-items-center"> 
                    {this.state.blockTimeSlot && this.state.blockTimeSlot.length > 0 ? 
                    this.state.blockTimeSlot.map( (slot,idx) => {
                      return<div className="col-sm-9"><div class="row "> <div className="col-xs-12 col-sm-12">
                        <div className="row mt-2 d-flex align-items-center">
                              <div className="col-xs-12 col-sm-1">
                            <label className="mt-15">From:</label>
                          </div>
                          <div className="col-sm-4">
                            <div className="right-inner-addon date datepicker" data-date-format="yyyy-mm-dd">
                              <input name={"blockDateFrom-"+idx} value={slot.blockFrom} className={" form-control custom-form blockDateFrom-" + idx + " datepicker"} type="text" id={"blockDateFrom-"+ idx} autoComplete="none" placeholder="yyyy-mm-dd" />
                              <img src={require("../../../../img/cal-icon.png")} alt="icon" />
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-1">
                            <label className="mt-15">To:</label>
                          </div>
                          <div className="col-sm-4">
                            <div className="right-inner-addon date datepicker" data-date-format="yyyy-mm-dd">
                              <input name={"blockDateTo-"+idx}  value={slot.blockTo} className={" form-control custom-form datepicker blockDateTo-" + idx + " "} type="text" id={"blockDateTo-"+ idx} autoComplete="none" placeholder="yyyy-mm-dd" />
                              <img src={require("../../../../img/cal-icon.png")} alt="icon" />
                            </div>
                            {/* <a href="" onClick={(e) => this.removeBlockSlot(e, idx)}><i className="fa fa-minus-circle"></i></a> */}
                          </div>
                          <div className="col-sm-2">
                            <a href="" onClick={(e) => this.removeBlockSlot(e,idx,slot.id)}><i className="fa fa-minus-circle"></i></a>
                          </div>
                        </div>  
                      </div>
                      </div>
                      </div>

                    } ) : ""}
                    <div className="col-sm-3 mt-4">
                      <div className="block-date-sec">
                        <label>Your block date:</label>
                        {this.state.blockTimeSlot && this.state.blockTimeSlot.length > 0 ? this.state.blockTimeSlot.map( (slot,idx) =>{
                          return <p>{slot.blockFrom || slot.blockTo != "" ? <span>{slot.blockFrom } to {slot.blockTo} </span>:""}</p>
                        } ) : ""}
                        
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <a href="" onClick={(e) => this.addBlockTime(e)} className="btn btn-md btn-add-block">Add Block</a>
                    </div>
                    {this.state.errorState == "blockTime" && this.state.errorType != "" && this.state.errorMsg != ""
                      ? <div className="col-sm-12"><div className={"alert alert-" + this.state.errorType}>
                        {this.state.errorMsg}
                      </div> </div> : ""}


                    <div className="col-xs-12 col-sm-10">
                      <hr />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      <div className="row">
                        <div className="col-xs-12 col-sm-9">
                          <div className="adv-content-holder">
                            <strong>Advance Preparations:</strong>
                            <p>How much time do you need in between reservations to prepare?</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                            tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                            quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
                            consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse
                            cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non
                            proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-3">
                          <div className="why-need pull-right">
                            <a href="#"><i className="fa fa-question-circle"></i> Why is this needed</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-10">
                      <hr />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      <div className="row">
                        <div className="col-xs-12 col-sm-9">
                          <div className="adv-content-holder">
                            <div className="row mb-19">
                              <div className="col-xs-12 col-sm-3 col-md-2">
                                <select className="custom-select" onChange={ (e) => this.setState({
                                  reservationTime:e.target.value
                                })}>

                                  {this.state.commuteTime.map((time, index) => {
                                    return <option key={index} selected={this.state.reservationTime == time}>{time}</option>
                                  })}

                                </select>
                              </div>
                              <div className="col-xs-12 col-sm-1">
                                <label className="mt-15">min</label>
                              </div>
                            </div>
                           {this.props.location.state && this.props.location.state.serviceType == "homeBased" ? <div>
                           <strong>Commute time:</strong>
                            <p>ad amount of time you would like between appointment at client's home.</p>
                           </div> : " "} 
                          </div>
                        </div>
                        {this.props.location.state && this.props.location.state.serviceType == "homeBased" ?
                        <div className="col-xs-12 col-sm-3">
                          <div className="why-need pull-right">
                            <a href="#"><i className="fa fa-question-circle"></i> How does this Work</a>
                          </div>
                        </div>
                        : ""
                                }
                      </div>
                    </div>
                    {this.props.location.state && this.props.location.state.serviceType == "homeBased" ? <div className="col-xs-12 col-sm-12"> <div className="row">
                    <div className="col-xs-12 col-sm-10">
                      <hr />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      <div className="row">
                        <div className="col-xs-12 col-sm-9">
                          <div className="adv-content-holder">
                            <div className="row mb-19">
                              <div className="col-xs-12 col-sm-3 col-md-2">
                                <select className="custom-select"  onChange={ (e) => this.setState({
                                  appointmentTime:e.target.value
                                })}>
                                  {this.state.commuteTime.map((time, index) => {
                                    return <option key={index} selected={this.state.appointmentTime == time}>{time}</option>
                                  })}
                                </select>
                              </div>
                              <div className="col-xs-12 col-sm-1">
                                <label className="mt-15">min</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                      </div>
                      </div>
                      : ""
                                }
                  </div>
                </div>
              </form>
            </div>
            {this.props.apiCallStatus.apiCallFor === 'addHealerAvalaiablity'
              && this.props.apiCallStatus.isCompleted
              === false && this.props.apiCallStatus.isFailed === false ?
              <div className="text-center">
                <img className="logo" style={{ width: "83px", display: 'inline' }} src={loader} />
              </div>
              : ""}
              
            <div className="text-btn pb-50 pull-right">
              <button className="btn btn-md btn-pink ripple" onClick={this.redirectToBack}>Back</button>
              <button className="btn btn-md btn-save-submit ripple" onClick={(e) => this.handleSubmit(e)}>Save &amp; Continue</button>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({

  apiCallStatus: state.apiCallStatus,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  addHealerAvaliablity: (data) => dispatch(addHealerAvaliablity(data)),
  getHealerAvaliablity: (token,serviceid) => dispatch(getHealerAvaliablity(token,serviceid)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddAvaliability)