import * as Action from "./../Actions/actions";
import StoreState from "./../../../../Constants/initialState";

export default function serviceReducer(state = StoreState.services, action) {
  switch (action.type) {
    case Action.GetCountries:
      var s = state;
      s.countries = action.payload.countries;
      return s;

    case Action.GetStateByCountry:
      var s = state;
      s.state = action.payload.states;
      return s;

    case Action.GetCityByState:
      var s = state;
      s.cities = action.payload.cities;
      return s;

    case Action.GetServiceDetail:
      var s = state;
      s.serviceDetail = action.payload.serviceDetail;
      return s;

    case Action.removeServiceImage:
      var s = state;
      if (s.serviceDetail.images.length > 0) {
        let index = s.serviceDetail.images.findIndex(x => x.id === action.payload.imageId);
        if (index > -1) {
          s.serviceDetail.images[index] = []
        }
      }
      return s;

    default:
      return state;
  }
}
