export const LoginSuccessful = 'LOGIN_SUCCESSFUL';
export const LogOutSuccessful = 'LOGOUT_SUCCESSFUL';
export const pageNotFound = 'PAGE_NOT_FOUND';



export function logInAction(token, email, userId, userRole) {

    return {
        type: LoginSuccessful,
        payload: { isLogin: true, token: token, email: '', userId: '', userRole: userRole, isPageFound: true }
    }
}
export function logOutAction() {
    return {
        type: LogOutSuccessful,
        payload: { isLogin: false, token: '', email: '', userId: '', userRole: '', isPageFound: true }
    }
}

export function pageNotFoundAction() {
    return {
        type: pageNotFound,
        payload: { isLogin: false, token: '', email: '', userId: '', userRole: '', isPageFound: false }
    }
}

