import React, { Component } from "react";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { Link } from "react-router-dom";
export default class ServiceReview extends Component {
    componentDidMount() {
        let token = getCookie('token');
        if (token === '') {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <div className="card-sec services-pages">
                <div className="container">
                    <strong>Nicole’s Calendar </strong>
                    <div className="Card-panel">
                        <form>
                            <div className="services-list-form">
                                <strong><i className="fa fa-signal"></i> | Nicole’s Yoga World</strong>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6 PLR-15">
                                        <label>Filter by service</label>
                                        <div className="custom-form-group">
                                            <select className="custom-select">
                                                <option selected>Select a category for this training or retreat</option>
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className=" row Pb-20">
                                            <div className="col-md-7">
                                                <span className="title pull-left">Deep Tissue Massage</span>
                                            </div>
                                            <div className="col-md-5 text-right">
                                                <ul className="feedback-star"><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><span className="total-feedback"> 4.3 out of 5 stars</span> <i className="fa fa-angle-down"></i></a></li></ul>
                                                <div className="total-reviews">
                                                    <div className="item">
                                                        <span className="pink">5 star</span>
                                                        <span className="back-bar"><span className="percentage-bar" style={{ width: "100%" }}></span></span>
                                                        <span className="percent"> 100%</span>
                                                    </div>
                                                    <div className="item">
                                                        <span className="pink">4 star</span>
                                                        <span className="back-bar"><span className="percentage-bar" style={{ width: "80%" }}></span></span>
                                                        <span className="percent"> 80%</span>
                                                    </div>
                                                    <div className="item">
                                                        <span className="pink">3 star</span>
                                                        <span className="back-bar"><span className="percentage-bar" style={{ width: "60%" }}></span></span>
                                                        <span className="percent"> 60%</span>
                                                    </div>
                                                    <div className="item">
                                                        <span className="pink">2 star</span>
                                                        <span className="back-bar"><span className="percentage-bar" style={{ width: "30%" }}></span></span>
                                                        <span className="percent"> 30%</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="details">
                                            <div className="item dark">
                                                <div className=" row">
                                                    <div className="col-md-5">
                                                        <span className="pull-left">"Awesome message,attention to details."</span>
                                                    </div>
                                                    <div className="col-md-4 text-center">
                                                        <ul className="feedback-star"><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li></ul>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <span className="pull-right download">March 2, 2019</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="item light">
                                                <div className=" row">
                                                    <div className="col-md-5">
                                                        <span className="pull-left">"Awesome message,attention to details."</span>
                                                    </div>
                                                    <div className="col-md-4 text-center">
                                                        <ul className="feedback-star"><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li></ul>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <span className="pull-right download">March 2, 2019</span>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="item dark">
                                                <div className=" row">
                                                    <div className="col-md-5">
                                                        <span className="pull-left">"Awesome message,attention to details."</span>
                                                    </div>
                                                    <div className="col-md-4 text-center">
                                                        <ul className="feedback-star"><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li><li><a href="#"><i className="fa fa-star"></i></a></li></ul>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <span className="pull-right download">March 2, 2019</span>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}