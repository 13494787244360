import React from 'react';
import { connect } from 'react-redux';
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import {Link} from 'react-router-dom';
import externalFileLoad from  '../../../externalfileLoad';
class CategoriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email : ''
    }
  }
  componentDidMount() {
    externalFileLoad();
    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_id');
    let userRole = getCookie('user_Role');
    if (token === '') {
      this.props.history.push('/login')
    }else if(token !== '' && userRole !== '1'){
      this.props.history.push('/client/dashboard')
    } else{
      this.props.logInAction(token,email,userId,userRole)
    }
  }

  render() {
    return (
      <main>
      <div className="login-sec">
      <div className="container">
      <div className="profile-panel">
      <h1>What services are you qualified to provide?</h1>
      <strong className="sub-heading">Select the services you will be providing from <span className="font-red">Nicole’s Acupuncture Clinic:</span></strong>
      <form>
      <div className="row">
      <div className="col-xs-12 col-sm-7">
      <div className="profile-form">
      <div className="row">
      <div className="col-xs-12 col-sm-6">
      <div className="form-group">
      <select className="form-control select-icon" id="exampleSelect1">
      <option>Services category/ Type</option>
      <option>Yoga Class</option>
      <option>Cooking</option>
      <option>Massage</option>
      </select>
      <div className="dropdown-arrow">
      <img src={require("../../../../img/icon/down-arrow.png")} alt="icon" />
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      <div className="row">
      <div className="col-xs-12 col-sm-5">
      <div className="dragdrop-box">
      <ul>
      <li className="active"><a href="#"><i className="fa fa-angle-right"></i> Yoga Class</a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Yoga Trainning</a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Weight Loss Program</a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Health eating habit coaching</a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Manageable stress levels</a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Elite Fitness</a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Body Massage</a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Alternative Therapies and Yoga training for other Healers </a></li>
      <li><a href="#"><i className="fa fa-angle-right"></i> Other</a></li>
      </ul>
      </div>
      </div>
      <div className="col-xs-12 col-sm-2">
      <div className="select-unselect-btn">
      <button className="btn btn-md btn-back ripple">Select <i className="fa fa-angle-right"></i> </button> 
      <button className="btn btn-md btn-save-submit ripple"><i className="fa fa-angle-left"></i>  Unselect</button> 
      </div>
      </div>
      <div className="col-xs-12 col-sm-5">
      <div className="dragdrop-box">
      <ul>
      <li className=""><a href="#"><i className="fa fa-angle-right"></i> Yoga Private Session</a></li>
      <li className=""><a href="#"></a></li>
      </ul>
      </div>
      </div>
      </div>
      <div className="text-btn">
      <button className="btn btn-md btn-pink ripple">Back</button> 
      <button className="btn btn-md btn-save-submit ripple">Save & Continue</button> 
      </div>
      </form>
      </div>
      </div>
      </div>
      </main>
      );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(CategoriesList)