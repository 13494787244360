import React, { Component } from "react";
import { connect } from 'react-redux';
import EndUserHeader from "./EndUserHeader/header";
import UserHeader from './userHeader/header';
import { setCookie, getCookie } from "../Login&SignUp/ApiCall/cookies";
import { logOutAction, logInAction, pageNotFoundAction } from '../Login&SignUp/Actions/action';
import { getProfileData } from "../Common/Apicalls/apicalls";


class Index extends Component {
    constructor() {
        super();
        this.state = {
            isShow: true
        }
    }
    componentDidMount() {
        // let isPageFound = getCookie('isPageFound');
        // if (isPageFound && isPageFound === 'true') {
        //     this.setState({
        //         isShow: false
        //     })
        //     setCookie('isPageFound','',0)
        //     this.props.pageNotFoundAction();
        // } else {
        let token = getCookie('token');
        let email = getCookie('user_email');
        let userId = getCookie('user_id');
        let userRole = getCookie('user_Role');
        if (token !== '') {
            this.props.logInAction(token, email, userId, userRole)
            this.props.getProfileData(userId, token)
            // }
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.apiCallStatus.apiCallFor === 'login' &&
            nextProps.apiCallStatus.isCompleted === true &&
            nextProps.apiCallStatus.isFailed === false) {
            let th = this;
            setTimeout(() => {
                let token = getCookie('token');
                let userId = getCookie('user_id');
                th.props.getProfileData(userId, token)
            }, 2000)

        }
        if (nextProps.apiCallStatus.apiCallFor === 'updateProfile' &&
            nextProps.apiCallStatus.isCompleted === true &&
            nextProps.apiCallStatus.isFailed === false) {
            let th = this;
            setTimeout(() => {
                let token = getCookie('token');
                let userId = getCookie('user_id');
                th.props.getProfileData(userId, token)
            }, 2000)
        }
        if (nextProps.apiCallStatus.apiCallFor === 'getProfileData' &&
            nextProps.apiCallStatus.isCompleted === true &&
            nextProps.apiCallStatus.isFailed === false) {

            if (nextProps.apiCallStatus.response && nextProps.apiCallStatus.response != "") {
                let profile = JSON.stringify(nextProps.apiCallStatus.response)
                setCookie("profile", profile, 30);
            }
        }
    }
    logOut = () => {
        setCookie("user_email", '', 30);
        setCookie("token", '', 30);
        setCookie("user_id", '', 30);
        this.props.logOutAction();
        // this.props.history.push('/');
        window.location.href = '/'
    }
    render() {
        return (
            this.props.loginReducer.isLogin ? <UserHeader
                    history={this.props.history}
                    logOut={() => this.logOut()} /> : <EndUserHeader />
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    logOutAction: () => dispatch(logOutAction()),
    pageNotFoundAction: () => dispatch(pageNotFoundAction()),
    logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole)),
    getProfileData: (userId, token) => dispatch(getProfileData(userId, token))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)