import React from 'react';
import { connect } from 'react-redux';
import { Banner } from "../../Common/Components/clientBanner";
import { getCookie, setCookie } from "../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../Login&SignUp/Actions/action";
import { Link } from 'react-router-dom';
import {Constant} from '../../../Constants/constant';
import externalFileLoad from '../../externalfileLoad';
import { Spinner } from '../../Common/Components/loader';
import InputMask from 'react-input-mask';
import * as Datetime from 'react-datetime';
import "../../../../node_modules/react-datetime/css/react-datetime.css"
import "./service.css";
import moment from 'moment';
import loader from '../../../img/Spinner-1s-200px.gif';

class ServiceDetail extends React.Component {
    constructor(){
        super();
        this.state = {
            service: "",
            userId : "",
            userRole : "",
            date : "",
            errorState : "",
            errorMsg : "",
            locations: [],
            locationId: "",
            time: "",
            bookingInfo : {},
            locationAddress : "",
            duration : "",
            min_people : 0,
            max_people:0,
            totle_people: [],
            selectedPeople : 0,
            govImg1 : "",
            govImg2 : "",
            imageError: "",
            imageState : "",
            rawGovimg1 : "",
            rawGovimg2 : "",
            isLoader : false,
            isSppiner : false,
            imageType : ""
        }
    }
    componentDidMount() {
        externalFileLoad();
        let imageBaseUrl = Constant.downloadUrl;
        let token = getCookie('token');
        let email = getCookie('user_email');
        let userId = getCookie('user_id');
        let userRole = getCookie('user_Role');
        let profile = getCookie('profile');
        if(profile != "undefined"){
          debugger
          profile = JSON.parse(profile)
          if(profile.identity_documents && profile.identity_documents.length > 0 &&  profile.identity_documents.length == 2){
            this.setState({
              rawGovimg1 : profile.identity_documents[0],
              rawGovimg2: profile.identity_documents[1],
              govImg1 : imageBaseUrl + profile.identity_documents[0].file_path + profile.identity_documents[0].file_name,
              govImg2: imageBaseUrl + profile.identity_documents[1].file_path + profile.identity_documents[1].file_name
            })
          }
          
          // console.log("profile",JSON.parse(profile))
        }
       
        this.setState({
            userId : userId,
            userRole : userRole
        },() => {
            console.log("userID",this.state.userRole)
        })
        let serviceUrl = getCookie("serviceUrl");
        if (token != '' && serviceUrl ) {
          setCookie("serviceUrl", '', 30);
        }
        this.getServiceByID(this.props.match.params.id);
        this.getclientLocation();
    }
    getclientLocation = () => {
      let self = this;
      let formData = new FormData();
      formData.append("auth_token",getCookie("token"))
      formData.append("user_id",getCookie("user_id"))
      fetch(Constant.apiURl + "get-locations-by-user", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
          body: JSON.stringify({
            auth_token : getCookie("token"),
            user_id : getCookie("user_id")
          })
      }).then(function (response) {
          return response.json();
      }).then(function (myJson) {
        
          if (myJson.success == 1) {
            let arr = [];
            let res =  myJson.data;
            
              for(let i=0; i < res.length;i++){
                arr.push({"name":res[i].address1,"locationId":res[i].id})
              }
              self.setState({
                  locations : arr,
                  locationId : arr[0].id,
                  locationAddress : arr[0]
              }, () => {
              })
             
          } else {

          }

      }).catch((error) => {
      });
    }
    getServiceByID = (id) => {
        let self = this;
        fetch(Constant.apiURl + "get-service-by-id ", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                "service_id":id
            })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson.success == 1) {
              let peopelRange = [];
              let minimumRange = myJson.data.minimum_person
              if((myJson.data.minimum_person !== null) && (myJson.data.maximum_person !=null) && myJson.data.service.is_classtype == 1){
                for(let i =myJson.data.minimum_person; i <= myJson.data.maximum_person;i++){
                  if(i == myJson.data.minimum_person){
                    peopelRange.push(myJson.data.minimum_person)
                  }else{
                    minimumRange++
                    peopelRange.push(minimumRange);
                  }
                }
                self.setState({
                  totle_people : peopelRange,
                  selectedPeople : myJson.data.minimum_person
                })
              }

                self.setState({
                    service : myJson.data,
                    duration :  myJson.data.pricing[0],
                    min_people: myJson.data.minimum_person,
                    max_people: myJson.data.maximum_person
                },() => {
                  
                  console.log(self.state.service)
                })
               
            } else {

            }

        }).catch((error) => {
        });
    }
    componentWillUnmount() {
    }

    validation = () => {
      if(this.state.time == ""){
        this.setState({
          errorMsg : "please enter time",
          errorState : "time"
        })
      }
      
      if(this.state.service.service.is_homebased == 1 && (this.state.govImg1 == "" || this.state.govImg2 == "")){
        this.setState({
          errorMsg : "please upload goverment id",
          errorState : "govId"
        })
      }
      else {
        return true;
      }
    }
    getProfile = () => {
      let self = this;
      this.setState({
        isLoader: true
      })
      let token = getCookie("token");
      let user_id = getCookie("user_id");
      let formData = new FormData();
      formData.append("auth_token",token);
      formData.append("user_id",user_id);
      fetch(Constant.apiURl + "get-account",{
        method: "POST",
        body: formData
  
      }).then(function(res){ return res.json()})
      .then(function(res) {
        self.setState({
          isLoader: false
        })
        if(res.success == "1"){
         
          let response = JSON.parse(res.data)
          let profile = response;
          profile = JSON.stringify(profile)
          setCookie("profile", profile, 30);

             console.log("get profile",getCookie("profile"))
             debugger
             self.moveToNextPage();
        }else{
  
        }
      }).catch(err => {
        self.setState({
          isLoader: false
        })
      })
    }
    moveToNextPage = () => {
      window.jQuery("#Verify_payment").modal('hide');
      let bookingInfo = this.state.bookingInfo;
      // bookingInfo.locationId = this.state.locationId;
      bookingInfo.locationAddress = this.state.locationAddress;
      bookingInfo.service_id = this.state.service.id;
      bookingInfo.duration = this.state.duration;
      bookingInfo.date = this.state.date;
      bookingInfo.time = this.state.time;
      bookingInfo.total_people = this.state.selectedPeople;
      bookingInfo.price = this.state.service.price;
      bookingInfo.isBookNow = this.props.location.state ? this.props.location.state.isBookNow : false;
      bookingInfo.service_type = this.state.service.service.is_classtype;
      this.setState({
        
      }, () => {
        
        let data = JSON.stringify(this.state.bookingInfo);
        this.props.history.push({
          pathname : "/confirm-booking-info",
          state :{
            bookingInfo: data,
            isBookNow : this.props.location.state ? this.props.location.state.isBookNow : false
          }
          } 
        );
      })
    }
    uploadGovId = (file,value) => {
      let self = this;
      let formData = new FormData();
      this.setState({
        isSppiner : true,
        imageType : value
      })
      formData.append("auth_token", getCookie("token"));
      formData.append("file", file[0]);
      fetch(Constant.apiURl + "add-identity-document", {
        method: "POST",
        body: formData
      }).then(function (res) { return res.json() })
        .then(function (myJson) {
          self.setState({
            isSppiner : false,
            imageType : value
          })
          if (myJson.success == "1") {
            if(value == "govImg1"){
              self.setState({
                govImg1 : Constant.downloadUrl + myJson.data.file_path + myJson.data.file_name,
                rawGovimg1 : myJson.data
              })
            }else{
              self.setState({
                govImg2 : Constant.downloadUrl + myJson.data.file_path + myJson.data.file_name,
                rawGovimg2 : myJson.data
              })
            }
          } else {
            if(value == "govImg1"){
              self.setState({
                govImg1 : "",
                isSppiner : false,
                imageType : value
              })
            }else{
              self.setState({
                govImg2 : "",
                isSppiner : false,
                imageType : value
              })
            }
            
            self.setState({
              errorMsg : "error in uploading goverment id's, please try again later",
              errorState : "uploadGovId"
            })
          }
        }).catch(err => {
          if(value == "govImg1"){
            self.setState({
              govImg1 : ""
            })
            window.jQuery('#govImg1').attr('src', '');
          }else{
            self.setState({
              govImg2 : ""
            })
            window.jQuery('#govImg2').attr('src', '');
          }
          self.setState({
            errorMsg : "error in uploading goverment id's, please try again later",
            errorState : "uploadGovId"
          })
        })
    }
    gotoConfirmBooking = () => {
      let date = new Date(this.state.date);
      
      if(date != "Invalid Date"){
        this.setState({
          errorMsg : "",
          errorState : ""
        })
        let valid = this.validation();
        if(valid){
          if(this.state.service.service.is_homebased ==1){
            this.getProfile();
          }else{
            
            this.moveToNextPage();
          }

        }
      }else{
        this.setState({
          errorMsg : "please enter valid date",
          errorState : "date"
        })
      }

        
    }
    gotoLogin = (e) => {
        e.preventDefault();
        setCookie("serviceUrl","/service/"+this.props.match.params.id)
        this.props.history.push("/login")
    }
    setTime = (e) => {
      let date = new Date(e);
      let getTime = date.getTime();
     let timeFormat = moment(getTime).format('hh:mm A')
      this.setState({
        time : timeFormat
      })
      
      // let time = moment(e)
    }
    selectLocation = (value) => {
      let findIndex = this.state.locations.findIndex(item => item.locationId == value);
      if (findIndex > -1){
        this.setState({
          locationId : this.state.locations[findIndex].locationId,
          locationAddress : this.state.locations[findIndex]
          
        })
      }
    }
    getServiceDuration =(value) => {
      let pricing = this.state.service.pricing;
      let durationIndex = pricing.findIndex(item => item.id == value);
      if(durationIndex > -1){
        this.setState({
          duration:pricing[durationIndex]
        })
      }

    }
    handleUploadFile = (e,value) => {
      e.preventDefault();
      
      this.setState({
        imageError:"",
        imageState: "",

      })
      let file = e.target.files;
      let length = e.target.files.length <= 2 ? e.target.files.length : 2;
      var FileSize = "";
      if(e.target.files && e.target.files[0]){
        FileSize = e.target.files[0].size / 1024 / 1024;
     
      if( FileSize > 2){
        if(value == "govImg1"){
          this.setState({
            imageError:"please uplaod max 2MB file",
            imageState : "govImg1"
          })
        }else{
          this.setState({
            imageError:"please uplaod max 2MB file",
            imageState : "govImg2"
          })
        }

      }
      else{
        let self = this; 
        var img = new Image();
        img.src = window.URL.createObjectURL( e.target.files[0] );
        this.setState({
          imageError:"",
          imageState: ""
        })
        img.onload = function() {
          var width = img.naturalWidth,
              height = img.naturalHeight;
  
          window.URL.revokeObjectURL( img.src );
  
          if( width <= 400 && height <= 400 ) {
            self.setState({
              imageError:"",
              imageState: ""
            })
            for (let index = 1; index <= length; index++) {
              var reader = new FileReader();
              reader.onload = function (e) {
                
                // if(value == "govImg1"){
                //   window.jQuery('#govImg1').attr('src', e.target.result);
                // }else{
                //   window.jQuery('#govImg2').attr('src', e.target.result);
                // }
               
              }
             
              if(value == "govImg1"){
                self.uploadGovId(file,"govImg1");
                // self.setState({
                //   govImg1: file
                //  }, () => reader.readAsDataURL(file[index - 1]));
              }else{
                self.uploadGovId(file,"govImg2");
                // self.setState({
                //   govImg2: file
                //  }, () => reader.readAsDataURL(file[index - 1]));
              }

            }
          }
          else {
            if(value == "govImg1"){
              self.setState({
                imageError:"please uplaod (400 x 400) dimension",
                imageState: "govImg1"
              })
            }else{
              self.setState({
                imageError:"please uplaod (400 x 400) dimension",
                imageState: "govImg2"
              })
            }
            
          }
      };
  
      }
    }
    }
    removeGovImage = (e,value,rawValue) => {
      debugger
      e.preventDefault();
      let self = this;
      this.setState({
        isSppiner : true,
        imageType : value
      })
      let formData = new FormData();
      formData.append("auth_token",getCookie("token"))
      formData.append("id",rawValue.id)
      fetch(Constant.apiURl + "remove-identity-document", {
          method: 'POST',
          body: formData
      }).then(function (response) {
          return response.json();
      }).then(function (myJson) {
        self.setState({
          isSppiner : false,
          imageType : value
        })
          if (myJson.success == 1) {
            if(value == "govImg1"){
              self.setState({
                govImg1 : "",
                rawGovimg1 : ""
              })
            }else{
              self.setState({
                govImg2 : "",
                rawGovimg2 : ""
              })
            }
             
          } else {
            if(value == "govImg1"){
              self.setState({
                errorMsg: "error in remove image please try again later",
                errorState:"removeImage1"
              })
            }else{
              self.setState({
                errorMsg: "error in remove image please try again later",
                errorState:"removeImage2"
              })
            }
            
          }

      }).catch((error) => {
        if(value == "govImg1"){
          self.setState({
            errorMsg: "error in remove image please try again later",
            errorState:"removeImage1",
            isSppiner : false,
            imageType: "govImg1"
          })
        }else{
          self.setState({
            errorMsg: "error in remove image please try again later",
            errorState:"removeImage2",
            isSppiner : false,
            imageType: "govImg2"
          })
        }
      });

    }
    render() {
        return (
            <main>
              {this.state.isLoader ? <Spinner/> : ""}
                {/* <Banner commonReducer={this.props.commonReducer}/> */}
             {this.state.service != "" ?    
             <div className="services-sec service-details">
          <div className="container">
            <div className="table-sec">
              <h1>{this.state.service.service_name}</h1>
              <div className="row">
                <div className="col-sm-4 col-md-4">
                  <div className="healer-profile-panel">
                    <div className="client-image-holder">
                      {this.state.service != "" ? 
                      <img src={Constant.downloadUrl + this.state.service.healer.profile.image.path + this.state.service.healer.profile.image.file_name} alt="image" />
                      :
                      <img src={require("../../../img/profile-1.jpg")} alt="image" />
                       }
                    </div>
                    <div className="client-info-holder text-center">
                      <strong>{this.state.service.healer.first_name} {this.state.service.healer.last_name}</strong>
                      <p><span>Expert Level:</span> {this.state.service.experty_level.name}</p>
                      <ul className="star-sec">
                        
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><strong>{this.state.service.healer.review_stars}.00 </strong><small>({this.state.service.healer.total_reviews} reviews)</small></a></li>
                      </ul>
                      <a href="#" className="btn btn-md btn-contactme">Contact me</a>
                     {this.state.service != "" && this.state.service.service.is_homebased == 1 ?  <small className="text-prof">This Healer requires proof of ID by Client</small>: ""}
                    </div>
                    <div className="language-tab">
                      <strong>Language</strong>
                      <ul>
                        {this.state.service.service && this.state.service.service.languages && this.state.service.service.languages.length > 0 ?
                        this.state.service.service.languages.map( (lang,idx) => {
                            return <li key={idx}><a  className="btn btn-md btn-language">{lang.name}</a></li>
                        } )
                        : ""
                        }

                      </ul>
                    </div>
                    {/* <div className="about-client">
                      <strong>About me</strong>
                      <p>{this.state.service.location.location.description}</p>
                    </div> */}
                  </div>    
                </div>
                <div className="col-sm-8 col-md-8">
                  <div id="demo" className="carousel slide service-slider" data-ride="carousel">
                    <ul className="carousel-indicators">
                        {this.state.service.images && this.state.service.images.length > 0 ? 
                    this.state.service.images.map( (image,idx) => {
                        return <li key={idx} data-target="#demo" data-slide-to={idx} className={idx == 0 ? "active": ""}></li>
                    } ) 
                    : ""   
                    }
                      
                    </ul>
                    <div className="carousel-inner">
                    {this.state.service.images && this.state.service.images.length > 0 ? 
                    this.state.service.images.map( (image,idx) => {
                        return <div key={idx} className={`carousel-item ${idx == 0 ? 'active' : ''} `}>
                        <img src={Constant.downloadUrl +  image.path + image.file_name} alt="Los Angeles" />
                      </div>
                    } ) 
                    : ""   
                    }
                    </div>
                    <a className="carousel-control-prev" href="#demo" data-slide="prev">
                      <i className="fa fa-angle-left" aria-hidden="true"></i>
                    </a>
                    <a className="carousel-control-next" href="#demo" data-slide="next">
                      <i className="fa fa-angle-right" aria-hidden="true"></i>
                    </a>
                  </div>
                  <div className="service-details-panel">
                    <div className="about-client">
                      <strong>About me</strong>
                      <p>{this.state.service.location.location.description}</p>
                    </div>
                    <div className="about-client">
                      <strong>About This Service</strong>
                      <p>{this.state.service.service_description}</p>
                    </div>
                    <div className="about-client">
                      <strong>Healer Qualification to provide this service</strong>
                      <p>{this.state.service.qualify_description}</p>
                    </div>
                    <div className="canecl-policy-txt">
                      <p>Cancellation policy  </p>
                        <table className="table table-striped table-bordered">
                        <thead>
                         <tr>
                        {this.state.service.service_type.cancelation_terms && this.state.service.service_type.cancelation_terms.length > 0 ? 
                          this.state.service.service_type.cancelation_terms.map( (term,idx) =>{
                            return <th key={idx} href="#">{term.cancelation_term.name}</th>
                          } )
                        :""}
                        </tr>
                        </thead>
                          <tbody>
                            <tr>
                            {this.state.service.service_type.cancelation_terms && this.state.service.service_type.cancelation_terms.length > 0 ? 
                          this.state.service.service_type.cancelation_terms.map( (term,idx) =>{
                            return term.percentage == 0 || term.percentage =="0" || term.percentage == null || term.percentage == "" || term.percentage == "null" ? <td>No refund is applicable if cancel time is less than {term.duration.name}</td> :<td key={idx} href="#">{term.percentage}% refund if cancelled in {term.duration.name}</td>
                          } )
                        :""}
                            </tr>
                          </tbody>
                        </table>
                          {/* {this.state.service.service_type.cancelation_terms && this.state.service.service_type.cancelation_terms.length > 0 ? 
                          this.state.service.service_type.cancelation_terms.map( (term,idx) =>{
                            return <a key={idx} href="#" style={{"marginLeft":"10px"}}>{term.cancelation_term.name}</a>
                          } )
                        :""} */}
                          
                     
                    </div>
                  </div> 
                  {this.state.userId !="" ?  
                   <a href="javascript:void(0);" className="btn btn-md btn-book-service ripple" data-toggle="modal" data-target="#Verify_payment">Book this Service</a>
                  : 
                  <a href="javascript:void(0);" className="btn btn-md btn-book-service ripple" onClick={(e) => this.gotoLogin(e)}>Book this Service</a>
                }
                 
                  
                </div>
              </div>
              <div className="card-sec services-pages pt-0">
                <div className="Card-panel">
                   <form>
                      <div className="services-list-form">
                         <strong><i className="fa fa-commenting-o"></i> | Reviews </strong>
                         <div className="row">
                            <div className="col-xs-12 col-sm-6 PLR-15">
                               <label>Filter by service</label>
                               <div className="custom-form-group">
                                  <select className="custom-select">
                                     <option  >Select a category for this training or retreat</option>
                                  </select>
                               </div>
                            </div>
                         </div>
                         <div className="row">
                            <div className="col-md-12">
                               <div className=" row Pb-20">
                                  <div className="col-md-7">
                                     <span className="title pull-left">Deep Tissue Massage</span>
                                  </div>
                                  <div className="col-md-5 text-right">
                                     <ul className="feedback-star">
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                                        <li><a href="#"><span className="total-feedback"> 4.3 out of 5 stars</span> <i className="fa fa-angle-down"></i></a></li>
                                     </ul>
                                     <div className="total-reviews">
                                        <div className="item">
                                           <span className="pink">5 star</span>
                                           <span className="back-bar"><span className="percentage-bar" style={{"width":"100%"}}></span></span>
                                           <span className="percent"> 100%</span>
                                        </div>
                                        <div className="item">
                                           <span className="pink">4 star</span>
                                           <span className="back-bar"><span className="percentage-bar" style={{"width":"80%"}}></span></span>
                                           <span className="percent"> 80%</span>
                                        </div>
                                        <div className="item">
                                           <span className="pink">3 star</span>
                                           <span className="back-bar"><span className="percentage-bar" style={{"width":"60%"}}></span></span>
                                           <span className="percent"> 60%</span>
                                        </div>
                                        <div className="item">
                                           <span className="pink">2 star</span>
                                           <span className="back-bar"><span className="percentage-bar" style={{"width":"30%"}}></span></span>
                                           <span className="percent"> 30%</span>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                               <div className="details">
                                  <div className="item dark">
                                     <div className=" row">
                                        <div className="col-md-5">
                                           <span className="pull-left">"Awesome message,attention to details."</span>
                                        </div>
                                        <div className="col-md-4 text-center">
                                           <ul className="feedback-star">
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                           </ul>
                                        </div>
                                        <div className="col-md-3">
                                           <span className="pull-right download">March 2, 2019</span>
                                        </div>
                                     </div>
                                  </div>
                                  <div className="item light">
                                     <div className=" row">
                                        <div className="col-md-5">
                                           <span className="pull-left">"Awesome message,attention to details."</span>
                                        </div>
                                        <div className="col-md-4 text-center">
                                           <ul className="feedback-star">
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                           </ul>
                                        </div>
                                        <div className="col-md-3">
                                           <span className="pull-right download">March 2, 2019</span>
                                        </div>
                                     </div>
                                  </div>
                                  <div className="item dark">
                                     <div className=" row">
                                        <div className="col-md-5">
                                           <span className="pull-left">"Awesome message,attention to details."</span>
                                        </div>
                                        <div className="col-md-4 text-center">
                                           <ul className="feedback-star">
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                              <li><a href="#"><i className="fa fa-star"></i></a></li>
                                           </ul>
                                        </div>
                                        <div className="col-md-3">
                                           <span className="pull-right download">March 2, 2019</span>
                                        </div>
                                     </div>
                                  </div>
                               </div>
                            </div>
                         </div>
                      </div>
                   </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        : <Spinner />}
        <div className="modal fade" id="Verify_payment" role="dialog">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="close-btn-sec">
            <button type="button" className="btn btn-default btn-green pull-right" data-dismiss="modal">Close</button>
          </div>
          {this.state.userRole && this.state.userRole == 1 ? 
          <div className="modal-body">
            <div className="modal-card-panel">
              <form className="services-list-form ">
                <strong><i className="fa fa-calendar"></i> | Comfirm Booking</strong>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-field">
                      <label>Select place of service</label>
                      <select className="custom-select" onChange={(e) => this.selectLocation(e.target.value)}>
                        {this.state.locations && this.state.locations.length > 0 ? 
                        this.state.locations.map( (loc,idx) => {
                          return <option key={idx} value={loc.locationId} selected={this.state.locationId == loc.locationId}>{loc.name}</option>
                        } ) : ""}
                      </select>
                      {/* <input type="text" className="form-control custom-form" value="My Home - 1234 Main st, Houston, 75935" name="" /> */}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-field">
                      <label>Select type of service</label>
                      
                      <input type="text" className="form-control custom-form" value={this.state.service ? this.state.service.service_type.service_type_name : ""} name="" />
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-field">
                      <label className="mt-20">Select Date</label>
                      <InputMask mask="99/99/9999" className="form-control custom-form" value={this.state.date} onChange={(e) => this.setState({date : e.target.value})} placeholder="mm/dd/yyyy" />
                      {this.state.errorState == "date" && this.state.errorMsg != "" ? <div className="alert alert-danger mt-3">
                        {this.state.errorMsg}
                      </div> : ""}
                      {/* <input type="text" className="form-control custom-form" value="05/06/2019" name="" /> */}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-field">
                      <label className="mt-20">Select Time</label>
                      <Datetime dateFormat={false} onChange={(e) => this.setTime(e)}  className="form-control custom-form custom-time" placeholder='Enter time' value={this.state.time} />
                      {this.state.errorState == "time" && this.state.errorMsg != "" ? <div className="alert alert-danger mt-3">
                        {this.state.errorMsg}
                      </div> : ""}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="input-field">
                      <label className="mt-20">Select service duration</label>
                      <select className="custom-select" onChange={(e) => this.getServiceDuration(e.target.value)}>
                        {this.state.service && this.state.service.pricing && this.state.service.pricing.length > 0 ? 
                        this.state.service.pricing.map( (dur,idx) => {
                          return <option key={idx} value={dur.id} selected={this.state.duration.id == dur.id}>{dur.service_time}</option>
                        } ) : ""}
                      </select>
                      {/* <input type="text" className="form-control custom-form" value="My Home - 1234 Main st, Houston, 75935" name="" /> */}
                    </div>
                  </div>
                </div>
                {this.state.service != "" && this.state.service.service.is_classtype == 1 ?
                <div className="row">
                <div className="col-sm-6">
                    <div className="input-field">
                      <label className="mt-20">People</label>
                      <select className="custom-select" onChange={(e) => this.setState({selectedPeople :e.target.value})}>
                        {this.state.totle_people && this.state.totle_people.length > 0 ? 
                        this.state.totle_people.map( (p,idx) => {
                          return <option key={idx} value={p} selected={this.state.selectedPeople == p}>{p}</option>
                        } ) : ""}
                      </select>
                      {/* <input type="text" className="form-control custom-form" value="My Home - 1234 Main st, Houston, 75935" name="" /> */}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-field">
                      <label className="mt-20">Total Price</label>
                      
                      <input type="text" className="form-control custom-form" value={this.state.service ? this.state.selectedPeople * Number(this.state.service.price) : ""} name="" />
                    </div>
                  </div>
                 
                </div>
               : "" }
              </form>
            </div>
            {this.state.service.service && this.state.service.service.is_homebased == 1 ? 
            <div className="modal-card-panel">
              <form>
                <div className="services-list-form">
                  <strong><i className="fa fa-id-card"></i> | Photos ID required</strong>
                  <div className="row">
                    <div className="col-xs-12 col-sm-8">
                      <div className="custom-form-group">
                        <label>Add pictures of Government-Issued ID:</label>
                        <p>Upload up to 2 pictures (400 x 400) max 2 MB per picture</p>
                        <p><span>This healer requires that you have a Government-issued ID on file, in order for them to provide a service at your home. Please upload picture of your ID below. </span></p>
                      </div> 
                    </div>
                    <div className="col-xs-12 col-sm-4">
                      <div className="image-upload-sec">
                        <div className="row">
                          <div className="col-xs-12 col-sm-6">
                            <div className="upload-btn-wrapper">
                            <div className="image-holder">
                              {this.state.isSppiner && this.state.imageType == "govImg1" ?  <div className="text-center"> <img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} /> 
                        </div>: 
                              this.state.govImg1 == "" ?  
                              <div>
                              <a href="#"><img src={require("../../../img/placeholder-img.png")} alt="Placeholder" /></a>
                              <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e,"govImg1")} id='image1' accept="image/*" /> 
                              </div>
                              : 
                              <div>
                              <a href="#"><img id="govImg1" src={this.state.govImg1} alt="Placeholder" /></a>
                              <a href="#" className="remove-image" onClick={(e) => this.removeGovImage(e,"govImg1",this.state.rawGovimg1)}>Remove</a>
                              </div>
                              
                              }
                            </div>
                            </div>
                            {this.state.imageError != "" && this.state.imageState == "govImg1" ? <div className="col-xs-12 col-sm-12">
                              <div className="alert alert-danger">
                                {this.state.imageError}
                              </div>
                          </div>
                          :""}
                          {this.state.imageError != "" && this.state.imageState == "removeImage1" ? <div className="col-xs-12 col-sm-12">
                              <div className="alert alert-danger">
                                {this.state.imageError}
                              </div>
                          </div>
                          :""}
                          </div>
                          <div className="col-xs-12 col-sm-6">
                          <div className="upload-btn-wrapper">
                            <div className="image-holder">
                            {this.state.isSppiner && this.state.imageType == "govImg2" ?  <div className="text-center"><img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} />
                        </div> : 
                            this.state.govImg2 == "" ?  
                            <div>
                              <a href="#"><img src={require("../../../img/placeholder-img.png")} alt="Placeholder" /></a>
                              <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e,"govImg2")} id='image1' accept="image/*" /> 
                              </div>
                              : 
                              <div>
                              <a href="#"><img id="govImg2" src={this.state.govImg2} alt="Placeholder" /></a>
                              <a href="#" className="remove-image" onClick={(e) => this.removeGovImage(e,"govImg2",this.state.rawGovimg2)}>Remove</a>
                              </div>
                              
                            }
                            </div>

                            </div>
                            {this.state.imageError != "" && this.state.imageState == "govImg2" ? <div className="col-xs-12 col-sm-12">
                              <div className="alert alert-danger">
                                {this.state.imageError}
                              </div>
                          </div>
                          :""}
                          {this.state.imageError != "" && this.state.imageState == "removeImage2" ? <div className="col-xs-12 col-sm-12">
                              <div className="alert alert-danger">
                                {this.state.imageError}
                              </div>
                          </div>
                          :""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            : ""}
            <div className="verify-inputfeild">
            </div>
            {this.state.errorState == "govId" && this.state.errorMsg != "" ? <div className="alert alert-danger mt-3">
                        {this.state.errorMsg}
                      </div> : ""}
                      {this.state.errorState == "uploadGovId" && this.state.errorMsg != "" ? <div className="alert alert-danger mt-3">
                        {this.state.errorMsg}
                      </div> : ""}
            <button type="button" className="btn btn-default btn-red continue-btn" onClick={() => this.gotoConfirmBooking()}>Continue Booking</button>
          </div>
          : <div className="modal-body">
              <div className="alert alert-info">
              <strong>Healer</strong> is not allowed to book a service. Please login as client to continue booking.
              </div>    
              
            </div>}
        </div>
      </div>
    </div>
            </main>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus,
    commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    // getUserServicesData: (token, userId) => dispatch(getUserServicesData(token, userId)),
    logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ServiceDetail)