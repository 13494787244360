import React from 'react';
import { connect } from 'react-redux';
import ServicesList from './serviceList';
import externalFileLoad from  '../../../externalfileLoad';
class ServicesList2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email : ''
    }
  }
  componentDidMount(){
    externalFileLoad();
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    this.setState({
      email
    })
  }

  render() {
    return (
    <main>
        <div className="sub-banner">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-5">
                <div className="profile-holder">
                  <div className="media">
                    <img className="mr-3" src={require("../../../../img/profile-1.jpg")}  alt="Generic placeholder image" />
                    <div className="media-body">
                      <h5 className="mt-0">Services at</h5>
                      <p>Nicole’s</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-7">
                <ul className="menu-tabs">
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-map-pin" aria-hidden="true"></i>
                      </div>
                      <div className="banner-content">
                        <small>Locations</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      <div className="banner-icon">
                        <i className="icon-leaf"></i>
                      </div>
                      <div className="banner-content">
                        <small>Services</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-calendar"></i>
                      </div>
                      <div className="banner-content">
                        <small>Calendar</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-envolope"></i>
                      </div>
                      <div className="banner-content">
                        <small>Inbox</small>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="services-sec">
          <div className="container">
          <ServicesList/>
          <ServicesList/>
          <ServicesList/>
          </div>
        </div>
      </main>
      );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(ServicesList2)