import * as Action from "./../Actions/actions";
import StoreState from "./../../../../Constants/initialState";
import * as LoginAction from './../../../Login&SignUp/Actions/action'
export default function profileReducer(state = StoreState.profile, action) {
  switch (action.type) {
    case Action.GetCountries:
      var s = state;
      s.countries = action.payload.countries;
      return s;

    case Action.GetStateByCountry:
      var s = state;
      s.state = action.payload.states;
      return s;

    case Action.GetCityByState:
      var s = state;
      s.cities = action.payload.cities;
      return s;

    case Action.GetUserServices:
      var s = state;
      s.services = action.payload.list;
      return s;

    case Action.GetLocationDetail:
      var s = state;
      s.locationDetail = action.payload.locationDetail;
      return s;

    case Action.UpdateLocationStatus:
      var s = state;
      debugger
      let index = s.services.data.findIndex(x => x.id === action.payload.locationId);
      if (index > -1) {
        s.services.data[index].status = action.payload.status;
      }
      return s;

    case LoginAction.LogOutSuccessful:
      return { data: {}, countries: [], state: [], cities: [], services: [], locationDetail: {} }
    default:
      return state;
  }
}
