import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import externalFileLoad from  '../../../externalfileLoad';
class ServicesList4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email : ''
    }
  }
  componentDidMount(){
    externalFileLoad();
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    this.setState({
      email
    })
  }

  render() {
    return (
    <main>
        <div className="sub-banner">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-5">
                <div className="profile-holder">
                  <div className="media">
                    <img className="mr-3" src={require("../../../../img/profile-1.jpg")}  alt="Generic placeholder image" />
                    <div className="media-body">
                      <h5 className="mt-0">Services at</h5>
                      <p>Nicole’s</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-7">
                <ul className="menu-tabs">
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-map-pin" aria-hidden="true"></i>
                      </div>
                      <div className="banner-content">
                        <small>Locations</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      <div className="banner-icon">
                        <i className="icon-leaf"></i>
                      </div>
                      <div className="banner-content">
                        <small>Services</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-calendar"></i>
                      </div>
                      <div className="banner-content">
                        <small>Calendar</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-envolope"></i>
                      </div>
                      <div className="banner-content">
                        <small>Inbox</small>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="services-sec">
          <div className="container">
            <div className="table-sec">
              <h1>Services at “Nicole’s Yoga World”</h1>
              <div className="card-panel">
                <div className="table-responsive">
                  <table className="table table-striped services-table">
                    <thead>
                      <tr>
                        <th width="30%">Services</th>
                        <th width="50%">status</th>
                        <th width="20%">active on / off</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Yoga Class</td>
                        <td>
                          <a href="#" className="complete-service">Complete</a>
                          <a href="#" className="edit-service">Edit</a>
                        </td>
                        <td>
                          <div className="toggleWrapper">
                            <input type="checkbox" id="dn" className="dn" />
                            <label for="dn" className="toggle"><span className="toggle__handler"></span></label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Yoga Class</td>
                        <td>
                          <a href="#" className="btn-notcomplete">Not complete</a>
                          <a href="#" className="btn btn-md btn-description ripple">Needs Description</a>
                        </td>
                        <td>
                          <div className="toggleWrapper">
                            <input type="checkbox" id="dn2" className="dn" />
                            <label for="dn2" className="toggle"><span className="toggle__handler"></span></label>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Yoga Class</td>
                        <td>
                          <a href="#" className="btn-notcomplete">Not complete</a>
                          <a href="#" className="btn btn-md btn-description ripple">Needs Description</a>
                        </td>
                        <td>
                          <div className="toggleWrapper">
                            <input type="checkbox" id="dn3" className="dn" />
                            <label for="dn3" className="toggle"><span className="toggle__handler"></span></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ul className="add-button">
                  <li><a href="#">+ Add additional services</a></li>
                  <li><a href="#">+ Add reteat or training service</a></li>
                </ul>
              </div>
            </div>
            <div className="table-sec">
              <h2>Services at “Nicole’s Acupuncture Clinic”</h2>
              <div className="card-panel">
                <div className="table-responsive">
                  <table className="table table-striped services-table">
                    <thead>
                      <tr>
                        <th width="30%">Services</th>
                        <th width="50%">status</th>
                        <th width="20%">active on / off</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Acupuncture</td>
                        <td>
                          <a href="#" className="btn-notcomplete">Not complete</a>
                          <a href="#" className="btn btn-md btn-description ripple">Needs Description</a>
                        </td>
                        <td>
                          <div className="toggleWrapper">
                            <input type="checkbox" id="dn" className="dn" />
                            <label for="dn" className="toggle"><span className="toggle__handler"></span></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ul className="add-button">
                  <li><a href="#">+ Add additional services</a></li>
                  <li><a href="#">+ Add reteat or training service</a></li>
                </ul>
              </div>
            </div>
            <div className="table-sec">
              <h2>Services you provide at your client's home</h2>
              <div className="card-panel">
                <div className="table-responsive">
                  <table className="table table-striped services-table">
                    <thead>
                      <tr>
                        <th width="30%">Services</th>
                        <th width="50%">status</th>
                        <th width="20%">active on / off</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Acupuncture</td>
                        <td>
                          <a href="#" className="btn-notcomplete">Not complete</a>
                          <a href="#" className="btn btn-md btn-description ripple">Needs Description</a>
                        </td>
                        <td>
                          <div className="toggleWrapper">
                            <input type="checkbox" id="dn" className="dn" />
                            <label for="dn" className="toggle"><span className="toggle__handler"></span></label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <ul className="add-button bordernone">
                  <li><a href="#">+ Add additional services you provide at client's home</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </main>
      );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(ServicesList4)