import React, { Component } from "react";

export function Filters() {
  return (
    <div className="dashboard-panel">
      <div className="container">
        <h1>Select Place of Service</h1>
        <div className="select-services-sec">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="row">
                <div className="col-sm-4">
                  <div className="placement-box">
                    <a href="#" className="active">
                      <div className="banner-icon">
                        <span className="icon icon-home-icon" />
                      </div>
                      <div className="banner-content">
                        <small>Home</small>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="placement-box active">
                    <a href="#">
                      <div className="banner-icon">
                        <span className="icon icon-location-icon" />
                      </div>
                      <div className="banner-content">
                        <small>Location</small>
                      </div>
                    </a>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="placement-box">
                    <a href="#">
                      <div className="banner-icon">
                        <span className="icon icon-retreat-icon" />
                      </div>
                      <div className="banner-content">
                        <small>Retreat</small>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="search-box-map">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d899.177740648968!2d-74.00989557147302!3d40.7078468287026!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25a176bb283a7%3A0x6e98ea7d931e0fa4!2sOffice+of+the+New+York+State+Attorney+General!5e0!3m2!1sen!2s!4v1559243790527!5m2!1sen!2s"
                  width="100%"
                  height="192px"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowfullscreen
                />
                <form>
                  <div className="search-input">
                    <input
                      type="text"
                      className="custom-search"
                      placeholder="Search Here..."
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <h2>Select Type of Service</h2>
        <div className="select-services-sec">
          <div className="row ">
            <div className="col-xs-12 col-sm-6">
              <div className="dashboard-services">
                <div className="row">
                  <div className="col-xs-12 col-sm-3">
                    <a href="#" className="active">
                      <div className="banner-icon">
                        <span className="icon icon-mes-icon " />
                      </div>
                      <div className="banner-content">
                        <small>Massage</small>
                      </div>
                    </a>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <a href="#" className="actives">
                      <div className="banner-icon">
                        <span className="icon icon-yoga-icon" />
                      </div>
                      <div className="banner-content">
                        <small>Yoga</small>
                      </div>
                    </a>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <a href="#">
                      <div className="banner-icon">
                        <span className="icon icon-mess-icon" />
                      </div>
                      <div className="banner-content">
                        <small>Acupuncture</small>
                      </div>
                    </a>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <a href="#">
                      <div className="banner-icon">
                        <span className="icon icon-c-icon" />
                      </div>
                      <div className="banner-content">
                        <small>chiropratic</small>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6">
              <div className="row custom-form-padding">
                <div className="col-xs-12 col-sm-6">
                  <div className="custom-form-group">
                    <label>From</label>
                    <div
                      className="right-inner-addon date datepicker"
                      data-date-format="yyyy-mm-dd"
                    >
                      <input
                        name="name"
                        value=""
                        className=" form-control custom-form"
                        type="text"
                        id="datepicker"
                        autoComplete="none"
                        placeholder="MM/DD/YY 8:30 AM"
                      />
                      <img
                        src={require("../../../../img/cal-icon.png")}
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="custom-form-group">
                    <div className="label-btn">
                      <label>To</label>
                      <a href="#" className="btn btn-md btn-now">
                        avilable now
                      </a>
                    </div>
                    <div
                      className="right-inner-addon date datepicker"
                      data-date-format="yyyy-mm-dd"
                    >
                      <input
                        name="name"
                        value=""
                        className=" form-control custom-form"
                        type="text"
                        id="datepicker-2"
                        autoComplete="none"
                        placeholder="MM/DD/YY 8:30 AM"
                      />
                      <img
                        src={require("../../../../img/cal-icon.png")}
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="dashboard-search-btn pull-right">
          <a href="#" className="btn btn-md btn-search ripple">
            Search Now
          </a>
        </div>
        <div className="clearfix" />
      </div>
    </div>
  );
}
