import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { uploadDocument, addService } from "./../ApiCalls/apicalls";
import { getCookie,setCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import loader from "./../../../../img/Spinner-1s-200px.gif";
import Banner from '../../../Common/Components/healerBanner'
import externalFileLoad from "../../../externalfileLoad";
import { Constant } from "../../../../Constants/constant";
import { getAllCountries, getStateByCountry, getCitiesByState} from "../../Profile/ApiCalls/apicalls";
import uuid from "uuid/v4";
import Webcam from "react-webcam";
import '../../../Login&SignUp/Css/custom-healmaker.css';
var fileDownload = require('js-file-download');
const cloneDeep = require("clone-deep");


class VerifyDocument extends Component {
  constructor() {
    super();
    this.state = {
      list: [],
      hasUnacceptedAgreements: '0',
      countryId : '',
      stateId: '',
      isGetData: false,
      typeId : '',
      attachment: '',
      imageType : '1',
      imageSrc: '',
      previewImage : '',
      expiry_date : '',
      successMessage: '',
      errorMessage: '',
      errorType: '',
      errorFor: '',
      isLoader : false,
      captureImage : '',
      agreementContent : '',
      agreementTitle: '',
      agreementUpload : {},
      verification_document : {},
      user_profile: {},
      uploadDocName : ""
    };
  }
  componentDidMount() {
    externalFileLoad();
    window.jQuery("#modelbutton").css("display","none")
    let token = getCookie("token");
    let email = getCookie("user_email");
    let userId = getCookie("user_id");
    let userRole = getCookie("user_Role");
    let profile = getCookie("profile")
    profile = JSON.parse(profile)
    this.setState({
      user_profile:profile
    },()=> {
    })
    // let hasUnacceptedAgreements = getCookie("hasUnacceptedAgreements");
    // if(hasUnacceptedAgreements){
    //   this.setState({
    //     hasUnacceptedAgreements : hasUnacceptedAgreements
    //   })
    // }
    if (token === "") {
      this.props.history.push("/login");
    } else {
      this.props.logInAction(token, email, userId, userRole);
    }
    this.getAgrremntList();
    this.props.getAllCountries();
    let self = this;
    window.jQuery("#datepicker").on('change',function(){
      var expiredate = window.jQuery("#datepicker").val();
      var verification_document = self.state.verification_document;
      verification_document.expiry_date = expiredate
      self.setState({
        expiry_date : expiredate,
        verification_document: verification_document
      }, () => {
        if(self.state.errorFor != ""){
          self.validation();
        }

      })
    })
  }
  componentWillReceiveProps(nextProps) {
    if (Object.keys(nextProps.commonReducer.profile).length > 0 && !this.state.isGetData) {
      if (nextProps.commonReducer.profile.country) {
        this.props.getStateByCountry(nextProps.commonReducer.profile.country)
      }
      this.setState({
        countryId: nextProps.commonReducer.profile.country ? nextProps.commonReducer.profile.country : '',
        stateId: nextProps.commonReducer.profile.state ? nextProps.commonReducer.profile.state : '',
        isGetData: true
      })
    }
  }
  getAgrremntList = () => {
    fetch(Constant.apiURl + 'agreement-status', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        auth_token: getCookie('token')
      })
    })
      .then(response => response.json())
      .then(myJson => {
        if (myJson.success === '1') {
          this.setState({
            list: myJson.agreements,
            hasUnacceptedAgreements: myJson.hasUnacceptedAgreements,
            verification_document : myJson.verification_document,
            imageSrc: myJson.verification_document != null && myJson.verification_document.verification_image_file_name && myJson.verification_document.verification_image_file_name ?( Constant.downloadUrl + myJson.verification_document.verification_image_file_path +  myJson.verification_document.verification_image_file_name ): "",
            // agreementUpload : myJson.verification_document != null && myJson.verification_document.file_path && myJson.verification_document.file_path ?( Constant.downloadUrl + myJson.verification_document.file_path +  myJson.verification_document.file_name ): "",
            attachment : myJson.verification_document !=null && myJson.verification_document.file_path && myJson.verification_document.file_path ?( Constant.downloadUrl + myJson.verification_document.file_path +  myJson.verification_document.file_name ): "",
            previewImage :  myJson.verification_document !=null ? "upload" : "",
            typeId : myJson.verification_document !=null && myJson.verification_document.document_type && myJson.verification_document.document_type ? myJson.verification_document.document_type: "",
            expiry_date : myJson.verification_document != null && myJson.verification_document.expiry_date && myJson.verification_document.expiry_date ? myJson.verification_document.expiry_date: "",
          }, () => {
          })
          setCookie('hasUnacceptedAgreements', myJson.hasUnacceptedAgreements,30)
        }
      });
  }
  updateAggrement = (id, status,requires_sign) => {
    let th = this;
    if(requires_sign == 1){
      let formData = new FormData();
      formData.append("auth_token", getCookie('token'));
      formData.append("agreement_id",id);
      formData.append("value", status);
      formData.append("file", this.state.agreementUpload);
      if(this.state.agreementUpload){
        fetch(Constant.apiURl + 'accept-agreement', {
          method: 'POST',
          body: formData
        })
          .then(response => response.json())
          .then(myJson => {
            if (myJson.success === '1') {
              let arr = Object.assign(th.state.list)
              let index = arr.findIndex(x => x.id === id)
              if (index > -1) {
                arr[index].agreed = status;
                this.setState({
                  list : arr,
                  hasUnacceptedAgreements : myJson.hasUnacceptedAgreements
                })
                setCookie('hasUnacceptedAgreements', myJson.hasUnacceptedAgreements,30)
              }
            }
          });
      }else{
        this.setState({
          errorMessage : "Please upload the agreement",
          errorType : "danger",
          errorFor : "uploadAgreement"
        })
      }
    }else{
      fetch(Constant.apiURl + 'accept-agreement', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          auth_token: getCookie('token'),
          agreement_id: id,
          value: status,
          file: ""
        })
      })
        .then(response => response.json())
        .then(myJson => {
          if (myJson.success === '1') {
            let arr = Object.assign(th.state.list)
            let index = arr.findIndex(x => x.id === id)
            if (index > -1) {
              arr[index].agreed = status;
              this.setState({
                list : arr,
                hasUnacceptedAgreements : myJson.hasUnacceptedAgreements
              })
              setCookie('hasUnacceptedAgreements', myJson.hasUnacceptedAgreements,30)
            }
          }
        });
    }
  
  }
  uploadDoc = (e) => {
    var self = this;
    let file = e.target.files[0];
    if (file && file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        // window.jQuery('#previewImage').attr('src', e.target.result);
      }
      reader.readAsDataURL(file);
      debugger
      this.setState({
        attachment: file,
        uploadDocName : file.name
      }, () => {
        if(this.state.errorFor != ""){
        this.validation()
        }
      })
    }
  }
  uploadPicture = (e) => {
    let file = e.target.files[0];
    if((file && file.type == "image/jpeg") || (file && file.type == "image/png") || (file && file.type == "image/jpg")){
      if (file && file) {
        var reader = new FileReader();
  
        reader.onload = function (e) {
          window.jQuery('#previewImage').attr('src', e.target.result);
        }
        reader.readAsDataURL(file);
        this.setState({
          previewImage : "upload",
          imageSrc: file
          
        }, () => {
          if(this.state.errorFor != ""){
          this.validation()
          }
        })
      }
    }
    else{
      this.setState({
        errorMessage: 'Please upload image (jpg / png)',
        errorType: 'danger',
        errorFor: 'imageSrc'
      })
    }
  }
  getStateByCountry = (e) => {
    let id = e.target.value;
    if (id !== '') {
      this.setState({
        countryId: id
      }, () => {
        if(this.state.errorFor != ""){
        this.validation()
        }
      })
      this.props.getStateByCountry(id)
    }
  }
  getCityByState = (e) => {
    if (e.target.value !== '') {
      let stateId = Number(e.target.value);
      this.setState({
        stateId: stateId
      }, () => {
        if(this.state.errorFor != ""){
        this.validation()
        }
      })
      this.props.getCitiesByState(stateId.toString())
    }
  }
  getTypeId (e){
    if (e.target.value !== '') {
      let typeId =e.target.value;
      let verification_document = this.state.verification_document;
      verification_document = {}
      verification_document.document_type = typeId;
      this.setState({
        typeId: typeId,
        verification_document: verification_document
      }, () => {
        if(this.state.errorFor != ""){
        this.validation()
        }
      })
    }
  }
  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, {type:mime});
}
  imageSelection = (e) => {
    let id = e.target.id;
    let val = e.target.value;
    this.setState({
      imageType : val,
      previewImage : '',
      imageSrc : ''
    })

  }
  setRef = webcam => {
    this.webcam = webcam;
  };
  capture = () => {
    const imageSrc = this.webcam.getScreenshot();
    
    // window.jQuery("#previewImage").attr('src', imageSrc);
    this.setState({
      previewImage : "webcam",
      captureImage : imageSrc
    })
  };
  uploadImageWebcam = () => {
    let imgSrc = this.state.captureImage;
    let fileName = uuid();
    var file  = this.dataURLtoFile(imgSrc, fileName +'.jpg');
    this.setState({
      previewImage : "webcam",
      imageSrc : file
    },() => {
      this.validation();
    })
  }
  resetUploadImage = () => {
    this.setState({
      imageSrc : '',
      previewImage: ""
    })
  }
  validation = () => {

    if(this.state.stateId != "" && this.state.countryId != "" && this.state.typeId != "" && this.state.imageSrc != "" && this.state.attachment != "" && this.state.expiry_date != "" ){
      this.setState({
        errorMessage: '',
        errorType: '',
        errorFor: ''
      })
      return true;
    }else{
      if (this.state.countryId === '') {

        this.setState({
          errorMessage: 'Please select country',
          errorType: 'danger',
          errorFor: 'country'
        })
      } else if (this.state.stateId === '') {

        this.setState({
          errorMessage: 'Please select state',
          errorType: 'danger',
          errorFor: 'state'
        })
      } else if (this.state.typeId === '') {

        this.setState({
          errorMessage: 'Please select type',
          errorType: 'danger',
          errorFor: 'type'
        })
      } else if (this.state.attachment === '') {

        this.setState({
          errorMessage: 'Please upload doc file',
          errorType: 'danger',
          errorFor: 'attachment'
        })
      } else if (this.state.imageSrc === '') {

        this.setState({
          errorMessage: 'Please upload image',
          errorType: 'danger',
          errorFor: 'imageSrc'
        })
      } else if (this.state.expiry_date === '') {

        this.setState({
          errorMessage: 'Please add expire date',
          errorType: 'danger',
          errorFor: 'expire_date'
        })
      }
    }
  }

  dataSubmit = () => {
    this.setState({
      isLoader : true
    })
    let token = getCookie('token');
    let self = this;
    let formData = new FormData();
    formData.append('auth_token',token);
    formData.append('country_id',self.state.countryId);
    formData.append('state_id',self.state.stateId);
    formData.append('document_type',self.state.typeId);
    formData.append('expiry_date',self.state.expiry_date);
    if(!this.state.verification_document == null || this.state.verification_document.verification_image_file_name == "" || this.state.verification_document.verification_image_file_name == null){
      formData.append('file',self.state.attachment);
    }
    if(!this.state.verification_document == null || this.state.verification_document.file_name == "" || this.state.verification_document.file_name == null){
      formData.append('document_verification_image',self.state.imageSrc);
    }


    fetch(Constant.apiURl + 'add-verification-document', {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(myJson => {
        this.setState({
          isLoader : false
        })
        if (myJson.success === '1') {
          this.setState({
            successMessage: 'your information has been successfully submited',
          }, () => {
            setTimeout(
              function() {
                this.props.history.push("/healer-profile-container");
              }
              .bind(this),
              3000
          );
           
          })
        }else{
          this.setState({
            successMessage: 'error in server please try later',
            errorType: 'danger',
            errorFor: 'server'
          })
        }
      });
  }
  submitHandler = () => {
    let validate = this.validation();
    if(validate){
      this.dataSubmit();
    }
  }
  agreementPreview = (contetn,title) => {
    window.jQuery("#modelbutton").click();
    this.setState({
      agreementContent : contetn,
      agreementTitle : title
    })
  }
  agreementContent = () =>{
    return {__html: this.state.agreementContent};
  } 
  downloadFile = (url,name) => {
    fileDownload(url, name);
  }
  uploadAgreement = (e) => {
    var self = this;
    debugger
    let file = e.target.files[0];
    if (file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        // window.jQuery('#previewImage').attr('src', e.target.result);
      }
      reader.readAsDataURL(file);
      self.setState({
        agreementUpload: file
      }, () => {
        if(self.state.errorFor != ""){
          self.validation()
        }
      })
    }
  }
  render() {
    const videoConstraints = {
      facingMode: "user"
    };

    return (
      <main>
        <Banner commonReducer={this.props.commonReducer} />
        <div className="services-sec">
          <div className="container">
            <div className="table-sec">
              <div className="card-panel services-padding">
                <h1 className="bold-txt">
                  <span className="font-red">{this.state.user_profile  ? this.state.user_profile.first_name : this.state.user_profile  ? this.state.user_profile.last_name : ""}</span>, it's required that
                  the following documents be read agreed to, in order to operate
                  as a <span className="font-pink">healing</span> service
                  provider in the { this.props.profileReducer.state && this.props.profileReducer.state.length > 0 &&
                               this.props.profileReducer.state.map((item, index) => {
                                return item.id == this.state.stateId ?  <span> {item.name}</span> : ""
                               })
                              } of  { this.props.profileReducer.countries && this.props.profileReducer.countries.length > 0 &&
                               this.props.profileReducer.countries.map((item, index) => {
                                return item.id == this.state.countryId ?  <span> {item.name}</span> : ""
                               })
                              }
                </h1>
                <div className="table-responsive">
                  <table className="table table-striped services-table ">
                    <tbody>
                      {this.state.list.map((item, index) => {

                        return <tr key={index}>
                          <td>   {item.agreement_name} <i class="fa fa-eye" aria-hidden="true" onClick={(e) => this.agreementPreview(item.agreement_content,item.agreement_name)}></i> |<i class="fa fa-download" aria-hidden="true" onClick={() => this.downloadFile(Constant.downloadUrl + item.agreement_document_path + "/" + item.agreement_document_name,item.agreement_document_name)}></i> </td>
                          <td>
                            <a href="#" className={item.agreed === 0 ? "btn-notcomplete" : 'btn-complete'}>
                              {item.agreed === 0 ? 'Uncomplete' : 'Complete'}
                            </a>
                          </td>
                          <td>
                          { item.agreed === 0 && item.requires_sign == 0 ? 
          
                            <a href="javascript:void(0)" onClick={() => this.updateAggrement(item.id,1,item.requires_sign)} className="btn-Accept">
                              Accept
                            </a>
                            :item.agreed === 0 && item.requires_sign == 1 ? 
                            <div>
                            <div className="d-flex  align-items-center justify-content-center">
                            <a href="javascript:void(0)" onClick={() => this.updateAggrement(item.id,1,item.requires_sign)} className="btn-Accept">
                              Accept
                            </a>
                            <div className="upload-btn-wrapper ml-2">
                            <button className="btn btn-md btn-upload ripple">Upload</button>
                            <input type="file" name="myfile"  onChange={(e) => this.uploadAgreement(e)}/>
                            
                          </div> 
                   
                          </div>
                          {this.state.errorMessage !== "" &&
                                          this.state.errorType !== "" &&
                                          this.state.errorFor === 'uploadAgreement' ?
                                          <div className={"alert alert-" + this.state.errorType}>
                                            {this.state.errorMessage}
                                          </div>
                                          : ""}
                          </div>
                          :  <div  className="btn-Accept" onClick={(e) => this.downloadFile(Constant.downloadUrl + item.agreement_document_path + "/" + item.agreement_document_name,item.agreement_document_name)}>
                              Download
                          </div>}
                          </td>
                        </tr>
                      })}
                      <tr>
                        <td>
                          Government issued ID (e.g Driver's License)
                        </td>
                        <td>
                         {(this.state.verification_document != null && this.state.verification_document.verified 
                         && this.state.verification_document.verified) == 0 ? 
                          "Incomplete" : 
                          (this.state.verification_document != null && this.state.verification_document.verified) == 1 ? 
                         "Complete" : ""}
                        </td>
                        <td>
                        <div className="upload-btn-wrapper">
                          <button className="btn btn-md btn-upload ripple">Upload</button>
                          <input type="file" name="myfile"  onChange={(e) => this.uploadDoc(e)}/>
                         {this.state.uploadDocName != "" ?  <p>{this.state.uploadDocName}</p>: ""}
                          {this.state.errorMessage !== "" &&
                                        this.state.errorType !== "" &&
                                        this.state.errorFor === 'attachment' ?
                                        <div className={"alert alert-" + this.state.errorType}>
                                          {this.state.errorMessage}
                                        </div>
                                        : ""}
                        </div>
                        </td>
                      </tr>
                      
                    </tbody>
                  </table>
                  <div className="row ml-md-0 mr-md-0">
                        <div className="col-xs-12 col-sm-3 PLR-15">
                          <div className="form-group">
                            <label htmlFor="exampleSelect1" className="bmd-label-floating">Select a Country</label>
                            <select className="form-control select-icon" value={this.state.countryId} onChange={(e) => this.getStateByCountry(e)}>
                              <option value=''>Select country</option>
                              {this.state.verification_document != null && this.state.verification_document.country_id ?   
                               this.props.profileReducer.countries &&
                               this.props.profileReducer.countries.map((item, index) => {
                                  return <option key={index} value={item.id} selected={item.id === this.state.verification_document.country_id}>{item.name}</option> 
                               })
                              :  !this.state.verification_document == null
                              ?  
                              this.props.profileReducer.countries &&
                                this.props.profileReducer.countries.map((item, index) => {
                                  return <option key={index} value={item.id} selected={item.id === this.state.countryId}>{item.name}</option>
                                })
                              
                               : 
                              "" }
                            </select>
                            <div className="dropdown-arrow">
                              <img
                                src={require('./../../../../img/icon/down-arrow.png')} alt="icon" />
                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'country' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-3 PLR-15">
                          <div className="form-group">
                            <label htmlFor="exampleSelect1" className="bmd-label-floating">Select a state or province</label>
                            <select className="form-control select-icon" value={this.state.stateId} onChange={(e) => this.getCityByState(e)}>
                            {this.props.profileReducer.state.length > 0 ?
                                <option value=''>Select state</option>
                                : ''}
                                 {this.state.verification_document != null && this.state.verification_document.state_id ?   
                               this.props.profileReducer.state &&
                               this.props.profileReducer.state.map((item, index) => {
                                  return <option key={index} value={item.id} selected={item.id === this.state.verification_document.state_id}>{item.name}</option> 
                               })
                              :  !this.state.verification_document != null
                              ?  
                              this.props.profileReducer.state && this.props.profileReducer.state.map((item, index) => {
  
                                return <option key={index} value={item.id} name={item.name} selected={item.id === this.state.stateId}>{item.name}</option>
                              })
                              : ""
                            }
                            </select>
                            <div className="dropdown-arrow">
                              <img src={require('./../../../../img/icon/down-arrow.png')} alt="icon" />
                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'state' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                          </div>
                        </div> 
                        <div className="col-xs-12 col-sm-3 PLR-15">
                          <div className="form-group">
                            <label htmlFor="exampleSelect1" className="bmd-label-floating">Select a Type</label>
                            {this.state.verification_document != null && this.state.verification_document.document_type ? 
                            <select className="form-control select-icon" value={this.state.typeId} onChange={(e) => this.getTypeId(e)}>
                                 <option value="driving_license" selected={this.state.verification_document.document_type == "driving_license"}>Driver's license</option>
                                 <option value="passport" selected={this.state.verification_document.document_type == "passport"}>Passport</option>
                                 <option value="national_id" selected={this.state.verification_document.document_type == "national_id"}>National ID</option>
                                 <option value="other" selected={this.state.verification_document.document_type == "other"}>Other</option>
                            </select>
                            : <select className="form-control select-icon" value={this.state.typeId} onChange={(e) => this.getTypeId(e)}>
                            <option value=''>Type of ID</option>
                            <option value="driving_license">Driver's license</option>
                            <option value="passport">Passport</option>
                            <option value="national_id">National ID</option>
                            <option value="other">Other</option>
                       </select>
                            }
                            <div className="dropdown-arrow">
                              <img src={require('./../../../../img/icon/down-arrow.png')} alt="icon" />
                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'type' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                          </div>
                        </div> 
                        <div className="col-xs-12 col-sm-3">
                          <div className="custom-form-group">
                            <div className="right-inner-addon date datepicker" data-date-format="yyyy-mm-dd">
                              {this.state.verification_document != null && this.state.verification_document.expiry_date ? <input name="text" value={this.state.expiry_date}  data-date-format="yyyy-mm-dd" className=" form-control custom-form" type="text" id="datepicker" autoComplete="none" placeholder="YY/MM/DD" /> : <input name="text" value={this.state.expiry_date}  data-date-format="yyyy-mm-dd" className=" form-control custom-form" type="text" id="datepicker" autoComplete="none" placeholder="YY/MM/DD" />}
                              
                              <img src={require("../../../../img/cal-icon.png")} alt="icon" />
                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'expire_date' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                          </div>

                        </div>
                  </div>
                  <div className="row ml-md-0 mr-md-0 mt-3">
                        <div className="col-xs-12 col-sm-12">
                        
                        <div className="custom-form-group checkbox-form">
                            <label>Please attach your image </label>
                            <div className="form-check form-check-inline">

                              <div className="radio ml-10">
                              <label>
                                  <input type="radio" id='serviceType' name="optionsRadios" value='1'
                                    checked={this.state.imageType === "1"} onChange={(e) => this.imageSelection(e)} />
                                  <span>Upload Image</span>
                                </label>
                                
                              </div>
                              <div className="radio">
                              <label>
                                  <input type="radio" id='serviceType' name="optionsRadios" value='2'
                                    checked={this.state.imageType === "2"} onChange={(e) => this.imageSelection(e)} />
                                  <span>Webcam</span>
                                </label>
                              </div>

                              </div>
                                        {this.state.errorMessage !== "" &&
                                        this.state.errorType !== "" &&
                                        this.state.errorFor === 'imageSrc' ?
                                        <div className={"alert alert-" + this.state.errorType}>
                                          {this.state.errorMessage}
                                        </div>
                                        : ""}
                                                                

                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                          {this.state.imageType == "1" && this.state.previewImage == ""  ?  
                              <div className="upload-btn-wrapper">
                                <button className="btn btn-md btn-upload ripple">Upload</button>
                                <input type="file" name="myfile" onChange={(e) => this.uploadPicture(e)}/>
                              </div>
                              : this.state.imageType == "2" && this.state.previewImage == "" ?  <div className="uploadImage">
                                <Webcam  screenshotWidth="500" height="200" width="300" screenshotQuality="1" screenshotFormat="image/jpeg" videoConstraints={videoConstraints} ref={this.setRef} />
                                <button onClick={this.capture} className="btn btn-md btn-save-submit ripple mt-2">Capture photo</button>
                                </div> : ''
                            }

                            {this.state.previewImage == "webcam" ? <div className="uploadImage">
                                  <img id="previewImage"  width="300" src={this.state.captureImage } /> 
                                  {this.state.imageSrc == '' ? <button onClick={this.uploadImageWebcam} className="btn btn-md btn-save-submit ripple mt-2">Upload</button> : '' }
                                  <i className="fa fa-refresh" aria-hidden="true" onClick={this.resetUploadImage}></i>
                                  </div>: this.state.previewImage == "upload" ? 
                                  <div>
                                    <img id="previewImage"  width="300" src={this.state.imageSrc } /> 
                                    <div>
                                    <i className="fa fa-refresh" aria-hidden="true" onClick={this.resetUploadImage}></i>
                                    </div>
                                  </div> 
                                  : ''}
                        </div>
                  </div>
                  <div className="row ml-md-0 mr-md-0 ">
                                       {this.state.errorMessage !== "" &&
                                        this.state.errorType !== "" &&
                                        this.state.errorFor === 'server' ?
                                        <div className={"col-xs-12 col-sm-12 alert alert-" + this.state.errorType}>
                                          {this.state.errorMessage}
                                        </div>
                                        : ""}
                                        {
                                        this.state.successMessage != '' ?
                                        <div className={"col-xs-12 col-sm-12 alert alert-success"}>
                                          {this.state.successMessage}
                                        </div>
                                        : ""}
                  
                  </div>
                  <div className="row col-xs-12 col-sm-12">
                    {this.state.isLoader ? <div className="text-center">
                      <img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} />
                    </div> : ''}
                  </div>
                  <div className="row col-xs-12 col-sm-12 d-flex justify-content-end">
                    
                    {this.state.loader  ?  <button className="btn btn-md btn-upload ripple text-left" >Next</button> : 
                     <button className="btn btn-md btn-upload ripple text-left"  onClick={this.submitHandler}>Next</button>
                    }
                     </div>
                
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-primary" id="modelbutton" data-toggle="modal" data-target="#exampleModal">
          Launch demo modal
        </button>
        <div class="modal fade show" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">{this.state.agreementTitle}</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" dangerouslySetInnerHTML={this.agreementContent()} >

      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer,
  profileReducer: state.profileReducer
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logInAction: (token, email, userId, userRole) =>
    dispatch(logInAction(token, email, userId, userRole)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  getAllCountries: () => dispatch(getAllCountries()),
  getStateByCountry: (countryId) => dispatch(getStateByCountry(countryId)),
  getCitiesByState: (stateId) => dispatch(getCitiesByState(stateId))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyDocument);
