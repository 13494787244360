import React, { Component } from "react";
import { connect } from 'react-redux';
import { Constant } from "../../../../Constants/constant";
import { uploadDocument, addService, getServiceDetail, removeImage } from "./../ApiCalls/apicalls";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { ApiClearAction } from "../../../ApiCallStatus/Actions/action";
import loader from './../../../../img/Spinner-1s-200px.gif';
import { Spinner } from '../../../Common/Components/loader';
import externalFileLoad from '../../../externalfileLoad';
import Select from 'react-select';
import "../../../Login&SignUp/Css/custom-healmaker.css"
const cloneDeep = require('clone-deep');

class AddService extends Component {
  constructor() {
    super();
    this.form = React.createRef();
    this.state = {
      serviceType: "",
      errorMsg: "",
      name: '',
      description: '',
      image1: [],
      image2: [],
      image3: [],
      documents: [],
      qualification: '',
      levelOfExpertise: '1',
      pricing: [],
      time: [],
      travelCharges: [],
      timeInput: '',
      priceInput: '',
      milesInput: '',
      travelChargeInput: '',
      serverValidation: {},
      errorMessage: '',
      isError: '',
      imageIndex: '',
      isLoading: false,
      loaderFor: '',
      serverErrorMsg: "",
      imageIndex: '',
      service: "",
      seriveDuration: [],
      serviceTimePricing: [],
      seriveDurationId: "",
      price: "",
      minimum_person: "",
      maximum_person: "",
      service_duration_id: "",
      certificateError: "",
      travelError: "",
      imageError: "",
      languages: [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Spanish' },
        { value: 'Chinese', label: 'Chinese' }
      ],
      selectedLanguage :[]
    }
  }
  componentDidMount() {
    externalFileLoad();
    this.getSeriveDuration();
    this.getPricingSlot();
    this.getTravelCharges();
    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_id');
    let userRole = getCookie('user_Role');
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (token === '') {
      this.props.history.push('/login')
    } else {
      this.props.logInAction(token, email, userId, userRole);
      if (hasUnacceptedAgreements === '1') {
        this.props.history.push('/verify-service-document')
      }

      this.props.getServiceDetail(token, this.props.match.params.id)
    }

  }
  getPricingSlot = () => {
    let self = this;
    let formData = new FormData();
    formData.append("auth_token", getCookie("token"));
    formData.append("service_id", this.props.match.params.id);
    fetch(Constant.apiURl + "get-service-price", {
      method: "POST",
      body: formData
    }).then(function (res) { return res.json() })
      .then(function (res) {
        if (res.success == "1") {
          // seriveDuration = cloneDeep(this.state.seriveDuration);
          // seriveDuration = res.data;
          self.setState({
            serviceTimePricing: res.data
          })
        } else {
          console.log("error in get service price")
        }
      }).catch(err => {
        console.log("error", err)
      })
  }
  getTravelCharges = () => {
    let self = this;
    let formData = new FormData();
    formData.append("auth_token", getCookie("token"));
    formData.append("service_id", this.props.match.params.id);
    fetch(Constant.apiURl + "get-service-travel-charges", {
      method: "POST",
      body: formData
    }).then(function (res) { return res.json() })
      .then(function (res) {
        if (res.success == "1") {

          self.setState({
            travelCharges: res.data
          })
        } else {
          console.log("error in get service price")
        }
      }).catch(err => {
        console.log("error", err)
      })
  }
  getSeriveDuration = () => {
    let self = this;
    fetch(Constant.apiURl + "get-service-durations", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
    }).then(function (res) { return res.json() })
      .then(function (res) {
        if (res.success == "1") {
          self.setState({
            seriveDuration: res.data,
            service_duration_id: res.data[0].id,
            seriveDurationId: res.data[0].id
          }, () => {
            console.log("service_duration_id", self.state.service_duration_id)
            console.log("seriveDurationId", self.state.seriveDurationId)
          })
        } else {

        }
      }).catch(err => {
        console.log("error", err)
      })
  }
  getExpireDate = () => {

    let $ = window.jQuery;
    let self = this;
    var documents = cloneDeep(this.state.documents);
    if (this.state.documents && this.state.documents.length > 0) {
      for (let i = 0; i < this.state.documents.length; i++) {
        $("#datepicker-" + i).on('change', function () {
          var expiredate = window.jQuery("#datepicker-" + i).val();

          documents[i].expiry_date = expiredate;
          self.setState({
            documents: documents
          })
        })
      }
    }

  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.apiCallStatus.apiCallFor === 'uploadDocument' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      let arr = cloneDeep(this.state.documents);
      arr.push(nextProps.apiCallStatus.message);
      externalFileLoad();
      this.setState({
        documents: arr
      })
      this.props.ApiClearAction();
    }
    if (nextProps.apiCallStatus.apiCallFor === 'getServiceDetail' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false &&
      Object.keys(nextProps.servicesReducer.serviceDetail).length > 0) {
      let obj = {}
      for (let index = 0; index < nextProps.servicesReducer.serviceDetail.images.length; index++) {
        const element = nextProps.servicesReducer.serviceDetail.images[index];
        let imgUrl =
          "https://healmaker.wantechsolutions.com" +
          element.path +
          element.file_name;
        window.jQuery('#previewimage' + (index + 1)).attr('src', imgUrl);
      }
      let pricing = [];
      let times = [];
      for (let index = 0; index < nextProps.servicesReducer.serviceDetail.pricing.length; index++) {
        let element = nextProps.servicesReducer.serviceDetail.pricing[index]
        pricing.push(element.service_price);
        times.push(element.service_time)
      }
      this.setState({
        serviceType: nextProps.servicesReducer.serviceDetail.service.is_classtype == 1 ? "classType" : nextProps.servicesReducer.serviceDetail.service.is_general == 1 ? "generalType" : nextProps.servicesReducer.serviceDetail.service.is_homebased == 1 ? "homeBased" : "",
        price: nextProps.servicesReducer.serviceDetail.price,
        service_duration_id: nextProps.servicesReducer.serviceDetail.service_duration_id,
        minimum_person: nextProps.servicesReducer.serviceDetail.minimum_person,
        maximum_person: nextProps.servicesReducer.serviceDetail.maximum_person,
        service: nextProps.servicesReducer.serviceDetail.service,
        name: nextProps.servicesReducer.serviceDetail.service_name ? nextProps.servicesReducer.serviceDetail.service_name : '',
        description: nextProps.servicesReducer.serviceDetail.service_description ? nextProps.servicesReducer.serviceDetail.service_description : '',
        images: [],
        // ...obj,
        // documents: nextProps.servicesReducer.serviceDetail.documents ? nextProps.servicesReducer.serviceDetail.documents : [],
        qualification: nextProps.servicesReducer.serviceDetail.qualify_description ? nextProps.servicesReducer.serviceDetail.qualify_description : '',
        levelOfExpertise: nextProps.servicesReducer.serviceDetail.experties_level ? nextProps.servicesReducer.serviceDetail.experties_level : "1",
        pricing: pricing,
        time: times,
        timeInput: '',
        priceInput: '',
        serverValidation: {},
        errorMessage: '',
        isError: false,
        isLoading: true,
        loaderFor: 'getDocumentDetail'
      }, () => {
        this.getDocumentDetail(getCookie('token'), nextProps.servicesReducer.serviceDetail.category_service_id)
      })
    }
    if (nextProps.apiCallStatus.apiCallFor === 'addService' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      this.setState({
        name: '',
        description: '',
        images: [],
        documents: [],
        qualification: '',
        levelOfExpertise: '',
        pricing: [],
        time: [],
        timeInput: '',
        priceInput: '',
        serverValidation: {},
        errorMessage: '',
        isError: false,
      })
      this.props.history.push("/add-avaliability/" + this.props.match.params.id);
      this.props.history.push({
        pathname: "/add-avaliability/" + this.props.match.params.id,
        state: {
          serviceType: this.state.serviceType
        }
      }
      );
    }
    if (nextProps.apiCallStatus.apiCallFor === 'addService' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === true) {

      this.setState({
        serverValidation: nextProps.apiCallStatus.message,
        serverErrorMsg: nextProps.apiCallStatus.message.msg
      })
      let isCertificateError = this.isCertificateError(nextProps.apiCallStatus.message);
      if (isCertificateError !== '') {
        this.setState({
          errorMessage: isCertificateError,
          isError: true
        })
      }
      this.props.ApiClearAction()
    }
    if (nextProps.apiCallStatus.apiCallFor === 'removeImage' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      window.jQuery("#previewimage" + this.state.imageIndex).attr("src", require('../../../../img/upload-image.png'));
      let id = 'image' + this.state.imageIndex;
      this.setState({
        [id]: []
      })
    }
  }
  getDocumentDetail = (token, categoryId) => {
    let self = this;
    fetch(Constant.apiURl + 'get-certificates', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        auth_token: token,
        category_service_id: categoryId,
        service_id: this.props.match.params.id
      })
    })
      .then(response => response.json())
      .then(myJson => {
        if (myJson.success === '1') {
          let data = myJson.certificates;
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            element.file_name = element.certificate_name;
          }
          self.setState({
            documents: data,
            isLoading: false,
            loaderFor: ''
          }, () => {
            externalFileLoad()
            self.getExpireDate();
          })
        }
        else if (myJson.length == 0) {
          self.setState({
            certificateError: "",
            isLoading: false,
            loaderFor: ''
          })
        }
        else {

          self.setState({
            certificateError: myJson.message,
            isLoading: false,
            loaderFor: ''
          })
        }
      }).catch((err) => {

        self.setState({
          isLoading: false,
          loaderFor: ''
        })
      });
  }
  selectServiceduration = (e) => {
    let val = e.target.value;
    this.setState({
      seriveDurationId: e.target.value,
      service_duration_id: e.target.id
    })
  }
  handleChange = (e) => {
    let id = e.target.id;
    let val = e.target.value;
    this.setState({
      [id]: val,
      errorMessage: '',
      isError: false,
      serverValidation: {}
    })
  }
  handleUploadFile = (e) => {
    e.preventDefault();
    this.setState({
      imageError: ""
    })
    let id = e.target.id;
    let length = e.target.files.length <= 2 ? e.target.files.length : 2;
    let file = e.target.files;
    var FileSize = "";
    if (e.target.files && e.target.files[0]) {
      FileSize = e.target.files[0].size / 1024 / 1024;

      if (FileSize > 2) {
        this.setState({
          imageError: "please uplaod max 2MB file"
        })
      }
      else {
        let self = this;
        var img = new Image();
        img.src = window.URL.createObjectURL(e.target.files[0]);
        this.setState({
          imageError: ""
        })
        img.onload = function () {
          var width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);

          if (width <= 400 && height <= 400) {
            self.setState({
              imageError: ""
            })
            for (let index = 1; index <= length; index++) {
              var reader = new FileReader();
              reader.onload = function (e) {
                window.jQuery('#preview' + id).attr('src', e.target.result);
              }
              self.setState({
                [id]: file
              }, () => reader.readAsDataURL(file[index - 1]));
            }
          }
          else {
            self.setState({
              imageError: "please uplaod (400 x 400) dimension"
            })
          }
        };

      }
    }
  }
  removeFile = (id) => {
    this.setState({
      [id]: []
    })
    window.jQuery("#" + id).val('');
  }
  uploadDocument = (e, index) => {
    let documents = cloneDeep(this.state.documents);
    documents[index]["certificate_file_" + documents[index].id] = e.target.files[0];
    documents[index]["certificate_file_" + documents[index].id + "_expires"] = documents[index].expires
    documents[index]["certificate_file_" + documents[index].id + "_expiry_date"] = documents[index].expiry_date
    documents[index]["certificate_file_" + documents[index].id + "_id"] = documents[index].id
    this.setState({
      documents
    })
    // this.props.uploadDocument(getCookie('token'), e.target.files[0]);
    // document.getElementById("uploadmyfile").value = "";

  }
  removeDocument = (e, id) => {
    e.preventDefault();
    let array = cloneDeep(this.state.documents)
    const index = array.findIndex(x => x.id === id);
    array.splice(index, 1);
    this.setState({
      documents: array
    })
    document.getElementById("uploadmyfile").value = "";
  }
  addTimePrice = (e) => {
    e.preventDefault();
    let servicetimePricingIndex;
    if (this.state.serviceTimePricing && this.state.serviceTimePricing.length > 0) {
      servicetimePricingIndex = this.state.serviceTimePricing.findIndex(item => item.service_duration_id == this.state.seriveDurationId)
    }
    if (servicetimePricingIndex == -1) {
      if (this.state.priceInput == "") {
        this.setState({
          errorMsg: "please add a price"
        })

      } else {
        this.setState({
          errorMsg: ""
        })
        let pricing = cloneDeep(this.state.pricing);
        let time = cloneDeep(this.state.time);
        pricing.push(this.state.priceInput);

        time.push(this.state.timeInput);
        let self = this;
        let formData = new FormData();
        formData.append("auth_token", getCookie("token"));
        formData.append("service_id", this.props.match.params.id);
        formData.append("service_duration_id", this.state.seriveDurationId);
        formData.append("price", this.state.priceInput);
        fetch(Constant.apiURl + "add-service-price", {
          method: "POST",
          body: formData

        }).then(function (res) { return res.json() })
          .then(function (res) {
            if (res.success == "1") {
              let serviceTimePricing = cloneDeep(self.state.serviceTimePricing)
              serviceTimePricing.push(res.data)
              self.setState({
                serviceTimePricing: serviceTimePricing,
                pricing,
                time,
                priceInput: '',
                timeInput: ''
              })
            } else {

            }
          }).catch(err => {
            console.log("error", err)
          })
      }

    }
    else if (this.state.serviceTimePricing && this.state.serviceTimePricing.length == 0) {
      if (this.state.priceInput == "") {
        this.setState({
          errorMsg: "please add a price"
        })

      } else {
        this.setState({
          errorMsg: ""
        })
        let pricing = cloneDeep(this.state.pricing);
        let time = cloneDeep(this.state.time);
        pricing.push(this.state.priceInput);

        time.push(this.state.timeInput);
        let self = this;
        let formData = new FormData();
        formData.append("auth_token", getCookie("token"));
        formData.append("service_id", this.props.match.params.id);
        formData.append("service_duration_id", this.state.seriveDurationId);
        formData.append("price", this.state.priceInput);
        fetch(Constant.apiURl + "add-service-price", {
          method: "POST",
          body: formData

        }).then(function (res) { return res.json() })
          .then(function (res) {
            if (res.success == "1") {
              let serviceTimePricing = cloneDeep(self.state.serviceTimePricing)
              serviceTimePricing.push(res.data)
              self.setState({
                serviceTimePricing: serviceTimePricing,
                pricing,
                time,
                priceInput: '',
                timeInput: ''
              })
            } else if (this.state.serviceTimePricing && this.state.serviceTimePricing.length == 0) {
              if (this.state.priceInput == "") {
                this.setState({
                  errorMsg: "please add a price"
                })
              }
              else {

              }
            }
          }).catch(err => {
            console.log("error", err)
          })
      }
    }
    else {
      this.setState({
        errorMsg: "selected duration already selected, please select another duration"
      })
    }
  }
  addTravelCharge = (e) => {
    e.preventDefault();
    if (this.state.travelChargeInput == "" || this.state.travelChargeInput == null) {
      this.setState({
        travelError: "please add travel charges"
      })
    }
    else if (this.state.milesInput == "" || this.state.milesInput == null) {
      this.setState({
        travelError: "please add travel miles"
      })
    }
    else {
      let travelCharges = cloneDeep(this.state.travelCharges);
      let self = this;
      let formData = new FormData();
      formData.append("auth_token", getCookie("token"));
      formData.append("service_id", this.props.match.params.id);
      formData.append("miles", this.state.milesInput);
      formData.append("charges", this.state.travelChargeInput);
      fetch(Constant.apiURl + "add-service-travel-charges", {
        method: "POST",
        body: formData

      }).then(function (res) { return res.json() })
        .then(function (res) {
          if (res.success == "1") {
            travelCharges.push(res.data)
            self.setState({
              travelCharges,
              milesInput: '',
              travelChargeInput: '',
              travelError: ""
            })
          } else {

          }
        }).catch(err => {
          console.log("error", err)
        })
    }


  }
  removeTimePrice = (e, id) => {
    e.preventDefault();
    let self = this;
    let pricing = cloneDeep(this.state.pricing);
    let time = cloneDeep(this.state.time);
    let formData = new FormData();
    formData.append("auth_token", getCookie("token"));
    formData.append("id", id);
    fetch(Constant.apiURl + "remove-service-price", {
      method: "POST",
      body: formData

    }).then(function (res) { return res.json() })
      .then(function (res) {
        if (res.success == "1") {
          let serviceTimePricing = cloneDeep(self.state.serviceTimePricing)

          let serviceTimePricingIndex = serviceTimePricing.findIndex(item => item.id == id);
          if (serviceTimePricingIndex > -1) {
            serviceTimePricing.splice(serviceTimePricingIndex, 1)
          }

          self.setState({
            serviceTimePricing: serviceTimePricing,
            pricing,
            time,
            priceInput: '',
            timeInput: ''
          })
        } else {

        }
      }).catch(err => {
        console.log("error", err)
      })

  }
  removeTravelCharges = (e, id) => {
    e.preventDefault();
    let travelCharges = cloneDeep(this.state.travelCharges);
    let self = this;
    let formData = new FormData();
    formData.append("auth_token", getCookie("token"));
    formData.append("id", id);
    fetch(Constant.apiURl + "remove-service-travel-charges", {
      method: "POST",
      body: formData

    }).then(function (res) { return res.json() })
      .then(function (res) {
        if (res.success == "1") {

          let travelChargesIndex = travelCharges.findIndex(item => item.id == id);
          if (travelChargesIndex > -1) {
            travelCharges.splice(travelChargesIndex, 1)
          }
          self.setState({
            travelCharges: travelCharges
          })
        } else {

        }
      }).catch(err => {
        console.log("error", err)
      })
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let isValid = this.validate();
    if (isValid) {

      if ((this.state.price == "" || this.state.price == 0 || this.state.price == null) && this.state.serviceType == "classType") {
        this.setState({
          errorMsg: "please add price per client"
        })
      }
      else if ((this.state.minimum_person == "" || this.state.minimum_person == 0 || this.state.minimum_person == null) && this.state.serviceType == "classType") {
        this.setState({
          errorMsg: "please add minimum person"
        })
      }
      else if ((this.state.maximum_person == "" || this.state.maximum_person == 0 || this.state.maximum_person == null) && this.state.serviceType == "classType") {
        this.setState({
          errorMsg: "please add maximum person"
        })
      }
      else {
        let images = [];
        if (this.state.image1.length > 0) {
          images.push(this.state.image1[0])
        }
        if (this.state.image2.length > 0) {
          images.push(this.state.image2[0])
        }
        if (this.state.image3.length > 0) {
          images.push(this.state.image3[0])
        }
        this.props.addService({
          'auth_token': getCookie('token'),
          'service_provider_owner': getCookie('user_id'),
          'service_name': this.state.name,
          'service_id': this.props.match.params.id,
          'service_description': this.state.description,
          'service_location': this.props.match.params.id,
          'qualify_description': this.state.qualification,
          'documnets': this.state.documents,
          'service_times': this.state.time,
          'service_prices': this.state.pricing,
          'experties_level': this.state.levelOfExpertise,
          'images': images,
          "service_duration_id": this.state.seriveDurationId,
          "price": this.state.price,
          "minimum_person": this.state.minimum_person,
          "maximum_person": this.state.maximum_person
        });
      }


    }

  }
  redirect = (e, path) => {
    e.preventDefault();
    this.props.history.push(path)
  }
  validate() {
    return this.form.current.reportValidity();
  }
  handleDocumentExpire = (index, value) => {
    let documents = cloneDeep(this.state.documents);
    documents[index].expires = value
    this.setState({
      documents
    }, () => {
      externalFileLoad()
      this.getExpireDate();
    })
  }
  handleDocumentExpireDate = (index, value) => {
    let documents = cloneDeep(this.state.documents);

    documents[index].expiryDate = value
    this.setState({
      documents
    })
  }
  removeImage = (imageId, imageIndex) => {
    this.setState({
      imageIndex: imageIndex
    }, () => this.props.removeImage(imageId))

  }
  isCertificateError = (obj) => {
    let objKeys = Object.keys(obj);
    let errorMessage = '';
    for (let index = 0; index < objKeys.length; index++) {
      const element = objKeys[index].split('certificate_file_');
      if (element.length > 1) {
        errorMessage = obj[objKeys[index]][0];
        break;
      }
    }
    return errorMessage;
  }
  handleSelectChange = selectedLanguage => {
    this.setState({ selectedLanguage });
    console.log(`Option selected:`, selectedLanguage);
  };
  render() {
    return (
      <main>
        <div className="card-sec services-pages">
          <div className="container">
            <strong>Tell us about your service at {this.props.commonReducer.profile &&
              this.props.commonReducer.profile.first_name &&
              this.props.commonReducer.profile.first_name !== '' ?
              this.props.commonReducer.profile.first_name : ''} {this.props.match.params.name} </strong>
            <div className="Card-panel">
              <form ref={this.form}>
                <div className="services-list-form">
                  <strong><img src={require('../../../../img/services-1.jpg')} className="icon-services" alt="image" /> | About the services</strong>
                  <div className="custom-form-group">
                    <label>Be specific, name your {this.props.match.params.name} service :</label>
                    <input type="text" className="form-control custom-form"
                      id="name"
                      onChange={(e) => this.handleChange(e)}
                      value={this.state.name}
                      placeholder="e.g. Hot power yoga, hatha yoga, rock and flow   " required />
                    {this.state.serverValidation &&
                      this.state.serverValidation.service_name &&
                      this.state.serverValidation.service_name[0] ?
                      <div className={"alert alert-danger"}>
                        {this.state.serverValidation.service_name[0]}
                      </div>
                      : ""}
                  </div>
                  <div className="custom-form-group">
                    <label>Briefly describe your {this.props.match.params.name} service :</label>
                    <textarea className="form-control custom-form" placeholder=" "
                      id="description"
                      onChange={(e) => this.handleChange(e)} required value={this.state.description}></textarea>
                  </div>
                  <div className="custom-form-group">
                    <label>Select Language :</label>
                    <Select
                      className="form-control "
                      placeholder='Select Language'
                      value={this.state.selectedLanguage}
                      onChange={(e)=>this.handleSelectChange(e)}
                      options={this.state.languages}
                      isMulti={true}
                    />
                    {/* <textarea className="form-control custom-form" placeholder=" "
                      id="description"
                      onChange={(e) => this.handleChange(e)} required value={this.state.description}></textarea> */}
                  </div>
                </div>
              </form>
            </div>
            <div className="Card-panel">
              <form>
                <div className="services-list-form">
                  <strong><i className="fa fa-picture-o"></i> | Photos</strong>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <div className="custom-form-group">
                        <label>Add some pictures of you providing this service :</label>
                        <p>Upload up to 3 pictures (400 x 400) max 2 MB per picture</p>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div className="image-upload-sec">
                        <div className="row">
                          <div className="col-xs-12 col-sm-4">
                            <div className="upload-btn-wrapper">
                              <div className="image-holder">
                                <a href="javascript:void(0)"><img src={this.state.image1.length === 0 ? require('../../../../img/upload-image.png') : ''} id='previewimage1' alt="Placeholder" /></a>
                                {this.state.image1.length !== 0 ?
                                  <a href="javascript:void(0)" onClick={() => this.removeFile('image1')} className="remove-image">Remove</a>
                                  : Object.keys(this.props.servicesReducer.serviceDetail).length > 0 &&
                                    this.props.servicesReducer.serviceDetail.images.length > 0 &&
                                    this.props.servicesReducer.serviceDetail.images[0] &&
                                    this.props.servicesReducer.serviceDetail.images[0].path ?
                                    <a href="javascript:void(0)" onClick={() => this.removeImage(this.props.servicesReducer.serviceDetail.images[0].id, 1)} className="remove-image">Remove</a> : ''}

                              </div>
                              {Object.keys(this.props.servicesReducer.serviceDetail).length > 0 &&
                                this.props.servicesReducer.serviceDetail.images.length > 0 &&
                                this.props.servicesReducer.serviceDetail.images[0] &&
                                this.props.servicesReducer.serviceDetail.images[0].path ?
                                ''
                                : this.state.image1.length === 0 ?
                                  <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e)} id='image1' accept="image/*" /> : ''
                              }

                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-4">
                            <div className="upload-btn-wrapper">
                              <div className="image-holder">
                                <a href="javascript:void(0)"><img src={this.state.image2.length === 0 ? require('../../../../img/upload-image.png') : ''} id='previewimage2' alt="Placeholder" /></a>
                                {this.state.image2.length !== 0 ?
                                  <a href="javascript:void(0)" onClick={() => this.removeFile('image2')} className="remove-image">Remove</a>
                                  : Object.keys(this.props.servicesReducer.serviceDetail).length > 0 &&
                                    this.props.servicesReducer.serviceDetail.images.length > 0 &&
                                    this.props.servicesReducer.serviceDetail.images[1] &&
                                    this.props.servicesReducer.serviceDetail.images[1].path ?
                                    <a href="javascript:void(0)"
                                      onClick={() => this.removeImage(this.props.servicesReducer.serviceDetail.images[1].id, 2)} className="remove-image">Remove</a> : ''}

                              </div>
                              {Object.keys(this.props.servicesReducer.serviceDetail).length > 0 &&
                                this.props.servicesReducer.serviceDetail.images.length > 0 &&
                                this.props.servicesReducer.serviceDetail.images[1] &&
                                this.props.servicesReducer.serviceDetail.images[1].path ?
                                ''
                                : this.state.image2.length === 0 ?
                                  <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e)} id='image2' accept="image/*" /> : ''
                              }

                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-4">
                            <div className="upload-btn-wrapper">

                              <div className="image-holder">
                                <a href="javascript:void(0)"><img
                                  src={this.state.image3.length === 0 ? require('../../../../img/upload-image.png') : ''}
                                  id='previewimage3' alt="Placeholder" /></a>
                                {this.state.image3.length !== 0 ?
                                  <a href="javascript:void(0)" onClick={() => this.removeFile('image3')} className="remove-image">Remove</a>
                                  : Object.keys(this.props.servicesReducer.serviceDetail).length > 0 &&
                                    this.props.servicesReducer.serviceDetail.images.length > 0 &&
                                    this.props.servicesReducer.serviceDetail.images[2] &&
                                    this.props.servicesReducer.serviceDetail.images[2].path ?
                                    <a href="javascript:void(0)" onClick={() => this.removeImage(this.props.servicesReducer.serviceDetail.images[2].id, 3)} className="remove-image">Remove</a>
                                    : ''
                                }
                              </div>
                              {Object.keys(this.props.servicesReducer.serviceDetail).length > 0 &&
                                this.props.servicesReducer.serviceDetail.images.length > 0 &&
                                this.props.servicesReducer.serviceDetail.images[2] &&
                                this.props.servicesReducer.serviceDetail.images[2].path ?
                                ''
                                : this.state.image3.length === 0 ?
                                  <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e)} id='image3' accept="image/*" /> : ''
                              }

                            </div>
                          </div>
                        </div>
                        {this.state.imageError != "" ? <div className="col-xs-12 col-sm-12">
                          <div className="alert alert-danger">
                            {this.state.imageError}
                          </div>
                        </div>
                          : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="Card-panel">
              <form>
                <div className="services-list-form">
                  <div className="row">
                    <div className="col-xs-9 col-sm-9">
                      <strong><i className="fa fa-file-text-o" aria-hidden="true"></i> | Your qualification  to provide this service</strong>
                    </div>
                    <div className="col-xs-3 col-sm-3">
                      <div className="why-need pull-right">
                        <a href="#" data-toggle="tooltip" data-placement="top" title="How it work dummy text"><i className="fa fa-question-circle"></i> Why is this needed</a>
                      </div>
                    </div>
                  </div>
                  <div className="custom-form-group">
                    <label>Describe your qualification for this service :</label>
                    <textarea className="form-control custom-form" placeholder=" " required=""
                      onChange={(e) => this.handleChange(e)}
                      id="qualification" value={this.state.qualification}></textarea>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-sm-6 PLR-15">
                      <div className="custom-form-group">
                        <label>Select your level of expertise to provide this service :</label>
                        <select className="custom-select" id='levelOfExpertise' onChange={(e) => this.handleChange(e)} required>
                          <option value="0">Not certified, but have some experience</option>
                          <option value="1" selected={this.state.levelOfExpertise === '1'}>Certified : 1-5 years experience</option>
                          <option value="2" selected={this.state.levelOfExpertise === '2'}>Expert : 5 to 10 years experience</option>
                          <option value="3" selected={this.state.levelOfExpertise === '3'}>Master : More than 10 years experience</option>
                        </select>
                        {this.state.serverValidation &&
                          this.state.serverValidation.service_name &&
                          this.state.serverValidation.service_name[0] ?
                          <div className={"alert alert-danger"}>
                            {this.state.serverValidation.service_name[0]}
                          </div>
                          : ""}
                      </div>
                    </div>
                  </div>
                  {this.state.documents && this.state.documents.length > 0 ? <label>Upload copies of your certifications and education :</label> : ""}
                  <div>
                    {this.state.documents.map((item, index) => {
                      return <div className="row" key={index} id={index}>
                        <div className="col-xs-12 col-sm-4 PLR-15">
                          <ul className="thumbnail-upload-img">
                            <li><a href="#">{item.file_name}</a></li>
                          </ul>
                        </div>
                        <div className="col-xs-12 col-sm-2 PLR-15">
                          <div className="checkbox custom-checkbox padding-T10">
                            <label>
                              <input type="checkbox" id='isDisplayName'
                                checked={item.expires == 0 || item.expires == null ? false : true}
                                onChange={(e) => this.handleDocumentExpire(index, item.expires == 0 || item.expires == null ? 1 : 0)}
                              /> Expires?
                                </label>
                          </div>
                        </div>
                        {item.expires === 1 ? <div className="col-xs-12 col-sm-3 PLR-15">
                          <div className="custom-form-group">
                            <div className="right-inner-addon date datepicker" data-date-format="yyyy-mm-dd">
                              <input name="datepicker" value={item.expiry_date} autoComplete='off' className="doc-datepicker form-control custom-form" type="text" id={"datepicker-" + index} name={index} autoComplete="none" placeholder="MM/DD/YY" />
                              <img src={require('../../../../img/cal-icon.png')} alt="icon" />
                            </div>
                          </div>
                        </div> : ''}

                        <div className="col-xs-12 col-sm-2 PLR-15">
                          <div className="upload-btn-wrapper text-left">
                            <button className="btn btn-md btn-upload ripple">Upload Files</button>
                            <input type="file" id="uploadmyfile" onChange={(e) => this.uploadDocument(e, index)} />
                          </div>
                          {/* <button className="btn btn-md btn-pink ripple" onClick={(e) => this.removeDocument(e, item.id)}>Remove</button> */}
                        </div>
                      </div>
                    })}

                  </div>
                  {this.state.certificateError != "" ? <div className="row">
                    <div className="col-xs-12">
                      <div className="alert alert-danger">
                        {this.state.certificateError}
                      </div>
                    </div>
                  </div> : ""}
                  {this.state.isLoading === true
                    && this.state.loaderFor === 'getDocumentDetail' ?
                    <div className="text-center">
                      <img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} />
                    </div>
                    : ""}
                  <div className="upload-btn-wrapper text-left">
                    {this.props.apiCallStatus.apiCallFor === 'uploadDocument' && this.props.apiCallStatus.isCompleted === false && this.props.apiCallStatus.isFailed === false ?
                      <div className="text-center">
                        <img className="logo"
                          style={{ width: "83px", display: 'inline' }}
                          src={loader} />
                      </div>
                      : ""}
                    {/* <button className="btn btn-md btn-upload ripple">Upload Files</button>
                    <input type="file" id="uploadmyfile" onChange={(e) => this.uploadDocument(e)} /> */}
                  </div>
                </div>
              </form>
            </div>
            <div className="Card-panel">
              <form>
                <div className="services-list-form">
                  <div className="row">
                    <div className="col-xs-9 col-sm-9">
                      <strong><i className="fa fa-tags" aria-hidden="true"></i> | Pricing</strong>
                    </div>
                    <div className="col-xs-3 col-sm-3">
                      <div className="why-need pull-right">
                        <a href="#" data-toggle="tooltip" data-placement="top" title="How it work dummy text"><i className="fa fa-question-circle"></i> How dose this work</a>
                      </div>
                    </div>
                  </div>
                  {(this.state.service && this.state.service.is_homebased == 1 || this.state.service && this.state.service.is_general == 1) ? <div>
                    <div className="custom-form-group">
                      <label>Let’s set up your Pricing :</label>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <div className="table-responsive">
                          <table className="table table-striped left-border services-table">
                            <thead>
                              <tr>
                                <th width="50%">Services Time (min) </th>
                                <th width="50%">Fee ($)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.serviceTimePricing && this.state.serviceTimePricing.length > 0 ? this.state.serviceTimePricing.map((item, index) => {
                                return <tr key={index}>
                                  <td>{item.service_time}</td>
                                  <td>
                                    <div className="display-flex">
                                      {item.service_price}
                                      <a onClick={(e) => this.removeTimePrice(e, item.id)}> <i className="fa fa-minus-circle"></i></a>
                                    </div>
                                  </td>
                                </tr>
                              })
                                : ""
                              }

                            </tbody>
                          </table>
                        </div>
                        <div className='row pb-20'>
                          <div className="col-xs-3 col-sm-3 col-md-4 PLR-15">
                            <select onChange={(e) => this.selectServiceduration(e)} id='timeInput' className=" custom-select ">
                              {this.state.seriveDuration && this.state.seriveDuration.length > 0 ? this.state.seriveDuration.map((dur, idx) => {

                                return <option value={dur.id} key={idx} >{dur.duration}</option>

                              }) : ""}
                            </select>
                            {/* <input type='number' id='timeInput' onChange={(e) => this.handleChange(e)} value={this.state.timeInput} className="form-control custom-form " placeholder="Enter Time" /> */}
                          </div>
                          <div className="col-xs-3 col-sm-3 col-md-4 PLR-15">
                            <input type='number' id='priceInput' onChange={(e) => this.handleChange(e)} value={this.state.priceInput} className="form-control custom-form" placeholder="Enter Price" />
                          </div>
                        </div>
                        {this.state.errorMsg != "" ? <div className="col-xs-12 col-sm-12">
                          <div className="alert alert-danger">
                            {this.state.errorMsg}
                          </div>
                        </div>
                          : ""
                        }
                        <div className="btn-sec">
                          <span><a href="#" className="btn btn-md add-btn ripple" onClick={(e) => this.addTimePrice(e)}>Add</a> </span>
                        </div>

                      </div>
                    </div>
                  </div>
                    : ""
                  }
                  {this.state.service && this.state.service.is_homebased == 1 ? <div>
                    <div className="custom-form-group mt-30">
                      <label>Travel charge for this healing Service:</label>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6">
                        <div className="table-responsive">
                          <table className="table table-striped services-table left-border">
                            <thead>
                              <tr>
                                <th width="50%">Up to Miles</th>
                                <th width="50%">Travel Charge ($)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.travelCharges.map((item, index) => {
                                return <tr key={index}>
                                  <td>{item.miles}</td>
                                  <td>
                                    <div className="display-flex">
                                      {item.charges}
                                      <a onClick={(e) => this.removeTravelCharges(e, item.id)}> <i className="fa fa-minus-circle"></i></a>
                                    </div>
                                  </td>
                                </tr>
                              })
                              }

                            </tbody>
                          </table>
                        </div>

                        <div className='row pb-20'>
                          <div className="col-xs-4 col-sm-4 PLR-15">
                            <input type='number' id='milesInput' onChange={(e) => this.handleChange(e)} value={this.state.milesInput} className="form-control custom-form " placeholder="Enter Miles" />
                          </div>
                          <div className="col-xs-4 col-sm-4 PLR-15">
                            <input type='number' id='travelChargeInput' onChange={(e) => this.handleChange(e)} value={this.state.travelChargeInput} className="form-control custom-form" placeholder="Enter Travel Charges" />
                          </div>
                        </div>
                        {this.state.travelError != "" && this.state.serviceType == "homeBased" ?

                          <div className="col-xs-12 col-sm-12">
                            <div className="alert alert-danger">
                              {this.state.travelError}
                            </div>
                          </div>

                          : ""
                        }
                        <div className="btn-sec">
                          <span><a href="#" className="btn btn-md add-btn ripple" onClick={(e) => this.addTravelCharge(e)}>Add</a> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                    : ""}

                  {(this.state.service && this.state.service.is_classtype) == 1 ?

                    <div className="services-list-form">
                      <div className="custom-form-group">
                        <label>Let’s set up your Pricing:</label>
                      </div>
                      <div className='row pb-20'>
                        <div className="col-xs-3 col-sm-3 col-md-3 PLR-15 pt-18">
                          <label> Duration of service</label>
                          {this.state.seriveDuration && this.state.seriveDuration.length > 0 ?
                            <select onChange={(e) => this.selectServiceduration(e)} className=" custom-select ">
                              {this.state.seriveDuration.map((dur, idx) => {
                                return <option value={dur.id} key={idx}>{dur.duration}</option>
                              })
                              }

                            </select> : ""}

                          {/* <input type='number' id='milesInput' onChange={(e) => this.handleChange(e)} value={this.state.milesInput} className="form-control custom-form " placeholder="Enter Miles" /> */}
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 PLR-15 pt-18">
                          <label> Cost of service per client</label>
                          <input type='number' id='travelChargeInput' onChange={(e) => this.setState({
                            price: e.target.value
                          })} value={this.state.price} className="form-control custom-form" placeholder="Enter price per client" required />
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 PLR-15">
                          <div className="form-grup">
                            <label> Minimum # of people who can register for the service</label>
                            <input type="text" value={this.state.minimum_person} onChange={(e) => this.setState({
                              minimum_person: e.target.value
                            })} className="form-control custom-form" name="" placeholder="Maximum no of people" required />
                          </div>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 PLR-15">
                          <div className="form-grup">
                            <label> Maximum # of people who can register for the service</label>
                            <input type="text" value={this.state.maximum_person} onChange={(e) => this.setState({
                              maximum_person: e.target.value
                            })} className="form-control custom-form" name="" placeholder="Maximum no of people" required />
                          </div>
                        </div>


                      </div>

                    </div>


                    : ""
                  }
                </div>
              </form>
            </div>
            {this.props.apiCallStatus.apiCallFor === 'addService'
              && this.props.apiCallStatus.isCompleted
              === false && this.props.apiCallStatus.isFailed === false ?
              <div className="text-center">
                <img className="logo" style={{ width: "83px", display: 'inline' }} src={loader} />
              </div>
              : ""}
            {this.props.apiCallStatus.isStarted.indexOf('getServiceDetail') !== -1 ?
              <Spinner />
              : ""}
            {this.state.errorMessage !== '' && this.state.isError === true
              ?
              <div className={"alert alert-danger"}>
                {this.state.errorMessage}
              </div>
              : ""}


            {/* {this.state.errorMsg != "" ? 
           <div className="row">
           <div className="col-xs-12 col-sm-12">
          
                    <div className="alert alert-danger">
                                {this.state.errorMsg}
                              </div>
     
           </div>
         </div>
           : ""}  */}

            <div className="text-btn pb-50 pull-right">
              <button className="btn btn-md btn-pink ripple" onClick={(e) => this.redirect(e, '/healer-services-list')}>Back</button>
              <button className="btn btn-md btn-save-submit ripple" onClick={(e) => this.handleSubmit(e)}>Save &amp; Continue</button>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer,
  servicesReducer: state.servicesReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole)),
  uploadDocument: (token, document) => dispatch(uploadDocument(token, document)),
  ApiClearAction: () => dispatch(ApiClearAction()),
  addService: (data) => dispatch(addService(data)),
  removeImage: (imageId) => dispatch(removeImage(imageId)),
  getServiceDetail: (token, locationId) => dispatch(getServiceDetail(token, locationId))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddService)