import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { Spinner } from "./Features/Common/Components/loader";
import Index from "./Features/EndUser/Index/index";
import Header from "./Features/Header/index";
import HealerCategoriesList from "./Features/Healer/Profile/Components/categoriesList";
import HealerServicesList1 from "./Features/Healer/Profile/Components/servicesList1";
import HealerAccountInfo from "./Features/Healer/Profile/Components/accountInfo";
import HealerLocationList from "./Features/Healer/Locations/Component/locationList";
import HealerProfileContainer from "./Features/Healer/Profile/Components/profileContainer";
import Footer from "./Features/Footer/index";
import Login from "./Features/Login&SignUp/Components/login";
import SignUp from "./Features/Login&SignUp/Components/signUp";
import ForgetPassword from "./Features/Login&SignUp/Components/forgetPassword";
import ResetPassword from "./Features/Login&SignUp/Components/resetPassword";
import HealerSignUp from "./Features/Login&SignUp/Components/healerSignUp";
import ThankYou from "./Features/Login&SignUp/Components/thankyou";
import LocationManager from "./Features/Common/Components/locationManager";
import EditLocationManager from "./Features/Healer/Locations/Component/editlocationManager";
import ClientDashboard from "./Features/Client/Dashboard/Components/dashboardContainer";
import AddHealerService from "./Features/Healer/Services/Components/addServices";
import VerifyDocumentHealer from "./Features/Healer/Services/Components/verifyDocument";
import VerifyAccount from "./Features/Login&SignUp/Components/verifyAccount";
import ClientProfileContainer from "./Features/Client/Profile/Components/profileContainer";
import ClientCategoriesList from "./Features/Client/Profile/Components/categoriesList";
import ClientServicesList1 from "./Features/Client/Profile/Components/servicesList1";
import ClientServicesList2 from "./Features/Client/Profile/Components/servicesList2";
import ClientServicesList3 from "./Features/Client/Profile/Components/servicesList3";
import ClientServicesList4 from "./Features/Client/Profile/Components/servicesList4";
import ClientServicesList5 from "./Features/Client/Profile/Components/servicesList5";
import ClientBooking from "./Features/Client/Profile/Components/booking";
import HealerCalendar from "./Features/Healer/Profile/Components/calendar";
import Payment from "./Features/Client/Payment/Components/payment";
import addAvaliability from "./Features/Healer/Services/Components/addAvaliability";
import locationPreview from "./Features/Healer/Locations/Component/location-preview";
import BookedUserProfilePreview from "./Features/Healer/Profile/Components/bookedUserProfile";
import ServiceReview from "./Features/Healer/Services/Components/serviceReview";
import TransactionDetail from "./Features/Healer/Profile/Components/transactionDetail";
import ConfirmBookingInfo from "./Features/Client/Profile/Components/confirm_booking_info";
import ServiceDetail from "./Features/Client/service/service-details-page";
import PageNotFound from "./Features/Common/Components/404";
class App extends Component {
  componentWillMount() {
    window.addEventListener('load', function () {
      this.document.getElementById('initialLoader').remove();
    }, false);
  }
  render() {
    return (
      <div id="wrapper">
        <Suspense fallback={<Spinner />}>
          
          <Header />
          <Switch>
            <Route exact path="/" component={Index} />
            <Route path="/login" component={Login} />
            <Route path="/sign-up" component={SignUp} />
            <Route path="/healer-signup" component={HealerSignUp} />
            <Route path="/forgot-password" component={ForgetPassword} />
            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/acctive_user_account" component={VerifyAccount} />
            <Route path="/thankyou" component={ThankYou} />
            <Route path="/categories-list/:id" component={HealerCategoriesList} />
            <Route
              path="/healer-services-list"
              component={HealerServicesList1}
            />
            <Route path="/healer-account-info" component={HealerAccountInfo} />
            <Route path="/healer-location" component={HealerLocationList} />
            <Route path="/add-avaliability/:id" component={addAvaliability} />
            <Route
              path="/healer-profile-container"
              component={HealerProfileContainer}
            />
            <Route path="/location-manager" component={LocationManager} />
            <Route path="/edit-location/:id" component={EditLocationManager} />
            <Route path="/healer-calender" component={HealerCalendar} />
            <Route path="/healer-location-preview/:id" component={locationPreview} />
            <Route path="/healer-user-profile-preview/:id" component={BookedUserProfilePreview} />
            <Route
              path="/verify-service-document"
              component={VerifyDocumentHealer}
            />
            <Route path='/transaction-detail' component={TransactionDetail} />
            <Route path='/service-review' component={ServiceReview} />
            <Route path="/add-service/:id" component={AddHealerService} />
            <Route
              path="/client-profile-container"
              component={ClientProfileContainer}
            />
            <Route
              path="/client-categories-list"
              component={ClientCategoriesList}
            />
            <Route
              path="/client-services-list1"
              component={ClientServicesList1}
            />
            <Route path="/clientServicesList2" component={ClientServicesList2} />
            <Route path="/clientServicesList3" component={ClientServicesList3} />
            <Route path="/clientServicesList4" component={ClientServicesList4} />
            <Route path="/clientServicesList5" component={ClientServicesList5} />
            <Route path="/client-booking" component={ClientBooking} />
            <Route path="/confirm-booking-info" component={ConfirmBookingInfo} />
            <Route path="/service/:id" component={ServiceDetail} />
            <Route path="/client-location" component={HealerLocationList} />

            <Route path="/payment" component={Payment} />
            {/* Clients Routes */}

            <Route path="/client/dashboard" component={ClientBooking} />

            <Route component={PageNotFound} />

          </Switch>
          <Footer />
        </Suspense>
      </div >
    );
  }
}

export default App;
