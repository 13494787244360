import React,{ Component } from "react";

export class AvailableServiceList extends Component{
    render(){
        return(
            <div className="avilable-services-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <h3>Available now</h3>  
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <a href="#" className="pull-right view-all">View all</a>  
                  </div>
                </div>
              </div>
              <div className="row">
                  {this.props.availableServices && this.props.availableServices.map((item,index)=>{
                      return  <ServiceItem 
                      key={index}
                      price={item.price} 
                      name={item.name} 
                      startTime={item.startTime}
                      endTime={item.endTime}
                      description={item.description}
                      companyIcon={item.className}
                      descriptionTitle={item.descriptionTitle}
                      imgUrl={item.imgUrl}
                      companyName={item.companyName}
                      />
                  }) }   
              </div>
            </div>
          </div>
        )
    }
}

function ServiceItem(props){
    return <div className="col-xs-12 col-sm-3">
    <div className="services-items">
      <div className="services-image">
        <img src={require("./../../../../img/services-img.png")} alt="Service" />
        <span className="services-price">
          <span><a href="#">${props.price}</a></span>
        </span>
      </div>
      <div className="services-content">
        <div className="time-sec">
          <a href="#"><small>{props.name}</small></a>  
          <span className="service-time">
            <i className="fa fa-clock-o"></i> {props.startTime} -{props.endTime}
          </span>
        </div>
        <h4><a href="#">{props.descriptionTitle}</a></h4>
        <p>{props.description}</p>
        <div className="company-logo">
          <img src={require("./../../../../img/icon/company-icon.png")} alt="icon" />
          <span>{props.companyName}</span>
        </div>
      </div>
    </div>
  </div>
}