import * as Action from './../Actions/action';
import StoreState from './../../../Constants/initialState';
import cloneDeep from 'lodash/cloneDeep';

export default function LoginReducer(state = StoreState.login, action) {
    switch (action.type) {


        case Action.LoginSuccessful:
            return action.payload


        case Action.LogOutSuccessful:
            return action.payload

        case Action.pageNotFound:
            let payload = cloneDeep(action.payload);
            return payload;


        default:
            return state;
    }
}