import React, { Component } from "react";
import { connect } from "react-redux";
import { forgetPassword } from "./../ApiCall/apiCalls";
import { Link } from "react-router-dom";
import loader from "./../../../img/Spinner-1s-200px.gif";
import externalFileLoad from "../../externalfileLoad";
class ForgetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      currentIp: "",
      message: "",
      messageType: ""
    };
  }

  componentDidMount() {
    externalFileLoad();
    let th = this;
    var xhttpIp2 = new XMLHttpRequest();
    xhttpIp2.onreadystatechange = function() {
      if (this.readyState === 4 && this.status === 200) {
        let ip = this.response;
        th.setState({
          currentIp: ip
        });
      }
    };
    xhttpIp2.open("GET", "https://api.ipify.org", true);
    xhttpIp2.send();
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.apiCallStatus.apiCallFor === "forgetPassword" &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false
    ) {
      this.setState({
        email: "",
        message: nextProps.apiCallStatus.message,
        messageType: "success"
      });
    }
  }
  handleChange = e => {
    e.preventDefault();
    var id = e.target.id;
    var val = e.target.value;
    this.setState({
      [id]: val
    });
  };
  handleSubmit = event => {
    var isEmpty = this.isEmptyFields();
    if (isEmpty === true) {
      var check = this.checkEmail();
      if (check === false) {
        this.props.forgetPassword(this.state.email, this.state.currentIp);
        this.setState({
          message: "",
          messageType: ""
        });
      } else {
        this.setState({
          message: "Email is not valid",
          messageType: "danger"
        });
      }
    } else {
      this.setState({
        message: isEmpty,
        messageType: "danger"
      });
    }

    event.preventDefault();
  };
  validateEmail = () => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.email);
  };
  checkEmail = () => {
    var isemailvalid = this.validateEmail();
    if (isemailvalid) {
      return false;
    } else {
      return true;
    }
  };
  isEmptyFields = () => {
    if (this.state.email === "") {
      this.setState({ isFieldsEmpty: true });
      return "Email is empty";
    } else {
      return true;
    }
  };
  render() {
    return (
      <div className="login-sec">
        <div className="container">
          <div className="login2-panel">
            <div className="login-holder">
              <img src={require("./../../../img/login-logo.jpg")} alt="logo" />
            </div>
            <form>
              <div className="form-group">
                <label
                  htmlFor="exampleInputEmail1"
                  className="bmd-label-floating"
                >
                  Email address
                </label>
                <input
                  type="email"
                  value={this.state.email}
                  onChange={e => this.handleChange(e)}
                  className="form-control"
                  id="email"
                />
              </div>
              {this.state.message !== "" &&
              this.state.messageType === "danger" ? (
                <div className="alert alert-danger">{this.state.message}</div>
              ) : (
                ""
              )}
              {this.state.message !== "" &&
              this.state.messageType === "success" ? (
                <div className="alert alert-success">{this.state.message}</div>
              ) : (
                ""
              )}
              {this.props.apiCallStatus.apiCallFor === "forgetPassword" &&
              this.props.apiCallStatus.isCompleted === false &&
              this.props.apiCallStatus.isFailed === false ? (
                <div className="text-center">
                  <img
                    className="logo"
                    style={{ width: "83px", display: "inline" }}
                    src={loader}
                  />
                </div>
              ) : (
                ""
              )}
              {this.props.apiCallStatus.apiCallFor === "forgetPassword" &&
              this.props.apiCallStatus.isCompleted === true &&
              this.props.apiCallStatus.isFailed === true ? (
                <div className="alert alert-danger">
                  {" "}
                  {this.props.apiCallStatus.message}{" "}
                </div>
              ) : (
                ""
              )}
              <div className="submit-btn text-center">
                <button
                  type="submit"
                  onClick={e => this.handleSubmit(e)}
                  className="btn btn-primary btn-raised"
                >
                  Send Email
                </button>
              </div>
            </form>
          </div>
          <div className="login-now text-center">
            <p>
              Already have HealMaker Account? <Link to="/login">Login</Link>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  forgetPassword: (email, ip) => dispatch(forgetPassword(email, ip))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgetPassword);
