import React from 'react';
import { connect } from 'react-redux';
import SubBanner from "../../../Common/Components/healerBanner";
import Timeline from 'react-calendar-timeline'
import moment from "moment";
import externalFileLoad from '../../../externalfileLoad';
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import 'react-calendar-timeline/lib/Timeline.css'

class HealerCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profile: "",
      email: '',
      groups: [
        { id: 1, title: '1 AM' },
        { id: 2, title: '2 AM' },
        { id: 3, title: '3 AM' },
        { id: 4, title: '4 AM' }
      ],
      items: [
        {
          id: 1,
          group: 1,
          title: 'item 1',
          start_time: moment(),
          end_time: moment().add(1, 'hour'),
          canMove: false,
          canResize: false,
          canChangeGroup: false,
          style: {
            backgroundColor: '#7F8FCD'
          }

        },
        {
          id: 2,
          group: 2,
          title: 'item 2',
          start_time: moment().add(-0.5, 'hour'),
          end_time: moment().add(0.5, 'hour'),
          style: {
            backgroundColor: '#7F8FCD'
          }
        },
        {
          id: 3,
          group: 1,
          title: 'item 3',
          start_time: moment().add(2, 'hour'),
          end_time: moment().add(3, 'hour'),
          style: {
            backgroundColor: '#7F8FCD'
          }
        }
      ]
    }
  }
  componentDidMount() {
    externalFileLoad();
    this.setState({
      profile: JSON.parse(getCookie("profile"))
    }, () => {
      console.log(this.state.profile)
    })
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    this.setState({
      email
    });
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (hasUnacceptedAgreements === '1') {
      this.props.history.push('/verify-service-document')
    }
    this.bindCalender();
  }
  bindCalender = () => {
    let $ = window.jQuery;
    $("#datepicker").datepicker({
      onSelect: function (dateText) {
        // console.log("Selected date: " + dateText + "; input's current value: " + this.value);
      }
    })

  }
  eventClick = (id,e,time) =>{
    this.props.history.push("/healer-user-profile-preview/" + id)
    debugger
  }
  render() {
    return (
      <main>
        <SubBanner commonReducer={this.props.commonReducer} subHeader="Bookings for" />
        <div className="card-sec">
          <div className="container">
            <div className="Card-panel calendar-sec">
              <strong><i className="fa fa-picture-o"></i> {this.state.profile.first_name} |  Calendar</strong>
              <form>
                <div className="row">
                  <div className="col-xs-12 col-sm-4">
                    <div className="custom-form-group">
                      <div className="right-inner-addon date datepicker" data-date-format="yyyy-mm-dd">
                        <input  value=""
                          autoComplete='off' className=" form-control custom-form" type="text" id="datepicker"  placeholder="MM/DD/YY" />
                        <img src={require("../../../../img/cal-icon.png")} alt="icon" />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="timeline-box">
                <Timeline
                  groups={this.state.groups}
                  items={this.state.items}
                  defaultTimeStart={moment().add(-12, 'hour')}
                  defaultTimeEnd={moment().add(12, 'hour')}
                  onItemClick = {this.eventClick}
                  onTouchStart={() => console.log('touch click')}
                />
                {/* <img src={require("../../../..//img/calendar.jpg")} alt="timeline" /> */}
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealerCalendar)