import * as apiCallActions from '../../ApiCallStatus/Actions/action';
import * as Actions from './../Actions/action'
import {
    Constant
} from './../../../Constants/constant'
import {
    getCookie,
    setCookie
} from "./../ApiCall/cookies";

export function login(user) {
    return dispatch => {
        dispatch(apiCallActions.ApiRequestedAction({
            apiCallFor: 'login'
        }));
        fetch(Constant.apiURl + "login", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: user.email,
                password: user.password
            })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson.login === true) {
                let profile = JSON.stringify(myJson.data);
                
                setCookie("profile",profile, 30);
                setCookie("user_email", user.email, 30);
                setCookie("token", myJson.token, 30);
                setCookie("user_id", myJson.user_id, 30);
                setCookie("user_Role", myJson.user_role, 30);
                setCookie("hasUnacceptedAgreements", myJson.hasUnacceptedAgreements, 30);
                dispatch(Actions.logInAction(
                    myJson.token,
                    false))
                dispatch(apiCallActions.ApiFulfilledAction({
                    apiCallFor: 'login',
                    message: {
                        has_location: myJson.has_location,
                        completed_profile: myJson.completed_profile
                    }
                }))
            } else {
                if (myJson.validation.length === 0) {
                    dispatch(apiCallActions.ApiRejectedAction({
                        apiCallFor: 'login',
                        message: myJson.message
                    }))
                } else {
                    dispatch(apiCallActions.ApiRejectedAction({
                        apiCallFor: 'login',
                        message: myJson.validation
                    }))
                }

            }

        }).catch((error) => {
            dispatch(apiCallActions.ApiRejectedAction({
                apiCallFor: 'login',
                message: error.message
            }));
        });
    }
}

export function signUp(user) {
    return dispatch => {
        dispatch(apiCallActions.ApiRequestedAction({
            apiCallFor: 'signUp'
        }));
        fetch(Constant.apiURl + "register", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                role_id: 1,
                email: user.email,
                password: user.password,
                password_confirmation: user.confirmPassword
            })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson.success === "1") {
                dispatch(apiCallActions.ApiFulfilledAction({
                    apiCallFor: 'signUp',
                    message: myJson.message,
                    response: myJson
                }))

            } else {
                if (myJson.validation.length === 0) {
                    dispatch(apiCallActions.ApiRejectedAction({
                        apiCallFor: 'signUp',
                        message: myJson.message,
                        response: myJson.data
                    }))
                } else {
                    dispatch(apiCallActions.ApiRejectedAction({
                        apiCallFor: 'signUp',
                        message: myJson.validation,
                        response: myJson.data
                    }))
                }
            }
        }).catch((error) => {
            dispatch(apiCallActions.ApiRejectedAction({
                apiCallFor: 'signUp',
                message: error.message,
                response: ""
            }));
        });
    }
}

export function healerSignUp(user) {
    return dispatch => {
        dispatch(apiCallActions.ApiRequestedAction({
            apiCallFor: 'healerSignUp'
        }));
        fetch(Constant.apiURl + "register", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                role_id: 2,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                password: user.password,
                password_confirmation: user.confirmPassword
            })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson.success === "1") {

                dispatch(apiCallActions.ApiFulfilledAction({
                    apiCallFor: 'healerSignUp',
                    message: myJson.message,
                    response: myJson
                }))
            } else {
                dispatch(apiCallActions.ApiRejectedAction({
                    apiCallFor: 'healerSignUp',
                    message: myJson.validation,
                    response: myJson
                }))
            }
        }).catch((error) => {
            dispatch(apiCallActions.ApiRejectedAction({
                apiCallFor: 'healerSignUp',
                message: error.message,
                response: ""
            }));
        });
    }
}

export function forgetPassword(email, ip) {
    return dispatch => {
        dispatch(apiCallActions.ApiRequestedAction({
            apiCallFor: 'forgetPassword'
        }));
        fetch(Constant.apiURl + "forgot-password", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email,
                ip_address: ip
            })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson.success === "1") {
                dispatch(apiCallActions.ApiFulfilledAction({
                    apiCallFor: 'forgetPassword',
                    message: myJson.message
                }))
            } else {
                dispatch(apiCallActions.ApiRejectedAction({
                    apiCallFor: 'forgetPassword',
                    message: myJson.message
                }));
            }
        }).catch((error) => {
            dispatch(apiCallActions.ApiRejectedAction({
                apiCallFor: 'forgetPassword',
                message: error.message
            }));
        });
    }
}

export function resetPassword(token, password, confirmPassword) {
    return dispatch => {
        dispatch(apiCallActions.ApiRequestedAction({
            apiCallFor: 'resetPassword'
        }));
        fetch(Constant.apiURl + "reset-password", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                auth_token: token,
                password: password,
                password_confirmation: confirmPassword
            })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson.success === "1") {
                dispatch(apiCallActions.ApiFulfilledAction({
                    apiCallFor: 'resetPassword',
                    message: myJson.message
                }))
            } else {

                dispatch(apiCallActions.ApiRejectedAction({
                    apiCallFor: 'resetPassword',
                    message: myJson.validation
                }));
            }
        }).catch((error) => {
            dispatch(apiCallActions.ApiRejectedAction({
                apiCallFor: 'resetPassword',
                message: error.message
            }));
        });
    }
}

export function socialsignUp(token, platform, role) {
    return dispatch => {
        dispatch(apiCallActions.ApiRequestedAction({
            apiCallFor: 'socialsignUp'
        }));
        fetch(Constant.apiURl + "social-register", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                access_token: token,
                plat_form: platform,
                role_id: role
            })
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            if (myJson.success === "1") {
                if (myJson.login === true) {
                    setCookie("user_email", myJson.email, 30);
                    setCookie("token", myJson.token, 30);
                    setCookie("user_id", myJson.user_id, 30);
                    setCookie("user_Role", myJson.user_role, 30);
                    dispatch(Actions.logInAction(
                        myJson.token,
                        false))
                    dispatch(apiCallActions.ApiFulfilledAction({
                        apiCallFor: 'socialsignUp',
                        message: myJson.message
                    }))
                }
            } else {
                dispatch(apiCallActions.ApiRejectedAction({
                    apiCallFor: 'socialsignUp',
                    message: myJson.message
                }))
            }
        }).catch((error) => {
            dispatch(apiCallActions.ApiRejectedAction({
                apiCallFor: 'socialsignUp',
                message: error.message
            }));
        });
    }
}