import React,{ Component } from "react";

export class ProviderList extends Component{
    render(){
        return(
            <div className="providers-services-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <h3>Top providers </h3>  
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <a href="#" className="pull-right view-all">View all</a>  
                  </div>
                </div>
              </div>
              <div className="row">
              {this.props.providerList && this.props.providerList.map((item,index)=>{
                      return <ProviderItem
                      key={index}
                      location={item.location}
                      startTime={item.startTime}
                      endTime={item.endTime} 
                      descriptionTitle={item.descriptionTitle}
                      categories={item.categories}
                      reviews={item.reviews}/>
                  }) }  
            
                </div>
            </div>
          </div>
        )
    }
}

function ProviderItem(props){
    return <div className="col-xs-12 col-sm-3">
    <div className="services-items">
      <div className="services-image">
        <img src={require('../../../../img/services-img-8.jpg')} alt="Service" />
        <div className="service-logo-sec">
          <div className="table-method">
            <a href="#">
              <img 
              src={require('../../../../img/icon/company-logo-8.png')} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="services-content">
        <div className="time-sec">
          <small><i className="fa fa-map-marker"></i>  {props.location}  <span className="border-left"></span></small> 
          <span className="service-time">
            <i className="fa fa-clock-o"></i> {props.startTime} - {props.endTime}
          </span> 
        </div>
        <h4><a href="#">{props.descriptionTitle}</a></h4>
        <ul className="categories-item">
            {props.categories && props.categories.map((item,index)=>{
               return <li><a href="#" key={index}>{item}</a></li>
            })}
        </ul>
        <ul className="feedback-star">
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><span className="total-feedback">{props.reviews} Reviews</span></a></li>
        </ul>
      </div>
    </div>
  </div>
}