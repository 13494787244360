import React from 'react';
import { connect } from 'react-redux';
import Banner  from "../../../Common/Components/clientBanner";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { Link } from 'react-router-dom';
import externalFileLoad from '../../../externalfileLoad';
import cardJsLoad from '../../../cardLoadjs';
import moment from 'moment';
import { Spinner } from '../../../Common/Components/loader';
import {Constant} from '../../../../Constants/constant';
class ConfirmBookingInfo extends React.Component {
    constructor() {
        super();
        this.state = {
            bookingInfo : "",
            service_fee : 6.00,
            tax : 6.00,
            card_num : "",
            card_name : "",
            expire_month: "",
            expire_year:"",
            cvc : "",
            errorMsg: "",
            errorState : "",
            isLoader: false,
            instant_booking_fee: 0,
            totlePrice : 0,
        }
    }
    componentDidMount() {
        externalFileLoad();
        cardJsLoad();
       
        let token = getCookie('token');
        let email = getCookie('user_email');
        let userId = getCookie('user_id');
        let userRole = getCookie('user_Role');
        if (token === '') {
            this.props.history.push('/login')
        } else {
            this.props.logInAction(token, email, userId, userRole)
        }
        if(this.props.location.state && this.props.location.state.bookingInfo){
            let data = JSON.parse(this.props.location.state.bookingInfo);
            this.setState({
                bookingInfo : data
            }, () => {
                console.log("state booking info", this.state.bookingInfo)
                this.getBookingReview();
            })
        }else{
            this.props.history.push('/')
        }
        console.log(this.props.location.state)
       
    }
    getBookingReview = () => {
        this.setState({
            isLoader : true
        })
        let self = this;
        let formData = new FormData();
        formData.append("auth_token",getCookie("token"))
        formData.append("service_id",this.state.bookingInfo.service_id)
        formData.append("booked_date",this.state.bookingInfo.date)
        formData.append("booked_time",this.state.bookingInfo.time)
        formData.append("total_person",this.state.bookingInfo.total_people)
        formData.append("instant_booking",this.state.bookingInfo.isBookNow == true ? 1 : 0)
        formData.append("location_id",this.state.bookingInfo.locationAddress.locationId)
        formData.append("duration_id",this.state.bookingInfo.duration.id)
        fetch(Constant.apiURl + "book/review", {
            method: 'POST',
            body: formData
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            self.setState({
                isLoader : false
            })
            if (myJson.success == 1) {
                self.setState({
                    tax_amount : myJson.data.tax_amount,
                    service_price : myJson.data.service_price,
                    service_fee : myJson.data.service_fee,
                    instant_booking_fee : myJson.data.instant_booking_fee,
                    totlePrice : myJson.data.client_receivable
                })
               
            } else {
  
            }
  
        }).catch((error) => {
            self.setState({
                isLoader : false
            })
        });
    }
    componentWillUnmount() {
    }
    validation = () => {
        let expire_month = Number(this.state.expire_month);
        
        if(this.state.card_num == ""){
            this.setState({
                errorMsg : "please enter card num",
                errorState: "card_num"
            })
        }
        else if(this.state.card_num.length < 16){
            this.setState({
                errorMsg : "please enter valid card num",
                errorState: "card_num"
            })
        }
        else if(this.state.card_name == ""){
            this.setState({
                errorMsg : "please enter name of card",
                errorState: "card_name"
            })
        }
        else if(this.state.expire_month == ""){
            this.setState({
                errorMsg : "please enter card expire month",
                errorState: "expire_month"
            })
        }
        else if(expire_month > 12){
            this.setState({
                errorMsg : "please enter valid expire date",
                errorState: "expire_month"
            })
        }
        else if(this.state.expire_year == ""){
            this.setState({
                errorMsg : "please enter card expire year",
                errorState: "expire_year"
            })
        }
        else if(this.state.cvc == ""){
            this.setState({
                errorMsg : "please enter cvc",
                errorState: "cvc"
            })
        }
        else if(this.state.cvc.length < 3 ){
            this.setState({
                errorMsg : "please enter valid cvc number",
                errorState: "cvc"
            })
        }else{
            this.setState({
                errorMsg : "",
                errorState: ""
            })
            return true;
        }
    }
    addBooking = () => {
        let self = this;
        this.setState({
            isLoader : true
        })
        let formData = new FormData();
        formData.append("auth_token",getCookie("token"));
        formData.append("user_id",getCookie("user_id"));
        formData.append("service_id",this.state.bookingInfo.service_id);
        formData.append("booked_date",this.state.bookingInfo.date);
        formData.append("booked_time",this.state.bookingInfo.time);
        formData.append("location_id",this.state.bookingInfo.locationAddress.locationId);
        formData.append("duration_id",this.state.bookingInfo.duration.id);
        formData.append("card_num",this.state.card_num);
        formData.append("card_name",this.state.card_name);
        formData.append("expire_month",this.state.expire_month);
        formData.append("expire_year",this.state.expire_year);
        formData.append("cvc",this.state.cvc);
        fetch(Constant.apiURl + "book", {
            method: 'POST',
            body: formData
        }).then(function (response) {
            return response.json();
        }).then(function (myJson) {
            self.setState({
                isLoader : false
            })
            if (myJson.success == 1) {
              self.props.history.push({
                pathname : "/thankyou",
                state :{
                  orderSucces: JSON.stringify(myJson.data)
                }
                } )
               
            } else {
                self.setState({
                    errorMsg : "Error in booking process, please try later",
                    errorState: "bookingProcess"
                })
            }
  
        }).catch((error) => {
            self.setState({
                isLoader : false,
                errorMsg : "Error in booking process, please try later",
                errorState: "bookingProcess"
            })
        });
    }
    confirmBooking = (e) => {
        let valid = this.validation();
        if(valid){
            this.addBooking();
        }
    }
    render() {
        return (
            <main>
                <Banner commonReducer={this.props.commonReducer} />
                {this.state.isLoader ? <Spinner/> : ""}
                <div className="services-sec">
                    <div className="container">
                        <div className="table-sec">
                            <h1>Payment Verification</h1>
                            <div className="card-panel">
                                <div className="payment-panel">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="custom-form-group">
                                                <label>Location</label>
                                                <span className="bmd-form-group"><input disabled value={this.state.bookingInfo != "" ? this.state.bookingInfo.locationAddress.name : ""} type="text" className="form-control custom-form" placeholder="e.g. Hot power yoga, hatha yoga, rock and flow   " required="" /></span>
                                            </div>
                                            <div className="custom-form-group">
                                                <label>Date and Time</label>
                                                <span className="bmd-form-group"><input type="text" disabled value={this.state.bookingInfo.date + " " + this.state.bookingInfo.time} className="form-control custom-form" placeholder="MM/DD/YYYY 3:00 PM" required="" /></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-9">
                                            <div className="custom-form-group">
                                                <label className="font-lightpink">About Your Session</label>
                                                <select className="custom-select">
                                                    <option selected="">Service is for myself</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-3">
                                            <a href="javascript:void(0);" className="btn btn-md btn-other-guest ripple">For another guest</a>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="custom-form-group">
                                                <label>Briefly describe your “Body Massage”.</label>
                                                <textarea className="form-control custom-form" placeholder=" " required="" value=""></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-sec">
                            <div className="card-panel">
                                <div className="payment-panel">
                                    <div className="services-list-form">
                                        <strong><i className="fa fa-credit-card-alt"></i> | Card Information</strong>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-7">
                                            <form>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-form-group">
                                                            <label>Card Number</label>
                                                            <input type="text" className="form-control custom-form" value={this.state.card_num} onChange={(e) => this.setState({card_num : e.target.value})} name="number" />
                                                            {this.state.errorMsg != "" && this.state.errorState == "card_num" ? 
                                                            <div className="alert alert-danger mt-3">
                                                            {this.state.errorMsg}
                                                            </div> : ""}
                                                        </div>
                                                        <div className="custom-form-group">
                                                            <label>Name of the Card</label>
                                                            <input type="text" value={this.state.card_name} onChange={(e) => this.setState({card_name : e.target.value})} className="form-control custom-form" name="full-name" />
                                                            {this.state.errorMsg != "" && this.state.errorState == "card_name" ? 
                                                            <div className="alert alert-danger mt-3">
                                                            {this.state.errorMsg}
                                                            </div> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <label>Expiry Date</label>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="custom-form-group">
                                                            <input type="text" className="form-control custom-form" value={this.state.expire_month} onChange={(e) => this.setState({expire_month : e.target.value})} name="expiry" />
                                                            {this.state.errorMsg != "" && this.state.errorState == "expire_month" ? 
                                                            <div className="alert alert-danger mt-3">
                                                            {this.state.errorMsg}
                                                            </div> : ""}
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="custom-form-group">
                                                            <input type="text" className="form-control custom-form" value={this.state.expire_year} onChange={(e) => this.setState({expire_year : e.target.value})} name="expiry" />
                                                            {this.state.errorMsg != "" && this.state.errorState == "expire_year" ? 
                                                            <div className="alert alert-danger mt-3">
                                                            {this.state.errorMsg}
                                                            </div> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="custom-form-group">
                                                            <label>CVC</label>
                                                            <input type="text" className="form-control custom-form" name="cvc" value={this.state.cvc} onChange={(e) => this.setState({cvc : e.target.value})} />
                                                            {this.state.errorMsg != "" && this.state.errorState == "cvc" ? 
                                                            <div className="alert alert-danger mt-3">
                                                            {this.state.errorMsg}
                                                            </div> : ""}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div className="col-sm-5">
                                            <div className="payment-imageholder">
                                                <div className='card-wrapper'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-sec">
                            <div className="card-panel">
                                <div className="payment-panel">
                                    <div className="services-list-form">
                                        <strong><i className="fa fa-credit-card-alt"></i> | Payment</strong>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="service-price-tab">
                                                <ul>
                                                    <li>Date of service: {this.state.bookingInfo != "" ? moment(this.state.bookingInfo.date).format('LL')  : ""}</li>
                                                    <li>Time of service: {this.state.bookingInfo != "" ? this.state.bookingInfo.time : ""}</li>
                                                    <li>Session length: {this.state.bookingInfo != "" ? this.state.bookingInfo.duration.service_time: ""} min</li>
                                                </ul>
                                            </div>
                                            <div className="service-price-list">
                                                <ul>
                                                    <li>
                                                        <span className="booking-title">Cost of your healing service:</span>
                                                        <span className="booking-amount">${this.state.service_price}</span>
                                                    </li>
                                                    <li>
                                                        <span className="booking-title">Service fee:</span>
                                                        <span className="booking-amount">${this.state.service_fee}</span>
                                                    </li>
                                                    <li>
                                                        <span className="booking-title">Tax (if applicable):</span>
                                                        <span className="booking-amount">${this.state.tax_amount}</span>
                                                    </li>
                                                    {this.props.location.state.bookingInfo.isBookNow == true ? <li>
                                                        <span className="booking-title">Instant Booking Service:</span>
                                                        <span className="booking-amount">${this.state.instant_booking_fee}</span>
                                                    </li> : ""}
                                                </ul>
                                                <ul className="final-pay">
                                                    <li>
                                                        <span className="booking-title">Total to Pay:</span>
                                                        <span className="booking-amount">${this.state.totlePrice}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="payment-imageholder mt-0">
                                                <img src={require("../../../../img/payment-vector.png")} alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {this.state.errorMsg != "" && this.state.errorState == "bookingProcess" ? 
                                                            <div className="alert alert-danger mt-3">
                                                            {this.state.errorMsg}
                                                            </div> : ""}
                        <a href="javascript:void(0);" className="btn btn-md btn-verify ripple pull-right" onClick={(e) => this.confirmBooking(e)} >Confirm Booking</a>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </main>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus,
    commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    // getUserServicesData: (token, userId) => dispatch(getUserServicesData(token, userId)),
    logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConfirmBookingInfo)