import React from 'react';
import { connect } from 'react-redux';
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { ApiClearAction } from "./../../../ApiCallStatus/Actions/action";
import { getLocationDetail } from "../../Profile/ApiCalls/apicalls";
import { getAllCountries, getStateByCountry, getCitiesByState, editServiceLocation, getServiceTypes } from "./../../../Common/Apicalls/apicalls";
import loader from './../../../../img/Spinner-1s-200px.gif';
import externalFileLoad from '../../../externalfileLoad';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

class EditLocation extends React.Component {
  constructor() {
    super();
    this.state = {
      name: '',
      isDisplayName: false,
      address1: '',
      address2: '',
      countryId: '',
      stateId: '',
      cityId: '',
      zipcode: '',
      serviceType: '',
      image1: [],
      image2: [],
      image3: [],
      image4: [],
      errorMessage: '',
      errorType: '',
      errorFor: '',
      address: '',
      lat: '',
      long: '',
      serverValidation: {},
      stateName: "",
      cityName: "",
      userRole : "",
      locationDes: ""
    }
  }
  componentDidMount() {
    externalFileLoad();
    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_id');
    let userRole = getCookie('user_Role');
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (token === '') {
      this.props.history.push('/login')
    } else {
      this.props.logInAction(token, email, userId, userRole)
      if(hasUnacceptedAgreements === '1'){
        this.props.history.push('/verify-service-document')
      }
    }
    this.setState({
      userRole
    })
    this.props.getAllCountries();
    this.props.getLocationDetail(token,userId,this.props.match.params.id);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.apiCallStatus.apiCallFor === 'editServiceLocation' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      this.setState({
        name: '',
        isDisplayName: false,
        address1: '',
        address2: '',
        countryId: '',
        stateId: '',
        cityId: '',
        zipcode: '',
        serviceType: '',
        errorMessage: '',
        errorType: '',
        serverValidation: {}
      })
      this.props.history.push("/healer-location");
    }
    if (nextProps.apiCallStatus.apiCallFor === 'getLocationDetail' &&
    Object.keys(nextProps.profileReducer.locationDetail).length > 0 &&
     nextProps.apiCallStatus.isCompleted === true &&
    nextProps.apiCallStatus.isFailed === false) {
      setTimeout(()=>{
        for (let index = 0; index < nextProps.profileReducer.locationDetail.images.length; index++) {
          const element = nextProps.profileReducer.locationDetail.images[index];
          let imgUrl =
          "https://healmaker.wantechsolutions.com" +
          element.path +
                    element.file_name;
          window.jQuery('#previewimage' + (index+1)).attr('src', imgUrl);
          
        }
      },1000)
    this.setState({
      name: nextProps.profileReducer.locationDetail.name,
      isDisplayName: false,
      address1: nextProps.profileReducer.locationDetail.address1,
      address2: nextProps.profileReducer.locationDetail.address2 ? nextProps.profileReducer.locationDetail.address2  :'',
      countryId: nextProps.profileReducer.locationDetail.country_id,
      stateId: nextProps.profileReducer.locationDetail.state_id,
      cityId: nextProps.profileReducer.locationDetail.city_id,
      zipcode: nextProps.profileReducer.locationDetail.zip_code,
      serviceType: nextProps.profileReducer.locationDetail.service_type.toString(),
      lat: nextProps.profileReducer.locationDetail.lat,
      long: nextProps.profileReducer.locationDetail.lon,
      locationDes : nextProps.profileReducer.locationDetail.description,
      errorMessage: '',
      errorType: '',
      serverValidation: {}
    })
    nextProps.getStateByCountry(nextProps.profileReducer.locationDetail.country_id);
    nextProps.getCitiesByState(nextProps.profileReducer.locationDetail.state_id)

  }
    if (nextProps.apiCallStatus.apiCallFor === 'editServiceLocation' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === true) {
      this.setState({
        serverValidation: nextProps.apiCallStatus.message
      })
      this.props.ApiClearAction()
    }
    
    if (nextProps.apiCallStatus.apiCallFor === 'getStateByCountry' &&
    nextProps.apiCallStatus.isCompleted === true &&
    nextProps.apiCallStatus.isFailed === false) {
    if ((this.props.commonReducer.state && this.props.commonReducer.state.length > 0) && this.state.stateName != "") {
      let s = this.props.commonReducer.state.find(s => s.name = this.state.stateName)
      if (s) {
        this.setState({
          stateId: s.id
        }, () => {
          this.getCityByState(s.id.toString())
          console.log(this.state.stateId)
        })
      }
    }
  }

  if (nextProps.apiCallStatus.apiCallFor === 'getCitiesByState' &&
    nextProps.apiCallStatus.isCompleted === true &&
    nextProps.apiCallStatus.isFailed === false) {

    if ((this.props.commonReducer.cities && this.props.commonReducer.cities.length > 0) && this.state.cityName != "") {
      let s = this.props.commonReducer.cities.find(s => s.name = this.state.cityName)
      if (s) {
        this.setState({
          cityId: s.id
        }, () => {
          //  this.getCityByState(s.id.toString())
          console.log(this.state.stateId)
        })
      }
    }
  }
  }
  handleChange = (e) => {
    let id = e.target.id;
    let val = e.target.value;
    this.setState({
      [id]: val,
      errorMessage: '',
      errorType: '',
      errorFor: '',
      serverValidation: {}
    })
  }
  handleSelect = (address, id) => {
    let th = this;
    geocodeByAddress(address)
      .then(results => {
        th.setState({
          [id]: results[0].formatted_address
        })
        getLatLng(results[0]).then(latLng => {
          th.setState({
            lat: latLng.lat,
            long: latLng.lng
          })
        })
        let country = '';
        let state = ''
        let city = ''
        let address = results[0].address_components;
        for (let index = 0; index < address.length; index++) {
          const element = address[index];
          let countryIndex = -1;
          let stateIndex = -1;
          let cityIndex = -1;
          countryIndex = element.types.indexOf("country");
          if (countryIndex > -1) {
            country = element.long_name
          }
          stateIndex = element.types.indexOf("administrative_area_level_1");
          if (stateIndex > -1) {
            state = element.long_name
          }
          cityIndex = element.types.indexOf("administrative_area_level_2");
          if (cityIndex > -1) {
            city = element.long_name
          }

        }
        this.setState({
          stateName: state,
          cityName: city
        })
        if (this.props.commonReducer.countries && this.props.commonReducer.countries.length > 0) {
          let c = this.props.commonReducer.countries.find(cn => cn.name == country)
          if (c) {
            this.props.getStateByCountry(c.id)
            th.setState({
              countryId: c.id
            })
          }
        }
      })
      .catch(error => console.error('Error', error));
  };
  getStateByCountry = (e) => {
    let id = e.target.value;
    if (id !== '') {
      this.setState({
        countryId: id,
        errorMessage: '',
        errorType: '',
        errorFor: '',
        serverValidation: {}
      })
      this.props.getStateByCountry(id)
    }
  }
  getCityByState = (e) => {
 
    if (e.target && e.target.value !== '') {
      let stateId = Number(e.target.value);
      this.setState({
        stateId: stateId,
        errorMessage: '',
        errorType: '',
        errorFor: '',
        serverValidation: {}
      })
      this.props.getCitiesByState(stateId.toString())
    }
    else if (e != '') {
      let stateId = Number(e);
      this.setState({
        stateId: stateId,
        errorMessage: '',
        errorType: '',
        errorFor: '',
        serverValidation: {}
      })
      this.props.getCitiesByState(stateId.toString())
    }
  }
  validateFields = () => {
    let isValid = true;
    if (this.state.name === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Name is empty',
        errorType: 'danger',
        errorFor: 'name'
      })
    } else if (this.state.address1 === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Address1 is empty',
        errorType: 'danger',
        errorFor: 'address1'
      })
    }
    // else if (this.state.address2 === '') {
    //   isValid = false;
    //   this.setState({
    //     errorMessage: 'Address2 is empty',
    //     errorType: 'danger',
    //     errorFor : 'address2'
    //   })
    // }
    else if (this.state.countryId === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Please select country',
        errorType: 'danger',
        errorFor: 'countryId'
      })
    } else if (this.state.stateId === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Please select state',
        errorType: 'danger',
        errorFor: 'stateId'
      })
    } else if (this.state.cityId === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Please select city',
        errorType: 'danger',
        errorFor: 'cityId'
      })
    } else if (this.state.zipcode === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Zipcode is empty',
        errorType: 'danger',
        errorFor: 'zipcode'
      })
    }
    // else if (this.state.serviceType === '') {
    //   isValid = false;
    //   this.setState({
    //     errorMessage: 'Please select service type',
    //     errorType: 'danger',
    //     errorFor : 'serviceType'
    //   })
    // }
    return isValid;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let isValid = this.validateFields();
    if (isValid) {
      let token = getCookie('token');
      let userId = getCookie('user_id');
      this.setState({
        errorMessage: '',
        errorType: '',
        errorFor: '',
        serverValidation: {}
      })
      let images = [];
      if (this.state.image1.length > 0) {
        images.push(this.state.image1[0])
      }
      if (this.state.image2.length > 0) {
        images.push(this.state.image2[0])
      }
      if (this.state.image3.length > 0) {
        images.push(this.state.image3[0])
      }
      if (this.state.image4.length > 0) {
        images.push(this.state.image4[0])
      }
      this.props.editServiceLocation({
        id : this.props.match.params.id,
        auth_token: token,
        name: this.state.name,
        address1: this.state.address1,
        address2: this.state.address2,
        state_id: this.state.stateId,
        country_id: this.state.countryId,
        city_id: this.state.cityId,
        zip_code: this.state.zipcode,
        service_type: this.state.serviceType,
        images: images,
        lat: this.state.lat,
        lon: this.state.long,
        description : this.state.locationDes
      })
    }
  }
  handleUploadFile = (e) => {
    let id = e.target.id;
    let length = e.target.files.length <= 2 ? e.target.files.length : 2;
    let file = e.target.files;
    for (let index = 1; index <= length; index++) {
      var reader = new FileReader();
      reader.onload = function (e) {
        window.jQuery('#preview' + id).attr('src', e.target.result);
      }
      this.setState({
        [id]: file
      }, () => reader.readAsDataURL(file[index - 1]));
    }
  }
  backToList = (e) => {
    e.preventDefault();
    this.props.history.push('/healer-location');
  }
  handleAddressChange = (address, id) => {

    this.setState({ [id]: address });
  }

  removeFile = (id) => {
    this.setState({
      [id]: []
    })
    window.jQuery("#" + id).val('');
  }
  render() {
    return (
      <main>
        <div className="login-sec">
          <div className="container">
            <div className="profile-panel services-panel padding-setting">
              {this.state.userRole === '1' ? 
              <h1>Now, let’s add your <span>"Home Location”</span> where <span
              className="font-pink">Healer </span> can provide <br />the service </h1>:
              <h1>Now, let’s add your <span>“Main Location”</span> from where you will provide <br />your <span
                className="font-pink">healing</span> services </h1>}
              <form autoComplete="off">
                <div className="row">
                  <div className="col-xs-12 col-sm-7">
                    <div className="profile-form">
                      <div className="row">
                        <div className="col-xs-12 col-sm-12 ">
                          <div className="custom-form-group">
                            <input type="text" className="form-control custom-form"
                              placeholder={this.props.commonReducer.profile &&
                                this.props.commonReducer.profile.first_name &&
                                this.props.commonReducer.profile.first_name
                                !== '' ? 'Name your location e.g. ' + this.props.commonReducer.profile.first_name + ' Yoga World ' : "Name your location e.g. Yoga World "}
                              id='name' value={this.state.name} onChange={(e) => this.handleChange(e)} required="" />
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'name' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                            {this.state.serverValidation &&
                              this.state.serverValidation.name &&
                              this.state.serverValidation.name[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.name[0]}
                              </div>
                              : ""}


                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="custom-form-group checkbox-form">
                            <label>What is the specific address of the location? </label>
                            <div className="checkbox">
                             {this.state.userRole === '1' ? '':
                              <label>
                                <input type="checkbox" id='isDisplayName' checked={this.state.isDisplayName}
                                  onChange={(e) => this.setState({
                                    isDisplayName: e.target.checked
                                  })} /> Display Personal Name
                             </label>}
                              {this.state.errorMessage !== "" &&
                                this.state.errorType !== "" &&
                                this.state.errorFor === 'isDisplayName' ?
                                <div className={"alert alert-" + this.state.errorType}>
                                  {this.state.errorMessage}
                                </div>
                                : ""}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="custom-form-group">
                            <PlacesAutocomplete value={this.state.address1}
                              id='address1'
                              onChange={(e) => this.handleAddressChange(e, 'address1')}
                              onSelect={(e) => this.handleSelect(e, 'address1')}>
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input {...getInputProps({
                                    placeholder: 'Address1',
                                    className: 'location-search-input form-control custom-form',
                                  })} />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div {...getSuggestionItemProps(suggestion, { className, style, })}>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete>
                            {/* <input type="text" className="form-control custom-form" placeholder="Address1" id='address1'
                             onChange={(e)=>this.handleChange(e)} required="" value={this.state.address1} autoComplete="off"/> */}
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'address1' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="custom-form-group">
                            {/* <PlacesAutocomplete value={this.state.address2}
                              onChange={(e) => this.handleAddressChange(e, 'address2')}
                              onSelect={(e) => this.handleSelect(e, 'address2')}>
                              {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div>
                                  <input {...getInputProps({
                                    placeholder: 'Address2',
                                    className: 'location-search-input form-control custom-form',
                                  })} />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const style = suggestion.active
                                        ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                        : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                      return (
                                        <div {...getSuggestionItemProps(suggestion, { className, style, })}>
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}
                            </PlacesAutocomplete> */}
                            <input type="text" className="form-control custom-form" placeholder="Address2" id='address2'
                             onChange={(e)=>this.setState({address2 : e.target.value})} required="" value={this.state.address2} autoComplete="off"/>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'address2' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6">
                          <div className="custom-form-group">
                            <select className="custom-select" value={this.state.countryId} onChange={(e) => this.getStateByCountry(e)}>
                              <option value=''>Select country</option>
                              {this.props.commonReducer.countries &&
                                this.props.commonReducer.countries.map((item, index) => {
                                  return <option key={index} value={item.id} selected={item.id === this.state.countryId}>
                                    {item.name}</option>
                                })}
                            </select>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'countryId' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                            {this.state.serverValidation &&
                              this.state.serverValidation.country_id &&
                              this.state.serverValidation.country_id[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.country_id[0]}
                              </div>
                              : ""}


                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="custom-form-group">
                            <select className="custom-select" onChange={(e) => this.getCityByState(e)}>
                              {this.props.commonReducer.state.length > 0 ?
                                <option value=''>Select state</option>
                                : ''}
                              {this.props.commonReducer.state && this.props.commonReducer.state.map((item, index) => {
                                return <option key={index} value={item.id} name={item.name}
                                  selected={item.id == this.state.stateId}>{item.name}</option>
                              })}
                            </select>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'stateId' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                            {this.state.serverValidation &&
                              this.state.serverValidation.state_id &&
                              this.state.serverValidation.state_id[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.state_id[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6">
                          <div className="custom-form-group">
                            <select className="custom-select" id='cityId' onChange={(e) => this.handleChange(e)}>
                              {this.props.commonReducer.cities.length > 0 ?
                                <option value=''>Select city</option>
                                : ''}
                              {this.props.commonReducer.cities && this.props.commonReducer.cities.map((item, index) => {
                                return <option key={index} value={item.id} selected={item.id == this.state.cityId}>
                                  {item.name}</option>
                              })}
                            </select>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'cityId' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                            {this.state.serverValidation &&
                              this.state.serverValidation.city_id &&
                              this.state.serverValidation.city_id[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.city_id[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="custom-form-group">
                            <input type="text" value={this.state.zipcode} className="form-control custom-form" placeholder="Zip Code" id='zipcode'
                              onChange={(e) => this.handleChange(e)} required="" />
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'zipcode' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                            {this.state.serverValidation &&
                              this.state.serverValidation.zip_code &&
                              this.state.serverValidation.zip_code[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.zip_code[0]}
                              </div>
                              : ""}

                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="custom-form-group checkbox-form">
                            <label>What is the specific address of the location? </label>
                            <div className="form-check form-check-inline">

                              <div className="radio ml-10">
                                <label>
                                  <input type="radio" id='serviceType' name="optionsRadios" value='1'
                                    checked={this.state.serviceType === "1"} onChange={(e) => this.handleChange(e)} />
                                  <span>Home</span>
                                </label>
                              </div>
                              {this.state.userRole === '1' ?
                              <div className="radio">
                              <label>
                                <input type="radio" id='serviceType' name="optionsRadios" value='3'
                                  checked={this.state.serviceType === "3"} onChange={(e) => this.handleChange(e)} />
                                <span>Other</span>
                              </label>
                            </div> :
                              <div className="radio">
                                <label>
                                  <input type="radio" id='serviceType' name="optionsRadios" value='2'
                                    checked={this.state.serviceType === "2"} onChange={(e) => this.handleChange(e)} />
                                  <span>Business</span>
                                </label>
                              </div>}

                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'serviceType' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}
                            {this.state.serverValidation &&
                              this.state.serverValidation.service_type &&
                              this.state.serverValidation.service_type[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.service_type[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>'
                  {this.state.userRole === '1' ? '':
                  <div className="col-xs-12 col-sm-4 offset-sm-1">
                    <div className="image-upload-sec">
                      <h2>Pictures of location</h2>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6">
                          <div className="upload-btn-wrapper">
                            <div className="image-holder">
                              <a href="#"><img src={this.state.image1.length === 0 ? require('../../../../img/upload-image.png') : ''} id='previewimage1'
                                alt="Placeholder" /></a>
                              {this.state.image1.length !== 0 ?
                                <a href="javascript:void(0)" onClick={() => this.removeFile('image1')} className="remove-image">Remove</a> : ''}
                            </div>
                            <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e)} id='image1' accept="image/*" />
                          </div>

                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="upload-btn-wrapper">
                            <div className="image-holder">
                              <a href="#"><img src={this.state.image2.length === 0 ? require('../../../../img/upload-image.png') : ''} id="previewimage2"
                                alt="Placeholder" /></a>
                              {this.state.image2.length !== 0 ?
                                <a href="javascript:void(0)" onClick={() => this.removeFile('image2')} className="remove-image">Remove</a> : ''}
                            </div>
                            <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e)} id='image2' accept="image/*" />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="upload-btn-wrapper">
                            <div className="image-holder">
                              <a href="#"><img src={this.state.image3.length === 0 ? require('../../../../img/upload-image.png') : ''} id="previewimage3"
                                alt="Placeholder" /></a>
                              {this.state.image3.length !== 0 ?
                                <a href="javascript:void(0)" onClick={() => this.removeFile('image3')} className="remove-image">Remove</a> : ''}
                            </div>
                            <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e)} id='image3' accept="image/*" />
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6">
                          <div className="upload-btn-wrapper">
                            <div className="image-holder">
                              <a href="#"><img src={this.state.image4.length === 0 ? require('../../../../img/upload-image.png') : ''} alt="Placeholder" id="previewimage4" /></a>
                              {this.state.image4.length !== 0 ?
                                <a href="javascript:void(0)" onClick={() => this.removeFile('image4')} className="remove-image">Remove</a> : ''}
                            </div>
                            <input type="file" name="myfile" onChange={(e) => this.handleUploadFile(e)} id='image4' accept="image/*" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
                <textarea id="locationDescription" className="location-descrition textarea-width" placeholder="Describe your location." value={this.state.locationDes} onChange={(e) => this.setState({locationDes : e.target.value})}></textarea>
                {this.state.errorMessage !== "" &&
                  this.state.errorType !== "" &&
                  this.state.errorFor === 'main' ?
                  <div className={"alert alert-" + this.state.errorType}>
                    {this.state.errorMessage}
                  </div>
                  : ""}
                <div className="text-btn">
                  {this.props.apiCallStatus.apiCallFor === 'editServiceLocation'
                    && this.props.apiCallStatus.isCompleted
                    === false && this.props.apiCallStatus.isFailed === false ?
                    <div className="text-center">
                      <img className="logo" style={{ width: "83px", display: 'inline' }} src={loader} />
                    </div>
                    : ""}
                  <button className="btn btn-md btn-back ripple" onClick={(e) => this.backToList(e)}>Back</button>
                  <button className="btn btn-md btn-save-submit ripple" onClick={(e) => this.handleSubmit(e)}>Save &
                   Continue</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  profileReducer: state.profileReducer,
  commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAllCountries: () => dispatch(getAllCountries()),
  getStateByCountry: (countryId) => dispatch(getStateByCountry(countryId)),
  getCitiesByState: (stateId) => dispatch(getCitiesByState(stateId)),
  editServiceLocation: (data) => dispatch(editServiceLocation(data)),
  getServiceTypes: () => dispatch(getServiceTypes()),
  ApiClearAction: () => dispatch(ApiClearAction()),
  logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole)),
  getLocationDetail : (token,userId,locationId)=>dispatch(getLocationDetail(token,userId,locationId))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditLocation)