import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { healerSignUp } from "./../ApiCall/apiCalls";
import { getCookie } from "./../ApiCall/cookies";
import loader from "./../../../img/Spinner-1s-200px.gif";
import externalFileLoad from "../../externalfileLoad";
import PasswordStrengthMeter from '../../PasswordStrengthMeter';
class SignUp extends Component {
  constructor() {
    super();
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      isAgreeTermCondition: false,
      message: "",
      messageType: "",
      messageFor: "",
      serverValidation: {}
    };
  }

  componentDidMount() {
    externalFileLoad();
    let th = this;
    var token = getCookie("token");
    if (token !== "") {
      this.props.history.push("/healer-services-list");
    }
    this.togglePassword();
  }
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.apiCallStatus.apiCallFor === "healerSignUp" &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false
    ) {
      this.props.history.push("/thankyou?email=" + this.state.email);
      this.setState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        message: "",
        messageType: ""
      });
    }
    if (
      nextProps.apiCallStatus.apiCallFor === "healerSignUp" &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === true
    ) {
      this.setState({
        serverValidation: nextProps.apiCallStatus.message
      });
    }
  }
  togglePassword = () => {
    let $ = window.jQuery;
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
    $(".toggle-retype-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }
  handleChange = e => {
    e.preventDefault();
    var id = e.target.id;
    var val = e.target.value;
    this.setState({
      [id]: val,
      message: "",
      messageType: "",
      messageFor: ""
    });
  };
  handleSubmit = event => {
    var isEmpty = this.isEmptyFields();
    if (isEmpty === true) {
      var check = this.checkEmail();
      if (check === false) {
        if (this.state.isAgreeTermCondition === false) {
          this.setState({
            message:
              "Please agree to terms and conditions to proceed with the signup.",
            messageType: "danger",
            messageFor: "agreement"
          });
        } else if (this.state.password === this.state.confirmPassword) {
          this.props.healerSignUp(this.state);
          this.setState({
            message: "",
            messageType: "",
            messageFor: ""
          });
        }
      } else {
        this.setState({
          message: "Email is not valid",
          messageType: "danger",
          messageFor: "email"
        });
      }
    }
    event.preventDefault();
  };
  validateEmail = () => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.email);
  };
  checkEmail = () => {
    var isemailvalid = this.validateEmail();
    if (isemailvalid) {
      return false;
    } else {
      return true;
    }
  };
  isEmptyFields = () => {
    if (this.state.firstName === "") {
      this.setState({
        message: "First Name is empty",
        messageType: "danger",
        messageFor: "firstName"
      });
      return false;
    } else if (this.state.lastName === "") {
      this.setState({
        message: "Last Name is empty",
        messageType: "danger",
        messageFor: "lastName"
      });
      return false;
    } else if (this.state.email === "") {
      this.setState({
        message: "Email is empty",
        messageType: "danger",
        messageFor: "email"
      });
      return false;
    } else if (this.state.password === "") {
      this.setState({
        message: "Password is empty",
        messageType: "danger",
        messageFor: "password"
      });
      return false;
    } else if (this.state.confirmPassword === "") {
      this.setState({
        message: "Retype password is empty",
        messageType: "danger",
        messageFor: "confirmPassword"
      });
      return false;
    } else if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        message: "Password and confirm password does not match.",
        messageType: "danger",
        messageFor: "confirmPassword"
      });
      return false;
    } else {
      return true;
    }
  };
  render() {
    return (
      <main>
        <div className="login-sec">
          <div className="container">
            <div className="login-panel">
              <h1>
                Let’s get <span>started</span>
              </h1>
              <form>
                <div className="form-group">
                  <label for="FirstName" className="bmd-label-floating">
                    First Name
                  </label>
                  <input
                    type="text"
                    value={this.state.firstName}
                    onChange={e => this.handleChange(e)}
                    className="form-control"
                    id="firstName"
                  />
                  {this.state.message !== "" &&
                    this.state.messageType === "danger" &&
                    this.state.messageFor === "firstName" ? (
                      <div className="alert alert-danger">
                        {this.state.message}
                      </div>
                    ) : (
                      ""
                    )}
                </div>
                <div className="form-group">
                  <label for="LastName" className="bmd-label-floating">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.lastName}
                    onChange={e => this.handleChange(e)}
                    id="lastName"
                  />
                  {this.state.message !== "" &&
                    this.state.messageType === "danger" &&
                    this.state.messageFor === "lastName" ? (
                      <div className="alert alert-danger">
                        {this.state.message}
                      </div>
                    ) : (
                      ""
                    )}
                </div>
                <div className="form-group">
                  <label
                    for="exampleInputEmail1"
                    className="bmd-label-floating"
                  >
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={this.state.email}
                    onChange={e => this.handleChange(e)}
                    id="email"
                  />
                  {this.state.message !== "" &&
                    this.state.messageType === "danger" &&
                    this.state.messageFor === "email" ? (
                      <div className="alert alert-danger">
                        {this.state.message}
                      </div>
                    ) : (
                      ""
                    )}
                  {this.state.serverValidation &&
                    this.state.serverValidation.email &&
                    this.state.serverValidation.email[0] ? (
                      <div className={"alert alert-danger"}>
                        {this.state.serverValidation.email[0]}
                      </div>
                    ) : (
                      ""
                    )}
                </div>
                <div className="form-group">
                  <label for="Password" className="bmd-label-floating">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={this.state.password}
                    onChange={e => this.handleChange(e)}
                    id="password"
                  />
                  <span toggle="#password" className="fa fa-fw fa-eye field-icon toggle-password"></span>
                  {this.state.message !== "" &&
                    this.state.messageType === "danger" &&
                    this.state.messageFor === "password" ? (
                      <div className="alert alert-danger">
                        {this.state.message}
                      </div>
                    ) : (
                      ""
                    )}
                  {this.state.serverValidation &&
                    this.state.serverValidation.password &&
                    this.state.serverValidation.password[0] ? (
                      <div className={"alert alert-danger"}>
                        {this.state.serverValidation.password[0]}
                      </div>
                    ) : (
                      ""
                    )}
                </div>
                <div className="form-group">
                  <label for="ConformPassword" className="bmd-label-floating">
                    Confirm Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={this.state.confirmPassword}
                    onChange={e => this.handleChange(e)}
                    id="confirmPassword"
                  />
                  <span toggle="#confirmPassword" className="fa fa-fw fa-eye field-icon toggle-retype-password"></span>
                  {this.state.message !== "" &&
                    this.state.messageType === "danger" &&
                    this.state.messageFor === "confirmPassword" ? (
                      <div className="alert alert-danger">
                        {this.state.message}
                      </div>
                    ) : (
                      ""
                    )}
                  {this.state.serverValidation &&
                    this.state.serverValidation.password_confirmation &&
                    this.state.serverValidation.password_confirmation[0] ? (
                      <div className={"alert alert-danger"}>
                        {this.state.serverValidation.password_confirmation[0]}
                      </div>
                    ) : (
                      ""
                    )}
                  <PasswordStrengthMeter password={this.state.password} />
                </div>
                <div className="checkbox">
                  <label>
                    <input
                      type="checkbox"
                      onChange={e =>
                        this.setState({
                          isAgreeTermCondition: e.target.checked
                        })
                      }
                      checked={
                        this.state.isAgreeTermCondition === true ? true : false
                      }
                    />{" "}
                    I accept HealMaker’s Terms of Services, Payments Terms of
                    Services, Privacy Policy, and Nodiscrmination Policy.
                  </label>
                </div>
                {this.state.message !== "" &&
                  this.state.messageType === "danger" &&
                  this.state.messageFor === "agreement" ? (
                    <div className="alert alert-danger">{this.state.message}</div>
                  ) : (
                    ""
                  )}
                {this.state.message !== "" &&
                  this.state.messageType === "success" ? (
                    <div className="alert alert-danger">{this.state.message}</div>
                  ) : (
                    ""
                  )}
                {this.props.apiCallStatus.apiCallFor === "healerSignUp" &&
                  this.props.apiCallStatus.isCompleted === false &&
                  this.props.apiCallStatus.isFailed === false ? (
                    <div className="text-center">
                      <img
                        className="logo"
                        style={{ width: "83px", display: "inline" }}
                        src={loader}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                <div className="submit-btn text-center">
                  <button
                    type="submit"
                    onClick={e => this.handleSubmit(e)}
                    className="btn btn-primary btn-raised"
                  >
                    Sign up
                  </button>
                </div>
              </form>
            </div>
            <div className="login-now text-center">
              <p>
                Already have HealMaker Account? <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apiCallStatus
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  healerSignUp: user => dispatch(healerSignUp(user))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp);
