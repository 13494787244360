import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from "../../../Common/Components/loader";
import Banner from "../../../Common/Components/healerBanner";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import externalFileLoad from '../../../externalfileLoad';
import { Constant } from './../../../../Constants/constant';
import Swiper from 'react-id-swiper';

const params = {
  slidesPerView: 3,
  spaceBetween: 10,
  slidesPerView: 'auto',
  isLoading: false,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
}
class LocationPreview extends React.Component {
  constructor() {
    super();
    this.state = {
      location: {}
    }
  }
  componentDidMount() {
    externalFileLoad();
    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_id');
    let userRole = getCookie('user_Role');
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (token === '') {
      this.props.history.push('/login')
    } else {
      if (hasUnacceptedAgreements === '1') {
        this.props.history.push('/verify-service-document')
      }
    }
    this.setState({
      isLoading: true
    }, () => this.getLocationById(this.props.match.params.id));
  }

  getLocationById = (id) => {
    let self = this;
    fetch(Constant.apiURl + "get-location-by-id ", {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        location_id: id
      })
    }).then(function (response) {
      return response.json();
    }).then(function (myJson) {
      if (myJson.success === '1') {
        self.setState({
          location: myJson.location,
          isLoading: false
        })
      } else {
        self.setState({
          isLoading: false
        })
      }
    })
  }
  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  }
  render() {
    return (<main>
      <Banner commonReducer={this.props.commonReducer} />
      <div className="location-sec">
        <div className="container">
          <div className="table-sec">
            <h1>Nicole’s Locations</h1>
            <div className="card-panel">
              <div className="locatiointop-table">
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div className="location-heading">
                      <strong>
                        {this.state.location.name}
                      </strong>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                        <li><a href="#"><span>(258)</span></a></li>
                      </ul>

                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="onoff-btn pull-right">
                      <div className="toggleWrapper ">
                        <input type="checkbox" id="hhhh" className="dn" />
                        <label for="hhhh" className="toggle"><span className="toggle__handler"></span></label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className="location-image-holder">
                    {this.state.location.images && this.state.location.images.length > 0 ?
                      <Swiper {...params} >
                        {this.state.location.images.map((img, idx) => {
                          return <img src={Constant.downloadUrl + img.path + img.file_name} key={idx} alt="placeholder" />

                        })}
                      </Swiper>
                      :
                      <img src={require("./../../../../img/location_placeholder_2.jpg")} alt="placeholder" />
                    }

                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="location-maps">
                    <iframe className="border-0" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3403.4281173632285!2d74.27064221480943!3d31.457407357396644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919017bb07114cd%3A0xc1215b29154f5c6a!2sWanTech+Solutions!5e0!3m2!1sen!2s!4v1561212448194!5m2!1sen!2s" width="100%" height="268" frameborder="0" allowfullscreen></iframe>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-xs-12 col-sm-12">
                  <div className="btn-block">
                    <a href="#" className="btn btn-md btn-green"> Change display picture</a>
                  </div>
                </div>
              </div> */}
              <div className="row">
                <div className="col-xs-12 col-sm-7">
                  <div className="locations-details-sec">
                    <div className="location-header">
                      <ul>
                        <li>
                          <span className="left-title">Location Address:</span>
                          <span>{this.state.location && this.state.location.address1 ? this.state.location.address1 : '--'}</span>
                        </li>
                        <li>
                          <span className="left-title">Service Type:</span>
                          <span>Yoga Sessions</span>
                        </li>
                        <li>
                          <span className="left-title">Hrs of Operation:</span>
                          <span>Mon-Fri 9am to 2pm; no service on weekends</span>
                        </li>
                      </ul>
                    </div>
                    <div className="location-content-main">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                      <strong>Services Levels:</strong>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
                            </p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-5">
                  <div className="view-booking-sec">
                    <strong><i className="fa fa-picture-o"></i> | View Booking</strong>
                    <h3>Nicole's Yoga World</h3>
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <tr>
                          <td colspan="2">
                            <p>Tuesday, May 10</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                            <p>Lorem ipsum dolor</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                            <p>Lorem ipsum dolor</p>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <p>Tuesday, May 10</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                            <p>Lorem ipsum dolor</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                            <p>Lorem ipsum dolor</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                            <p>Lorem ipsum dolor</p>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <p>5:00</p>
                          </td>
                          <td>
                            <p>Lorem ipsum dolor</p>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-2 col-sm-2 mt-2 offset-10">
                   <a href="#" className="btn btn-md btn-search ripple" onClick={(e) => this.goBack(e)} >Back</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {this.state.isLoading ?
        <Spinner/>
        : ''}
    </main>)
  }
}

const mapStateToProps = (state, ownProps) => ({
  commonReducer: state.commonReducer
})
const mapDispatchToProps = (dispatch, ownProps) => ({

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationPreview)