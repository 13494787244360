import React from 'react';
import { connect } from 'react-redux';
import Banner from "../../../Common/Components/clientBanner";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { Constant } from "../../../../Constants/constant";
import { Spinner } from "../../../Common/Components/loader";
// import { Link } from 'react-router-dom';
import externalFileLoad from '../../../externalfileLoad';
class Booking extends React.Component {
    constructor() {
        super();
        this.state = {
            currentBookings: {},
            pastBookings: {},
            isLoading: false,
            profile: ""
        }
    }
    componentDidMount() {
        externalFileLoad();
        let token = getCookie('token');
        let email = getCookie('user_email');
        let userId = getCookie('user_id');
        let userRole = getCookie('user_Role');
        let profile = getCookie('profile');
        profile = JSON.parse(profile);
        this.setState({
            profile : profile
        })
        if (token === '') {
            this.props.history.push('/login')
        } else {
            this.props.logInAction(token, email, userId, userRole)
            this.getBooking();
        }
        if (window.jQuery('link[id="healerstyle"]')) {
            window.jQuery('link[id="healerstyle"]').remove();
        }
        this.loadjscssfile('/css/client_style.css', 'css', 'clientstyle')
    }
    componentWillUnmount() {
        if (window.jQuery('link[id="clientstyle"]')) {
            window.jQuery('link[id="clientstyle"]').remove();
        }
        this.loadjscssfile('/css/style.css', 'css', 'healerstyle')
    }
    getBooking = () => {
        let th = this;
        this.setState({
            isLoading: true
        }, () => {
            fetch(Constant.apiURl + 'order/history', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'auth_token': getCookie('token')
                })
            })
                .then(response => response.json())
                .then(myJson => {
                    if (myJson.success === '1') {
                        debugger
                        this.setState({
                            currentBookings: myJson.data.current_bookings,
                            pastBookings: myJson.data.past_bookings,
                            isLoading: false
                        })
                    } else {
                        this.setState({
                            isLoading: false
                        })
                    }
                });
        })

    }
    loadjscssfile = (filename, filetype, title) => {
        if (filetype == "js") { //if filename is a external JavaScript file
            var fileref = document.createElement('script')
            fileref.setAttribute("type", "text/javascript")
            fileref.setAttribute("src", filename)
        }
        else if (filetype == "css") { //if filename is an external CSS file
            var fileref = document.createElement("link")
            fileref.setAttribute("rel", "stylesheet")
            fileref.setAttribute("type", "text/css")
            fileref.setAttribute("href", filename)
            fileref.setAttribute("id", title)
        }
        if (typeof fileref != "undefined")
            document.getElementsByTagName("head")[0].appendChild(fileref)
    }
    render() {
        return (
            <main>
                {this.state.isLoading ?
                    <Spinner /> : ''}
                <Banner heading={this.state.profile != "" ? this.state.profile.first_name : ""} />
                <div className="card-sec services-pages">
                    <div className="container">
                    {this.state.currentBookings && this.state.currentBookings.data && this.state.currentBookings.data.length > 0 ?
                        <div className="Card-panel">
                            <form>
                          
                                <div className="services-list-form">
                                    <strong><i className="icon-calendar booking-icon"></i> | Current Bookings</strong>
                                    {this.state.currentBookings && this.state.currentBookings.data ?
                                        this.state.currentBookings.data.map((item, index) => {
                                            return <div key={index} className="booking-list-box">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-3 custom-width-23">
                                                        <div className="services-image-holder">
                                                            <img src={Constant.downloadUrl + item.service.service_type.active_image_path + "/" + item.service.service_type.image_name} alt="image" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 custom-width-30">
                                                        <div className="media">
                                                            <img className="mr-3" src={require('../../../../img/profile-1.jpg')} alt="Generic placeholder image" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">{item.healer.first_name + " " + item.healer.last_name}</h5>
                                                                <small><i className="fa fa-map-marker"></i>{item.healer.profile.address}</small>
                                                            </div>
                                                        </div>
                                                        <div className="msg-map-btn">
                                                            <a href="#" className="btn btn-md btn-green"><i className="fa fa-commenting-o"></i> Message</a>
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-map-marker"></i> View Map</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 custom-width-30">
                                                        <div className="msg-services-box">
                                                            <h5>{item.service.service_name}</h5>
                                                            <small>{item.booked_date} {item.booked_time}</small>
                                                        </div>
                                                        <div className="msg-map-btn">
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-ban"></i> Cancle</a>
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-exchange"></i> Change Time</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 custom-width-17">
                                                        <div className="Duration-services-box">
                                                            <h5>Duration</h5>
                                                            <small>{item.duration.duration} Mins</small>
                                                        </div>
                                                        <div className="msg-map-btn">
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-flag-o"></i> Report</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                        : ''}

                                </div>
                                
                            </form>
                        </div>
                        : ""
                                    }
                        <div className="Card-panel">
                            <form>
                                <div className="services-list-form">
                                    <strong><i className="icon-calendar booking-icon"></i> | Past Bookings</strong>
                                    {this.state.currentBookings && this.state.currentBookings.data && this.state.currentBookings.data.length > 0 ?
                                        this.state.currentBookings.data.map((item, index) => {
                                            return <div key={index} className="booking-list-box">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-3 custom-width-23">
                                                        <div className="services-image-holder">
                                                            <img src={Constant.downloadUrl + item.service.service_type.active_image_path + "/" + item.service.service_type.image_name} alt="image" />
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 custom-width-30">
                                                        <div className="media">
                                                            <img className="mr-3" src={require('../../../../img/profile-1.jpg')} alt="Generic placeholder image" />
                                                            <div className="media-body">
                                                                <h5 className="mt-0">{item.healer.first_name + " " + item.healer.last_name}</h5>
                                                                <small><i className="fa fa-map-marker"></i>{item.healer.profile.address}</small>
                                                            </div>
                                                        </div>
                                                        <div className="msg-map-btn">
                                                            <a href="#" className="btn btn-md btn-green"><i className="fa fa-commenting-o"></i> Message</a>
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-map-marker"></i> View Map</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 custom-width-30">
                                                        <div className="msg-services-box">
                                                            <h5>{item.service.service_name}</h5>
                                                            <small>{item.booked_date} {item.booked_time}</small>
                                                        </div>
                                                        <div className="msg-map-btn">
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-ban"></i> Cancle</a>
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-exchange"></i> Change Time</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 custom-width-17">
                                                        <div className="Duration-services-box">
                                                            <h5>Duration</h5>
                                                            <small>{item.duration.duration} Mins</small>
                                                        </div>
                                                        <div className="msg-map-btn">
                                                            <a href="#" className="btn btn-md btn-black"><i className="fa fa-flag-o"></i> Report</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : <p>No Booking found!</p>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus,
    commonReducer: state.commonReducer,
    profileReducer: state.profileReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    // getUserServicesData: (token, userId) => dispatch(getUserServicesData(token, userId)),
    logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole))
})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Booking)