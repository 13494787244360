import React, { Component } from "react";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";

export default class TransactionDetail extends Component {
    componentDidMount() {
        let token = getCookie('token');
        if (token === '') {
            this.props.history.push('/login')
        }
    }
    render() {
        return (
            <div className="card-sec services-pages">
                <div className="container">
                    <strong>Tell us about your “Body Massage” at Nicole’s Yoga World </strong>
                    <div className="Card-panel">
                        <form>
                            <div className="services-list-form">
                                <strong><i className="fa fa-signal"></i> | About the Training or Retreat</strong>
                                <ul className="nav nav-tabs cust" id="myTab" role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Complete Payout</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Upcoming Payouts</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="messages-tab" data-toggle="tab" href="#messages" role="tab" aria-controls="messages" aria-selected="false">Gross Earnings</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" id="settings-tab" data-toggle="tab" href="#settings" role="tab" aria-controls="settings" aria-selected="false">Linked Accounts Summary</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6 PLR-15">
                                                <div className="custom-form-group">
                                                    <select className="custom-select">
                                                        <option selected>All Payout Methods</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-xs-12 col-sm-6 PLR-15">
                                                <div className="custom-form-group">
                                                    <select className="custom-select">
                                                        <option selected>All Listings</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row time_date_sec">
                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>From: January</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>2019</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>To: March</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>2019</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="upper">
                                                    <span className="title pull-left">Paid Out: $98574.52</span>
                                                    <span className="pull-right download"><a href="#">Download CSV</a></span>
                                                </div>
                                                <div className="details">
                                                    <div className="item dark">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Lorem Ipsum Feb 25,2019 - March 15,2019</span><br />
                                                        <span><i className="fa fa-star yellow"></i> Lorem Ipsum Feb 25,2019 - March 15,2019 <i className="fa fa-heart red"></i></span>
                                                    </div>
                                                    <div className="item light">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Lorem Ipsum Feb 25,2019 - March 15,2019</span><br />
                                                        <span><i className="fa fa-star yellow"></i> Lorem Ipsum Feb 25,2019 - March 15,2019 <i className="fa fa-heart red"></i></span>
                                                    </div>
                                                    <div className="item dark">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Lorem Ipsum Feb 25,2019 - March 15,2019</span><br />
                                                        <span><i className="fa fa-star yellow"></i> Lorem Ipsum Feb 25,2019 - March 15,2019 <i className="fa fa-heart red"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 PLR-15">
                                                <div className="custom-form-group">
                                                    <select className="custom-select">
                                                        <option selected>All Listings</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="upper">
                                                    <span className="title pull-left">Paid Out: $98574.52</span>
                                                    <span className="pull-right download"><a href="#">Download CSV</a></span>
                                                </div>
                                                <div className="details">
                                                    <div className="item dark">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Lorem Ipsum Feb 25,2019 - March 15,2019</span><br />
                                                        <span><i className="fa fa-star yellow"></i> Lorem Ipsum Feb 25,2019 - March 15,2019 <i className="fa fa-heart red"></i></span>
                                                    </div>
                                                    <div className="item light">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Lorem Ipsum Feb 25,2019 - March 15,2019</span><br />
                                                        <span><i className="fa fa-star yellow"></i> Lorem Ipsum Feb 25,2019 - March 15,2019 <i className="fa fa-heart red"></i></span>
                                                    </div>
                                                    <div className="item dark">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Lorem Ipsum Feb 25,2019 - March 15,2019</span><br />
                                                        <span><i className="fa fa-star yellow"></i> Lorem Ipsum Feb 25,2019 - March 15,2019 <i className="fa fa-heart red"></i></span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="messages" role="tabpanel" aria-labelledby="messages-tab">
                                        <div className="row time_date_sec">
                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>From: January</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>2019</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="row">
                                                    <div className="col-sm-9">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>To: March</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-3">
                                                        <div className="custom-form-group">
                                                            <select className="custom-select">
                                                                <option selected>2019</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="upper">
                                                    <span className="pull-right download"><a href="#">Download CSV</a></span>
                                                </div>
                                                <div className="details">
                                                    <div className="item dark">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Reservation, Feb 25,2019 - March 15,2019</span>
                                                        <ul className="print-receipt-sec">
                                                            <li><a href="#">ASHKFHLOUJKIK</a></li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="fa fa-print"></i> <span>Print receipt</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <span>Occupancy Taxes $58.65</span>
                                                    </div>
                                                    <div className="item light">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Reservation, Feb 25,2019 - March 15,2019</span>
                                                        <ul className="print-receipt-sec">
                                                            <li><a href="#">ASHKFHLOUJKIK</a></li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="fa fa-print"></i> <span>Print receipt</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <span>Occupancy Taxes $58.65</span>
                                                    </div>
                                                    <div className="item dark">
                                                        <div className="upper">
                                                            <span className="pull-left">Feb 26,2019</span>
                                                            <span className="pull-right download"><a href="#">$125 <i className="fa fa-angle-down"></i></a></span>
                                                        </div>
                                                        <span>Reservation, Feb 25,2019 - March 15,2019</span>
                                                        <ul className="print-receipt-sec">
                                                            <li><a href="#">ASHKFHLOUJKIK</a></li>
                                                            <li>
                                                                <a href="#">
                                                                    <i className="fa fa-print"></i> <span>Print receipt</span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                        <span>Occupancy Taxes $58.65</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <ul className="statement-list">
                                            <li><a href="#"><i className="fa fa-file-text-o"></i> <span>statement 02/05/19 (42k, PDF)</span></a></li>
                                            <li><a href="#"><i className="fa fa-file-text-o"></i> <span>statement 02/05/19 (42k, PDF)</span></a></li>
                                            <li><a href="#"><i className="fa fa-file-text-o"></i> <span>statement 02/05/19 (42k, PDF)</span></a></li>
                                        </ul>
                                    </div>
                                    <div className="tab-pane" id="settings" role="tabpanel" aria-labelledby="settings-tab">...</div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}