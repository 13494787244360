import * as apiCallActions from "../../../ApiCallStatus/Actions/action";
import * as Actions from "./../Actions/actions";
import { Constant } from "./../../../../Constants/constant";

export function uploadDocument(token, document) {
  var formData = new FormData();
  formData.append("file", document);
  formData.append("auth_token", token);
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "uploadDocument"
      })
    );
    fetch(Constant.apiURl + "add-service-document", {
      method: "POST",
      body: formData
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "uploadDocument",
              message: myJson.data
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "uploadDocument",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "uploadDocument",
            message: error.message
          })
        );
      });
  };
}

export function addService(data) {
  var formData = new FormData();
  if (data && data.images.length > 0) {
    for (let index = 0; index < data.images.length; index++) {
      formData.append("images[]", data.images[index]);
    }
  }

  if (data && data.documnets.length > 0) {
    for (let index = 0; index < data.documnets.length; index++) {
      let document = data.documnets[index]["certificate_file_" + data.documnets[index].id];
      formData.append("certificate_file_" + data.documnets[index].id, document == undefined ? "" : document);
      formData.append("certificate_file_" + data.documnets[index].id + "_expires", data.documnets[index]["certificate_file_" + data.documnets[index].id + "_expires"] == undefined ? data.documnets[index].expires : data.documnets[index]["certificate_file_" + data.documnets[index].id + "_expires"]);
      formData.append("certificate_file_" + data.documnets[index].id + "_expiry_date", data.documnets[index]["certificate_file_" + data.documnets[index].id + "_expiry_date"] = data.documnets[index].expiry_date == undefined ? data.documnets[index].expiry_date : data.documnets[index]["certificate_file_" + data.documnets[index].id + "_expiry_date"] = data.documnets[index].expiry_date);
      formData.append("certificate_file_" + data.documnets[index].id + "_id", data.documnets[index]["certificate_file_" + data.documnets[index].id + "_id"] == undefined ? data.documnets[index].id : data.documnets[index]["certificate_file_" + data.documnets[index].id + "_id"]);
    }
  }
  formData.append("auth_token", data.auth_token);
  formData.append("service_provider_owner", data.service_provider_owner);
  formData.append("service_name", data.service_name);
  formData.append("service_description", data.service_description);
  formData.append("service_location", data.service_location);
  formData.append("qualify_description", data.qualify_description);
  // formData.append("documnets_ids", data.documnets_ids);
  formData.append("service_times", data.service_times);
  formData.append("service_prices", data.service_prices);
  formData.append("experties_level", data.experties_level);
  formData.append("service_id", data.service_id);
  formData.append("service_duration_id", data.service_duration_id);
  formData.append("price", data.price);
  formData.append("minimum_person", data.minimum_person);
  formData.append("maximum_person", data.maximum_person);
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "addService"
      })
    );
    fetch(Constant.apiURl + "update-service", {
      method: "POST",
      body: formData
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "addService",
              message: myJson.data
            })
          );
        } else {
          myJson.validation.msg = myJson.message;
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "addService",
              message: myJson.validation,
              msg : myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "addService",
            message: error.message
          })
        );
      });
  };
}

export function getServiceDetail(token, serviceId) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getServiceDetail"
      })
    );
    fetch(Constant.apiURl + "get-service-by-id", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        service_id: serviceId
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetServiceDetailAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getServiceDetail",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getServiceDetail",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getServiceDetail",
            message: error.message
          })
        );
      });
  };
}

export function addHealerAvaliablity(data) {
  var formData = new FormData();

  formData.append('service_id',data.service_id);   
  formData.append('service_days',data.service_days);   
  formData.append('auth_token',data.auth_token);    
  formData.append('availability_slots',data.availability_slots);   
  formData.append('advance_prepration_time',data.advance_prepration_time);   
  formData.append('commute_time',data.commute_time);   
  formData.append('blocked_slots',data.block_slots);   

  return dispatch => {
    dispatch(apiCallActions.ApiRequestedAction({
      apiCallFor: 'addHealerAvalaiablity'
    }));
    fetch(Constant.apiURl + "update-service-availablility", {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body : JSON.stringify(data)
    }).then(function (response) {
        return response.json();
    }).then(function (myJson) {
       if (myJson.success === '1') {
            dispatch(apiCallActions.ApiFulfilledAction({
                apiCallFor: 'addHealerAvalaiablity',
                message: myJson.data
            }))
        } else {
            dispatch(apiCallActions.ApiRejectedAction({
                apiCallFor: 'addHealerAvalaiablity',
                message: myJson.validation
            }))
        }

    }).catch((error) => {
        dispatch(apiCallActions.ApiRejectedAction({
            apiCallFor: 'addHealerAvalaiablity',
            message: error.message
        }));
    });
  }
}
export function getHealerAvaliablity(token,serviceid) {
  let self= this;
  let formData = new FormData();
  formData.append("auth_token",token);
  formData.append("service_id",serviceid);
  return dispatch => {
    dispatch(apiCallActions.ApiRequestedAction({
      apiCallFor: 'geAvalaiablity'
    }));
  fetch(Constant.apiURl + "get-service-availablility", {
    method: "POST",
    body: formData
  })
    .then(function(response) {
      return response.json();
    })
    .then(function(myJson) {
      if (myJson.success === '1') {
        dispatch(apiCallActions.ApiFulfilledAction({
            apiCallFor: 'geAvalaiablity',
            response: myJson.data
        }))
    } else {
        dispatch(apiCallActions.ApiRejectedAction({
            apiCallFor: 'geAvalaiablity',
            message: myJson.validation
        }))
    }
    })
    .catch(error => {
      dispatch(apiCallActions.ApiRejectedAction({
        apiCallFor: 'geAvalaiablity',
        message: error.message
    }));
    });
  }
}
export function removeImage(imageId) {
  let formData = new FormData();
  formData.append("id",imageId)
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "removeImage"
      })
    );
    fetch(Constant.apiURl + "remove-service-image", {
      method: "POST",
      body: formData
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(
            Actions.removeServiceImageAction(imageId)
          );
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "removeImage",
              message: myJson.data
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "removeImage",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "removeImage",
            message: error.message
          })
        );
      });
  };
}