import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
class Banner extends React.Component{
    render(){
        return <div className="sub-banner">
        <div className="container">
            <div className="row">
                <div className="col-xs-12 col-sm-5">
                    <div className="profile-holder">
                        <div className="media">
                            <img className="mr-3" src={require('../../../img/profile-1.jpg')} alt="Generic placeholder image" />
                            <div className="media-body">
                                <h5 className="visible-hidden">Services at</h5>
                                <p>{this.props.heading}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-7">
                    <ul className="menu-tabs">
                        <li>
                            <Link to="/client-location">
                                <div className="">
                                    <div className="banner-icon">
                                        <i className="icon-calendar"></i>
                                    </div>
                                    <div className="banner-content">
                                        <small>Location</small>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <Link to="/client-booking">
                                <div className="">
                                    <div className="banner-icon">
                                        <i className="icon-calendar"></i>
                                    </div>
                                    <div className="banner-content">
                                        <small>Booking</small>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li>
                            <a href="#">
                                <div className="banner-icon">
                                    <i className="icon-message"></i>
                                </div>
                                <div className="banner-content">
                                    <small>Message</small>
                                </div>
                            </a>
                        </li>
                        {/* <li>
                            <Link to="/client-profile-container">
                                <div className="banner-icon">
                                    <i className="icon-settings"></i>
                                </div>
                                <div className="banner-content">
                                    <small>Settings</small>
                                </div>
                            </Link>
                        </li> */}
                        <li>
                            <Link to="/location-manager">
                                <div className="banner-icon">
                                    <i className="icon-map-pin"></i>
                                </div>
                                <div className="banner-content">
                                    <small>Add Location</small>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    }
}
const mapStateToProps = (state, ownProps) => ({

  })
  
  const mapDispatchToProps = (dispatch, ownProps) => ({

  })
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Banner)