import React from 'react';
import { connect } from 'react-redux';
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import {Link} from 'react-router-dom';
import externalFileLoad from  '../../../externalfileLoad';
class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardName:'',
      cardNumber : '',
      cvc : '',
      month : '',
      year:''
    }
  }
  componentDidMount() {
    externalFileLoad();
    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_id');
    let userRole = getCookie('user_Role');
    if (token === '') {
      this.props.history.push('/login')
    }else if(token !== '' && userRole !== '1'){
      this.props.history.push('/client/dashboard')
    } else{
      this.props.logInAction(token,email,userId,userRole)
    }
  }

  render() {
    return (
        <div className="services-sec">
            <div className="container">
            <div className="add-profile-btn">
              <ul className="pull-right">
                <li className="active">
                  <Link to="/client-profile-container" className="btn btn-md">Profile</Link></li>
                <li><Link to="/payment" className="btn btn-md">Payment</Link></li>
              </ul>
              <div className="clearfix"></div>
            </div>
                <div className="table-sec">
                    <h1>Payment Verification</h1>
                    <div className="card-panel">
                        <div className="payment-panel">
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                     <div className="row">
                                        <div className="col-xs-12 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="Card" className="bmd-label-floating">Name of the Card</label>
                                                <input type="text" className="form-control" id="Card" />
                                            </div>
                                         </div>
                                     </div>
                                     <div className="row">
                                        <div className="col-xs-12 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="Cardnumber" className="bmd-label-floating">Card Number</label>
                                                <input type="text" className="form-control" id="Cardnumber"/>
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12">
                                            <div className="form-group">
                                                <label htmlFor="Card" className="bmd-label-floating">CVV</label>
                                                <input type="text" className="form-control" id="Card"/>
                                            </div>
                                        </div>
                                    </div> 
                                    <strong>Expiry Date</strong>
                                    <div className="row PLR-15">
                                        <div className="col-xs-12 col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="Month" className="bmd-label-floating">Month</label>
                                            <input type="text" className="form-control" id="Month"/>
                                        </div>
                                        </div>
                                        <div className="col-xs-12 col-sm-6">
                                            <div className="form-group">
                                                <label htmlFor="Year" className="bmd-label-floating">Year</label>
                                                <input type="text" className="form-control" id="Year"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <div className="payment-imageholder">
                                        <img src="img/payment-image.png" alt="image" />
                                    </div>
                                </div>
                            </div>
                            <a href="javascript:void(0);" className="btn btn-md btn-verify ripple" data-toggle="modal" data-target="#Verify_payment">Verify Now</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="Verify_payment" role="dialog">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                      <div className="modal-body">
                          <div className="verify-inputfeild">
                            <p className="text-center">Please enter the amount at below For verify payment</p>
                            <form>
                                <div className="form-group bmd-form-group">
                                <label htmlFor="Card" className="bmd-label-floating">Enter Amount Here</label>
                                <input type="text" className="form-control" id="Card" />
                                </div>
                                <button type="button" className="btn btn-default btn-red" data-dismiss="modal">verify</button>
                            </form>
                          </div>
                      </div>
                      <div className="modal-footer">
                          <button type="button" className="btn btn-default btn-green" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                </div>
            </div>
        </div>
      );
  }
}

const mapStateToProps = (state, ownProps) => ({
//   loginReducer: state.loginReducer,
//   apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(Payment)