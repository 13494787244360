import React from "react";
import { Link } from "react-router-dom";
import  "../../../Login&SignUp/Css/custom-healmaker.css"

export default class ServicesList extends React.Component {

  render() {
    return (
      <div className="table-sec">
        <h1>
          Services at “{this.props.data.name}”
        </h1>
        <div className="card-panel">
          <div className="table-responsive">
            <table className="table table-striped services-table">
              <thead>
                <tr>
                  <th width="30%">Services</th>
                  <th width="50%">status</th>
                  <th width="20%">active on / off</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.services.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td><Link
                            to={"/add-service/" + item.id}
                            className="service-list-title"
                          >{item.service_name}</Link></td>
                      <td>
                        <a
                          href="#"
                          className={
                            item.canBeActivated === 1 
                              ? "btn-complete"
                              : "btn-notcomplete"
                          }
                        >
                          {item.canBeActivated === 1
                            ? "Complete"
                            : "Incomplete"}
                        </a>
                        {item.status_message !== null ||
                        item.status_message !== "" ? (
                          <Link
                            to={"/add-service/" + item.id}
                            className="btn btn-md btn-description ripple"
                          >
                            {item.status_message}
                          </Link>
                        ) : (
                          ""
                        )}
                        {/* {item.qualify_description === null ||
                        item.qualify_description == "" ? (
                          <a
                            href="#"
                            className="btn btn-md btn-description ripple"
                          >
                            Need Qualifying Description
                          </a>
                        ) : (
                          ""
                        )} */}
                      </td>
                      <td>
                         <div className={item.canBeActivated === 1 ? "toggleWrapper": "toggleWrapper disable"}>
                            <input
                              type="checkbox"
                              id={"dn" + index + item.id + this.props.data.id}
                              className={
                                "dn" + index + item.id + this.props.data.id
                              }
                              disabled={item.canBeActivated === 1 ? false : true}
                            />
                            <label
                              for={"dn" + index + item.id + this.props.data.id}
                              className="toggle"
                            >
                              <span className="toggle__handler" />
                            </label>
                          </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <ul className="add-button">
            <li>
              <Link to={"/categories-list/" + this.props.data.id}>
                + Add additional services
              </Link>
            </li>
            <li>
              <Link to={"/categories-list/" + this.props.data.id}>
                + Add retreat or training services{" "}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
