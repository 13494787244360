import React from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import externalFileLoad from  '../../externalfileLoad';
import {Constant} from '../../../Constants/constant'
class ThankYou extends React.Component {
    constructor(props) {
    super(props);
    this.state = {
        email : '',
        successMsg: "",
        errorMsg : "",
        isError : false
    }
    }
componentDidMount(){
    externalFileLoad();
const urlParams = new URLSearchParams(window.location.search);
const email = urlParams.get('email');
this.setState({
    email
})
}
resendEmail = () =>{
    let th = this;
    let formData = new FormData();
    formData.append("email",this.state.email);
    let url = Constant.apiURl + 'resend-activation-email';

    fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(response => response.json())
      .then(myJson => {
        if (myJson.success === '1') {
          this.setState({
              successMsg : "Please check your email",
              isError: false
          })
        }else{
            this.setState({
                errorMsg : "error in sending email please try agian later",
                isError : true
            })
        }
      });
}
render() {
    let data = this.props.location.state && this.props.location.state.orderSucces ? this.props.location.state.orderSucces : "";
    data = JSON.parse(data);
    
return (
<main>
    <div className="login-sec">
        <div className="container">
            <div className="thnkyou-panel">
                <h1><span>Thank You</span></h1>
                <div className="img-holder">
                    <img src={require("../../../img/thnk-icon.png")} alt="Image" />
                </div>
                {this.props.location.state && this.props.location.state.orderSucces ? 
                <div className="email-body">
                <p> Your Booking #{data.booking_no} is received successfully.</p>
                <p>Continue booking.</p>
                <small>Continue booking will link to homepage. while order id will link to order detail page</small>
            </div>: 
                <div className="email-body">
                    <p>We have sent an email to {this.state.email} </p>
                    <small>Please click the link in the email to verify and log into your HealMaker account (if you
                        have not received an email, check the "spam" folder Or, click here to <span onClick={this.resendEmail}>resend</span>)</small>
                </div>
               
                }
                {this.state.isError && this.state.errorMsg != "" ? <div className="alert alert-danger">
                    {this.state.errorMsg}
                </div> : !this.state.isError && this.state.successMsg != "" ? <div className="alert alert-success">
                    {this.state.successMsg}
                </div> : ""}
               
            </div>
            {this.props.location.state && this.props.location.state.orderSucces ? "": 
            <div className="login-now text-center">
                <p>Already have HealMaker Account?
                    <Link to="/login">Login</Link>
                </p>
            </div>
            }
        </div>
    </div>
</main>
);
}
}

const mapStateToProps = (state, ownProps) => ({
loginReducer: state.loginReducer,
apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})
export default connect(
mapStateToProps,
mapDispatchToProps
)(ThankYou)