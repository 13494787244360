import * as Action from "./../Actions/actions";
import StoreState from "./../../../Constants/initialState";

export default function profileReducer(state = StoreState.common, action) {
  switch (action.type) {
    case Action.GetCountriesCommon:
      var s = state;
      s.countries = action.payload.countries;
      return s;

    case Action.GetStateByCountryCommon:
      var s = state;
      s.state = action.payload.states;
      return s;

    case Action.GetCityByStateCommon:
      var s = state;
      s.cities = action.payload.cities;
      return s;

    case Action.GetAddressDataCommon:
      var s = state;
      s.countries = action.payload.countries;
      s.state = action.payload.states;
      s.cities = action.payload.cities;
      return s;

    case Action.GetServiceTypeCommon:
      var s = state;
      s.services = action.payload.services;
      return s;

    case Action.GetProfileDataCommon:
      var s = state;
      s.profile = action.payload.data;
      return s;

    case Action.GetServiceTypes:
      var s = state;
      s.serviceType = action.payload.data;
      return s;

    case Action.GetServiceDurations:
      var s = state;
      s.serviceDuration = action.payload.data;
      return s;
      

    default:
      return state;
  }
}
