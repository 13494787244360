import React, { Component } from "react";
import { connect } from 'react-redux';
import EndUserFooter from "./EndUserFooter/footer";
import UserFooter from './userFooter/footer';
import { getCookie } from "../Login&SignUp/ApiCall/cookies";
class Index extends Component {
    constructor() {
        super();
        this.state = {
            isShow: true
        }
    }
    componentDidMount() {
        let isPageFound = getCookie('isPageFound');
        if (isPageFound && isPageFound === 'true') {
            this.setState({
                isShow: false
            })
        }
    }
    render() {
        return (
                this.props.loginReducer.isLogin ?
                    <UserFooter />
                    : <EndUserFooter />
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Index)