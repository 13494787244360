var StoreState = {
  login: { isLogin: false, token: '', email: '', userId: '', userRole: '', isPageFound: true },
  apiCallStatus: {
    apiCallFor: "",
    isCompleted: true,
    isFailed: false,
    message: "",
    isStarted: [],
    isError: []
  },
  profile: { data: {}, countries: [], state: [], cities: [], services: [], locationDetail: {} },
  common: { countries: [], state: [], cities: [], services: [], profile: {}, serviceType: [], serviceDuration: [] },
  services: {
    data: {},
    countries: [],
    state: [],
    cities: [],
    services: [],
    locationDetail: {},
    serviceDetail: {}
  },
  payment: { data: {}, response: {} },
  healerAvaliablity: { data: {}, response: {} }
}
export default StoreState;
