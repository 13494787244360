import React from 'react';
import { connect } from 'react-redux';
import SubBanner from "../../../Common/Components/healerBanner";
import { Link } from "react-router-dom";
import externalFileLoad from '../../../externalfileLoad';
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { getBookedUserProfile } from '../ApiCalls/apicalls';
import { cloneDeep } from '@babel/types';

class BookedUserProfilePreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        clientProfile: ""
    }
  }
  componentDidMount() {
    externalFileLoad();
    let token = getCookie('token');
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (hasUnacceptedAgreements === '1') {
      this.props.history.push('/verify-service-document')
    }
    this.props.getBookedUserProfile(token,this.props.match.params.id)
    window.scroll(0,0)
  }
  componentWillReceiveProps(nextProps){
    if (nextProps.apiCallStatus.apiCallFor === 'getBookedUserProfile' &&
    nextProps.apiCallStatus.isCompleted === true &&
    nextProps.apiCallStatus.isFailed === false) {
    let clientProfile = cloneDeep(this.state.clientProfile);
    this.setState({
        clientProfile: clientProfile
    })
  }
  }
  goback = (e) => {
      e.preventDefault();
      this.props.history.goBack();
  }
  render() {
    return (
        <main>
        <div className="card-sec services-pages">
          <div className="container">
            <strong className="pull-left">Nicole's  Calendar</strong>
            <span className="pull-right"><a href="#" className="back-links" onClick={(e) => this.goback(e)}> <i className="fa fa-angle-left" aria-hidden="true"></i> back to calendar</a></span>
            <div className="clearfix"></div>
            <div className="Card-panel">
              <div className="row">
                <div className="col-sm-8 col-md-8">
                  <ul className="service-user-info">
                    <li>
                     <span className="userInfo"><h1>Asif Bukhtiar</h1></span>
                     <span className="verify-badge"><i className="fa fa-check-circle-o"></i> Verified</span>
                    </li>
                    <li>
                      <span className="price-label">Deep Tissue Massage</span>
                      <span className="service-price">$60</span>
                    </li>
                    <li>
                      <span className="price-label">Nicole's Yoga World</span>
                    </li>
                  </ul>
                  <div className="message-label">
                    Message
                  </div>
                </div>
                <div className="offset-md-1 col-sm-3 col-md-3">
                  <div className="profile-image">
                    <img src={require("../../../../img/profile-1.jpg")} alt="image" />
                    <Link to="/service-review" className="review-link text-center">8 reviews</Link>
                  </div>
                  <div className="phone-number-sr">
                    <i className="fa fa-mobile"></i> | 1-713-366-1006
                  </div>
                </div>
              </div>
              <div className="service-price-tab">
                <ul>
                  <li>Date of service: March 2, 2019</li>
                  <li>Time of service: 1:00 pm</li>
                  <li>Session length: 60 min</li>
                </ul>
              </div>
              <div className="service-price-list">
                <ul>
                  <li>
                    <span className="booking-title">Booking:</span>
                    <span className="booking-amount">$60.00</span>
                  </li>
                  <li>
                    <span className="booking-title">Service fee:</span>
                    <span className="booking-amount">$60.00</span>
                  </li>
                </ul>
                <ul className="final-pay">
                  <li>
                    <span className="booking-title">Payout:</span>
                    <span className="booking-amount">$60.00</span>
                  </li>
                </ul>
              </div>
              <ul className="service-tabs">
                <li><a href="#">Change reservation</a></li>
                <li><a href="#">Change reservation</a></li>
                <li><a href="#">Report User</a></li>
              </ul>
              <textarea className="form-control custom-form mb-20" placeholder="Type message here..."></textarea>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
    getBookedUserProfile: (token,id) => dispatch(getBookedUserProfile(token,id)),
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookedUserProfilePreview)