import React,{ Component } from "react";

export class RetreatList extends Component{
    render(){
        return(
            <div className="Retreats-services-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-8 col-sm-8">
                <div className="box-title">
                  <h3>Retreats around the world </h3>  
                </div>
                </div>
                <div className="col-xs-4 col-sm-4">
                  <div className="box-title">
                    <a href="#" className="pull-right view-all">View all</a>  
                  </div>
                </div>
              </div>
              <div className="row">
              {this.props.retreatList && this.props.retreatList.map((item,index)=>{
                      return <RetreatItem
                      key={index}
                      name={item.name}
                      location={item.location}
                      startDate={item.startDate}
                      endDate={item.endDate} 
                      description={item.description}
                      reviews={item.reviews}/>
                  }) }  
            
                </div>
            </div>
          </div>
        )
    }
}

function RetreatItem(props){
    return <div className="col-xs-12 col-sm-3">
    <div className="services-items">
      <div className="services-image">
        <img src={require('../../../../img/services-img-5.jpg')} alt="Service" />
        <div className="service-logo-sec">
          <div className="table-method">
            <a href="#">
              <img 
              src={require('../../../../img/icon/company-logo-5.png')} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="services-content">
      <div className="time-sec">
      <small>{props.name}   <span className="border-left"></span></small> 
                      <span className="service-time">
                        <i className="fa fa-calendar"></i> {props.startDate}<span className="border-left"></span> {props.endDate}
                      </span>
                    </div>
                    <h5><a href="#"><i className="fa fa-map-marker"></i>{props.location}</a></h5>
                    <p>{props.description}</p>               
        <ul className="feedback-star">
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><i className="fa fa-star"></i></a></li>
          <li><a href="#"><span className="total-feedback">{props.reviews} Reviews</span></a></li>
        </ul>
      </div>
    </div>
  </div>
}