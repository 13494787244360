import * as Action from './../Actions/actions';
import StoreState from './../../../../Constants/initialState';

export default function healerAvaliablityReducer(state = StoreState.healerAvaliablity, action) {
    switch (action.type) {
        case Action.addHealerAvaliablity:
            var s = state;
            s = action.payload;
            return s;
        case Action.healerAvaliablityRresponse:
            var s = state;
            s = action.payload;
            return s;
        default:
            return state;
    }
}