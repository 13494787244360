import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Constant } from "../../../../Constants/constant";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { getAllCountries, getStateByCountry, getCitiesByState, updateProfile, sendOPT, verifyOTP } from "./../ApiCalls/apicalls";
import loader from './../../../../img/Spinner-1s-200px.gif';
import { Spinner } from '../../../Common/Components/loader';
import externalFileLoad from '../../../externalfileLoad';
class ProfileContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      companyName: '',
      address: '',
      mobile: '',
      cc: '',
      opt: '',
      isPhoneVerified: false,
      countryId: '',
      stateId: '',
      cityId: '',
      zipcode: '',
      blogUrl: '',
      faceboolUrl: '',
      instagramUrl: '',
      twitterUrl: '',
      gender: "0",
      img: '',
      errorMessage: '',
      errorType: '',
      isGetData: false,
      serverValidation: {},
      errorFor: '',
      display_company_name: 0
    }
  }

  componentDidMount() {
    externalFileLoad();
    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_Id');
    let userRole = getCookie('user_Role');
    if (token === '') {
      this.props.history.push('/login')
    } else if (token !== '' && userRole !== '2') {
      this.props.history.push('/client/dashboard')
    } else {
      this.props.logInAction(token, email, userId, userRole)
    }
    this.props.getAllCountries();
    let profile = getCookie("profile");
    profile = JSON.parse(profile)
    
    this.setState({
      firstName: profile.first_name,
      lastName: profile.last_name,
      companyName: profile.company_name,
      address: profile.address,
      address2: profile.address2,
      mobile: profile.mobile_number,
      cc: profile.phone_code_id,
      countryId: profile.country,
      stateId: profile.state,
      cityId: profile.city,
      zipcode: profile.zipcode,
      blogUrl: profile.web_url == "undefined"? "" : profile.web_url,
      faceboolUrl: profile.facebook_url,
      instagramUrl: profile.instagram_url,
      twitterUrl: profile.twitter_url,
      gender: profile.gender,
      img: profile.image.path == "" ||  profile.image.path== null ? "" : Constant.downloadUrl + profile.image.path + profile.image.file_name 
    })
    if(profile.country != ""){
      this.props.getStateByCountry(profile.country)
    }
    if(profile.state != ""){
      this.props.getCitiesByState(profile.state)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.apiCallStatus.apiCallFor === 'updateProfile' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === true) {
      this.setState({
        errorMessage: nextProps.apiCallStatus.message,
        errorType: 'danger',
        serverValidation: nextProps.apiCallStatus.message
      })
      // this.props.history.push("/healer-services-list");
    }
    if (nextProps.apiCallStatus.apiCallFor === 'updateProfile' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      if (nextProps.apiCallStatus.message && nextProps.apiCallStatus.message.hasUnacceptedAgreements === '1') {
        this.props.history.push("/verify-service-document");
      } else if (nextProps.apiCallStatus.message &&
        nextProps.apiCallStatus.message.hasUnacceptedAgreements === '0' &&
        nextProps.apiCallStatus.message.hasLocation === '0') {
        this.props.history.push("/location-manager");
      } else {
        this.props.history.push("/healer-services-list");
      }

    }
    if (Object.keys(nextProps.commonReducer.profile).length > 0 && !this.state.isGetData) {
      if (nextProps.commonReducer.profile.country) {
        this.props.getStateByCountry(nextProps.commonReducer.profile.country)
      }
      if (nextProps.commonReducer.profile.state) {
        this.props.getCitiesByState(nextProps.commonReducer.profile.state)
      }
      window.jQuery('#previewImage').attr('src', nextProps.commonReducer.profile.imgUrl);

      this.setState({
        isPhoneVerified: nextProps.commonReducer.profile.isPhoneVerified === 1 ? true : false,
        cc: nextProps.commonReducer.profile.phone_code_id ? nextProps.commonReducer.profile.phone_code_id : '',
        firstName: nextProps.commonReducer.profile.first_name ? nextProps.commonReducer.profile.first_name : '',
        lastName: nextProps.commonReducer.profile.last_name ? nextProps.commonReducer.profile.last_name : '',
        companyName: nextProps.commonReducer.profile.company_name ? nextProps.commonReducer.profile.company_name : '',
        address: nextProps.commonReducer.profile.address ? nextProps.commonReducer.profile.address : '',
        mobile: nextProps.commonReducer.profile.mobile_number ? nextProps.commonReducer.profile.mobile_number : '',
        countryId: nextProps.commonReducer.profile.country ? nextProps.commonReducer.profile.country : '',
        stateId: nextProps.commonReducer.profile.state ? nextProps.commonReducer.profile.state : '',
        cityId: nextProps.commonReducer.profile.city ? nextProps.commonReducer.profile.city : '',
        zipcode: nextProps.commonReducer.profile.zipcode ? nextProps.commonReducer.profile.zipcode : '',
        blogUrl: nextProps.commonReducer.profile.web_url ? nextProps.commonReducer.web_url : '',
        faceboolUrl: nextProps.commonReducer.profile.facebook_url ? nextProps.commonReducer.profile.facebook_url : '',
        instagramUrl: nextProps.commonReducer.profile.instagram_url ? nextProps.commonReducer.profile.instagram_url : '',
        twitterUrl: nextProps.commonReducer.profile.twitter_url ? nextProps.commonReducer.profile.twitter_url : '',
        gender: nextProps.commonReducer.profile.gender ? JSON.stringify(nextProps.commonReducer.profile.gender) : '',
        isGetData: true,
        display_company_name: nextProps.commonReducer.profile.display_company_name ? nextProps.commonReducer.profile.display_company_name : false
      })
    }
    if (nextProps.apiCallStatus.apiCallFor === 'sendOPT' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      window.jQuery('#verifyOpt').modal('show')
    }
    if (nextProps.apiCallStatus.apiCallFor === 'verifyOTP' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      this.setState({
        opt: '',
        isPhoneVerified: true
      })
      window.jQuery('#verifyOpt').modal('hide')
    }
  }
  handleChange = (e) => {

    let id = e.target.id;
    let val = e.target.value;
    this.setState({
      [id]: val,
      serverValidation: {}
    })
  }
  getStateByCountry = (e) => {
    if(this.state.countryId != "" && this.state.countryId != e.target.value){
      this.props.profileReducer.cities = []
      this.setState({
        zipcode : ""
      })
    }
    let id = e.target.value;
    if (id !== '') {
      this.setState({
        countryId: id,
        serverValidation: {}
      })
      this.props.getStateByCountry(id)
    }
  }
  getCityByState = (e) => {
    if (e.target.value !== '') {
      let stateId = Number(e.target.value);
      this.setState({
        stateId: stateId,
        serverValidation: {}
      })
      this.props.getCitiesByState(stateId.toString())
    }
  }
  uploadImage = (e) => {
    let file = e.target.files[0];
    if (file && file) {
      var reader = new FileReader();

      reader.onload = function (e) {
        window.jQuery('#previewImage').attr('src', e.target.result);
      }
      reader.readAsDataURL(file);
      this.setState({
        img: file
      })
    }
  }
  validateFields = () => {
    let isValid = true;
    if (this.state.firstName === '') {
      isValid = false;
      this.setState({
        errorMessage: 'First Name is empty',
        errorType: 'danger',
        errorFor: 'first_name'
      })
    } else if (this.state.lastName === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Last Name is empty',
        errorType: 'danger',
        errorFor: 'last_name'
      })
    } else if (this.state.companyName === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Company name is empty',
        errorType: 'danger',
        errorFor: 'company_name'
      })
    } else if (this.state.address === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Address is empty',
        errorType: 'danger',
        errorFor: 'address'
      })
    } else if (this.state.mobile === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Mobile number is empty',
        errorType: 'danger',
        errorFor: 'mobile'
      })
    } else if (this.state.countryId === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Please select country',
        errorType: 'danger',
        errorFor: 'country'
      })
    } else if (this.state.stateId === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Please select state',
        errorType: 'danger',
        errorFor: 'state'
      })
    } else if (this.state.cityId === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Please select city',
        errorType: 'danger',
        errorFor: 'city'
      })
    } else if (this.state.zipcode === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Zipcode is empty',
        errorType: 'danger',
        errorFor: 'zipcode'
      })
    } else if (this.state.gender === '') {
      isValid = false;
      this.setState({
        errorMessage: 'Please select gender',
        errorType: 'danger',
        errorFor: 'gender'
      })
    }
    return isValid;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    let isValid = this.validateFields();
    if (isValid) {
      let token = getCookie('token');
      let userId = getCookie('user_id');
      this.setState({
        errorMessage: '',
        errorType: '',
        serverValidation: {}
      })
      this.props.updateProfile({
        auth_token: token,
        owner_id: userId,
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        company_name: this.state.companyName,
        mobile: this.state.mobile,
        address: this.state.address,
        state: this.state.stateId,
        country: this.state.countryId,
        city: this.state.cityId,
        zipcode: this.state.zipcode,
        gender: this.state.gender,
        twitter_url: this.state.twitterUrl,
        instagram_url: this.state.instagramUrl,
        facebook_url: this.state.faceboolUrl,
        web_url: this.state.blogUrl,
        user_media: this.state.img,
        display_company_name: this.state.display_company_name,
        phone_code_id : this.state.cc
      })
    }
  }
  selectDisplayName = (e) => {
    if (e.target.value == "personalName") {
      this.setState({
        display_company_name: 0
      })
    }
    else if (e.target.value == "companyName") {
      this.setState({
        display_company_name: 1
      })
    }
  }
  showOPTModel = (e) => {
    e.preventDefault();
    if (this.state.mobile !== '') {
      if (this.validatePhone(this.state.mobile)) {
        this.props.sendOPT(this.props.loginReducer.token, this.state.cc, this.state.mobile);
        this.setState({
          errorMessage: '',
          errorType: '',
          errorFor: ''
        })
      } else {
        this.setState({
          errorMessage: 'Mobile number is not valid',
          errorType: 'danger',
          errorFor: 'mobile'
        })
      }
    } else {
      this.setState({
        errorMessage: 'Please enter the mobile number',
        errorType: 'danger',
        errorFor: 'mobile'
      })
    }


  }
  validatePhone = (str) => {
    let phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    if (phoneRegex.test(str)) {
      return true;
    }
    else {
      return false;
    }
  }
  render() {
    return (
      <main>
        <div className="login-sec">
          <div className="container">
            <div className="add-profile-btn">
              <ul className="pull-right">
                <li className="active"><Link to="/healer-profile-container" className="btn btn-md">Profile</Link></li>
                <li><Link to="/healer-account-info" className="btn btn-md">Payout</Link></li>
              </ul>
              <div className="clearfix"></div>
            </div>
            <div className="profile-panel">
              <h1>{this.props.commonReducer.profile &&
                this.props.commonReducer.profile.first_name &&
                this.props.commonReducer.profile.first_name !== '' ?
                <span>{this.props.commonReducer.profile.first_name}, tell us about yourself:</span> : 'Tell us about yourself:'} </h1>
              <form>
                <div className="row">
                  <div className="col-xs-12 col-sm-7">
                    <div className="profile-form">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 PLR-15">
                          <div className="form-group">
                            <label htmlFor="FirstName" className="bmd-label-floating">First Name</label>
                            <input type="text" className="form-control" id="firstName" value={this.state.firstName} onChange={(e) => this.handleChange(e)} />
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'first_name' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.first_name &&
                              this.state.serverValidation.first_name[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.first_name[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 PLR-15">
                          <div className="form-group">
                            <label htmlFor="LastName" className="bmd-label-floating">Last Name</label>
                            <input type="text" className="form-control" id="lastName" value={this.state.lastName} onChange={(e) => this.handleChange(e)} />
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'last_name' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.last_name &&
                              this.state.serverValidation.last_name[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.last_name[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="Company" className="bmd-label-floating">Your company’s name</label>
                            <input type="text" className="form-control" id="companyName" value={this.state.companyName} onChange={(e) => this.handleChange(e)} />
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'company_name' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.company_name &&
                              this.state.serverValidation.company_name[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.company_name[0]}
                              </div>
                              : ""}
                          </div>

                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="address" className="bmd-label-floating">Address</label>
                            <input type="text" className="form-control" id="address" value={this.state.address} onChange={(e) => this.handleChange(e)} />
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'address' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.address &&
                              this.state.serverValidation.address[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.address[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-5">
                          <div className="form-group bmd-form-group">
                            <label htmlhtmlFor="exampleSelect1" className="bmd-label-floating">Select a Country Code</label>
                            <select className="form-control select-icon" id="cc" value={this.state.cc} onChange={(e) => this.handleChange(e)}>
                              <option value=''>Select Country Code</option>
                              {this.props.profileReducer.countries &&
                                this.props.profileReducer.countries.map((item, index) => {
                                  return <option key={index} value={item.phonecode} selected={item.id === this.state.cc}>{item.sortname + "(+" + item.phonecode + ")"}</option>
                                })}
                            </select>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-7">
                          <div className="form-group phone-input bmd-form-group">
                            <label htmlFor="mobile" className="bmd-label-floating">Mobile no.</label>
                            <input type="number" className="form-control" id="mobile" value={this.state.mobile} onChange={(e) => this.handleChange(e)} />
                            <button className="btn btn-sm btn-verify ripple"
                              onClick={(e) => this.showOPTModel(e)}
                              disabled={this.state.isPhoneVerified}>{this.state.isPhoneVerified ? 'Verified' : 'Verify'}</button>



                          </div>
                          {this.state.errorMessage !== "" &&
                            this.state.errorType !== "" &&
                            this.state.errorFor === 'mobile' ?
                            <div className={"alert alert-" + this.state.errorType}>
                              {this.state.errorMessage}
                            </div>
                            : ""}

                          {this.state.serverValidation &&
                            this.state.serverValidation.mobile &&
                            this.state.serverValidation.mobile[0] ?
                            <div className={"alert alert-danger"}>
                              {this.state.serverValidation.mobile[0]}
                            </div>
                            : ""}
                          {this.props.apiCallStatus.apiCallFor === 'sendOPT' &&
                            this.props.apiCallStatus.isCompleted === false &&
                            this.props.apiCallStatus.isFailed === false ?
                            <img className="logo"
                              style={{ width: "83px", display: 'inline' }}
                              src={loader} />
                            : ""}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 PLR-15">
                          <div className="form-group">
                            <label htmlhtmlFor="exampleSelect1" className="bmd-label-floating">Select a Country</label>
                            <select className="form-control select-icon" value={this.state.countryId} onChange={(e) => this.getStateByCountry(e)}>
                              <option value=''>Select country</option>
                              {this.props.profileReducer.countries &&
                                this.props.profileReducer.countries.map((item, index) => {
                                  return <option key={index} value={item.id} selected={item.id === this.state.countryId}>{item.name}</option>
                                })}
                            </select>
                            <div className="dropdown-arrow">
                              <img
                                src={require('./../../../../img/icon/down-arrow.png')} alt="icon" />
                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'country' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.country &&
                              this.state.serverValidation.country[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.country[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 PLR-15">
                          <div className="form-group">
                            <label htmlhtmlFor="exampleSelect1" className="bmd-label-floating">Select a state or province</label>
                            <select className="form-control select-icon" value={this.state.stateId} onChange={(e) => {this.setState({zipcode:""})  ; this.getCityByState(e)}}>
                              {this.props.profileReducer.state.length > 0 ?
                                <option value=''>Select state</option>
                                : ''}
                              {this.props.profileReducer.state && this.props.profileReducer.state.map((item, index) => {
                                return <option key={index} value={item.id} name={item.name} selected={item.id === this.state.stateId}>{item.name}</option>
                              })}
                            </select>
                            <div className="dropdown-arrow">
                              <img src={require('./../../../../img/icon/down-arrow.png')} alt="icon" />
                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'state' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.state &&
                              this.state.serverValidation.state[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.state[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-6 PLR-15">
                          <div className="form-group">
                            <label htmlhtmlFor="City" className="bmd-label-floating">City</label>
                            <select className="form-control select-icon" id='cityId' value={this.state.cityId} onChange={(e) => { this.setState({
                              zipcode: ""
                            }) ; this.handleChange(e)}}>
                              {this.props.profileReducer.cities.length > 0 ?
                                <option value=''>Select city</option>
                                : ''}
                              {this.props.profileReducer.cities && this.props.profileReducer.cities.map((item, index) => {
                                return <option key={index} value={item.id} selected={item.id === this.state.cityId}>{item.name}</option>
                              })}
                            </select>
                            <div className="dropdown-arrow">
                              <img src={require('./../../../../img/icon/down-arrow.png')} alt="icon" />
                            </div>
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'city' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.city &&
                              this.state.serverValidation.city[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.city[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-6 PLR-15">
                          <div className="form-group">
                            <label htmlFor="ZipCode" className="bmd-label-floating">Zip Code</label>
                            <input type="text" className="form-control" value={this.state.zipcode}
                              onChange={(e) => this.handleChange(e)}
                              id="zipcode" />
                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'zipcode' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.zipcode &&
                              this.state.serverValidation.zipcode[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.zipcode[0]}
                              </div>
                              : ""}
                          </div>
                        </div>
                      </div>
                      {/* <div className="row">
                      <div className="col-xs-12 col-sm-2">
                        <div className="form-group">
                          <label htmlFor="CC" className="bmd-label-floating">CC</label>
                          <input type="text" className="form-control" id="CC" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-3">
                        <div className="form-group">
                          <label htmlFor="Area" className="bmd-label-floating">Area Code</label>
                          <input type="text" className="form-control" id="Area" />
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-7">
                        <div className="form-group phone-input">
                          <label htmlFor="Area" className="bmd-label-floating">Area Code</label>
                          <input type="text" className="form-control" id="Area" />
                          <button className="btn btn-sm btn-verify ripple">Verify</button>
                        </div>
                      </div>
                    </div> */}
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="blog" className="bmd-label-floating">Your Website or blog URL (if any...)</label>
                            <input type="text" className="form-control" value={this.state.blogUrl}
                              onChange={(e) => this.handleChange(e)} id="blogUrl" />

                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="Facebook" className="bmd-label-floating">Your Facebook group page (if any...)</label>
                            <input type="text" className="form-control" value={this.state.faceboolUrl}
                              onChange={(e) => this.handleChange(e)} id="faceboolUrl" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="Instagram" className="bmd-label-floating">Your Instagram page (if any...)</label>
                            <input type="text" className="form-control" value={this.state.instagramUrl}
                              onChange={(e) => this.handleChange(e)} id="instagramUrl" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <div className="form-group">
                            <label htmlFor="Twitter" className="bmd-label-floating">Your Twitter page (if any...)</label>
                            <input type="text" className="form-control" value={this.state.twitterUrl}
                              onChange={(e) => this.handleChange(e)} id="twitterUrl" />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-3">
                          <div className="form-group">
                            <label htmlFor="exampleSelect1" className="bmd-label-floating">Gender</label>
                            <select className="form-control select-icon" id="gender" value={this.state.gender} onChange={(e) => this.handleChange(e)}>
                              <option selected={this.state.gender === "0"} value={'0'}>Select gender</option>
                              <option selected={this.state.gender === "1"} value={'1'}>Male</option>
                              <option selected={this.state.gender === "2"} value={'2'}>Female</option>
                              <option selected={this.state.gender === "3"} value={'3'}>Other</option>
                            </select>
                            <div className="dropdown-arrow">
                              <img src={require('./../../../../img/icon/down-arrow.png')} alt="icon" />
                            </div>

                            {this.state.errorMessage !== "" &&
                              this.state.errorType !== "" &&
                              this.state.errorFor === 'gender' ?
                              <div className={"alert alert-" + this.state.errorType}>
                                {this.state.errorMessage}
                              </div>
                              : ""}

                            {this.state.serverValidation &&
                              this.state.serverValidation.gender &&
                              this.state.serverValidation.gender[0] ?
                              <div className={"alert alert-danger"}>
                                {this.state.serverValidation.gender[0]}
                              </div>
                              : ""}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-3 offset-sm-2">
                    <div className="profile-pic-sec">
                      <div className="profile-img-holder">
                      {this.state.img != "" ?
                      <img src={this.state.img} id='previewImage' alt="image" />
                      : 
                      <img src={require('./../../../../img/profile-placeholder.png')} id='previewImage' alt="image" />
                      }
                      </div>
                      <div className="upload-btn-wrapper">
                        <button className="btn btn-md btn-upload ripple">Upload your photo</button>
                        <input type="file" name="myfile" onChange={(e) => this.uploadImage(e)} />
                        <span className="upload-txt" ></span>
                      </div>
                      <div className="text-radio">
                        <p>What should the client see on your profile when booking your services? </p>
                        <div className="radio">
                          <label>
                            <input type="radio" name="optionsRadios" id="optionsRadios1" value="personalName" onChange={(e) => this.selectDisplayName(e)} checked={this.state.display_company_name == 0} />
                            <span>Display Personal Name</span>
                          </label>
                        </div>
                        <div className="radio">
                          <label>
                            <input type="radio" name="optionsRadios" id="optionsRadios2" value="companyName" onChange={(e) => this.selectDisplayName(e)} checked={this.state.display_company_name == 1} />
                            <span>Display Company Name</span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-btn">
                  <p>(This is required, as certain clients may prefer services from <span>Healers</span> of specific gender)
                </p>

                  {this.props.apiCallStatus.apiCallFor === 'updateProfile' &&
                    this.props.apiCallStatus.isCompleted === false &&
                    this.props.apiCallStatus.isFailed === false ?
                    <div className="text-center">
                      <img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} />
                    </div>
                    : ""}
                  {this.props.apiCallStatus.isStarted.indexOf('getProfileData') !== -1 ?
                    <Spinner />
                    : ""}
                  <button className="btn btn-md btn-save-submit ripple" onClick={(e) => this.handleSubmit(e)}>Save & Continue</button>
                </div>
              </form>
            </div>
          </div>
          <div className="modal" id='verifyOpt' tabindex="-1" role="dialog">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Verify Phone no</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form className="form-inline">
                    <div className="form-group mx-sm-12 mb-12">
                      <label for="inputPassword2" className="sr-only">OPT</label>
                      <input type="text" className="form-control" id="opt"
                        value={this.state.opt} onChange={(e) => this.handleChange(e)} placeholder="OPT" />
                    </div>
                    {this.props.apiCallStatus.apiCallFor === 'sendOPT' &&
                      this.props.apiCallStatus.isCompleted === false &&
                      this.props.apiCallStatus.isFailed === false ?
                      <img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} />
                      : ""}
                    {this.props.apiCallStatus.apiCallFor === 'verifyOTP' &&
                      this.props.apiCallStatus.isCompleted === false &&
                      this.props.apiCallStatus.isFailed === false ?
                      <img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} />
                      : ""}
                  </form>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary" onClick={() => this.props.sendOPT(this.props.loginReducer.token, this.state.cc, this.state.mobile)}>Resend</button>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  <button type="button" className="btn btn-primary" onClick={() => this.props.verifyOTP(getCookie('user_id'), this.state.opt)}>Verify</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  profileReducer: state.profileReducer,
  commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getAllCountries: () => dispatch(getAllCountries()),
  sendOPT: (token, cc, mobile) => dispatch(sendOPT(token, cc, mobile)),
  verifyOTP: (userId, opt) => dispatch(verifyOTP(userId, opt)),
  getStateByCountry: (countryId) => dispatch(getStateByCountry(countryId)),
  getCitiesByState: (stateId) => dispatch(getCitiesByState(stateId)),
  updateProfile: (data) => dispatch(updateProfile(data)),
  logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileContainer)