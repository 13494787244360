import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import {
    Link
} from "react-router-dom";
import {
    login
} from "./../ApiCall/apiCalls";
import {
    getCookie,
    setCookie
} from "./../ApiCall/cookies";
import {
    logInAction
} from './../Actions/action'
import loader from './../../../img/Spinner-1s-200px.gif';
import {
    Constant
} from './../../../Constants/constant';
import externalFileLoad from '../../externalfileLoad';
import { thisExpression } from '@babel/types';
class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            message: "",
            messageType: '',
            messageFor: '',
            serverValidation: {},
            rememberMe: getCookie("rememberMe")
        };

    }
    componentDidMount() {
        externalFileLoad();
        let th = this;
        let $ = window.jQuery;

        $('input[name="radio-btn"]').wrap('<div className="radio-btn"><i></i></div>');
        $(".radio-btn").on('click', function () {

            var _this = $(this),
                block = _this.parent().parent();
            block.find('input:radio').attr('checked', false);
            block.find(".radio-btn").removeClass('checkedRadio');
            _this.addClass('checkedRadio');
            _this.find('input:radio').attr('checked', true);
        });
        $('input[name="check-box"]').wrap('<div className="check-box"><i></i></div>');
        $.fn.toggleCheckbox = function () {
            setCookie("rememberMe", !this.attr('checked'), 30);
            th.setState({
                rememberMe: !this.attr('checked')
            })
            this.attr('checked', !this.attr('checked'));
        }
        $('.check-box').on('click', function () {
            $(this).find(':checkbox').toggleCheckbox();
            $(this).toggleClass('checkedBox');
            // th.setState({
            //     rememberMe: !th.state.checkbox
            // })
        });
        // $('body').bootstrapMaterialDesign();
        if (th.state.rememberMe === "true") {
            th.setState({
                email: getCookie("uemail"),
                password: getCookie("pass")
            })
        }
        var token = getCookie("token");
        if (token !== "") {
            this.props.history.push("/healer-services-list");
        }
        this.togglePassword();
    }

    componentWillMount() {
        var token = getCookie("token");

        if (token != "") {
            this.props.logInAction(token)
            //   this.props.history.push("/mysites");
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.loginReducer.token !== "" &&
            nextProps.apiCallStatus.apiCallFor === 'login' &&
            nextProps.apiCallStatus.isCompleted === true &&
            nextProps.apiCallStatus.isFailed === false) {

            var userRole = getCookie("user_Role");
            var hasUnacceptedAgreements = getCookie("hasUnacceptedAgreements");
            let serviceUrl = getCookie("serviceUrl");
            if(serviceUrl){
                this.props.history.push(serviceUrl);
            }
            else{
                if (userRole === '1') {
                    this.props.history.push("/client/dashboard");
                } else {
                    if (nextProps.apiCallStatus.message.completed_profile === 0) {
                        this.props.history.push("/healer-profile-container");
                    } else if (nextProps.apiCallStatus.message.completed_profile === 1 &&
                        hasUnacceptedAgreements === '1') {
                        this.props.history.push("/verify-service-document");
                    } else if (nextProps.apiCallStatus.message.completed_profile === 1 &&
                        nextProps.apiCallStatus.message.has_location === 0) {
                        this.props.history.push("/location-manager");
                    } else if (nextProps.apiCallStatus.message.completed_profile === 1 &&
                        nextProps.apiCallStatus.message.has_location === 1) {
                        this.props.history.push("/healer-services-list");
                    }
                }
            }
           
        }
        if (nextProps.apiCallStatus.apiCallFor === 'login' &&
            nextProps.apiCallStatus.isCompleted === true &&
            nextProps.apiCallStatus.isFailed === true) {
            this.setState({
                serverValidation: nextProps.apiCallStatus.message
            })
        }
    }
    togglePassword = () => {
        let $ = window.jQuery;
        $(".toggle-password").click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            } else {
                input.attr("type", "password");
            }
        });
    }
    handleChange = (e) => {
        e.preventDefault();
        var id = e.target.id;
        var val = e.target.value;
        this.setState({
            [id]: val,
            message: "",
            messageType: '',
            messageFor: '',
            serverValidation: {}
        })
    }
    handleSubmit = (event) => {
        var isEmpty = this.isEmptyFields();
        if (isEmpty === true) {
            var check = this.checkEmail();
            if (check === false) {
                this.setState({
                    message: "",
                    messageType: "",
                    messageFor: ''
                })
                this.props.login(this.state);
                if (this.state.rememberMe === "true") {
                    setCookie("rememberMe", "true", 30);
                    setCookie("uemail", this.state.email, 30);
                    setCookie("pass", this.state.password, 30);
                }
            } else {
                // alert("Email is not valid");
                this.setState({
                    message: "Email is not valid",
                    messageType: "danger",
                    messageFor: 'email'
                })
            }
        }

        event.preventDefault();
    }
    validateEmail = () => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.state.email);
    }
    checkEmail = () => {
        var isemailvalid = this.validateEmail();
        if (isemailvalid) {
            return false
        } else {
            return true
        }
    }
    isEmptyFields = () => {
        // if (this.state.email === '' && this.state.password === '') {
        //     this.setState({ 
        //         message:'Password is empty',
        //         messageType:'danger',
        //         messageFor :'password'
        //      });
        //     return "All Fields are empty"
        // }
        // else
        if (this.state.email === '') {
            this.setState({
                message: 'Email is empty',
                messageType: 'danger',
                messageFor: 'email'
            });
            return false
        } else if (this.state.password === '') {
            this.setState({
                message: 'Password is empty',
                messageType: 'danger',
                messageFor: 'password'
            });
            return false
        } else {
            return true
        }
    }
    handleRememberMe = () => {
        if (this.state.rememberMe === "true") {
            this.setState({
                rememberMe: ""
            }, () => {
                setCookie("rememberMe", "", 0);
                setCookie("uemail", this.state.email, 0);
                setCookie("pass", this.state.password, 0);
            })
        } else {
            this.setState({
                rememberMe: "true"
            }, () => {
                setCookie("rememberMe", "true", 30);
                setCookie("uemail", this.state.email, 30);
                setCookie("pass", this.state.password, 30);
            })
        }

    }
    render() {
        return (<
            div className="login-sec" >
            <
            div className="container" >
                <
            div className="login2-panel" >
                    <
            div className="login-holder" >
                        <
                            img src={
                                require("./../../../img/login-logo.jpg")
                            }
                            alt="logo" />
                        <
            /div> <
            form >
                            <
            div className="form-group" >
                                <
            label htmlFor="exampleInputEmail1"
                                    className="bmd-label-floating" > Email address < /label> <
                                        input type="email"
                                        value={
                                            this.state.email
                                        }
                                        onChange={
                                            (e) => this.handleChange(e)
                                        }
                                        className="form-control"
                                        id="email" /> {
                                        this.state.message !== "" && this.state.messageType === "danger" &&
                                            this.state.messageFor === 'email' ?
                                            <
                div className="alert alert-danger" > {
                                                    this.state.message
                                                } <
                /div> :
                                                    ""
            } {
                                                    this.state.serverValidation &&
                                                        this.state.serverValidation.email &&
                                                        this.state.serverValidation.email[0] ?
                                                        <
                    div className={
                                                                "alert alert-danger"
                                                            } > {
                                                                this.state.serverValidation.email[0]
                                                            } <
                    /div> :
                                                            ""
            } <
            /div> <
            div className="form-group" >
                                                                <label htmlFor="Password"
                                                                    className="bmd-label-floating" > Password < /label>
            <input type="password"
                                                                        value={
                                                                            this.state.password
                                                                        }
                                                                        onChange={
                                                                            (e) => this.handleChange(e)
                                                                        }
                                                                        className="form-control"
                                                                        id="password" />
                                                                    <span toggle="#password" className="fa fa-fw fa-eye field-icon toggle-password"></span>
                                                                    {
                                                                        this.state.message !== "" && this.state.messageType === "danger" &&
                                                                            this.state.messageFor === 'password' ?
                                                                            <
                div className="alert alert-danger" > {
                                                                                    this.state.message
                                                                                } <
                /div> :
                                                                                    ""
            } {
                                                                                    this.state.serverValidation &&
                                                                                        this.state.serverValidation.password &&
                                                                                        this.state.serverValidation.password[0] ?
                                                                                        <
                    div className={
                                                                                                "alert alert-danger"
                                                                                            } > {
                                                                                                this.state.serverValidation.password[0]
                                                                                            } <
                    /div> :
                                                                                            ""
            } <
            /div> <
            div className="checkbox custom-checkbox pull-left" >
                                                                                                <
            label >
                                                                                                    <
                                                                                                        input type="checkbox"
                                                                                                        onChange={
                                                                                                            () => this.handleRememberMe()
                                                                                                        }
                                                                                                        checked={
                                                                                                            this.state.rememberMe === "true" ? true : false
                                                                                                        }
                                                                                                    /> Remember me  <
            /label> <
            /div> <
            div className="pull-right"
                                                                                                        style={
                                                                                                            {
                                                                                                                textAlign: 'center',
                                                                                                                marginTop: '30px'
                                                                                                            }
                                                                                                        } >
                                                                                                        <
            a href="/forgot-password" > Forgot Password ? < /a>  <
            /div> <
            div className="clearfix" > < /div> {
                                                                                                                    /* {this.state.message !== "" && this.state.messageType === "danger" ?
                                                                                                                                                                        <div className="alert alert-danger">
                                                                                                                                                                            {this.state.message}
                                                                                                                                                                        </div>
                                                                                                                                                                        : ""} */
                                                                                                                } {
                                                                                                                    this.state.message !== "" && this.state.messageType === "success" ?
                                                                                                                        <
                    div className="alert alert-danger" > {
                                                                                                                                this.state.message
                                                                                                                            } <
                    /div> :
                                                                                                                            ""
            } {
                                                                                                                                this.props.apiCallStatus.apiCallFor === 'login' && this.props.apiCallStatus.isCompleted === false && this.props.apiCallStatus.isFailed === false ?
                                                                                                                                    <
                    div className="text-center" >
                                                                                                                                        <
                                                                                                                                            img className="logo"
                                                                                                                                            style={
                                                                                                                                                {
                                                                                                                                                    width: "83px",
                                                                                                                                                    display: 'inline'
                                                                                                                                                }
                                                                                                                                            }
                                                                                                                                            src={
                                                                                                                                                loader
                                                                                                                                            }
                                                                                                                                        /> <
                /div>: ""
            } {
                                                                                                                                            this.props.apiCallStatus.apiCallFor === 'login' &&
                                                                                                                                                this.props.apiCallStatus.isCompleted === true &&
                                                                                                                                                this.props.apiCallStatus.isFailed === true &&
                                                                                                                                                (typeof this.props.apiCallStatus.message === 'string') ?
                                                                                                                                                <
                    div className="alert alert-danger" > {
                                                                                                                                                        this.props.apiCallStatus.message
                                                                                                                                                    } < /div> :
                                                                                                                                                ""
            } <
            div className="submit-btn text-center" >
                                                                                                                                                        <
            button type="submit"
                                                                                                                                                            onClick={
                                                                                                                                                                (e) => this.handleSubmit(e)
                                                                                                                                                            }
                                                                                                                                                            className="btn btn-primary btn-raised" > Login < /button> <
            /div>
                                                                                                                                                
            <
            /form> <
            /div> <
            div className="login-now text-center" >
                                                                                                                                                                <
                                                                                                                                                                    p > Don 't have HealMaker Account? <Link to="/sign-up">Sign up</Link></p> <
            /div> <
            /div> <
            /div>
                                                                                                                                                            );
                                                                                                                                                        }
                                                                                                                                                    }
const mapStateToProps = (state, ownProps) => ({
                                                                                                                                                                    loginReducer: state.loginReducer,
                                                                                                                                                                apiCallStatus: state.apiCallStatus
                                                                                                                                                            })
                                                                                                                                                            
const mapDispatchToProps = (dispatch, ownProps) => ({
                                                                                                                                                                    login: (user) => dispatch(login(user)),
                                                                                                                                                                logInAction: (token) => dispatch(logInAction(token))
                                                                                                                                                            })
                                                                                                                                                            export default connect(
                                                                                                                                                                mapStateToProps,
                                                                                                                                                                mapDispatchToProps
)(Login)