import React,{ Component } from "react";
import { Link } from "react-router-dom";
export default class EndUserHeader extends Component{
   
    render(){
        return(
            <header className="header">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container">
                <Link className="navbar-brand" to="/"><img src={require('./../../../img/logo.png')} /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="nav navbar-nav ml-auto">
                    <li className="nav-item active">
                    <Link className="nav-link" to="/sign-up">Signup</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link" to="/login">Login</Link>
                    </li>
                    <li className="nav-item">
                    <Link className="nav-link btn btn-outline-success my-2 my-sm-0 ripple" to="/healer-signup">Become a healer </Link>
                    </li>
                </ul>
                </div>
            </div>
            </nav>   
        </header>
        )
    }
}