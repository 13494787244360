import React, { Component } from "react";
import { setCookie } from "../../Login&SignUp/ApiCall/cookies";
export default class PageNotFound extends Component {
    // componentWillMount(){
    //     setCookie('isPageFound','true',1)
    // }
    render() {
        return (
            <div className="row">
                <div className="col-sm-12 col-xs-12">
                    <div className="alert alert-info">
                     Page not found
                    </div>
                </div>
            </div>
        )
    }
}