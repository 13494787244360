export const GetCountries = 'GET_COUNTRIES';
export const GetStateByCountry = 'GET_STATE_BY_COUNTRY';
export const GetCityByState = 'GET_CITY_BY_STATE';
export const GetProfileData = 'GET_PROFILE_DATA';
export const addHealerAvaliablity = 'ADD_HEALER_AVALIABLITY';
export const GetServiceDetail = "GET_SERVICE_DETAIL";
export const healerAvaliablityRresponse = 'HEALER_AVALIABLITY_RESPONSE';
export const removeServiceImage = 'REMOVE_SERIVCE_IMAGE';

export function GetProfileDataAction(data) {
  return {
    type: GetProfileData,
    payload: {
      data
    }
  };
}

export function GetCountriesAction(countries) {
  return {
    type: GetCountries,
    payload: {
      countries
    }
  };
}

export function GetStateByCountryAction(states) {
  return {
    type: GetStateByCountry,
    payload: {
      states
    }
  };
}

export function GetCityByStateAction(cities) {
  return {
    type: GetCityByState,
    payload: {
      cities
    }
  };
}

export function addAvaliablity(data) {
    return {
        type: addHealerAvaliablity,
        payload: {
            data
        }
    }

}
export function GetServiceDetailAction(serviceDetail) {
  return {
    type: GetServiceDetail,
    payload: {
      serviceDetail
    }
  };
}
export function avaliablityResponse(data) {
    return {
        type: healerAvaliablityRresponse,
        payload: {
            data
        }
    }
    
}

export function removeServiceImageAction(imageId) {
  return {
      type: removeServiceImage,
      payload: {
        imageId
      }
  }
  
}