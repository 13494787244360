import React from 'react';
import { connect } from 'react-redux';
import { getCookie, setCookie } from "./../../Login&SignUp/ApiCall/cookies";
import externalFileLoad from '../../externalfileLoad';
import { Constant } from "../../../Constants/constant";
import Swiper from 'react-id-swiper';
import moment from 'moment';
import loader from './../../../img/Spinner-1s-200px.gif';
import { Spinner } from './../../Common/Components/loader';
import { GetServiceTypesAction, GetServiceDurationsAction } from "../../Common/Actions/actions";
import "../../Login&SignUp/Css/custom-healmaker.css";
const cloneDeep = require('clone-deep');
const params = {
  slidesPerView: 3,
  spaceBetween: 10,
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
}
class Index extends React.Component {
  constructor() {
    super();
    this.state = {
      isBookNow : false,
      serviceTypes: [],
      serivces: [],
      searchDateFrom: "",
      isApiCall: false,
      searchDateTo: "",
      service_type_id: "",
      lat: "",
      lng: "",
      serviceDurations: [],
      service_duration_id: "",
      serviceCategories: [{
        id: 1,
        name: "Home",
        selected: 1,
        icon: "icon-home-icon",
        dataToggle: "",
        dataTarget: ""
      },
      {
        id: 2,
        name: "Location",
        selected: 0,
        icon: "icon-location-icon",
        dataToggle: "modal",
        dataTarget: "#location_model"
      }, {
        id: 3,
        name: "Retreat",
        selected: 0,
        icon: "icon-retreat-icon",
        dataToggle: "",
        dataTarget: ""
      }],
      page: 1,
      plat_form: "web",
      isServiceTypeLoading: false,
      isLoadingServiceDuration: false,
      isGetDuration: false
    }

  }
  componentDidMount() {
    if (this.props.commonReducer.serviceType.length === 0) {
      this.setState({
        isServiceTypeLoading: true
      }, () => this.getServiceTypes())
    } else {
      this.setState({
        serviceTypes: this.props.commonReducer.serviceType,
        service_type_id: this.props.commonReducer.serviceType[0].id,
      })
    }
    if(this.props.commonReducer.serviceDuration.length > 0){
      this.setState({
        serviceDurations: this.props.commonReducer.serviceDuration,
      service_duration_id: this.props.commonReducer.serviceDuration[0].id
      })
     
    }
    this.getCurrentLocation();
    this.initAutocomplete();
    var self = this;
    window.jQuery("#searchDateForm").on('change', function (e) {
      self.setState({
        searchDateFrom: e.target.value
      }, () => {
        // window.jQuery('#searchDateForm').datepicker('setDate', self.state.searchDateFrom);
      })
    })
    window.jQuery("#searchDateTo").on('change', function (e) {
      self.setState({
        searchDateTo: e.target.value
      })
    })
    externalFileLoad();
    // let th = this;
    // var token = getCookie("token");
    // if (token !== "") {
    //     this.props.history.push("/healer-services-list");
    // }
  }
  initAutocomplete = () => {
    var self = this;
    var google = window.google;
    var map = new google.maps.Map(document.getElementById('map'), {
      center: { lat: -33.8688, lng: 151.2195 },
      zoom: 13,
      mapTypeId: 'roadmap'
    });

    // Create the search box and link it to the UI element.
    var input = document.getElementById('pac-input');
    var searchBox = new google.maps.places.SearchBox(input);
    map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);

    // Bias the SearchBox results towards current map's viewport.
    map.addListener('bounds_changed', function () {
      searchBox.setBounds(map.getBounds());
    });

    var markers = [];
    // Listen for the event fired when the user selects a prediction and retrieve
    // more details for that place.
    searchBox.addListener('places_changed', function () {
      var places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach(function (marker) {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      var bounds = new google.maps.LatLngBounds();
      places.forEach(function (place) {
        if (!place.geometry) {
          console.log("Returned place contains no geometry");
          return;
        }
        var icon = {
          url: place.icon,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25)
        };

        // Create a marker for each place.
        markers.push(new google.maps.Marker({
          map: map,
          icon: icon,
          title: place.name,
          position: place.geometry.location
        }));
        console.log("place.geometry.location", place.geometry.location.lat())
        self.setState({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        })
        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      map.fitBounds(bounds);
    });
  }
  getCurrentLocation = () => {
    var self = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        var pos = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        };
        self.setState({
          lat: pos.lat,
          lng: pos.lng
        }, () => {
          console.log("location ", pos.lat + " -----" + pos.lng)
        })
      }, function () {
        console.log("error")
      });
    } else {
      console.log("error")
    }
  }
  getServiceTypes = () => {
    fetch(Constant.apiURl + 'get-service-types', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(myJson => {
        if (myJson.success === '1') {
          myJson.data[0].selected = 1;
          this.props.GetServiceTypesAction(myJson.data)
          this.setState({
            serviceTypes: myJson.data,
            service_type_id: myJson.data[0].id,
            isServiceTypeLoading: false
          }, () => {
          })
        } else {
          this.setState({
            isServiceTypeLoading: false
          })
        }
      });
  }
  selectService = (e, id) => {
    e.preventDefault();
    let serviceTypes = [...this.state.serviceTypes];
    if (serviceTypes && serviceTypes.length > 0) {
      for (let i = 0; i < serviceTypes.length; i++) {
        serviceTypes[i].selected = 0;
      }
    }
    var serviceIndex = serviceTypes.findIndex(service => service.id == id);
    var selectedIndex = serviceTypes.findIndex(service => service.selected == 1);
    if (serviceIndex > -1) {
      if (serviceTypes[serviceIndex].selected == 0) {
        serviceTypes[serviceIndex].selected = 1;
        this.setState({
          service_type_id: serviceTypes[serviceIndex].id
        })
      } else {
        var counter = 0;
        if (serviceTypes && serviceTypes.length > 0) {
          for (let i = 0; i < serviceTypes.length; i++) {
            if (serviceTypes[i].selected == 1) {
              counter++;
            }

          }
        }
        if (counter > 1) {
          serviceTypes[serviceIndex].selected = 0;
          this.setState({
            serviceTypes: serviceTypes,
            service_type_id: ""
          })
        }

      }
      this.setState({
        serviceTypes: serviceTypes,
      })
    }
    if (selectedIndex > -1) {
      var counter = 0;
      if (serviceTypes && serviceTypes.length > 0) {
        for (let i = 0; i < serviceTypes.length; i++) {
          if (serviceTypes[i].selected == 1) {
            counter++;
          }

        }
      }
      if (counter > 1) {
        serviceTypes[selectedIndex].selected = 0;
      }

    }

  }
  selectCategory = (e, id) => {

    e.preventDefault();
    if (id == 2) {
      // this.initAutocomplete();
    }
    let serviceCategories = [...this.state.serviceCategories];
    if (serviceCategories && serviceCategories.length > 0) {
      for (let i = 0; i < serviceCategories.length; i++) {
        serviceCategories[i].selected = 0;
      }
    }
    var catIndex = serviceCategories.findIndex(cat => cat.id == id);
    var selectedIndex = serviceCategories.findIndex(cat => cat.selected == 1);

    if (catIndex > -1) {
      if (serviceCategories[catIndex].selected == 0) {
        serviceCategories[catIndex].selected = 1;

      } else {
        var counter = 0;
        if (serviceCategories && serviceCategories.length > 0) {
          for (let i = 0; i < serviceCategories.length; i++) {
            if (serviceCategories.selected == 1) {
              counter++;
            }
          }
        }
        if (counter > 1) {
          serviceCategories[catIndex].selected = 0;
        }

      }
      this.setState({
        serviceTserviceCategoriesypes: serviceCategories
      })
    }
    if (selectedIndex > -1) {
      var counter = 0;
      if (serviceCategories && serviceCategories.length > 0) {
        for (let i = 0; i < serviceCategories.length; i++) {
          if (serviceCategories.selected == 1) {
            counter++;
          }
        }
      }
      if (counter > 1) {
        serviceCategories[selectedIndex].selected = 0;
      }

    }
  }

  getServiceDuration = (e) => {
    e.preventDefault();
    let th = this;
    if (this.state.serviceDurations.length === 0) {
      this.setState({
        isLoadingServiceDuration: true
      }, () => {
        fetch(Constant.apiURl + "get-service-durations", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }).then(response => response.json())
          .then(res => {
            if (res.success == "1") {
              if (res.data && res.data.length > 0) {
                for (let i = 0; i < res.data.length; i++) {
                  if (i == 0) {
                    res.data[i].selected = 1;
                  } else {
                    res.data[i].selected = 0;
                  }

                }
              }
              this.props.GetServiceDurationsAction(res.data)
              th.setState({
                serviceDurations: res.data,
                service_duration_id: res.data[0].id,
                isLoadingServiceDuration: false
              })
            } else {
              console.log("service not found")
              th.setState({
                isLoadingServiceDuration: false
              })
            }
          }).catch(error => {
            th.setState({
              isLoadingServiceDuration: false
            })
          });
      })
    }


  }
  getDuration = (durationId) => {
    let th = this;
    this.setState({
      isGetDuration: true
    }, () => {
      let serviceDurations = cloneDeep(this.state.serviceDurations);
      for (let i = 0; i < serviceDurations.length; i++) {
        serviceDurations[i].selected = 0;
      }
      th.setState({
        serviceDurations: serviceDurations
      })
      let durationIndex = serviceDurations.findIndex(d => d.id == durationId)
      if (durationIndex > -1) {
        let duration = serviceDurations[durationIndex];

        if (duration.selected == 0) {
          duration.selected = 1;
          th.setState({
            service_duration_id: durationId,
            serviceDurations: serviceDurations,
            isGetDuration: false
          })
        } else {
          var count = 0;
          for (let i = 0; i < serviceDurations.length; i++) {
            if (serviceDurations[i].selected == 1) {
              count++
            }
          }
          if (count > 1) {
            duration.selected = 0;
          }
          // duration.selected = 0;
          th.setState({
            isGetDuration: false,
            serviceDurations: serviceDurations
          })
        }
      } else {
        th.setState({
          isGetDuration: false
        })
      }
    })


  }
  serchServices = () => {
    let formData = new FormData();
    formData.append("service_type_id", this.state.service_type_id);
    formData.append("page", this.state.page);
    formData.append("lat", this.state.lat);
    formData.append("lon", this.state.lng);
    formData.append("plat_form", "web");
    formData.append("from_date", this.state.searchDateFrom);
    formData.append("to_date", this.state.searchDateTo);
    formData.append("service_duration_id", this.state.service_duration_id);
    fetch(Constant.apiURl + "get_services ", {
      method: 'POST',
      body: formData
    }).then(response => response.json())
      .then(res => {
        this.setState({
          isApiCall: true
        })
        if (res.success == "1") {
          this.setState({
            serivces: res.data.data
          }, () => {
            console.log("serivces", this.state.serivces)
          })
        } else {
          console.log("service not found")
        }
      }).catch(error => console.error('Error', error));
  }
  setCurrentDate = (e) => {
    if (e.target.checked === true) {
      this.setState({
        isBookNow : true
      })
      let from = moment().format("YYYY-MM-DD HH:mm:ss");
      let to = moment().add('hours', 1).format("YYYY-MM-DD HH:mm:ss");
      window.jQuery('#searchDateForm').datepicker('setDate', from);
      window.jQuery('#searchDateTo').datepicker('setDate', to);
      this.setState({
        searchDateFrom: from,
        searchDateTo: to
      })
    } else {
      this.setState({
        searchDateFrom: '',
        searchDateTo: '',
        isBookNow : false
      })
    }

  }
  gotoServiceDetail = (e, id) => {
    e.preventDefault();
    this.props.history.push({pathname:"/service/"+id,state : {
      isBookNow : this.state.isBookNow
    }});
  }
  render() {
    return (
      <main>
        {this.state.isGetDuration ? <Spinner /> : ''}
        <div className="banner">
          <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
              <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
            </ol>
            <div className="carousel-inner" role="listbox">
              <div className="carousel-item active" style={{ backgroundImage: "url(http://wantechsolutions.com/healmaker_images/banner-1.png)" }}>
              </div>
              <div className="carousel-item" style={{ backgroundImage: "url(http://wantechsolutions.com/healmaker_images/banner-2.jpg)" }}>
              </div>
              <div className="carousel-item" style={{ backgroundImage: "url(http://wantechsolutions.com/healmaker_images/banner-3.jpg)" }}>
              </div>
              <div className="carousel-item" style={{ backgroundImage: "url(http://wantechsolutions.com/healmaker_images/banner-4.jpg)" }}>
              </div>
              <div className="main-services">
                <strong>Let’s Find a</strong>
                <h1>Healmaker</h1>
                <a href="#" className="btn btn-md how-work-btn ripple">how it works <img src="img/icon/home-arrow.png" alt="icon" /></a>
                <div className="header-title">
                  <h2>Where</h2>
                </div>
                <div className="where-tab-sec">
                  <div className="row">
                    {this.state.serviceCategories && this.state.serviceCategories.length > 0 && this.state.serviceCategories.map((cat, idx) => {
                      return <div className="col-xs-12 col-sm-4">
                        <a href="" className={cat.selected == 1 ? 'active' : ''} onClick={(e) => this.selectCategory(e, cat.id)} data-toggle={cat.dataToggle} data-target={cat.dataTarget}>
                          <div className="banner-icon">
                            <span className={`icon ${cat.icon} `}></span>
                          </div>
                          <div className="banner-content">
                            <small>{cat.name}</small>
                          </div>
                        </a>
                      </div>
                    })}
                  </div>
                </div>
                <div className="header-title">
                  <h3>What</h3>
                </div>
                <div className="where-tab-sec">
                  <div className="row text-center">
                    {this.state.isServiceTypeLoading ?
                      <div className="col-12 text-center">
                        <img className="logo"
                          style={{ width: "83px", display: 'inline' }}
                          src={loader} />
                      </div> :
                      this.state.serviceTypes && this.state.serviceTypes.length > 0 ? <Swiper {...params} >
                        {this.state.serviceTypes.map((service, idx) => {
                          return <div className="col-xs-12 col-sm-3">
                            <a href="" className={service.selected == 1 ? 'actives' : ''} onClick={(e) => this.selectService(e, service.id)}>
                              <div className="banner-icon">
                                <span className={`icon ${service.service_type_name == "Massage" ? 'icon-mes-icon' : service.service_type_name == 'Yoga' ? 'icon-yoga-icon' : service.service_type_name == 'Acupuncture' ? 'icon-mess-icon' : service.service_type_name == 'Chiropratic' ? 'icon-c-icon' : ''} `}></span>
                              </div>
                              <div className="banner-content">
                                <small>{service.service_type_name}</small>
                              </div>
                            </a>
                          </div>

                        })}
                      </Swiper>
                        : ""
                    }
                  </div>
                </div>
                <div className="header-title">
                  <h3>When</h3>
                </div>
                <div className="time-fliter-sec">
                  <div class="now-checkbox">
                    <label class="text-left">
                      <input type='checkbox' onChange={(e) => this.setCurrentDate(e)} />Now
                    </label>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 PR-0">
                      <div className="date-time-sec">
                        <div className="right-inner-addon date datepicker" data-date-format="yyyy-mm-dd">
                          <input name="name" value={this.state.searchDateFrom}
                            className=" form-control custom-form"
                            type="text" id="searchDateForm" autoComplete="off" placeholder="MM/DD/YY" />
                          <img src={require("../../../img/calendar-icon.png")} alt="icon" />
                        </div>
                        {/* <div className="time-input">
                          <input type="text" className="form-control" name="" placeholder="3:00 AM" />
                        </div> */}
                      </div>
                    </div>
                    <div className="col-sm-6 PL-0">
                      <div className="date-time-sec">
                        <div className="right-inner-addon date datepicker" data-date-format="yyyy-mm-dd">
                          <input name="name" value={this.state.searchDateTo} className=" form-control custom-form" type="text" id="searchDateTo" autoComplete="off" placeholder="MM/DD/YY" />
                          <img src={require("../../../img/calendar-icon.png")} alt="icon" />
                        </div>
                        {/* <div className="time-input">
                          <input type="text" className="form-control" name="" placeholder="3:00 AM" />
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="banner-btn">
                  <a href="#" className="btn btn-md btn-search ripple" onClick={(e) => this.getServiceDuration(e)} data-toggle="modal" data-target="#search_model">search now!</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="avilable-services-sec">
          <div className="container">
            <div className="row">
              <div className="col-xs-6 col-sm-6">
                <div className="box-title">
                  {this.state.isApiCall == true ? <h3>Search Result</h3> : <h3>Available now</h3>}
                </div>
              </div>
              <div className="col-xs-6 col-sm-6">
                <div className="box-title">
                  <a href="#" className="pull-right view-all">View all</a>
                </div>
              </div>
            </div>
            <div className="row" >
              {this.state.serivces && this.state.serivces.length > 0 ? this.state.serivces.map((service, idx) => {
                return <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image" onClick={(e) => this.gotoServiceDetail(e, service.service_url_key)}>
                      <img src={Constant.downloadUrl + service.images[0].path + service.images[0].file_name} alt="Service" />
                      <span className="services-price">
                        <span><a href="#">${service.service_price}</a></span>
                      </span>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <a href="" onClick={(e) => this.gotoServiceDetail(e, service.service_url_key)}><small>{service.service_type.service_type_name}</small></a>
                        {service.time_slots && service.time_slots.length > 0 ?
                          <div class="dropdown">
                            <a href="javascript:void(0)" class="dropdown-toggle service-time" data-toggle="dropdown">
                              <span>
                                <i class="fa fa-clock-o"></i>
                                {service.time_slots[0].time_from} - {service.time_slots[0].time_to}
                              </span>

                              <span class="caret"></span></a>
                            <ul class="dropdown-menu">
                              {service.time_slots && service.time_slots.length > 0 ? service.time_slots.map((time, idx) => {
                                const dateTime = new Date();
                                var date = dateTime.getDate();
                                var year = dateTime.getFullYear();
                                var month = dateTime.getMonth();
                                console.log(year + month + date)
                                return <li class="service-time">
                                  <i class="fa fa-clock-o"></i> {time.time_from}  -  {time.time_to}
                                </li>


                              }) : ""}
                            </ul>

                          </div>
                          : ""}
                      </div>
                      <h4><a href="" onClick={(e) => this.gotoServiceDetail(e, service.service_url_key)}>{service.service_name}</a></h4>
                      <p>{service.service_description}</p>
                      <div className="company-logo">
                        <img src={Constant.downloadUrl + service.user.profile.image.path + service.user.profile.image.file_name} alt="icon" />
                        <span>{service.user.profile.first_name + " " + service.user.profile.last_name}</span>
                      </div>
                    </div>
                  </div>
                </div>

              }) : this.state.serivces && this.state.serivces.length <= 0 && this.state.isApiCall == true ? "No services found!" :
                  <div className="col-xs-12 -col-sm-12">
                    <div className="row">
                      <div className="col-xs-12 col-sm-3">
                        <div className="services-items">
                          <div className="services-image">
                            <img src={require("../../../img/services-img.png")} alt="Service" />
                            <span className="services-price">
                              <span><a href="#">$29.50</a></span>
                            </span>
                          </div>
                          <div className="services-content">
                            <div className="time-sec">
                              <a href="#"><small>Yoga</small></a>
                              <span className="service-time">
                                <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                            </div>
                            <h4><a href="#">Advanced Yoga</a></h4>
                            <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                            <div className="company-logo">
                              <img src={require("../../../img/icon/company-icon.png")} alt="icon" />
                              <span>Wise Care</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-3">
                        <div className="services-items">
                          <div className="services-image">
                            <img src={require("../../../img/services-img-2.jpg")} alt="Service" />
                            <span className="services-price">
                              <span><a href="#">$30.50</a></span>
                            </span>
                          </div>
                          <div className="services-content">
                            <div className="time-sec">
                              <a href="#"><small>massages</small></a>
                              <span className="service-time">
                                <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                            </div>
                            <h4><a href="#">Advanced Yoga</a></h4>
                            <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                            <div className="company-logo">
                              <img src={require("../../../img/icon/company-icon-2.png")} alt="icon" />
                              <span>Wise Care</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-3">
                        <div className="services-items">
                          <div className="services-image">
                            <img src={require("../../../img/services-img-3.jpg")} alt="Service" />
                            <span className="services-price">
                              <span><a href="#">$28.50</a></span>
                            </span>
                          </div>
                          <div className="services-content">
                            <div className="time-sec">
                              <a href="#"><small>ayurveda</small></a>
                              <span className="service-time">
                                <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                            </div>
                            <h4><a href="#">Advanced Yoga</a></h4>
                            <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                            <div className="company-logo">
                              <img src={require("../../../img/icon/company-logo-3.png")} alt="icon" />
                              <span>Wise Care</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-3">
                        <div className="services-items">
                          <div className="services-image">
                            <img src={require("../../../img/services-img-4.jpg")} alt="Service" />
                            <span className="services-price">
                              <span><a href="#">$40.50</a></span>
                            </span>
                          </div>
                          <div className="services-content">
                            <div className="time-sec">
                              <a href="#"><small>acupuncture</small></a>
                              <span className="service-time">
                                <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                            </div>
                            <h4><a href="#">Advanced Yoga</a></h4>
                            <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                            <div className="company-logo">
                              <img src={require("../../../img/icon/company-logo-4.png")} alt="icon" />
                              <span>Wise Care</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
        {this.state.serivces && this.state.serivces.length <= 0 && this.state.isApiCall == false ? <div>
          <div className="recommended-services-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <h3>Recommended for you</h3>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <a href="#" className="pull-right view-all">View all</a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-5.jpg')}
                        alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img
                              src={require('./../../../img/icon/company-logo-5.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <a href="#"><small>Yoga</small></a>
                      </div>
                      <h4><a href="#">Advanced Yoga</a></h4>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img
                        src={require('./../../../img/services-img-6.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img
                              src={require('./../../../img/icon/company-logo-6.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <a href="#"><small>massages</small></a>
                      </div>
                      <h4><a href="#">Advanced Yoga</a></h4>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-7.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img
                              src={require('./../../../img/icon/company-logo-7.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <a href="#"><small>ayurveda</small></a>
                      </div>
                      <h4><a href="#">Advanced Yoga</a></h4>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img
                        src={require('./../../../img/services-img-8.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img
                              src={require('./../../../img/icon/company-logo-8.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <a href="#"><small>acupuncture</small></a>
                      </div>
                      <h4><a href="#">Advanced Yoga</a></h4>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="providers-services-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <h3>Top providers </h3>
                  </div>
                </div>
                <div className="col-xs-6 col-sm-6">
                  <div className="box-title">
                    <a href="#" className="pull-right view-all">View all</a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-5.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-5.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small><i className="fa fa-map-marker"></i> NewYork   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                      </div>
                      <h4><a href="#">Jason Smith</a></h4>
                      <ul className="categories-item">
                        <li><a href="#">Massages</a></li>
                        <li><a href="#">yoga</a></li>
                        <li><a href="#">Acupuncture</a></li>
                      </ul>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-6.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-6.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small><i className="fa fa-map-marker"></i> NewYork   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                      </div>
                      <h4><a href="#">Advanced Yoga</a></h4>
                      <ul className="categories-item">
                        <li><a href="#">yoga</a></li>
                        <li><a href="#">Acupuncture</a></li>
                      </ul>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-7.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-7.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small><i className="fa fa-map-marker"></i> NewYork   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                      </div>
                      <h4><a href="#">Advanced Yoga</a></h4>
                      <ul className="categories-item">
                        <li><a href="#">Massages</a></li>
                        <li><a href="#">Acupuncture</a></li>
                      </ul>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-8.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-8.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small><i className="fa fa-map-marker"></i> NewYork   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-clock-o"></i> 8:30 AM - 9:30 AM
                      </span>
                      </div>
                      <h4><a href="#">Advanced Yoga</a></h4>
                      <ul className="categories-item">
                        <li><a href="#">Massages</a></li>
                        <li><a href="#">yoga</a></li>
                      </ul>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Retreats-services-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-8 col-sm-8">
                  <div className="box-title">
                    <h3>Retreats around the world </h3>
                  </div>
                </div>
                <div className="col-xs-4 col-sm-4">
                  <div className="box-title">
                    <a href="#" className="pull-right view-all">View all</a>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-5.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-5.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small>yoga   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-calendar"></i> 28, March<span className="border-left"></span> 01 July
                      </span>
                      </div>
                      <h5><a href="#"><i className="fa fa-map-marker"></i>City hall park, NY, USA</a></h5>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-6.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-6.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small>yoga   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-calendar"></i> 28, March<span className="border-left"></span> 01 July
                      </span>
                      </div>
                      <h5><a href="#"><i className="fa fa-map-marker"></i>City hall park, NY, USA</a></h5>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-7.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-7.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small>yoga   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-calendar"></i> 28, March<span className="border-left"></span> 01 July
                      </span>
                      </div>
                      <h5><a href="#"><i className="fa fa-map-marker"></i>City hall park, NY, USA</a></h5>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-3">
                  <div className="services-items">
                    <div className="services-image">
                      <img src={require('./../../../img/services-img-8.jpg')} alt="Service" />
                      <div className="service-logo-sec">
                        <div className="table-method">
                          <a href="#">
                            <img src={require('./../../../img/icon/company-logo-8.png')} alt="" />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="services-content">
                      <div className="time-sec">
                        <small>yoga   <span className="border-left"></span></small>
                        <span className="service-time">
                          <i className="fa fa-calendar"></i> 28, March<span className="border-left"></span> 01 July
                      </span>
                      </div>
                      <h5><a href="#"><i className="fa fa-map-marker"></i>City hall park, NY, USA</a></h5>
                      <p>Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.</p>
                      <ul className="feedback-star">
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><i className="fa fa-star"></i></a></li>
                        <li><a href="#"><span className="total-feedback">256 Reviews</span></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="downlaod-app-sec">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-6">
                  <div className="app-image-holder">
                    <img src={require('./../../../img/mobile-img.png')} alt="Mobile" />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="app-content-holder">
                    <small>The Healmaker App</small>
                    <h3>Do even more with the Healmaker app</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim. veniam, quis nostrud exercitation ullamco laboris. nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor.</p>
                    <ul className="app-store-images">
                      <li><a href="#"><img src={require('./../../../img/play-store.jpg')} alt="play store" /></a></li>
                      <li><a href="#"><img src={require('./../../../img/itune-btn.jpg')} alt="play store" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div> : ""}

        <div className="modal fade" id="search_model">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">

                <div className="timer">
                  {this.state.isLoadingServiceDuration ?
                    <div className="col-12 text-center">
                      <img className="logo"
                        style={{ width: "83px", display: 'inline' }}
                        src={loader} />
                    </div> :
                    this.state.serviceDurations && this.state.serviceDurations.length > 0 && this.state.serviceDurations.map((dur, idx) => {
                      return <ul>	<li onClick={(e) => this.getDuration(dur.id)}><span className={dur.selected == 1 ? 'active' : ''}><b>{dur.duration}</b><br />MIN</span></li>		</ul>
                    })}
                </div>

                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              {/* <div className="modal-body">
				<form className="search-location" action="#" method="post">
					<div className="form-group has-search">
						<span className="fa fa-search form-control-feedback"></span>
						<input type="text" class="form-control" placeholder="Search Location" />
					</div>
				</form>
			 <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11892.678581556853!2d-71.402593!3d41.824645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e4453c442f5b9f%3A0x22d5b1cf16b79db7!2s21+Brown+St%2C+Providence%2C+RI+02906%2C+USA!5e0!3m2!1sen!2s!4v1563206851544!5m2!1sen!2s" width="100%" height="350" frameborder="0" className="border-0" allowfullscreen></iframe>
			</div> */}
              <div class="modal-footer">
                <button type="button" onClick={this.serchServices} className="btn btn-md btn-search ripple" data-dismiss="modal">ADD DURATION</button>
              </div>

            </div>
          </div>
        </div>
        <div className="modal fade" id="location_model">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">

                <div className="map-div">


                </div>

                <button type="button" className="close" data-dismiss="modal">&times;</button>
              </div>
              <div className="modal-body">
                <form className="search-location" action="#" method="post">
                  <div className="form-group has-search popupsearch">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input type="text" id="pac-input" class="form-control" placeholder="Search Location" />
                  </div>
                </form>
                <div id="map"></div>
                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11892.678581556853!2d-71.402593!3d41.824645!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e4453c442f5b9f%3A0x22d5b1cf16b79db7!2s21+Brown+St%2C+Providence%2C+RI+02906%2C+USA!5e0!3m2!1sen!2s!4v1563206851544!5m2!1sen!2s" width="100%" height="350" frameborder="0" className="border-0" allowfullscreen></iframe> */}
              </div>
              <div class="modal-footer">
                <button type="button" onClick={this.getSearchLocationLatLng} className="btn btn-md btn-search ripple" data-dismiss="modal">SET LOCATION</button>
              </div>

            </div>
          </div>
        </div>

      </main>
    )
  }
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  GetServiceTypesAction: (data) => dispatch(GetServiceTypesAction(data)),
  GetServiceDurationsAction: (data) => dispatch(GetServiceDurationsAction(data))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)