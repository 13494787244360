import React,{ Component } from "react";

export default class UserHeader extends Component{
    render(){
        return(
            <footer className="footer">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-4">
                  <div className="footer-item">
                    <img src={require('./../../../img/footer-logo.png')} alt="logo" />
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore aliqua.</p>
                    <p>Copyright 2019 Healmaker. All rights reserved</p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-2">
                  <strong>About</strong>
                  <ul className="menu-links">
                    <li><a href="#">Log in</a></li>
                    <li><a href="#">Sign Up</a></li>
                    <li><a href="#">Become a healer</a></li>
                    <li><a href="#">Help</a></li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-2">
                  <strong>Meet Us</strong>
                  <ul className="menu-links">
                    <li><a href="#">Press</a></li>
                    <li><a href="#">Job</a></li>
                    <li><a href="#">Apply</a></li>
                    <li><a href="#">Contact</a></li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-2">
                  <strong>Social media</strong>
                  <ul className="menu-links">
                    <li><a href="#"><i className="fa fa-facebook"></i> Facebook</a></li>
                    <li><a href="#"><i className="fa fa-twitter"></i> Twitter</a></li>
                    <li><a href="#"><i className="fa fa-instagram"></i> Instagram</a></li>
                  </ul>
                </div>
                <div className="col-xs-12 col-sm-2">
                  <strong>Contact Us</strong>
                  <ul className="contact-links">
                    <li><a href="#">Phone: (000)123 456 789</a></li>
                    <li><a href="javascript:void(0)" className="wave "><img src={require('./../../../img/chat-btn.png')} alt="Button" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        )
    }
}