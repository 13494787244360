import * as apiCallActions from "../../ApiCallStatus/Actions/action";
import * as Actions from "./../Actions/actions";
import { Constant } from "./../../../Constants/constant";

export function getAllCountries(token) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getAllCountries"
      })
    );
    fetch(Constant.apiURl + "get-countries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetCountriesAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getAllCountries",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getAllCountries",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getAllCountries",
            message: error.message
          })
        );
      });
  };
}

export function getServiceTypes() {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getServiceTypes"
      })
    );
    fetch(Constant.apiURl + "get-service-types", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetServiceTypeCommonAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getServiceTypes",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getServiceTypes",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getServiceTypes",
            message: error.message
          })
        );
      });
  };
}
export function getStateByCountry(countryId) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getStateByCountry"
      })
    );
    fetch(Constant.apiURl + "get-states-by-country", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        country_id: countryId
      })
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetStateByCountryAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getStateByCountry",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getStateByCountry",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getStateByCountry",
            message: error.message
          })
        );
      });
  };
}

export function getCitiesByState(stateId) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getCitiesByState"
      })
    );
    fetch(Constant.apiURl + "get-cities-by-state", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        state_id: stateId
      })
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetCityByStateAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getCitiesByState",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getCitiesByState",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getCitiesByState",
            message: error.message
          })
        );
      });
  };
}

export function addServiceLocation(data) {
  var formData = new FormData();
  if (data.images.length > 0) {
    for (let index = 0; index < data.images.length; index++) {
      formData.append("images[]", data.images[index]);
    }
  }
  formData.append("auth_token", data.auth_token);
  formData.append("name", data.name);
  formData.append("address1", data.address1);
  formData.append("address2", data.address2);
  formData.append("state_id", data.state_id);
  formData.append("country_id", data.country_id);
  formData.append("city_id", data.city_id);
  formData.append("zip_code", data.zip_code);
  formData.append("service_type", data.service_type);
  formData.append("lat", data.lat);
  formData.append("lon", data.lon);
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "addServiceLocation"
      })
    );
    fetch(Constant.apiURl + "save-loaction", {
      method: "POST",
      body: formData
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (myJson.success === "1") {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "addServiceLocation",
              message: myJson.location.id
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "addServiceLocation",
              message: myJson.validation
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "addServiceLocation",
            message: error.message
          })
        );
      });
  };
}

export function getProfileData(userId, token) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getProfileData"
      })
    );
    fetch(Constant.apiURl + "get-account", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_id: userId,
        auth_token: token
      })
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (myJson.success === "1") {
          let data = JSON.parse(myJson.data);
          data.profile_data.isPhoneVerified = myJson.status;
          if (myJson.image_data && myJson.image_data.path) {
            let imgUrl =
              "https://healmaker.wantechsolutions.com/" +
              myJson.image_data.path +
              "/" +
              myJson.image_data.file_name;
            data.profile_data.imgUrl = imgUrl;
          }

          if (data.profile_data) {
            dispatch(Actions.GetProfileDataCommonAction(data.profile_data));
          }
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getProfileData",
              message: "",
              response : data.profile_data
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getProfileData",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getProfileData",
            message: error.message
          })
        );
      });
  };
}

export function editServiceLocation(data) {
  var formData = new FormData();
  if (data.images.length > 0) {
    for (let index = 0; index < data.images.length; index++) {
      formData.append("images[]", data.images[index]);
    }
  }
  formData.append("id", data.id);
  formData.append("auth_token", data.auth_token);
  formData.append("name", data.name);
  formData.append("address1", data.address1);
  formData.append("address2", data.address2);
  formData.append("state_id", data.state_id);
  formData.append("country_id", data.country_id);
  formData.append("city_id", data.city_id);
  formData.append("zip_code", data.zip_code);
  formData.append("service_type", data.service_type);
  formData.append("lat", data.lat);
  formData.append("lon", data.lon);
  formData.append("description", data.description);
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "editServiceLocation"
      })
    );
    fetch(Constant.apiURl + "update-location", {
      method: "POST",
      body: formData
    })
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        if (myJson.success === "1") {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "editServiceLocation",
              message: myJson.location.id
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "editServiceLocation",
              message: myJson.validation
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "editServiceLocation",
            message: error.message
          })
        );
      });
  };
}

export function getAddressDetail(country, state, city) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getAddressDetail"
      })
    );
    dispatch(Actions.GetAddressDataCommonAction(country, state, city));
    dispatch(
      apiCallActions.ApiFulfilledAction({
        apiCallFor: "getAddressDetail",
        message: ""
      })
    );
  };
}
