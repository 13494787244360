import React,{ Component } from "react";
import { connect } from 'react-redux';
import  Banner  from "./../../../Common/Components/clientBanner";
import { Filters } from "./filters";
import { AvailableServiceList } from "./availableServicesList";
import { RecommendedServiceList } from "./recommendedServicesList";
import { ProviderList } from "./providersList";
import { RetreatList } from "./retreatsList";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import externalFileLoad from  '../../../externalfileLoad';
class DashboardContainer extends Component{
    constructor(){
        super();
        this.state = {
            availableServices: [{
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }, {
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }, {
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }, {
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }, {
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }, {
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }, {
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }, {
                price: '29.50',
                name: 'Yoga',
                startTime: '8:30 AM',
                endTime: '9:30 AM',
                description: 'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                companyIcon: 'Yoga',
                descriptionTitle: 'Advanced Yoga',
                imgUrl: "img/services-img.png",
                companyName: 'Wise Care'
            }],
            recomendedServices : [
                {
                    name:'YOGA',
                    descriptionTitle:'Advanced Yoga',
                    description:'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                    reviews:'256'
                },
                {
                    name:'YOGA',
                    descriptionTitle:'Advanced Yoga',
                    description:'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                    reviews:'256'
                },
                {
                    name:'YOGA',
                    descriptionTitle:'Advanced Yoga',
                    description:'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                    reviews:'256'
                }, {
                    name:'YOGA',
                    descriptionTitle:'Advanced Yoga',
                    description:'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod. tempor.',
                    reviews:'256'
                }
            ],
            providers:[
                {
                    location :'NEWYORK',
                      startTime :'8:30 AM',
                      endTime :'9:30 AM', 
                      descriptionTitle :'Jason Smith',
                      categories :['Massages','Yoga','Acupuncture'],
                      reviews :'256'
                },
                {
                    location :'NEWYORK',
                      startTime :'8:30 AM',
                      endTime :'9:30 AM', 
                      descriptionTitle :'Jason Smith',
                      categories :['Massages','Yoga','Acupuncture'],
                      reviews :'256'
                },
                {
                    location :'NEWYORK',
                      startTime :'8:30 AM',
                      endTime :'9:30 AM', 
                      descriptionTitle :'Jason Smith',
                      categories :['Massages','Yoga','Acupuncture'],
                      reviews :'256'
                },
                {
                    location :'NEWYORK',
                      startTime :'8:30 AM',
                      endTime :'9:30 AM', 
                      descriptionTitle :'Jason Smith',
                      categories :['Massages','Yoga','Acupuncture'],
                      reviews :'256'
                }
            ],
            retreatList :[
                {
                    name:'YOGA',
                    location : 'City hall park, NY, USA',
                    startDate :'28, March',
                    endDate :'01 July', 
                    description :'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.',
                    reviews :'256'
                },
                {
                    name:'YOGA',
                    location : 'City hall park, NY, USA',
                    startDate :'28, March',
                    endDate :'01 July', 
                    description :'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.',
                    reviews :'256'
                },
                {
                    name:'YOGA',
                    location : 'City hall park, NY, USA',
                    startDate :'28, March',
                    endDate :'01 July', 
                    description :'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.',
                    reviews :'256'
                },
                {
                    name:'YOGA',
                    location : 'City hall park, NY, USA',
                    startDate :'28, March',
                    endDate :'01 July', 
                    description :'Lorem ipsum dolor sit amet, onsectetur adipisicing elit, sed do eiusmod.',
                    reviews :'256'
                }
            ]
        }
    }
    componentWillMount(){
        if(window.jQuery('link[id="healerstyle"]')){
            this.loadjscssfile('/css/client_style.css','css','clientstyle')
            window.jQuery('link[id="healerstyle"]').remove();
        }
    }
    componentDidMount(){
        externalFileLoad();
        let token = getCookie('token');
        let email = getCookie('user_email');
        let userId = getCookie('user_id');
        let userRole = getCookie('user_Role');
        if (token === '') {
            this.props.history.push('/login')
        } else {
            this.props.logInAction(token, email, userId, userRole)
        }
     }
     componentWillUnmount(){
        if(window.jQuery('link[id="clientstyle"]')){
            window.jQuery('link[id="clientstyle"]').remove();
        }
        this.loadjscssfile('/css/style.css','css','healerstyle')
     }
     loadjscssfile=(filename, filetype,title)=>{
          if (filetype=="css"){ //if filename is an external CSS file
             var fileref=document.createElement("link")
             fileref.setAttribute("rel", "stylesheet")
             fileref.setAttribute("type", "text/css")
             fileref.setAttribute("href", filename)
             fileref.setAttribute("id",title)
         }else if (filetype=="js"){ //if filename is a external JavaScript file
            var fileref=document.createElement('script')
            fileref.setAttribute("type","text/javascript")
            fileref.setAttribute("src", filename)
        }
         if (typeof fileref!="undefined")
             document.getElementsByTagName("head")[0].appendChild(fileref)
     }
    render(){
        return(
            <main>
                <Banner/>
                <Filters/>
                <AvailableServiceList availableServices={this.state.availableServices}/>
                <RecommendedServiceList recomendedServices={this.state.recomendedServices}/>
                <ProviderList providerList={this.state.providers}/>
                <RetreatList retreatList={this.state.retreatList}/>
            </main>
        )
    }
}
const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus,
    commonReducer: state.commonReducer
  })
  
  const mapDispatchToProps = (dispatch, ownProps) => ({ 
    logInAction: (token, email, userId, userRole) => dispatch(logInAction(token, email, userId, userRole))
  })
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(DashboardContainer)