import React, { component } from 'react';
import { connect } from 'react-redux';
// import loader from './../../../images/Spinner-1s-200px.gif';
import { Constant } from './../../../Constants/constant';
import { Link } from "react-router-dom";
import loader from './../../../img/Spinner-1s-200px.gif';
import './../Css/style.css';
class VerifyAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            email :'',
            loading: false,
            response: '',
            verified : false,
            message:"",
            messageType:''
        }
    }

    validateEmail = () => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(this.state.email);
    }
    checkEmail = () => {
        var isemailvalid = this.validateEmail();
        if (isemailvalid) {
            return false
        } else {
            return true
        }
    }
    VerifyAccount=(e)=>{
        e.preventDefault();
        let th = this;
        if(this.state.email === ''){
            this.setState({
                message:"Email is empty"  ,
                messageType:"danger"
            })
        }else{
            var check = this.checkEmail();
            if (check === false) {
                this.setState({
                    loading :true,
                    message:""  ,
                messageType:""
                })
                const urlParams = new URLSearchParams(window.location.search);
                const activationcode = urlParams.get('activationcode');
                let th = this;
                fetch(Constant.apiURl + "confirm-email", {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email  : th.state.email,
                        activationcode :activationcode
                    })
                }).then(function (response) {
                    return response.json();
                }).then(function (myJson) {
                    if(myJson.success === '1'){
                        th.setState({
                            loading : false,
                            verified : true
                        })
                    }else{
                        th.setState({
                            message:myJson.message  ,
                            messageType:"danger",
                            loading : false
                        })
                    }
                })
            }else{
                this.setState({
                    message:"Email is not valid",
                    messageType:"danger"
                })
            }
        }
        
    }
    render() {
        return (
            <React.Fragment>
                <div className="login-sec">
          <div className="container">
            <div className="login2-panel">
              <div className="login-holder">
                <img src={require("./../../../img/login-logo.jpg")} alt="logo" />
              </div>
              {this.state.verified ? 
              <div style={{ padding: "10px 40px 30px", textAlign: "center" }}>
              <p style={{ fontSize: "16px", lineHeight: "25px" }}>
                  Your account has been activated. <br />Simply click the Login button below to Login.
</p>
              <br />
              <a href="/login" className="btn btn-primary btn-raised">Login</a><br />
              <br />
          </div>
                : <form>
                <div className="form-group">
                  <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Email address</label>
                  <input type="email" value={this.state.email} 
                   onChange={(e) => this.setState({email : e.target.value})} className="form-control" id="email"/>
                </div>
                {this.state.message !== "" && this.state.messageType === "danger" ?
                                                      <div className="alert alert-danger">
                                                          {this.state.message}
                                                      </div>
                                                      : ""}
                                                      {this.state.loading ?
                      <div className="text-center">
                          <img className="logo"
                                  style={{ width: "83px", display: 'inline' }}
                                 src={loader} />
                            </div>
                              : ""}
                <div className="submit-btn text-center">
                    <button type="submit"  onClick={(e) => this.VerifyAccount(e)} 
                    className="btn btn-primary btn-raised">VerifyAccount</button>
                  </div></form>}
              
              </div>
            <div className="login-now text-center">
              <p>Already have HealMaker Account? <Link to="/signUp">Sign Up</Link></p>
            </div>
          </div>
        </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
    loginReducer: state.loginReducer,
    apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({

})
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VerifyAccount)