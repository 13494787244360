import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Banner extends Component {
    render() {
        return (
            <div className="sub-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-12 col-sm-5">
                            <div className="profile-holder">
                                <div className="media">
                                    <img
                                        className="mr-3"
                                        src={require("../../../img/profile-1.jpg")}
                                        alt="Generic placeholder image"
                                    />
                                    <div className="media-body">
                                        <h5 className="mt-0">{this.props.subHeader}</h5>
                                        <p>
                                            {this.props.commonReducer.profile &&
                                                this.props.commonReducer.profile.first_name &&
                                                this.props.commonReducer.profile.first_name !== ""
                                                ? this.props.commonReducer.profile.first_name
                                                : "Dashboard"}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-7">
                            <ul className="menu-tabs">
                                <li>
                                    <Link  to="/healer-location" className={window.location.pathname === '/healer-location' ? "active" :''}>
                                        <div className="banner-icon">
                                            <i className="icon-map-pin" aria-hidden="true" />
                                        </div>
                                        <div className="banner-content">
                                            <small>Locations</small>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/healer-services-list"  className={window.location.pathname === '/healer-services-list' ? "active" :''}>
                                        <div className="banner-icon">
                                            <i className="icon-leaf" />
                                        </div>
                                        <div className="banner-content">
                                            <small>Services</small>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/healer-calender"  className={window.location.pathname === '/healer-calender' ? "active" :''}>
                                        <div className="banner-icon">
                                            <i className="icon-calendar" />
                                        </div>
                                        <div className="banner-content">
                                            <small>Calendar</small>
                                        </div>
                                    </Link>
                                </li>
                                <li>
                                    <a href="#">
                                        <div className="banner-icon">
                                            <i className="icon-envolope" />
                                        </div>
                                        <div className="banner-content">
                                            <small>Inbox</small>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}