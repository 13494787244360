import React,{ Component } from "react";
import { Link } from "react-router-dom";
import { getCookie } from "./../../Login&SignUp/ApiCall/cookies";
export default class UserHeader extends Component{
  redirect=(url)=>{
    if(url === '/client/dashboard'){
      if(window.jQuery('link[id="healerstyle"]')){
        this.loadjscssfile('/css/client_style.css','css','clientstyle')
        window.jQuery('link[id="healerstyle"]').remove();
    }
      this.props.history.push(url);
    }
  }
  loadjscssfile=(filename, filetype,title)=>{
    if (filetype=="css"){ //if filename is an external CSS file
       var fileref=document.createElement("link")
       fileref.setAttribute("rel", "stylesheet")
       fileref.setAttribute("type", "text/css")
       fileref.setAttribute("href", filename)
       fileref.setAttribute("id",title)
   }else if (filetype=="js"){ //if filename is a external JavaScript file
      var fileref=document.createElement('script')
      fileref.setAttribute("type","text/javascript")
      fileref.setAttribute("src", filename)
  }
   if (typeof fileref!="undefined")
       document.getElementsByTagName("head")[0].appendChild(fileref)
}
    render(){
      let userRole = getCookie('user_Role');
        return(
            <header className="header">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
              <div className="container">
                <Link className="navbar-brand" to="/"><img src="/img/logo.png" /></Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="nav navbar-nav ml-auto">

                    {/* <li className="nav-item" style={{cursor:'pointer'}} >
                      <a className="nav-link" onClick={()=>this.props.logOut()}>Signout</a>
                    </li> */}
                    <li className="nav-item">
                    <div className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Profile
                    </div>
                      <div className="dropdown-menu menu-dropdown" aria-labelledby="navbarDropdown">
                      {/* {userRole == '2' ?  <Link className="nav-link dropdown" to="/transaction-detail">Transactions</Link> :  ""} */}
                      {userRole == '1' ?  <Link className="nav-link" to="/client/dashboard">Dashboard</Link> :  <Link className="nav-link" to="/healer-services-list">Dashboard</Link>}                      {userRole == '2' ?  <Link className="nav-link dropdown" to="/transaction-detail">Transactions</Link> :  ""}
                      <Link className="nav-link dropdown" to="/transaction-detail">Inbox</Link> 
                      {userRole == '1' ?  <Link className="nav-link dropdown" to="/transaction-detail">Bookings (0)</Link> :  ""}
                      <Link className="nav-link dropdown" to="/verify-service-document">Balance ($5000)</Link> 
                      
                        <div className="dropdown-divider"></div>
                        {userRole == '1' ?  <Link className="nav-link dropdown" to="/client-profile-container">Profile</Link> :  <Link className="nav-link dropdown" to="/healer-profile-container">Manage Profile</Link>}
                        <a className="nav-link dropdown">Messages (0)</a>
                        <a className="nav-link dropdown" onClick={()=>this.props.logOut()}>Logout</a>
                      </div>
                    </li>
                     <li className="nav-item">
                     <a href="javascript:void(0)" className="nav-link">Help</a>
                      {/* <Link className="nav-link" to="#">Help</Link> */}
                    </li>
                  </ul>
                </div>
              </div>
            </nav>   
          </header>
        )
    }
}