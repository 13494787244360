import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { signUp, socialsignUp } from "./../ApiCall/apiCalls";
import { getCookie } from "./../ApiCall/cookies";
import loader from './../../../img/Spinner-1s-200px.gif';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import externalFileLoad from '../../externalfileLoad';
import PasswordStrengthMeter from '../../PasswordStrengthMeter';

class SignUp extends Component {
  constructor() {
    super();
    this.facebookLogin = React.createRef();
    this.password = React.createRef();
    this.state = {
      email: '',
      socialEmail: '',
      password: '',
      confirmPassword: '',
      message: "",
      messageType: '',
      messageFor: '',
      serverValidation: {},
    }
  }
  componentDidMount() {
    externalFileLoad();
    let th = this;
    var token = getCookie("token");
    if (token !== "") {
      this.props.history.push("/healer-services-list");
    }
    this.togglePassword();
  }
  togglePassword = () => {
    let $ = window.jQuery;
    $(".toggle-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
    $(".toggle-retype-password").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var input = $($(this).attr("toggle"));
      if (input.attr("type") == "password") {
        input.attr("type", "text");
      } else {
        input.attr("type", "password");
      }
    });
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.apiCallStatus.apiCallFor === 'signUp' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      this.props.history.push("/thankyou?email=" + this.state.email);
      //   if(nextProps.apiCallStatus.response.user_role == 1 && nextProps.apiCallStatus.response.status == 1){
      //     this.props.history.push("/client/dashboard");
      // }else if(nextProps.apiCallStatus.response.user_role == 2 && nextProps.apiCallStatus.response.status == 1){
      //   this.props.history.push("/healer-services-list");
      // }

    }

    if (nextProps.apiCallStatus.apiCallFor === 'socialsignUp' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
      var userRole = getCookie("user_Role");
      if (userRole === '1') {
        this.props.history.push("/client/dashboard");
      } else {
        this.props.history.push("/healer-services-list");
      }
      // this.props.history.push("/thankyou?email="+this.state.socialEmail);
      this.setState({
        email: '',
        password: '',
        confirmPassword: '',
        message: "",
        messageType: ''
      })
    }
    // if (nextProps.apiCallStatus.apiCallFor === 'socialsignUp' &&
    //  nextProps.apiCallStatus.isCompleted === true &&
    //   nextProps.apiCallStatus.isFailed === false) {
    //     this.props.history.push("/thankyou?email="+this.state.socialEmail);
    //     this.setState({
    //         email: '',
    //         password: '',
    //         confirmPassword: '',
    //         message: "",
    //         messageType: ''
    //     })
    // }
    if (nextProps.apiCallStatus.apiCallFor === 'signUp' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === true) {
      this.setState({
        serverValidation: nextProps.apiCallStatus.message
      })
    }
  }
  handleChange = (e) => {
    e.preventDefault();
    var id = e.target.id;
    var val = e.target.value;
    this.setState({
      [id]: val,
      message: "",
      messageType: '',
      messageFor: '',
      serverValidation: {}
    })
  }
  handleSubmit = (event) => {
    var isEmpty = this.isEmptyFields();
    if (isEmpty === true) {
      var check = this.checkEmail();
      if (check === false) {
        if (this.state.password === this.state.confirmPassword) {
          this.props.signUp(this.state);
          this.setState({
            message: "",
            messageType: ""
          })
        } else {
          this.setState({
            message: "Password and confirm password does not match",
            messageType: "danger"
          })
        }
      }
      else {
        this.setState({
          message: "Email is not valid",
          messageType: "danger",
          messageFor: 'email'
        })
      }
    }
    event.preventDefault();
  }
  validateEmail = () => {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.state.email);
  }
  checkEmail = () => {
    var isemailvalid = this.validateEmail();
    if (isemailvalid) {
      return false
    } else {
      return true
    }
  }
  isEmptyFields = () => {
    if (this.state.email === '') {
      this.setState({
        message: "Email is empty",
        messageType: 'danger',
        messageFor: 'email'
      });
      return false;
    }
    else if (this.state.password === '') {
      this.setState({
        message: "Password is empty",
        messageType: 'danger',
        messageFor: 'password',
      });
      return false
    }
    else if (this.state.confirmPassword === '') {
      this.setState({
        message: "Retype password is empty",
        messageType: 'danger',
        messageFor: 'confirmPassword',
      });
      return false
    } else if (this.state.confirmPassword !== this.state.password) {
      this.setState({
        message: 'Your password and confirmation password does not match. Try again.',
        messageType: 'danger',
        messageFor: 'confirmPassword'
      });
      return false;
    }
    else {
      return true
    }
  }
  responseFacebook = response => {
    this.setState({
      socialEmail: response.email
    })
    // this.password.current.focus();
    this.props.socialsignUp(response.accessToken, 'facebook', 1)
    // this.props.facebookSignUp(response.accesToken)
    // setCookie('token', response.accesToken, 30)
    // this.props.history.push("/dashboard");
  }
  responseGoogle = (response) => {
    this.props.socialsignUp(response.tokenId, 'google', 1)
    // if(response.profileObj && response.profileObj.email){
    this.setState({
      socialEmail: response.profileObj.email
    })
    //     this.password.current.focus();
    // }
    // this.props.googleSignUp(response.accesToken)
  }
  render() {
    return (
      <main>
        <div className="signup-sec">
          <div className="container">
            <div className="signup-inner">
              <div className="sign-panel">
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div className="form-section">
                      <h1>Sign up</h1>
                      <form>
                        {/* <div className="form-group">
                              <label htmlFor="username" className="bmd-label-floating">Username</label>
                              <input type="text" className="form-control" id="username"/>
                            </div> */}
                        <div className="form-group">
                          <label htmlFor="exampleInputEmail1" className="bmd-label-floating">Email address</label>
                          <input type="email" value={this.state.email} className="form-control" onChange={(e) => this.handleChange(e)} id="email" />
                          {this.state.message !== "" && this.state.messageType === "danger" &&
                            this.state.messageFor === 'email' ?
                            <div className="alert alert-danger">
                              {this.state.message}
                            </div>
                            : ""}
                          {this.state.serverValidation &&
                            this.state.serverValidation.email &&
                            this.state.serverValidation.email[0] ?
                            <div className={"alert alert-danger"}>
                              {this.state.serverValidation.email[0]}
                            </div>
                            : ""}
                        </div>
                        <div className="form-group">
                          <label htmlFor="Password" className="bmd-label-floating">Password</label>
                          <input type="password"
                            ref={this.password}
                            value={this.state.password} className="form-control" 
                            onChange={(e) => this.handleChange(e)} id="password"></input>
                          <span toggle="#password" className="fa fa-fw fa-eye field-icon toggle-password"></span>

                          {this.state.message !== "" && this.state.messageType === "danger" &&
                            this.state.messageFor === 'password' ?
                            <div className="alert alert-danger">
                              {this.state.message}
                            </div>
                            : ""}
                          {this.state.serverValidation &&
                            this.state.serverValidation.password &&
                            this.state.serverValidation.password[0] ?
                            <div className={"alert alert-danger"}>
                              {this.state.serverValidation.password[0]}
                            </div>
                            : ""}
                        </div>
                        <div className="form-group">
                          <label htmlFor="ConformPassword" className="bmd-label-floating">Retype Password</label>
                          <input type="password" value={this.state.confirmPassword} className="form-control" 
                          onChange={(e) => this.handleChange(e)} id="confirmPassword" />
                          <span toggle="#confirmPassword" className="fa fa-fw fa-eye field-icon toggle-retype-password"></span>
                          {this.state.message !== "" && this.state.messageType === "danger" &&
                            this.state.messageFor === 'confirmPassword' ?
                            <div className="alert alert-danger">
                              {this.state.message}
                            </div>
                            : ""}
                          {this.state.serverValidation &&
                            this.state.serverValidation.password_confirmation &&
                            this.state.serverValidation.password_confirmation[0] ?
                            <div className={"alert alert-danger"}>
                              {this.state.serverValidation.password_confirmation[0]}
                            </div>
                            : ""}
                        </div>

                        {this.state.message !== "" && this.state.messageType === "success" ?
                          <div className="alert alert-danger">
                            {this.state.message}
                          </div>
                          : ""}
                        {this.props.apiCallStatus.apiCallFor === ('signUp' || 'socialsignUp') &&
                          this.props.apiCallStatus.isCompleted === false && this.props.apiCallStatus.isFailed === false ?
                          <div className="text-center">
                            <img className="logo"
                              style={{ width: "83px", display: 'inline' }}
                              src={loader} />
                          </div>
                          : ""}
                        {this.props.apiCallStatus.apiCallFor === ('signUp' || 'socialsignUp')
                          && this.props.apiCallStatus.isCompleted === true &&
                          this.props.apiCallStatus.isFailed === true &&
                          (typeof this.props.apiCallStatus.message === 'string') ?
                          <div className="alert alert-danger" > {
                            this.props.apiCallStatus.message
                          } </div> :
                          ""
                        }
                        <PasswordStrengthMeter password={this.state.password} />
                        <div className="submit-btn mt-40">
                          <button type="submit" onClick={(e) => this.handleSubmit(e)} className="btn btn-primary btn-raised">Sign up</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="signup-panel">
                      <h2>Create your <span>client</span> account get started</h2>
                      <div className="social-btn text-center">
                        <FacebookLogin
                          appId="316673582594279"
                          autoLoad={false}
                          ref={this.facebookLogin}
                          fields="name,email,picture"
                          callback={(data) => this.responseFacebook(data)}
                          render={renderProps => (
                            <a onClick={() => renderProps.onClick()} className="btn btn-md btn-fb ripple">Log in with Facebook</a>
                          )}
                        />
                        <GoogleLogin
                          clientId="503826332481-b8cqukqbdbba63tfk83j9vbv476jj9hb.apps.googleusercontent.com"
                          render={renderProps => (
                            <a onClick={renderProps.onClick} className="btn btn-md btn-Gmail ripple">Log in with Gmail</a>
                          )}
                          buttonText="Login"
                          onSuccess={(response) => this.responseGoogle(response)}
                          // onFailure={(response)=>this.responseGoogle(response)}
                          fields="name,email,picture"
                          callback={(data) => this.responseGoogle(data)}
                        />

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="signup-now text-center">
              <p>Already have HealMaker Account? <Link to="/login">Login</Link></p>
            </div>
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state, ownProps) => ({
  apiCallStatus: state.apiCallStatus
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  signUp: (user) => dispatch(signUp(user)),
  socialsignUp: (token, platform, role) => dispatch(socialsignUp(token, platform, role))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUp)