import React from 'react';
import { Link } from "react-router-dom";

export default class ServicesList extends React.Component {
  render() {
    return (
        <div className="table-sec">
        <h1>Services at “{this.props.name} {this.props.data.name}”</h1>
        <div className="card-panel">
          <div className="table-responsive">
            <table className="table table-striped services-table">
              <thead>
                <tr>
                  <th width="30%">Services</th>
                  <th width="50%">status</th>
                  <th width="20%">active on / off</th>
                </tr>
              </thead>
              <tbody>
                {this.props.data.services.map((item,index)=>{
                      return <tr key={index}>
                      <td>{item.service_name}</td>
                      <td>
                        <a href="#" className="btn-notcomplete">{item.service_status_by_provider === 1 ? 'Complete':'Not complete'}</a>
                        <a href="#" className="btn btn-md btn-description ripple">Needs Description</a>
                      </td>
                      <td>
                        <div className="toggleWrapper">
                          <input type="checkbox" id="dn" className="dn" />
                          <label for="dn" className="toggle"><span className="toggle__handler"></span></label>
                        </div>
                      </td>
                      </tr>
                })}
                
              </tbody>
            </table>
          </div>
          <ul className="add-button">
            <li><Link to="/add-service">+ Add additional services</Link></li>
            <li><Link to="/add-service">+ Add retreat or training service</Link></li>
          </ul>
        </div>
      </div>
      );
  }
}

