import { combineReducers } from "redux";
import apiCallStatus from "./../Features/ApiCallStatus/Reducer/apicallStatusReducer";
import loginReducer from "./../Features/Login&SignUp/Reducers/loginReducer";
import  profileReducer  from "./../Features/Healer/Profile/Reducer/profileReducer";
import  commonReducer  from "./../Features/Common/Reducer/commonReducer";
import healerAvaliablityReducer from "../Features/Healer/Services/Reducer/healerAvaliablityReducer";
import servicesReducer from ".././Features/Healer/Services/Reducer/servicesReducer";
export default combineReducers({
    apiCallStatus,
    loginReducer,
    profileReducer,
    commonReducer,
    servicesReducer,
    healerAvaliablityReducer
})