import React from 'react';
import { connect } from 'react-redux';
import ServicesList from './serviceList';
import {Link} from 'react-router-dom';
import { getCookie } from "./../../../Login&SignUp//ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { getUserServicesData } from "./../ApiCalls/apicalls";
import externalFileLoad from  '../../../externalfileLoad';
class ServicesList1 extends React.Component {
  componentDidMount() {
    externalFileLoad();
    let token = getCookie('token');
    let email = getCookie('user_email');
    let userId = getCookie('user_id');
    let userRole = getCookie('user_Role');
    if (token === '') {
      this.props.history.push('/login')
    }else if(token !== '' && userRole !== '1'){
      this.props.history.push('/client/dashboard')
    } else{
      this.props.logInAction(token,email,userId,userRole)
    }
    this.props.getUserServicesData(token,userId);
  }
 
  redirect=(path)=>{
    this.props.history.push(path)
  }
  render() {
    return (
     <main>
        <div className="sub-banner">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-5">
                <div className="profile-holder">
                  <div className="media">
                    <img className="mr-3" src={require("../../../../img/profile-1.jpg")}  alt="Generic placeholder image" />
                    <div className="media-body">
                      <h5 className="mt-0">Services at</h5>
                      <p>{this.props.commonReducer.profile && 
                this.props.commonReducer.profile.first_name &&  
                this.props.commonReducer.profile.first_name !== '' ? 
                this.props.commonReducer.profile.first_name:
                'Dashboard'}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-7">
                <ul className="menu-tabs">
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-map-pin" aria-hidden="true"></i>
                      </div>
                      <div className="banner-content">
                        <small>Locations</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#" className="active">
                      <div className="banner-icon">
                        <i className="icon-leaf"></i>
                      </div>
                      <div className="banner-content">
                        <small>Services</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-calendar"></i>
                      </div>
                      <div className="banner-content">
                        <small>Calendar</small>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="banner-icon">
                        <i className="icon-envolope"></i>
                      </div>
                      <div className="banner-content">
                        <small>Inbox</small>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="services-sec">
          <div className="container">
            {this.props.profileReducer.services ? 
            this.props.profileReducer.services.map((item,index)=>{
                return   <ServicesList name={this.props.commonReducer.profile && 
                  this.props.commonReducer.profile.first_name &&  
                  this.props.commonReducer.profile.first_name !== '' ? 
                  this.props.commonReducer.profile.first_name :
                  ''} 
                  data={item} />  
            }):''}
         
               
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="table-sec">
                  <div className="uploadcard-panel text-center">
                    <p><Link to="/location-manager">Do you have another location where you provide servies from? (e.g. your home, another business location)</Link></p>
                    <div className="plus-image-holder mb-100" onClick={()=>this.redirect('/locationManager')}>
                      <img src={require("../../../../img/plus-icon.jpg")} alt="image" />
                    </div>
                    <Link to="/location-manager">Setup home visit services here</Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="table-sec">
                  <div className="uploadcard-panel text-center">
                    <div className="plus-image-holder mtb-50" onClick={()=>this.redirect('/locationManager')}>
                      <img src={require("../../../../img/plus-icon.jpg")} alt="image" />
                    </div>
                    <p>Do you provide services at the client's home?</p>
                    <Link to="/location-manager">Setup home visit services here</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer,
  profileReducer: state.profileReducer
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserServicesData :(token, userId)=>dispatch(getUserServicesData(token, userId)),
  logInAction:(token, email, userId, userRole)=>dispatch(logInAction(token, email, userId, userRole))
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
  )(ServicesList1)