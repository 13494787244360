import React from "react";
import { connect } from "react-redux";
import { getCookie, setCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { Link } from "react-router-dom";
import externalFileLoad from "../../../externalfileLoad";
import { Constant } from "../../../../Constants/constant";
import "../profile.css";
import InputMask from 'react-input-mask';
import { Spinner } from "../../../Common/Components/loader";
class AccounInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      accountTypes: ["individual","company"],
      isPaymentMethod: 0,
      userRole : "",
      isAccountAttached : false,
      accountAttachedError : false,
      address : "",
      currency : "",
      routing_number : "",
      account_holder_type: "individual",
      account_holder_name:"",
      account_number:"",
      dob_day :  '',
      dob_month : '',
      dob_year : '',
      bankError: "",
      errorState: '',
      ssn : "",
      date: "",
      currencies : [],
      isLoader:false
    };
  }
  componentDidMount() {
    externalFileLoad();
    this.getCurrencies();
    let token = getCookie("token");
    let email = getCookie("user_email");
    let userId = getCookie("user_id");
    let userRole = getCookie("user_Role");
    let profile = getCookie("profile");
    profile = JSON.parse(profile);
    
    if (profile.payment_method == "stripe-connect"){
      this.setState({
        isPaymentMethod : 1
      })
    }
    else if (profile.payment_method == "bank"){
      this.setState({
        isPaymentMethod : 0
      })
    }
    this.setState({
      userRole : userRole
    })
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (token === "") {
      this.props.history.push("/login");
    } else if (token !== "" && userRole !== "2") {
      this.props.history.push("/client/dashboard");
    } else {
      this.props.logInAction(token, email, userId, userRole);
      if (hasUnacceptedAgreements === '1') {
        this.props.history.push('/verify-service-document')
      }
    }
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let code = params.get('code');
    if(code){
      this.connectStripeAccount(code)
    }
    
  }
  getCurrencies = () => {
    let self = this;
    fetch(Constant.apiURl + "currencies",{
      method: "POST"
    }).then(function(res){ return res.json()})
    .then(function(res) {
      if(res.success == "1"){
        
        self.setState({
          currencies : res.data,
          currency : res.data[0]
        })
      }else{

      }
    }).catch(err => {

    })
  }
  connectStripeAccount = (code) => {
    let self = this;
    let token = getCookie("token");
    let formData = new FormData();
    formData.append("auth_token",token);
    formData.append("stripe_token",code);
    fetch(Constant.apiURl + "stripe/attach",{
      method: "POST",
      body: formData

    }).then(function(res){ return res.json()})
    .then(function(res) {
      if(res.success == "1"){
          self.getProfile();
          self.props.history.push("/healer-account-info");
          self.setState({
            isAccountAttached : true
          })
      }else{
        self.setState({
          accountAttachedError : true
        })
      }
      if(self.state.isAccountAttached || self.state.accountAttachedError){
          setTimeout(() => {
            
            self.setState({
              isAccountAttached : false,
              accountAttachedError : false
            })
        }, 3000)
      }
    }).catch(err => {
      self.setState({
        accountAttachedError : true
      })
    })
    if(self.state.isAccountAttached || self.state.accountAttachedError){
        setTimeout(() => {
        
          self.setState({
            isAccountAttached : false,
            accountAttachedError : false
          })
      }, 3000)
    }
  }

  getProfile = () => {
    let self = this;
    let token = getCookie("token");
    let user_id = getCookie("user_id");
    let formData = new FormData();
    formData.append("auth_token",token);
    formData.append("user_id",user_id);
    fetch(Constant.apiURl + "get-account",{
      method: "POST",
      body: formData

    }).then(function(res){ return res.json()})
    .then(function(res) {
      
      if(res.success == "1"){
        
        let response = JSON.parse(res.data)
        let profile = response.profile_data;
        profile = JSON.stringify(profile)
           setCookie("profile",profile, 30);
      }else{

      }
    }).catch(err => {

    })
  }
  selectPayment = (e,value) => {
    e.preventDefault();
    let isPaymentMethod = this.state.isPaymentMethod;
    if(value == 0){
      this.setState({
        isPaymentMethod : 0
      },() => {
      })
    }
    else if(value == "1"){
      this.setState({
        isPaymentMethod : 1
      },() => {
        if(this.state.isPaymentMethod == 1){
          window.location.href = "https://connect.stripe.com/oauth/authorize?client_id=ca_FSYRjukoP95X3eFw5udKp6VoP5HKsLgl&response_type=code&scope=read_write&redirect_uri=http://localhost:3000/healer-account-info";
        }
      })
    }

  }
  savePayout = () => {
    if(this.state.isPaymentMethod == 0){
      this.connectBankAccount();
    }
    else{
      if(this.state.userRole == 2){
        this.props.history.push("/healer-services-list")
      }
      else if(this.state.userRole == 1){
        this.props.history.push("/client/dashboard")
      }
    }
  }
  validation = () => {
     if(this.state.account_holder_name == ""){
      this.setState({
        bankError : "please enter account name",
        errorState : "account_holder_name"
      })
    }
    else if(this.state.account_holder_type == ""){
      this.setState({
        bankError : "please enter account type",
        errorState : "account_holder_type"
      })
    }
    else if(this.state.routing_number == ""){
      this.setState({
        bankError : "please enter routing number",
        errorState : "routing_number"
      })
    }
    else if(this.state.account_number == ""){
      this.setState({
        bankError : "please enter account number",
        errorState : "account_number"
      })
    }
    else if(this.state.currency == ""){
      this.setState({
        bankError : "please enter currency",
        errorState : "currency"
      })
    }
    else if(this.state.dob_day == ""){
      this.setState({
        bankError : "please enter dob",
        errorState : "dob_day"
      })
    }
    else if(this.state.dob_month == ""){
      this.setState({
        bankError : "please enter dob",
        errorState : "dob_day"
      })
    }
    else if(this.state.dob_year == ""){
      this.setState({
        bankError : "please enter dob",
        errorState : "dob_day"
      })
    }
    else if(this.state.ssn == ""){
      this.setState({
        bankError : "please enter social security number",
        errorState : "ssn"
      })
    }
    else if(this.state.ssn.endsWith('_')){
      this.setState({
        bankError : "please enter valid SSN",
        errorState : "ssn"
      })
    }
    else if(this.state.address == ""){
      this.setState({
        bankError : "please enter address",
        errorState : "address"
      })
    }
    else{
      this.setState({
        bankError : "",
        errorState : ""
      })
      return true;
    }

  }
  connectBankAccount = () => {
    let dob = new Date(this.state.date);
    let valid;
    if(dob != "Invalid Date"){
     let day = dob.getDate();
     let month = dob.getMonth();
     let year = dob.getFullYear();
     this.setState({
       dob_day :  day,
       dob_month:month,
       dob_year: year
     }, () => {
       valid = this.validation();
       if(valid){
        debugger
      let self = this;
      this.setState({
        isLoader : true
      })
      let token = getCookie("token");
      let formData = new FormData();
      formData.append("auth_token",token);
      formData.append("account_holder_name",this.state.account_holder_name);
      formData.append("currency",this.state.currency);
      formData.append("account_holder_type",this.state.account_holder_type);
      formData.append("routing_number",this.state.routing_number);
      formData.append("account_number",this.state.account_number);
      formData.append("address",this.state.address);
      formData.append("dob_day",this.state.dob_day);
      formData.append("dob_month",this.state.dob_month);
      formData.append("dob_year",this.state.dob_year);
      formData.append("ssn",this.state.ssn);
      fetch(Constant.apiURl + "stripe/attach",{
        method: "POST",
        body: formData
  
      }).then(function(res){ return res.json()})
      .then(function(res) {
        self.setState({
          isLoader : false
        })
        if(res.success == "1"){
            self.getProfile();
            self.setState({
              isAccountAttached : true,
              isPaymentMethod : 0
            }, () => {
              self.props.history.push("/healer-account-info");
            })
           
        }else{
          self.setState({
            accountAttachedError : true
          })
        }
        if(self.state.isAccountAttached || self.state.accountAttachedError){
            setTimeout(() => {
              
              self.setState({
                isAccountAttached : false,
                accountAttachedError : false
              })
          }, 3000)
        }
      }).catch(err => {
        self.setState({
          accountAttachedError : true,
          isLoader:false
        })
      })
      if(self.state.isAccountAttached || self.state.accountAttachedError){
          setTimeout(() => {
          
            self.setState({
              isAccountAttached : false,
              accountAttachedError : false
            })
        }, 3000)
      }
    }
     })
    }else{
     this.setState({
       bankError : "please enter valid date",
       errorState : "dob_day"
     })
    }


  }
  getDob = (e) => {
    this.setState({
      date : e.target.value
    })
    
  }
  render() {
    return (
      <main>
        {this.state.isLoader ? <Spinner/> : ""}
        <div className="card-sec">
          <div className="container">
            <div className="add-profile-btn">
              <ul className="pull-right">
                <li>
                  <Link to="/healer-profile-container" className="btn btn-md">
                    Profile
                  </Link>
                </li>
                <li className="active">
                  <Link to="/healer-account-info" className="btn btn-md">
                    Payout
                  </Link>
                </li>
              </ul>
              <div className="clearfix" />
            </div>
            <div className="Card-panel">
              <form>
                {this.state.isPaymentMethod == 0 ? <div>
                <div className="row">
                  <div className="col-xs-12 col-sm-4">
                  <div className="custom-form-group">
                      <label className="font-pink">Account Name:</label>
                        <input
                          name="name"
                          value={this.state.account_holder_name}
                          className="form-control custom-form"
                          type="text"
                          placeholder="Account Holder Name"
                          onChange={(e) => this.setState({account_holder_name : e.target.value})}
                        />
                      </div>
                      {this.state.bankError != "" && this.state.errorState == "account_holder_name" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                  </div>
                  <div className="col-xs-12 col-sm-4">
                    <div className="custom-form-group">
                      <label className="font-pink">Account Type:</label>
                      <select  className="custom-select " onChange={(e) => this.setState({account_holder_type : e.target.value})}>
                      {this.state.accountTypes.map((type,idx)=>{
                        return <option value={type} selected={type == this.state.account_holder_type}>{type}</option>
                      })}
                      </select>
                        {/* <input
                          name="name"
                          value={this.state.account_holder_type}
                          className="form-control custom-form"
                          type="text"
                          placeholder="Account Holder Type"
                          onChange={(e) => this.setState({account_holder_type : e.target.value})}
                        /> */}
                      </div>
                      {this.state.bankError != "" && this.state.errorState == "account_holder_type" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                    </div>
                  
                  <div className="col-xs-12  col-sm-3 offset-sm-1">
                    <div className="why-need pull-right">
                      <a  className="tolltip-custom" data-toggle="tooltip" data-placement="top" title="Hooray!">
                        <i className="fa fa-question-circle" /> Why is this
                        needed
                      </a>
                      <div className="tolltip-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis justo elit, maximus in tristique ut, consequat ac nunc. </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-10 col-sm-4">
                    <div className="custom-form-group">
                    <label className="font-pink">Routing Number:</label>
                      <input
                        name="name"
                        value={this.state.routing_number}
                        className="form-control custom-form"
                        type="text"
                        placeholder="Routing Number"
                        onChange={(e) => this.setState({routing_number : e.target.value})}
                      />
                    </div>
                    {this.state.bankError != "" && this.state.errorState == "routing_number" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                  </div>
                  <div className="col-xs-10 col-sm-4">
                    <div className="custom-form-group">
                    <label className="font-pink">Account Number:</label>
                      <input
                        name="name"
                        value={this.state.account_number}
                        className="form-control custom-form"
                        type="text"
                        placeholder="Account Number"
                        onChange={(e) => this.setState({account_number : e.target.value})}
                      />
                    </div>
                    {this.state.bankError != "" && this.state.errorState == "account_number" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                  </div>
                </div>
                <div className="row">
                <div className="col-xs-12 col-sm-4">
                    <div className="custom-form-group">
                      <label className="font-pink">Currency:</label>
                        <select  className="custom-select " onChange={(e) => this.setState({currency : e.target.value})}>
                          {this.state.currencies && this.state.currencies.length > 0 ?
                          this.state.currencies.map( (cur,idx) => {
                           return <option value={cur} selected={cur == this.state.currency}>{cur}</option>
                          } ) : ""}
                        </select>
                      </div>
                      {this.state.bankError != "" && this.state.errorState == "currency" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                    </div>
                  <div className="col-xs-12 col-sm-4">
                    <div className="custom-form-group">
                        <label className="font-pink">Dob</label>
                        <InputMask value={this.state.date} onChange={(e) => this.getDob(e)} className=" form-control custom-form added-spaces" mask="99/99/9999" placeholder="MM/DD/YY" />
                        {/* <div
                          className="right-inner-addon date datepicker"
                          data-date-format="yyyy-mm-dd"
                        >
                          <input
                            name="name"
                            value={this.state.date}
                            className=" form-control custom-form added-spaces"
                            type="text"
                            id="datepicker"
                            autoComplete="none"
                            placeholder="MM/DD/YY"
                          />
                          <img
                            src={require("../../../../img/cal-icon.png")}
                            alt="icon"
                          />
                        </div> */}
                      </div>
                      {this.state.bankError != "" && this.state.errorState == "dob_day" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                  </div>
                   
                </div>
                <div className="row">
                <div className="col-xs-10 col-sm-4">
                    <div className="custom-form-group">
                    <label className="font-pink">Social Security Number</label>
                    <InputMask mask="999-99-9999" placeholder="000-00-0000" className="form-control custom-form" value={this.state.ssn} onChange={(e) => this.setState({ssn : e.target.value})}  />
                      {/* <input
                        type="text"
                        value={this.state.ssn}
                        className="form-control custom-form"
                        placeholder="Social Security Number"
                        required=""
                        onChange={(e) => this.setState({ssn : e.target.value})}
                      /> */}
                    </div>
                    {this.state.bankError != "" && this.state.errorState == "ssn" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                  </div>
                  <div className="col-xs-10 col-sm-4">
                    <div className="custom-form-group">
                    <label className="font-pink">Address</label>
                      <input
                        type="text"
                        value={this.state.address}
                        className="form-control custom-form"
                        placeholder="Address"
                        required=""
                        onChange={(e) => this.setState({address : e.target.value})}
                      />
                    </div>
                    {this.state.bankError != "" && this.state.errorState == "address" ? 
                      <div className="alert alert-danger">
                        {this.state.bankError}  
                      </div>  
                      :""
                    }
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-10 col-sm-12">
                    <div className="custom-form-group">
                      <label className="label-font">
                        How would you like to be paid?
                      </label>
                    </div>
                  </div>
                </div>
                </div>
                : ""
    }
                <div className="row">
                  <div className="col-xs-12 col-sm-3">
                    <div className="payment-btn">
                      <ul>
                        {this.state.userRole == 2 ? <div> <li>
                          <a >
                            {/* <i className="fa fa-angle-left" />{" "} */}
                            <i onClick={(e) => this.selectPayment(e,1)} className={`fa fa-cc-stripe ${this.state.isPaymentMethod == 1 ? 'active':''}`}  />
                          </a>
                        </li>
                        <li>
                          <a onClick={(e) => this.selectPayment(e,1)} 
                            className={` txt-setup ${this.state.isPaymentMethod == 1 ? 'active':''} `}>
                            Setup Now
                          </a>
                        </li> </div>:  <div><li>
                          <a onClick={(e) => this.selectPayment(e,1)}>
                            {/* <i className="fa fa-angle-left" />{" "} */}
                            <i onClick={(e) => this.selectPayment(e,1)} className={`fa fa-cc-stripe ${this.state.isPaymentMethod == 1 ? 'active' : ''} `} />
                          </a>
                        </li>
                        <li>
                          <a onClick={(e) => this.selectPayment(e,1)} className={`txt-setup ${this.state.isPaymentMethod == 1 ? 'active':''}`}>
                            Setup Now
                          </a>
                        </li>
                        </div>}
                       
                      </ul>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                  {this.state.userRole == 2 ? 
                    <div className="payment-btn">
                      <ul>
                        <li>
                          <a onClick={(e) => this.selectPayment(e,0)}>
                            {/* <i className="fa fa-angle-left" />{" "} */}
                            <i onClick={(e) => this.selectPayment(e,0)} className={`fa fa-university ${this.state.isPaymentMethod == 0 ? 'active':''}`} />
                          </a>
                        </li>
                        <li>
                          <a  onClick={(e) => this.selectPayment(e,0)} className={`txt-setup ${this.state.isPaymentMethod == 0 ? 'active':''}`} >
                            Setup Now
                          </a>
                        </li>
                      </ul>
                    </div>
                    : ""
                  }
                  </div>
                  {this.state.isAccountAttached ?  <div className="col-xs-12 col-sm-12"><div className="alert alert-success">
              you have successfully attach your account
              </div>
              </div>
               : ""}
               {this.state.accountAttachedError ?  <div className="col-xs-12 col-sm-12"><div className="alert alert-danger">
             error in attaching account, please try again later
              </div>
              </div>
               : ""}
                </div>
              </form>
            </div>
           
            <div className="text-btn pb-50">
              <button className="btn btn-md pull-right save-btn ripple" onClick={() => this.savePayout()}>
                Save
              </button>
            </div>
          </div>
        </div>
       
      </main >
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logInAction: (token, email, userId, userRole) =>
    dispatch(logInAction(token, email, userId, userRole))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccounInfo);
