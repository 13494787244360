export const GetCountries = 'GET_COUNTRIES';
export const GetStateByCountry = 'GET_STATE_BY_COUNTRY';
export const GetCityByState = 'GET_CITY_BY_STATE';
export const GetProfileData = 'GET_PROFILE_DATA';
export const GetUserServices = 'GET_USER_SERVICES';


export function GetProfileDataAction(data) {
    return {
        type: GetProfileData,
        payload: {
            data
        }
    }
}

export function GetCountriesAction(countries) {
    return {
        type: GetCountries,
        payload: {
            countries
        }
    }
}

export function GetStateByCountryAction(states) {
    return {
        type: GetStateByCountry,
        payload: {
            states
        }
    }
}

export function GetCityByStateAction(cities) {
    return {
        type: GetCityByState,
        payload: {
            cities
        }
    }
}

export function GetUserServicesAction(list) {
    return {
        type: GetUserServices,
        payload: {
            list
        }
    }
}