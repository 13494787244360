import * as apiCallActions from "../../../ApiCallStatus/Actions/action";
import * as Actions from "./../Actions/actions";
import { Constant } from "./../../../../Constants/constant";

export function getProfileData(token) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getProfileData"
      })
    );
    fetch(Constant.apiURl + "login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.login === true) {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getProfileData",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getProfileData",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getProfileData",
            message: error.message
          })
        );
      });
  };
}

export function getAllCountries(token) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getAllCountries"
      })
    );
    fetch(Constant.apiURl + "get-countries", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetCountriesAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getAllCountries",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getAllCountries",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getAllCountries",
            message: error.message
          })
        );
      });
  };
}

export function getStateByCountry(countryId) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getStateByCountry"
      })
    );
    fetch(Constant.apiURl + "get-states-by-country", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        country_id: countryId
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetStateByCountryAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getStateByCountry",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getStateByCountry",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getStateByCountry",
            message: error.message
          })
        );
      });
  };
}

export function getCitiesByState(stateId) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getCitiesByState"
      })
    );
    fetch(Constant.apiURl + "get-cities-by-state", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        state_id: stateId
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetCityByStateAction(myJson.data));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getCitiesByState",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getCitiesByState",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getCitiesByState",
            message: error.message
          })
        );
      });
  };
}

export function updateProfile(data) {
  var formData = new FormData();
  formData.append("user_media", data.user_media);
  formData.append("auth_token", data.auth_token);
  formData.append("owner_id", data.owner_id);
  formData.append("first_name", data.first_name);
  formData.append("last_name", data.last_name);
  formData.append("company_name", data.company_name);
  formData.append("mobile", data.phone_code_id + data.mobile);
  formData.append("mobile_number", data.mobile);
  formData.append("address", data.address);
  formData.append("state", data.state);
  formData.append("country", data.country);
  formData.append("city", data.city);
  formData.append("zipcode", data.zipcode);
  formData.append("gender", data.gender);
  formData.append("twitter_url", data.twitter_url);
  formData.append("instagram_url", data.instagram_url);
  formData.append("facebook_url", data.facebook_url);
  formData.append("web_url", data.web_url);
  formData.append("display_company_name", data.display_company_name);
  formData.append("phone_code_id", data.phone_code_id);
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "updateProfile"
      })
    );
    fetch(Constant.apiURl + "save-profile", {
      method: "POST",
      body: formData
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "updateProfile",
              message: {
                hasUnacceptedAgreements: myJson.hasUnacceptedAgreements,
                hasLocation: myJson.hasLocation
              }
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "updateProfile",
              message: myJson.validation
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "updateProfile",
            message: error.message
          })
        );
      });
  };
}

export function getUserServicesData(token, userId, page) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getUserServicesData"
      })
    );
    dispatch(Actions.GetUserServicesAction([]));
    fetch(Constant.apiURl + "get-service-by-healer", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_id: userId,
        page: page
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          let data = myJson.data.data;
          let rawData = myJson.data;
          for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if (element.images && element.images.length > 0) {
              for (let i = 0; i < element.images.length; i++) {
                const element2 = element.images;
                let imgUrl =
                  "https://healmaker.wantechsolutions.com/" +
                  element2[i].path +
                  "/" +
                  element2[i].file_name;
                element2[i].imgUrl = imgUrl;
              }
            }
            data[index] = element;
          }
          
          rawData.data = data;
          dispatch(Actions.GetUserServicesAction(rawData));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getUserServicesData",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getUserServicesData",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getUserServicesData",
            message: error.message
          })
        );
      });
  };
}

export function getLocationDetail(token, userId, locationId) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getLocationDetail"
      })
    );
    fetch(Constant.apiURl + "get-location-by-id", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        location_id: locationId
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === "1") {
          dispatch(Actions.GetLocationDetailAction(myJson.location));
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getLocationDetail",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getLocationDetail",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getLocationDetail",
            message: error.message
          })
        );
      });
  };
}

export function verifyOTP(userId, opt) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "verifyOTP"
      })
    );
    fetch(Constant.apiURl + "verify-phone", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user_id: userId,
        otp: opt
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === '1') {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "verifyOTP",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "verifyOTP",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "verifyOTP",
            message: error.message
          })
        );
      });
  };
}

export function sendOPT(token, cc, mobile) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "sendOPT"
      })
    );
    let num = cc + mobile;
    fetch(Constant.apiURl + "verify-new-phone", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        auth_token: token,
        mobile: num
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === '1') {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "sendOPT",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "sendOPT",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "sendOPT",
            message: error.message
          })
        );
      });
  };
}
export function getBookedUserProfile(token, id) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "getBookedUserProfile"
      })
    );
    fetch(Constant.apiURl + "get-account", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        auth_token: token,
        user_id: id
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === '1') {
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "getBookedUserProfile",
              response: myJson.data
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "getBookedUserProfile",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "getBookedUserProfile",
            message: error.message
          })
        );
      });
  };
}

export function updateLocationStatus(token, locationId, status) {
  return dispatch => {
    dispatch(
      apiCallActions.ApiRequestedAction({
        apiCallFor: "updateLocationStatus",
        message: locationId
      })
    );
    fetch(Constant.apiURl + "update-location-status", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        auth_token: token,
        id: locationId,
        value: status
      })
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        if (myJson.success === '1') {
          dispatch(Actions.UpdateLocationStatusAction(locationId, status))
          dispatch(
            apiCallActions.ApiFulfilledAction({
              apiCallFor: "updateLocationStatus",
              message: ""
            })
          );
        } else {
          dispatch(
            apiCallActions.ApiRejectedAction({
              apiCallFor: "updateLocationStatus",
              message: myJson.message
            })
          );
        }
      })
      .catch(error => {
        dispatch(
          apiCallActions.ApiRejectedAction({
            apiCallFor: "updateLocationStatus",
            message: error.message
          })
        );
      });
  };
}