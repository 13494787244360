import React from "react";
import { connect } from "react-redux";
import ServicesList from "./serviceList";
import HealerBanner from "../../../Common/Components/healerBanner";
import ClientBanner from "../../../Common/Components/clientBanner";
import { Link } from "react-router-dom";
import { getCookie } from "./../../../Login&SignUp//ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { getUserServicesData } from "./../ApiCalls/apicalls";
import externalFileLoad from "../../../externalfileLoad";
import { Spinner } from "../../../Common/Components/loader";
import InfiniteScroll from 'react-infinite-scroll-component';
import loader from '../../../../img/Spinner-1s-200px.gif';
class ServicesList1 extends React.Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      locationServices: [],
      isLoader: false,
      isHasMore: true,
      userRole: getCookie('user_Role')
    }
  }
  componentDidMount() {
    externalFileLoad();
    window.scroll(0, 0)
    let token = getCookie("token");
    let email = getCookie("user_email");
    let userId = getCookie("user_id");
    let userRole = getCookie("user_Role");
    let hasUnacceptedAgreements = getCookie("hasUnacceptedAgreements");
    if (token === "") {
      this.props.history.push("/login");
    } else if (token !== "" && userRole !== "2") {
      this.props.history.push("/client/dashboard");
    } else {
      this.props.logInAction(token, email, userId, userRole);
      if (hasUnacceptedAgreements === "1") {
        this.props.history.push("/verify-service-document");
      }
    }
    this.props.getUserServicesData(token, userId, this.state.page);
  }

  redirect = path => {
    this.props.history.push(path);
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.page == 1) {
      window.scrollTo(0, 0)
    }

    if (nextProps.profileReducer.services.length > 0 && this.state.isMapLoaded === false) {
      let th = this;
      th.setState({
        isMapLoaded: true
      })
      setTimeout(function () {
        th.markLocationOnMap();
      }, 2000)
    }
    if (nextProps.apiCallStatus.apiCallFor === 'getUserServicesData' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {

      let services = [...this.state.locationServices];
      if (services.length > 0) {
        let arr = [];
        if (nextProps.profileReducer.services.current_page != nextProps.profileReducer.services.last_page) {
          arr = services.concat(nextProps.profileReducer.services.data);
          services = arr;
        } else {
          arr = services.concat(nextProps.profileReducer.services.data);
          services = arr;
          this.setState({
            isLoader: false,
            isHasMore: false
          })
        }
      } else {
        services = nextProps.profileReducer.services.data;
      }

      this.setState({
        locationServices: services
      }, () => {
        console.log("locationServices", this.state.locationServices)
        console.log("nextProps.profileReducer.services", nextProps.profileReducer.services)
      })
    }
  }
  loadMoreData = () => {

    if (this.state.locationServices && this.state.locationServices.length > 0) {

      let p = this.state.page;
      let token = getCookie("token");
      let userId = getCookie("user_id");
      p += 1;
      this.setState({
        page: p,
        isLoader: true
      }, () => {
        this.props.getUserServicesData(token, userId, this.state.page);
      })
    }

  }
  render() {
    return (
      <main>
        {this.state.userRole === '1' ?
          <ClientBanner commonReducer={this.props.commonReducer} subHeader="Services provided by" />
          :
          <HealerBanner commonReducer={this.props.commonReducer} subHeader="Services provided by" />
        }
        <div className="services-sec">
          <div className="container">
            {this.props.apiCallStatus.isStarted.indexOf('getUserServicesData') !== -1 && this.state.page == 1 ?
              <Spinner />
              : ""}
            <InfiniteScroll
              dataLength={this.state.locationServices.length} //This is important field to render the next data
              next={this.loadMoreData}
              hasMore={this.state.isHasMore}
              loader={<div className="text-center" >
                <img className="logo" style={{ width: "83px", display: 'inline' }} src={loader} /> </div>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>Yay! You have seen it all</b>
                </p>
              }>
              {this.state.locationServices
                ? this.state.locationServices.map((item, index) => {
                  return (
                    <ServicesList
                      name={
                        this.props.commonReducer.profile &&
                          this.props.commonReducer.profile.first_name &&
                          this.props.commonReducer.profile.first_name !== ""
                          ? this.props.commonReducer.profile.first_name
                          : ""
                      }
                      data={item}
                      key={index}
                    />
                  );
                })
                : ""}

            </InfiniteScroll>


            <div className="row">
              <div className="col-xs-12 col-sm-6 PLR-15">
                <div className="table-sec">
                  <div className="uploadcard-panel text-center">
                    <p>
                      <Link to="/location-manager">
                        Do you have another location where you provide services
                        from? (e.g. your home, another business location)
                      </Link>
                    </p>
                    <div
                      className="plus-image-holder mb-100"
                      onClick={() => this.redirect("/location-manager")}
                    >
                      <img
                        src={require("../../../../img/plus-icon.jpg")}
                        alt="image"
                      />
                    </div>
                    {/* <Link to="/location-manager">
                      Setup home visit services here
                    </Link> */}
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 PLR-15">
                <div className="table-sec">
                  <div className="uploadcard-panel text-center">
                    <div
                      className="plus-image-holder mtb-50"
                      onClick={() => this.redirect("/location-manager")}
                    >
                      <img
                        src={require("../../../../img/plus-icon.jpg")}
                        alt="image"
                      />
                    </div>
                    <p>Do you provide services at the client's home?</p>
                    {/* <Link to="/location-manager">
                      Setup home visit services here
                    </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer,
  profileReducer: state.profileReducer
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserServicesData: (token, userId, page) =>
    dispatch(getUserServicesData(token, userId, page)),
  logInAction: (token, email, userId, userRole) =>
    dispatch(logInAction(token, email, userId, userRole))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServicesList1);
