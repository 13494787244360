import React from "react";
import { connect } from "react-redux";
import { getCookie } from "./../../../Login&SignUp/ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import externalFileLoad from "../../../externalfileLoad";
import { Constant } from "../../../../Constants/constant";
class CategoriesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      serviceType: "",
      services: [],
      selected: [],
      selectServiceType: "",
      preselected: [],
      predeselect: [],
      deselect: [],
      errorMessage: "",
      errorType: ""
    };
  }
  componentDidMount() {
    externalFileLoad();
    let token = getCookie("token");
    let email = getCookie("user_email");
    let userId = getCookie("user_id");
    let userRole = getCookie("user_Role");
    if (token === "") {
      this.props.history.push("/login");
    } else if (token !== "" && userRole !== "2") {
      this.props.history.push("/client/dashboard");
    } else {
      this.props.logInAction(token, email, userId, userRole);
    }
    this.getCategoryList();
    // this.getServicesList()
  }
  getCategoryList = () => {
    fetch(Constant.apiURl + "get-service-types", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    })
      .then(response => response.json())
      .then(myJson => {
        if (myJson.success === "1") {
          this.setState({
            categories: myJson.data,
            selected: [],
            selectServiceType: "",
            preselected: [],
            predeselect: [],
            deselect: []
          });
        }
      });
  };
  getServicesList = id => {
    fetch(Constant.apiURl + "get-services-by-category", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        service_type_id: id
      })
    })
      .then(response => response.json())
      .then(myJson => {
        if (myJson.success === "1") {
          this.setState({ services: myJson.data, serviceType: id });
        }
      });
  };
  preselectService = item => {
    let arr = Object.assign(this.state.preselected);
    let id = 1;
    if (item.is_homebased === 1) {
      id = 2;
    } else if (item.is_classtype === 1) {
      id = 3;
    }
    let index = arr.findIndex(x => x.id === item.id);
    if (index > -1) {
      id = "";
      arr.splice(index, 1);
    } else {
      arr.push(item);
    }
    this.setState({
      preselected: arr,
      selectServiceType: id
    });
  };
  selectService = e => {
    e.preventDefault();
    let arr = Object.assign(this.state.selected);
    for (let index = 0; index < this.state.preselected.length; index++) {
      const i = arr.findIndex(x => x.id === this.state.preselected[index].id);
      if (i === -1) {
        arr.push(this.state.preselected[index]);
      }
    }
    this.setState({
      preselected: [],
      predeselect: [],
      selected: arr
    });
  };
  predeselectService = item => {
    let arr = Object.assign(this.state.predeselect);
    arr.push(item);
    this.setState({
      predeselect: arr
    });
  };
  deselectService = e => {
    e.preventDefault();
    let arr = [];
    for (let index = 0; index < this.state.selected.length; index++) {
      const i = this.state.predeselect.findIndex(
        x => x.id === this.state.selected[index].id
      );
      if (i === -1) {
        arr.push(this.state.selected[index]);
      }
    }
    this.setState({
      preselected: [],
      deselect: [],
      selected: arr
    });
  };
  save = e => {
    e.preventDefault();
    if (this.state.serviceType === "") {
      this.setState({
        errorMessage: "Please select the category",
        errorType: "danger"
      });
    } else if (this.state.selected.length === 0) {
      this.setState({
        errorMessage: "Please select the services",
        errorType: "danger"
      });
    } else {
      let serviceIds = "";
      let th = this;
      for (let index = 0; index < this.state.selected.length; index++) {
        const element = this.state.selected[index];
        serviceIds = element.id + "," + serviceIds;
      }
      let serviceTypeName = "";
      let index = this.state.categories.findIndex(
        x => x.id === Number(this.state.serviceType)
      );
      if (index > -1) {
        serviceTypeName = this.state.categories[index].service_type_name;
        fetch(Constant.apiURl + "save-service", {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            service_type_id: th.state.serviceType,
            auth_token: getCookie("token"),
            services: this.state.selected,
            location_id: th.props.match.params.id
          })
        })
          .then(response => response.json())
          .then(myJson => {
            if (myJson.success === "1") {
              th.props.history.push("/healer-services-list");
              th.setState({
                errorMessage: "",
                errorType: ""
              });
            }
          });
      }
    }
  };
  render() {
    return (
      <main>
        <div className="login-sec">
          <div className="container">
            <div className="profile-panel">
              <h1>What services are you qualified to provide?</h1>
              <strong className="sub-heading">
                Select the services you will be providing from{" "}
                <span className="font-red">Nicole’s Acupuncture Clinic:</span>
              </strong>
              <form>
                <div className="row">
                  <div className="col-xs-12 col-sm-7">
                    <div className="profile-form">
                      <div className="row">
                        <div className="col-xs-12 col-sm-6">
                          <div className="form-group">
                            <select
                              className="form-control select-icon"
                              onChange={e =>
                                this.getServicesList(e.target.value)
                              }
                              id="exampleSelect1"
                            >
                              <option>Services category/ Type</option>
                              {this.state.categories.map((item, index) => {
                                return (
                                  <option
                                    key={index}
                                    value={item.id}
                                    selected={
                                      item.id === this.state.serviceType
                                    }
                                  >
                                    {item.service_type_name}
                                  </option>
                                );
                              })}
                            </select>
                            <div className="dropdown-arrow">
                              <img
                                src={require("../../../../img/icon/down-arrow.png")}
                                alt="icon"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-5">
                    <div className="dragdrop-box">
                      <ul>
                        {this.state.services.map((item, index) => {
                          return (
                            <li
                              className={
                                this.state.preselected.findIndex(
                                  x => x.id === item.id
                                ) > -1
                                  ? "active"
                                  : ""
                              }
                              onClick={() => this.preselectService(item)}
                            >
                              <a
                                className={
                                  this.state.selectServiceType !== ""
                                    ? this.state.selectServiceType === 1 &&
                                      item.is_general === 1
                                      ? ""
                                      : this.state.selectServiceType === 2 &&
                                        item.is_homebased === 1
                                      ? ""
                                      : this.state.selectServiceType === 3 &&
                                        item.is_classtype === 1
                                      ? ""
                                      : "disabled"
                                    : ""
                                }
                              >
                                <i className="fa fa-angle-right" />
                                {item.service_name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-2">
                    <div className="select-unselect-btn">
                      <button
                        className="btn btn-md btn-back ripple"
                        onClick={e => this.selectService(e)}
                      >
                        Select <i className="fa fa-angle-right" />{" "}
                      </button>
                      <button
                        className="btn btn-md btn-save-submit ripple"
                        onClick={e => this.deselectService(e)}
                      >
                        <i className="fa fa-angle-left" /> Unselect
                      </button>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-5">
                    <div className="dragdrop-box">
                      <ul>
                        {this.state.selected.map((item, index) => {
                          return (
                            <li
                              className={
                                this.state.predeselect.length > 0 &&
                                this.state.predeselect.findIndex(
                                  x => x.id === item.id
                                ) > -1
                                  ? "deactive"
                                  : ""
                              }
                              onClick={() => this.predeselectService(item)}
                            >
                              <a>
                                {" "}
                                <i className="fa fa-angle-right" />
                                {item.service_name}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </div>
                {this.state.errorMessage !== "" &&
                this.state.errorType !== "" ? (
                  <div className={"alert alert-danger"}>
                    {this.state.errorMessage}
                  </div>
                ) : (
                  ""
                )}
                <div className="text-btn">
                  <button
                    className="btn btn-md btn-pink ripple"
                    onClick={() =>
                      this.props.history.push("/healer-services-list")
                    }
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-md btn-save-submit ripple"
                    onClick={e => this.save(e)}
                  >
                    Save & Continue
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logInAction: (token, email, userId, userRole) =>
    dispatch(logInAction(token, email, userId, userRole))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CategoriesList);
