export const ApiRejected = 'API_CALL_REJECTED';
export const ApiFulfilled = 'API_CALL_FULFILLED';
export const ApiRequested = 'API_CALL_REQUESTED';
export const ApiClear = 'API_CALL_CLEAR';

export function ApiRejectedAction(data) {
    return {
        type: ApiRejected,
        payload: { apiCallFor: data.apiCallFor, isCompleted: true, isFailed: true, message: data.message }
    }
}
export function ApiRequestedAction(data,message) {
    return {
        type: ApiRequested,
        payload: { apiCallFor: data.apiCallFor, isCompleted: false, isFailed: false, message: data.message && data.message !== ''? data.message :'' }
    }
}

export function ApiFulfilledAction(data) {
    if(data.apiCallFor == "signUp" || data.apiCallFor == "healerSignUp"){
        return {
            type: ApiFulfilled,
            payload: { apiCallFor:data.apiCallFor, isCompleted: true, isFailed: false, message: data.message,response :data.response }
        };
    }
    if(data.apiCallFor == "geAvalaiablity" || data.apiCallFor == "getBookedUserProfile" || data.apiCallFor == "getProfileData"){
        return {
            type: ApiFulfilled,
            payload: { apiCallFor:data.apiCallFor, isCompleted: true, isFailed: false, message: data.message,response :data.response }
        };
    }
    else{
        return {
            type: ApiFulfilled,
            payload: { apiCallFor:data.apiCallFor, isCompleted: true, isFailed: false, message: data.message,response :"" }
        };
    }
  
}
export function ApiClearAction() {
    return {
        type: ApiClear,
        payload: { apiCallFor:"", isCompleted: true, isFailed: false, message: "" }
    };
}
