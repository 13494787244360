import React from "react";
import { connect } from "react-redux";
import SubBanner from "./../../../Common/Components/healerBanner";
import ClientBanner from "./../../../Common/Components/clientBanner";
import { Link } from "react-router-dom";
import { getCookie } from "./../../../Login&SignUp//ApiCall/cookies";
import { logInAction } from "../../../Login&SignUp/Actions/action";
import { getUserServicesData, updateLocationStatus } from "./../../Profile/ApiCalls/apicalls";
import externalFileLoad from "../../../externalfileLoad";
// import { isMyScriptLoaded } from "../../../Common/Functions/function";
import { Spinner } from "../../../Common/Components/loader";
import InfiniteScroll from 'react-infinite-scroll-component';
import "../../../Login&SignUp/Css/custom-healmaker.css"
import loader from '../../../../img/Spinner-1s-200px.gif';
// import { cloneDeep } from "@babel/types";
class LocationList extends React.Component {
  constructor() {
    super();
    this.state = {
      isMapLoaded: false,
      page: 1,
      locationServices: [],
      isLoader: false,
      isHasMore: true,
      userRole: 2
    }
  }
  componentDidMount() {
    externalFileLoad();
    let token = getCookie("token");
    let email = getCookie("user_email");
    let userId = getCookie("user_id");
    let userRole = getCookie("user_Role");
    let hasUnacceptedAgreements = getCookie('hasUnacceptedAgreements');
    if (token === "") {
      this.props.history.push("/login");
    } else {
      this.props.logInAction(token, email, userId, userRole);
      if (hasUnacceptedAgreements === '1') {
        this.props.history.push('/verify-service-document')
      }
    }
    window.scrollTo(0, 0)
    this.setState({
      userRole
    })
    this.props.getUserServicesData(token, userId, this.state.page);

  }
  componentWillReceiveProps(nextProps) {
    // if (this.state.page == 1) {
    //   window.scrollTo(0, 0)
    // }

    if (nextProps.profileReducer.services.length > 0 && this.state.isMapLoaded === false) {
      let th = this;
      th.setState({
        isMapLoaded: true
      })
      setTimeout(function () {
        window.scrollTo(0, 0)
        th.markLocationOnMap();
      }, 2000)
    }
    if (nextProps.apiCallStatus.apiCallFor === 'getUserServicesData' &&
      nextProps.apiCallStatus.isCompleted === true &&
      nextProps.apiCallStatus.isFailed === false) {
        
        
      let services = [...this.state.locationServices];
      if (services.length > 0) {
        let arr = [];
        if (nextProps.profileReducer.services.current_page != nextProps.profileReducer.services.last_page) {
          if(nextProps.profileReducer.services.data.length == 0){
            this.setState({
              isLoader: false,
              isHasMore: false
            })
          }
          arr = services.concat(nextProps.profileReducer.services.data);
          services = arr;
        } else {
          arr = services.concat(nextProps.profileReducer.services.data);
          services = arr;
          this.setState({
            isLoader: false,
            isHasMore: false
          })
        }
      } else {
        if(nextProps.profileReducer.services.data && nextProps.profileReducer.services.data.length > 0){
          services = nextProps.profileReducer.services.data;
        }else{
          services = nextProps.profileReducer.services.data;
          this.setState({
            isLoader: false,
            isHasMore: false
          })
        }
       

      }

      this.setState({
        locationServices: services
      }, () => {
        this.markLocationOnMap();
        console.log("locationServices", this.state.locationServices)
        console.log("nextProps.profileReducer.services", nextProps.profileReducer.services)
      })
    }
  }
  markLocationOnMap = () => {
    let th = this;
    function initMap() {
      let data = th.props.profileReducer.services.data ? th.props.profileReducer.services.data : th.props.profileReducer.services;
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        var map = new window.google.maps.Map(
          document.getElementById('map' + index), { zoom: 1, center: { lat: element.lat, lng: element.lon } });
        var marker = new window.google.maps.Marker({ position: { lat: element.lat, lng: element.lon }, map: map });
      }

    }
    initMap();
  }
  redirect = path => {
    this.props.history.push(path);
  };
  loadMoreData = () => {

    if (this.state.locationServices && this.state.locationServices.length > 0) {

      let p = this.state.page;
      let token = getCookie("token");
      let userId = getCookie("user_id");
      p += 1;
      this.setState({
        page: p,
        isLoader: true
      }, () => {
        this.props.getUserServicesData(token, userId, this.state.page);
      })
    }

  }
  updateLocationStatus = (e, token, locationId, status) => {
    e.preventDefault();
    this.props.updateLocationStatus(token, locationId, status)
  }
  render() {
    return (
      <main>
        {this.state.userRole === '1' ?
          <ClientBanner commonReducer={this.props.commonReducer} subHeader="Locations setup by" /> :
          <SubBanner commonReducer={this.props.commonReducer} subHeader="Locations setup by" />
        }
        <div className="location-sec">
          <div className="container">
            <div className="table-sec">
              <h1> {this.props.commonReducer.profile &&
                this.props.commonReducer.profile.first_name &&
                this.props.commonReducer.profile.first_name !== ""
                ? this.props.commonReducer.profile.first_name + ' Locations'
                : "Locations"}</h1>
              {this.props.apiCallStatus.isStarted.indexOf('getUserServicesData') !== -1 && this.state.page == 1 ?
                <Spinner />
                : ""}
              {this.props.apiCallStatus.isStarted.indexOf('updateLocationStatus') > -1 ?
                <Spinner />
                : ""}
              <InfiniteScroll
                dataLength={this.state.locationServices.length} //This is important field to render the next data
                next={this.loadMoreData}
                hasMore={this.state.isHasMore}
                loader={<div className="text-center" >
                  <img className="logo" style={{ width: "83px", display: 'inline' }} src={loader} /> </div>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    {this.state.locationServices && this.state.locationServices.length > 0 ? <b>Yay! You have seen it all</b> : <b>No location found</b>}
                  </p>
                }>
                {this.state.locationServices
                  ? this.state.locationServices.map((item, index) => {
                    return <div className="card-panel" key={index}>
                      <div className="locatiointop-table">
                        <div className="row">
                          <div className="col-xs-12 col-sm-6">
                            <div className="location-heading">
                              <strong>
                                {item.name}
                              </strong>
                              <ul className="feedback-star">
                                <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                                <li><a href="#"><i className="fa fa-star" aria-hidden="true"></i></a></li>
                                <li><a href="#"><span>(258)</span></a></li>
                              </ul>

                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6">
                            <div className="onoff-btn pull-right">
                              <div className="toggleWrapper ">
                                <input type="checkbox" id={"hhhh" + index}
                                  onClick={(e) => {
                                    this.updateLocationStatus(e, this.props.loginReducer.token, item.id, item.status === 1 ? 0 : 1)
                                  }}
                                  disabled={this.props.apiCallStatus.isStarted.indexOf('updateLocationStatus') > -1 ? true : false}
                                  className="dn"
                                  checked={item.status === 1 ? true : false}
                                />
                                <label htmlFor={"hhhh" + index} className="toggle"><span className="toggle__handler"></span></label>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">

                        {this.state.userRole === '2' ?
                          <div className={`col-xs-12 ${this.state.userRole == '2' ? 'col-sm-6' : 'col-sm-12'}`}>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-PL-15">
                                <div className="location-image-holder">
                                  <img src={item.images && item.images[0] && item.images[0].imgUrl ? item.images[0].imgUrl : require("../../../../img/location_placeholder.jpg")} alt="placeholder" />
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-PL-15">
                                <div className="location-image-holder">
                                  <img src={item.images && item.images[1] && item.images[1].imgUrl ? item.images[1].imgUrl : require("../../../../img/location_placeholder.jpg")} alt="placeholder" />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-xs-12 col-sm-6 col-PL-15">
                                <div className="location-image-holder">
                                  <img src={item.images && item.images[2] && item.images[2].imgUrl ? item.images[2].imgUrl : require("../../../../img/location_placeholder.jpg")} alt="placeholder" />
                                </div>
                              </div>
                              <div className="col-xs-12 col-sm-6 col-PL-15">
                                <div className="location-image-holder">
                                  <img src={item.images && item.images[3] && item.images[3].imgUrl ? item.images[3].imgUrl : require("../../../../img/location_placeholder.jpg")} alt="placeholder" />
                                </div>
                              </div>
                            </div>
                            <ul className="location_tabs">
                              <li><Link to={"/edit-location/" + item.id}>Edit</Link></li>
                              <li><Link to={"/healer-location-preview/" + item.id}>Preview</Link></li>
                              {this.state.userRole === '1' ? '' :
                                <React.Fragment>
                                  <li><a href="#">Calendar</a></li>
                                  <li><a href="#">Services & Retreats</a></li>
                                  <li><a href="#">Policy</a></li>
                                </React.Fragment>}
                            </ul>
                          </div> : ''
                        }



                        <div className="col-xs-12 col-sm-6">
                          <div className="location-maps">
                            {/* <iframe src={'https://maps.google.com/maps?q='+item.lat+','+item.lon+'&hl=es;z=14&amp;output=embed'} width="100%" height="299" frameBorder="0" style={{ border: "0" }} allowFullScreen></iframe> */}
                            <div id={"map" + index} className="map"></div>
                          </div>
                          <div className="get-addresss-sec">
                            <a  href="javascript:void(0)" >
                              <img src={require("../../../../img/map-icon2.jpg")} alt="icon" />
                              <span>{item.address1}</span>
                            </a>
                          </div>
                        </div>
                        {this.state.userRole === '1' ?
                          <div className={`col-xs-12 ${this.state.userRole == '2' ? 'col-sm-6' : 'col-sm-12'}`}>
                            <ul className="location_tabs">
                              <li><Link to={"/edit-location/" + item.id}>Edit</Link></li>
                              <li><Link to={"/healer-location-preview/" + item.id}>Preview</Link></li>
                            </ul>
                          </div> : ''
                        }

                      </div>
                    </div>
                  })
                  : ""}
              </InfiniteScroll>


            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="table-sec">
                  <div className="uploadcard-panel text-center">
                    <p><Link to="/location-manager">Do you have another location where you provide servies from? (e.g. your home, another business location)</Link></p>
                    <div onClick={() => this.redirect("/location-manager")} className="plus-image-holder mb-100">
                      <img src="img/plus-icon.jpg" alt="image" />
                    </div>
                    <Link to="/location-manager">Setup home visit services here</Link>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div className="table-sec">
                  <div className="uploadcard-panel text-center">
                    <div onClick={() => this.redirect("/location-manager")} className="plus-image-holder mtb-50">
                      <img src="img/plus-icon.jpg" alt="image" />
                    </div>
                    <p>Do you provide services at the client's home?</p>
                    <Link to="/location-manager">Setup home visit services here</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  loginReducer: state.loginReducer,
  apiCallStatus: state.apiCallStatus,
  commonReducer: state.commonReducer,
  profileReducer: state.profileReducer
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  getUserServicesData: (token, userId, page) =>
    dispatch(getUserServicesData(token, userId, page)),
  logInAction: (token, email, userId, userRole) =>
    dispatch(logInAction(token, email, userId, userRole)),
  updateLocationStatus: (token, locationId, status) => dispatch(updateLocationStatus(token, locationId, status))
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationList);
